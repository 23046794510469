@charset "UTF-8";
html {
  font-size: 10px; }

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-regular.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-regular.eot?#iefix") format("embedded-opentype"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-regular.woff2") format("woff2"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-regular.woff") format("woff"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-regular.ttf") format("truetype"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: "Open Sans";
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-italic.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-italic.eot?#iefix") format("embedded-opentype"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-italic.woff2") format("woff2"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-italic.woff") format("woff"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-italic.ttf") format("truetype"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-italic.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: "Open Sans";
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  src: url("/out/ra-theme/fonts/open-sans/open-sans-700.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-700.eot?#iefix") format("embedded-opentype"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-700.woff2") format("woff2"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-700.woff") format("woff"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-700.ttf") format("truetype"), url("/out/ra-theme/fonts/open-sans/1743584751/open-sans-700.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333em;
  line-height: 1em; }
  .flag-icon:before {
    content: '\00a0'; }

.flag-icon-ad {
  background-image: url(../../../../ra-theme/img/flags/4x3/ad.svg); }

.flag-icon-ae {
  background-image: url(../../../../ra-theme/img/flags/4x3/ae.svg); }

.flag-icon-af {
  background-image: url(../../../../ra-theme/img/flags/4x3/af.svg); }

.flag-icon-ag {
  background-image: url(../../../../ra-theme/img/flags/4x3/ag.svg); }

.flag-icon-ai {
  background-image: url(../../../../ra-theme/img/flags/4x3/ai.svg); }

.flag-icon-al {
  background-image: url(../../../../ra-theme/img/flags/4x3/al.svg); }

.flag-icon-am {
  background-image: url(../../../../ra-theme/img/flags/4x3/am.svg); }

.flag-icon-ao {
  background-image: url(../../../../ra-theme/img/flags/4x3/ao.svg); }

.flag-icon-aq {
  background-image: url(../../../../ra-theme/img/flags/4x3/aq.svg); }

.flag-icon-ar {
  background-image: url(../../../../ra-theme/img/flags/4x3/ar.svg); }

.flag-icon-as {
  background-image: url(../../../../ra-theme/img/flags/4x3/as.svg); }

.flag-icon-at {
  background-image: url(../../../../ra-theme/img/flags/4x3/at.svg); }

.flag-icon-au {
  background-image: url(../../../../ra-theme/img/flags/4x3/au.svg); }

.flag-icon-aw {
  background-image: url(../../../../ra-theme/img/flags/4x3/aw.svg); }

.flag-icon-ax {
  background-image: url(../../../../ra-theme/img/flags/4x3/ax.svg); }

.flag-icon-az {
  background-image: url(../../../../ra-theme/img/flags/4x3/az.svg); }

.flag-icon-ba {
  background-image: url(../../../../ra-theme/img/flags/4x3/ba.svg); }

.flag-icon-bb {
  background-image: url(../../../../ra-theme/img/flags/4x3/bb.svg); }

.flag-icon-bd {
  background-image: url(../../../../ra-theme/img/flags/4x3/bd.svg); }

.flag-icon-be {
  background-image: url(../../../../ra-theme/img/flags/4x3/be.svg); }

.flag-icon-bf {
  background-image: url(../../../../ra-theme/img/flags/4x3/bf.svg); }

.flag-icon-bg {
  background-image: url(../../../../ra-theme/img/flags/4x3/bg.svg); }

.flag-icon-bh {
  background-image: url(../../../../ra-theme/img/flags/4x3/bh.svg); }

.flag-icon-bi {
  background-image: url(../../../../ra-theme/img/flags/4x3/bi.svg); }

.flag-icon-bj {
  background-image: url(../../../../ra-theme/img/flags/4x3/bj.svg); }

.flag-icon-bl {
  background-image: url(../../../../ra-theme/img/flags/4x3/bl.svg); }

.flag-icon-bm {
  background-image: url(../../../../ra-theme/img/flags/4x3/bm.svg); }

.flag-icon-bn {
  background-image: url(../../../../ra-theme/img/flags/4x3/bn.svg); }

.flag-icon-bo {
  background-image: url(../../../../ra-theme/img/flags/4x3/bo.svg); }

.flag-icon-bq {
  background-image: url(../../../../ra-theme/img/flags/4x3/bq.svg); }

.flag-icon-br {
  background-image: url(../../../../ra-theme/img/flags/4x3/br.svg); }

.flag-icon-bs {
  background-image: url(../../../../ra-theme/img/flags/4x3/bs.svg); }

.flag-icon-bt {
  background-image: url(../../../../ra-theme/img/flags/4x3/bt.svg); }

.flag-icon-bv {
  background-image: url(../../../../ra-theme/img/flags/4x3/bv.svg); }

.flag-icon-bw {
  background-image: url(../../../../ra-theme/img/flags/4x3/bw.svg); }

.flag-icon-by {
  background-image: url(../../../../ra-theme/img/flags/4x3/by.svg); }

.flag-icon-bz {
  background-image: url(../../../../ra-theme/img/flags/4x3/bz.svg); }

.flag-icon-ca {
  background-image: url(../../../../ra-theme/img/flags/4x3/ca.svg); }

.flag-icon-cc {
  background-image: url(../../../../ra-theme/img/flags/4x3/cc.svg); }

.flag-icon-cd {
  background-image: url(../../../../ra-theme/img/flags/4x3/cd.svg); }

.flag-icon-cf {
  background-image: url(../../../../ra-theme/img/flags/4x3/cf.svg); }

.flag-icon-cg {
  background-image: url(../../../../ra-theme/img/flags/4x3/cg.svg); }

.flag-icon-ch {
  background-image: url(../../../../ra-theme/img/flags/4x3/ch.svg); }

.flag-icon-ci {
  background-image: url(../../../../ra-theme/img/flags/4x3/ci.svg); }

.flag-icon-ck {
  background-image: url(../../../../ra-theme/img/flags/4x3/ck.svg); }

.flag-icon-cl {
  background-image: url(../../../../ra-theme/img/flags/4x3/cl.svg); }

.flag-icon-cm {
  background-image: url(../../../../ra-theme/img/flags/4x3/cm.svg); }

.flag-icon-cn {
  background-image: url(../../../../ra-theme/img/flags/4x3/cn.svg); }

.flag-icon-co {
  background-image: url(../../../../ra-theme/img/flags/4x3/co.svg); }

.flag-icon-cr {
  background-image: url(../../../../ra-theme/img/flags/4x3/cr.svg); }

.flag-icon-cu {
  background-image: url(../../../../ra-theme/img/flags/4x3/cu.svg); }

.flag-icon-cv {
  background-image: url(../../../../ra-theme/img/flags/4x3/cv.svg); }

.flag-icon-cw {
  background-image: url(../../../../ra-theme/img/flags/4x3/cw.svg); }

.flag-icon-cx {
  background-image: url(../../../../ra-theme/img/flags/4x3/cx.svg); }

.flag-icon-cy {
  background-image: url(../../../../ra-theme/img/flags/4x3/cy.svg); }

.flag-icon-cz {
  background-image: url(../../../../ra-theme/img/flags/4x3/cz.svg); }

.flag-icon-de {
  background-image: url(../../../../ra-theme/img/flags/4x3/de.svg); }

.flag-icon-dj {
  background-image: url(../../../../ra-theme/img/flags/4x3/dj.svg); }

.flag-icon-dk {
  background-image: url(../../../../ra-theme/img/flags/4x3/dk.svg); }

.flag-icon-dm {
  background-image: url(../../../../ra-theme/img/flags/4x3/dm.svg); }

.flag-icon-do {
  background-image: url(../../../../ra-theme/img/flags/4x3/do.svg); }

.flag-icon-dz {
  background-image: url(../../../../ra-theme/img/flags/4x3/dz.svg); }

.flag-icon-ec {
  background-image: url(../../../../ra-theme/img/flags/4x3/ec.svg); }

.flag-icon-ee {
  background-image: url(../../../../ra-theme/img/flags/4x3/ee.svg); }

.flag-icon-eg {
  background-image: url(../../../../ra-theme/img/flags/4x3/eg.svg); }

.flag-icon-eh {
  background-image: url(../../../../ra-theme/img/flags/4x3/eh.svg); }

.flag-icon-el {
  background-image: url(../../../../ra-theme/img/flags/4x3/el.svg); }

.flag-icon-en {
  background-image: url(../../../../ra-theme/img/flags/4x3/en.svg); }

.flag-icon-er {
  background-image: url(../../../../ra-theme/img/flags/4x3/er.svg); }

.flag-icon-es {
  background-image: url(../../../../ra-theme/img/flags/4x3/es.svg); }

.flag-icon-et {
  background-image: url(../../../../ra-theme/img/flags/4x3/et.svg); }

.flag-icon-fi {
  background-image: url(../../../../ra-theme/img/flags/4x3/fi.svg); }

.flag-icon-fj {
  background-image: url(../../../../ra-theme/img/flags/4x3/fj.svg); }

.flag-icon-fk {
  background-image: url(../../../../ra-theme/img/flags/4x3/fk.svg); }

.flag-icon-fm {
  background-image: url(../../../../ra-theme/img/flags/4x3/fm.svg); }

.flag-icon-fo {
  background-image: url(../../../../ra-theme/img/flags/4x3/fo.svg); }

.flag-icon-fr {
  background-image: url(../../../../ra-theme/img/flags/4x3/fr.svg); }

.flag-icon-ga {
  background-image: url(../../../../ra-theme/img/flags/4x3/ga.svg); }

.flag-icon-gb {
  background-image: url(../../../../ra-theme/img/flags/4x3/gb.svg); }

.flag-icon-gd {
  background-image: url(../../../../ra-theme/img/flags/4x3/gd.svg); }

.flag-icon-ge {
  background-image: url(../../../../ra-theme/img/flags/4x3/ge.svg); }

.flag-icon-gf {
  background-image: url(../../../../ra-theme/img/flags/4x3/gf.svg); }

.flag-icon-gg {
  background-image: url(../../../../ra-theme/img/flags/4x3/gg.svg); }

.flag-icon-gh {
  background-image: url(../../../../ra-theme/img/flags/4x3/gh.svg); }

.flag-icon-gi {
  background-image: url(../../../../ra-theme/img/flags/4x3/gi.svg); }

.flag-icon-gl {
  background-image: url(../../../../ra-theme/img/flags/4x3/gl.svg); }

.flag-icon-gm {
  background-image: url(../../../../ra-theme/img/flags/4x3/gm.svg); }

.flag-icon-gn {
  background-image: url(../../../../ra-theme/img/flags/4x3/gn.svg); }

.flag-icon-gp {
  background-image: url(../../../../ra-theme/img/flags/4x3/gp.svg); }

.flag-icon-gq {
  background-image: url(../../../../ra-theme/img/flags/4x3/gq.svg); }

.flag-icon-gr {
  background-image: url(../../../../ra-theme/img/flags/4x3/gr.svg); }

.flag-icon-gs {
  background-image: url(../../../../ra-theme/img/flags/4x3/gs.svg); }

.flag-icon-gt {
  background-image: url(../../../../ra-theme/img/flags/4x3/gt.svg); }

.flag-icon-gu {
  background-image: url(../../../../ra-theme/img/flags/4x3/gu.svg); }

.flag-icon-gw {
  background-image: url(../../../../ra-theme/img/flags/4x3/gw.svg); }

.flag-icon-gy {
  background-image: url(../../../../ra-theme/img/flags/4x3/gy.svg); }

.flag-icon-hk {
  background-image: url(../../../../ra-theme/img/flags/4x3/hk.svg); }

.flag-icon-hm {
  background-image: url(../../../../ra-theme/img/flags/4x3/hm.svg); }

.flag-icon-hn {
  background-image: url(../../../../ra-theme/img/flags/4x3/hn.svg); }

.flag-icon-hr {
  background-image: url(../../../../ra-theme/img/flags/4x3/hr.svg); }

.flag-icon-ht {
  background-image: url(../../../../ra-theme/img/flags/4x3/ht.svg); }

.flag-icon-hu {
  background-image: url(../../../../ra-theme/img/flags/4x3/hu.svg); }

.flag-icon-id {
  background-image: url(../../../../ra-theme/img/flags/4x3/id.svg); }

.flag-icon-ie {
  background-image: url(../../../../ra-theme/img/flags/4x3/ie.svg); }

.flag-icon-il {
  background-image: url(../../../../ra-theme/img/flags/4x3/il.svg); }

.flag-icon-im {
  background-image: url(../../../../ra-theme/img/flags/4x3/im.svg); }

.flag-icon-in {
  background-image: url(../../../../ra-theme/img/flags/4x3/in.svg); }

.flag-icon-io {
  background-image: url(../../../../ra-theme/img/flags/4x3/io.svg); }

.flag-icon-iq {
  background-image: url(../../../../ra-theme/img/flags/4x3/iq.svg); }

.flag-icon-ir {
  background-image: url(../../../../ra-theme/img/flags/4x3/ir.svg); }

.flag-icon-is {
  background-image: url(../../../../ra-theme/img/flags/4x3/is.svg); }

.flag-icon-it {
  background-image: url(../../../../ra-theme/img/flags/4x3/it.svg); }

.flag-icon-je {
  background-image: url(../../../../ra-theme/img/flags/4x3/je.svg); }

.flag-icon-jm {
  background-image: url(../../../../ra-theme/img/flags/4x3/jm.svg); }

.flag-icon-jo {
  background-image: url(../../../../ra-theme/img/flags/4x3/jo.svg); }

.flag-icon-jp {
  background-image: url(../../../../ra-theme/img/flags/4x3/jp.svg); }

.flag-icon-ke {
  background-image: url(../../../../ra-theme/img/flags/4x3/ke.svg); }

.flag-icon-kg {
  background-image: url(../../../../ra-theme/img/flags/4x3/kg.svg); }

.flag-icon-kh {
  background-image: url(../../../../ra-theme/img/flags/4x3/kh.svg); }

.flag-icon-ki {
  background-image: url(../../../../ra-theme/img/flags/4x3/ki.svg); }

.flag-icon-km {
  background-image: url(../../../../ra-theme/img/flags/4x3/km.svg); }

.flag-icon-kn {
  background-image: url(../../../../ra-theme/img/flags/4x3/kn.svg); }

.flag-icon-kp {
  background-image: url(../../../../ra-theme/img/flags/4x3/kp.svg); }

.flag-icon-kr {
  background-image: url(../../../../ra-theme/img/flags/4x3/kr.svg); }

.flag-icon-kw {
  background-image: url(../../../../ra-theme/img/flags/4x3/kw.svg); }

.flag-icon-ky {
  background-image: url(../../../../ra-theme/img/flags/4x3/ky.svg); }

.flag-icon-kz {
  background-image: url(../../../../ra-theme/img/flags/4x3/kz.svg); }

.flag-icon-la {
  background-image: url(../../../../ra-theme/img/flags/4x3/la.svg); }

.flag-icon-lb {
  background-image: url(../../../../ra-theme/img/flags/4x3/lb.svg); }

.flag-icon-lc {
  background-image: url(../../../../ra-theme/img/flags/4x3/lc.svg); }

.flag-icon-li {
  background-image: url(../../../../ra-theme/img/flags/4x3/li.svg); }

.flag-icon-lk {
  background-image: url(../../../../ra-theme/img/flags/4x3/lk.svg); }

.flag-icon-lr {
  background-image: url(../../../../ra-theme/img/flags/4x3/lr.svg); }

.flag-icon-ls {
  background-image: url(../../../../ra-theme/img/flags/4x3/ls.svg); }

.flag-icon-lt {
  background-image: url(../../../../ra-theme/img/flags/4x3/lt.svg); }

.flag-icon-lu {
  background-image: url(../../../../ra-theme/img/flags/4x3/lu.svg); }

.flag-icon-lv {
  background-image: url(../../../../ra-theme/img/flags/4x3/lv.svg); }

.flag-icon-ly {
  background-image: url(../../../../ra-theme/img/flags/4x3/ly.svg); }

.flag-icon-ma {
  background-image: url(../../../../ra-theme/img/flags/4x3/ma.svg); }

.flag-icon-mc {
  background-image: url(../../../../ra-theme/img/flags/4x3/mc.svg); }

.flag-icon-md {
  background-image: url(../../../../ra-theme/img/flags/4x3/md.svg); }

.flag-icon-me {
  background-image: url(../../../../ra-theme/img/flags/4x3/me.svg); }

.flag-icon-mf {
  background-image: url(../../../../ra-theme/img/flags/4x3/mf.svg); }

.flag-icon-mg {
  background-image: url(../../../../ra-theme/img/flags/4x3/mg.svg); }

.flag-icon-mh {
  background-image: url(../../../../ra-theme/img/flags/4x3/mh.svg); }

.flag-icon-mk {
  background-image: url(../../../../ra-theme/img/flags/4x3/mk.svg); }

.flag-icon-ml {
  background-image: url(../../../../ra-theme/img/flags/4x3/ml.svg); }

.flag-icon-mm {
  background-image: url(../../../../ra-theme/img/flags/4x3/mm.svg); }

.flag-icon-mn {
  background-image: url(../../../../ra-theme/img/flags/4x3/mn.svg); }

.flag-icon-mo {
  background-image: url(../../../../ra-theme/img/flags/4x3/mo.svg); }

.flag-icon-mp {
  background-image: url(../../../../ra-theme/img/flags/4x3/mp.svg); }

.flag-icon-mq {
  background-image: url(../../../../ra-theme/img/flags/4x3/mq.svg); }

.flag-icon-mr {
  background-image: url(../../../../ra-theme/img/flags/4x3/mr.svg); }

.flag-icon-ms {
  background-image: url(../../../../ra-theme/img/flags/4x3/ms.svg); }

.flag-icon-mt {
  background-image: url(../../../../ra-theme/img/flags/4x3/mt.svg); }

.flag-icon-mu {
  background-image: url(../../../../ra-theme/img/flags/4x3/mu.svg); }

.flag-icon-mv {
  background-image: url(../../../../ra-theme/img/flags/4x3/mv.svg); }

.flag-icon-mw {
  background-image: url(../../../../ra-theme/img/flags/4x3/mw.svg); }

.flag-icon-mx {
  background-image: url(../../../../ra-theme/img/flags/4x3/mx.svg); }

.flag-icon-my {
  background-image: url(../../../../ra-theme/img/flags/4x3/my.svg); }

.flag-icon-mz {
  background-image: url(../../../../ra-theme/img/flags/4x3/mz.svg); }

.flag-icon-na {
  background-image: url(../../../../ra-theme/img/flags/4x3/na.svg); }

.flag-icon-nc {
  background-image: url(../../../../ra-theme/img/flags/4x3/nc.svg); }

.flag-icon-ne {
  background-image: url(../../../../ra-theme/img/flags/4x3/ne.svg); }

.flag-icon-nf {
  background-image: url(../../../../ra-theme/img/flags/4x3/nf.svg); }

.flag-icon-ng {
  background-image: url(../../../../ra-theme/img/flags/4x3/ng.svg); }

.flag-icon-ni {
  background-image: url(../../../../ra-theme/img/flags/4x3/ni.svg); }

.flag-icon-nl {
  background-image: url(../../../../ra-theme/img/flags/4x3/nl.svg); }

.flag-icon-no {
  background-image: url(../../../../ra-theme/img/flags/4x3/no.svg); }

.flag-icon-np {
  background-image: url(../../../../ra-theme/img/flags/4x3/np.svg); }

.flag-icon-nr {
  background-image: url(../../../../ra-theme/img/flags/4x3/nr.svg); }

.flag-icon-nu {
  background-image: url(../../../../ra-theme/img/flags/4x3/nu.svg); }

.flag-icon-nz {
  background-image: url(../../../../ra-theme/img/flags/4x3/nz.svg); }

.flag-icon-om {
  background-image: url(../../../../ra-theme/img/flags/4x3/om.svg); }

.flag-icon-pa {
  background-image: url(../../../../ra-theme/img/flags/4x3/pa.svg); }

.flag-icon-pe {
  background-image: url(../../../../ra-theme/img/flags/4x3/pe.svg); }

.flag-icon-pf {
  background-image: url(../../../../ra-theme/img/flags/4x3/pf.svg); }

.flag-icon-pg {
  background-image: url(../../../../ra-theme/img/flags/4x3/pg.svg); }

.flag-icon-ph {
  background-image: url(../../../../ra-theme/img/flags/4x3/ph.svg); }

.flag-icon-pk {
  background-image: url(../../../../ra-theme/img/flags/4x3/pk.svg); }

.flag-icon-pl {
  background-image: url(../../../../ra-theme/img/flags/4x3/pl.svg); }

.flag-icon-pm {
  background-image: url(../../../../ra-theme/img/flags/4x3/pm.svg); }

.flag-icon-pn {
  background-image: url(../../../../ra-theme/img/flags/4x3/pn.svg); }

.flag-icon-pr {
  background-image: url(../../../../ra-theme/img/flags/4x3/pr.svg); }

.flag-icon-ps {
  background-image: url(../../../../ra-theme/img/flags/4x3/ps.svg); }

.flag-icon-pt {
  background-image: url(../../../../ra-theme/img/flags/4x3/pt.svg); }

.flag-icon-pw {
  background-image: url(../../../../ra-theme/img/flags/4x3/pw.svg); }

.flag-icon-py {
  background-image: url(../../../../ra-theme/img/flags/4x3/py.svg); }

.flag-icon-qa {
  background-image: url(../../../../ra-theme/img/flags/4x3/qa.svg); }

.flag-icon-re {
  background-image: url(../../../../ra-theme/img/flags/4x3/re.svg); }

.flag-icon-ro {
  background-image: url(../../../../ra-theme/img/flags/4x3/ro.svg); }

.flag-icon-rs {
  background-image: url(../../../../ra-theme/img/flags/4x3/rs.svg); }

.flag-icon-ru {
  background-image: url(../../../../ra-theme/img/flags/4x3/ru.svg); }

.flag-icon-rw {
  background-image: url(../../../../ra-theme/img/flags/4x3/rw.svg); }

.flag-icon-sa {
  background-image: url(../../../../ra-theme/img/flags/4x3/sa.svg); }

.flag-icon-sb {
  background-image: url(../../../../ra-theme/img/flags/4x3/sb.svg); }

.flag-icon-sc {
  background-image: url(../../../../ra-theme/img/flags/4x3/sc.svg); }

.flag-icon-sd {
  background-image: url(../../../../ra-theme/img/flags/4x3/sd.svg); }

.flag-icon-se {
  background-image: url(../../../../ra-theme/img/flags/4x3/se.svg); }

.flag-icon-sg {
  background-image: url(../../../../ra-theme/img/flags/4x3/sg.svg); }

.flag-icon-sh {
  background-image: url(../../../../ra-theme/img/flags/4x3/sh.svg); }

.flag-icon-si {
  background-image: url(../../../../ra-theme/img/flags/4x3/si.svg); }

.flag-icon-sj {
  background-image: url(../../../../ra-theme/img/flags/4x3/sj.svg); }

.flag-icon-sk {
  background-image: url(../../../../ra-theme/img/flags/4x3/sk.svg); }

.flag-icon-sl {
  background-image: url(../../../../ra-theme/img/flags/4x3/sl.svg); }

.flag-icon-sm {
  background-image: url(../../../../ra-theme/img/flags/4x3/sm.svg); }

.flag-icon-sn {
  background-image: url(../../../../ra-theme/img/flags/4x3/sn.svg); }

.flag-icon-so {
  background-image: url(../../../../ra-theme/img/flags/4x3/so.svg); }

.flag-icon-sr {
  background-image: url(../../../../ra-theme/img/flags/4x3/sr.svg); }

.flag-icon-ss {
  background-image: url(../../../../ra-theme/img/flags/4x3/ss.svg); }

.flag-icon-st {
  background-image: url(../../../../ra-theme/img/flags/4x3/st.svg); }

.flag-icon-sv {
  background-image: url(../../../../ra-theme/img/flags/4x3/sv.svg); }

.flag-icon-sx {
  background-image: url(../../../../ra-theme/img/flags/4x3/sx.svg); }

.flag-icon-sy {
  background-image: url(../../../../ra-theme/img/flags/4x3/sy.svg); }

.flag-icon-sz {
  background-image: url(../../../../ra-theme/img/flags/4x3/sz.svg); }

.flag-icon-tc {
  background-image: url(../../../../ra-theme/img/flags/4x3/tc.svg); }

.flag-icon-td {
  background-image: url(../../../../ra-theme/img/flags/4x3/td.svg); }

.flag-icon-tf {
  background-image: url(../../../../ra-theme/img/flags/4x3/tf.svg); }

.flag-icon-tg {
  background-image: url(../../../../ra-theme/img/flags/4x3/tg.svg); }

.flag-icon-th {
  background-image: url(../../../../ra-theme/img/flags/4x3/th.svg); }

.flag-icon-tj {
  background-image: url(../../../../ra-theme/img/flags/4x3/tj.svg); }

.flag-icon-tk {
  background-image: url(../../../../ra-theme/img/flags/4x3/tk.svg); }

.flag-icon-tl {
  background-image: url(../../../../ra-theme/img/flags/4x3/tl.svg); }

.flag-icon-tm {
  background-image: url(../../../../ra-theme/img/flags/4x3/tm.svg); }

.flag-icon-tn {
  background-image: url(../../../../ra-theme/img/flags/4x3/tn.svg); }

.flag-icon-to {
  background-image: url(../../../../ra-theme/img/flags/4x3/to.svg); }

.flag-icon-tr {
  background-image: url(../../../../ra-theme/img/flags/4x3/tr.svg); }

.flag-icon-tt {
  background-image: url(../../../../ra-theme/img/flags/4x3/tt.svg); }

.flag-icon-tv {
  background-image: url(../../../../ra-theme/img/flags/4x3/tv.svg); }

.flag-icon-tw {
  background-image: url(../../../../ra-theme/img/flags/4x3/tw.svg); }

.flag-icon-tz {
  background-image: url(../../../../ra-theme/img/flags/4x3/tz.svg); }

.flag-icon-ua {
  background-image: url(../../../../ra-theme/img/flags/4x3/ua.svg); }

.flag-icon-ug {
  background-image: url(../../../../ra-theme/img/flags/4x3/ug.svg); }

.flag-icon-uk {
  background-image: url(../../../../ra-theme/img/flags/4x3/uk.svg); }

.flag-icon-um {
  background-image: url(../../../../ra-theme/img/flags/4x3/um.svg); }

.flag-icon-us {
  background-image: url(../../../../ra-theme/img/flags/4x3/us.svg); }

.flag-icon-uy {
  background-image: url(../../../../ra-theme/img/flags/4x3/uy.svg); }

.flag-icon-uz {
  background-image: url(../../../../ra-theme/img/flags/4x3/uz.svg); }

.flag-icon-va {
  background-image: url(../../../../ra-theme/img/flags/4x3/va.svg); }

.flag-icon-vc {
  background-image: url(../../../../ra-theme/img/flags/4x3/vc.svg); }

.flag-icon-ve {
  background-image: url(../../../../ra-theme/img/flags/4x3/ve.svg); }

.flag-icon-vg {
  background-image: url(../../../../ra-theme/img/flags/4x3/vg.svg); }

.flag-icon-vi {
  background-image: url(../../../../ra-theme/img/flags/4x3/vi.svg); }

.flag-icon-vn {
  background-image: url(../../../../ra-theme/img/flags/4x3/vn.svg); }

.flag-icon-vu {
  background-image: url(../../../../ra-theme/img/flags/4x3/vu.svg); }

.flag-icon-wf {
  background-image: url(../../../../ra-theme/img/flags/4x3/wf.svg); }

.flag-icon-ws {
  background-image: url(../../../../ra-theme/img/flags/4x3/ws.svg); }

.flag-icon-ye {
  background-image: url(../../../../ra-theme/img/flags/4x3/ye.svg); }

.flag-icon-yt {
  background-image: url(../../../../ra-theme/img/flags/4x3/yt.svg); }

.flag-icon-za {
  background-image: url(../../../../ra-theme/img/flags/4x3/za.svg); }

.flag-icon-zm {
  background-image: url(../../../../ra-theme/img/flags/4x3/zm.svg); }

.flag-icon-zw {
  background-image: url(../../../../ra-theme/img/flags/4x3/zw.svg); }

.flag-icon-es-ct {
  background-image: url(../../../../ra-theme/img/flags/4x3/es-ct.svg); }

.flag-icon-eu {
  background-image: url(../../../../ra-theme/img/flags/4x3/eu.svg); }

.flag-icon-gb-eng {
  background-image: url(../../../../ra-theme/img/flags/4x3/gb-eng.svg); }

.flag-icon-gb-nir {
  background-image: url(../../../../ra-theme/img/flags/4x3/gb-nir.svg); }

.flag-icon-gb-sct {
  background-image: url(../../../../ra-theme/img/flags/4x3/gb-sct.svg); }

.flag-icon-gb-wls {
  background-image: url(../../../../ra-theme/img/flags/4x3/gb-wls.svg); }

.flag-icon-un {
  background-image: url(../../../../ra-theme/img/flags/4x3/un.svg); }

.flag-icon-xk {
  background-image: url(../../../../ra-theme/img/flags/4x3/xk.svg); }

.energy-label-outer {
  display: inline-block;
  margin-top: 2rem; }
  .energy-label-outer.small {
    margin-top: 0;
    overflow: hidden; }
    .energy-label-outer.small.article-box {
      margin-top: -5px; }
  .energy-label-outer .energy-label-inner {
    display: inline-block; }
    .energy-label-outer .energy-label-inner .energy-label {
      display: flex; }
      .energy-label-outer .energy-label-inner .energy-label.small .label {
        font-size: 11px;
        width: 13px;
        height: 25.5px; }
      .energy-label-outer .energy-label-inner .energy-label.small .range {
        font-size: 6px;
        height: 25.5px; }
      .energy-label-outer .energy-label-inner .energy-label.small .arrow {
        width: 14px; }
        .energy-label-outer .energy-label-inner .energy-label.small .arrow .arrow-inner {
          height: 19px;
          width: 19px;
          top: 3px; }
      .energy-label-outer .energy-label-inner .energy-label.small.left .arrow-inner {
        left: 5px; }
      .energy-label-outer .energy-label-inner .energy-label.small.right .label {
        text-align: right; }
      .energy-label-outer .energy-label-inner .energy-label.small.right .arrow-inner {
        right: 5px; }
      .energy-label-outer .energy-label-inner .energy-label.small .range svg {
        width: 3.5px;
        height: 5.5px; }
      .energy-label-outer .energy-label-inner .energy-label.right .range {
        border-left: 1px solid #000000; }
      .energy-label-outer .energy-label-inner .energy-label.right .arrow-inner {
        right: 5px; }
      .energy-label-outer .energy-label-inner .energy-label.left .range {
        border-right: 1px solid #000000; }
      .energy-label-outer .energy-label-inner .energy-label.left .arrow-inner {
        left: 5px; }
      .energy-label-outer .energy-label-inner .energy-label .range {
        border-top: 1px solid #000000;
        border-bottom: 1px solid #000000;
        text-align: center;
        font-size: 11px;
        width: 12px;
        background-color: #fff;
        height: 40.95px; }
        .energy-label-outer .energy-label-inner .energy-label .range svg {
          width: 5px;
          height: 6px;
          display: block;
          margin: 0 auto; }
      .energy-label-outer .energy-label-inner .energy-label .label {
        display: table;
        border-collapse: initial;
        font-size: 40px;
        color: #fff;
        font-weight: bold;
        line-height: 0;
        padding: 0 5px;
        height: 40.95px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000; }
        .energy-label-outer .energy-label-inner .energy-label .label span {
          display: table-cell;
          vertical-align: middle;
          text-shadow: 0 0 2px #000; }
      .energy-label-outer .energy-label-inner .energy-label .arrow {
        position: relative;
        overflow: hidden;
        width: 20px; }
        .energy-label-outer .energy-label-inner .energy-label .arrow .arrow-inner {
          position: absolute;
          width: 29px;
          height: 29px;
          top: 6px;
          transform: rotate(45deg);
          border: 1px solid #000; }
      .energy-label-outer .energy-label-inner .energy-label.A .arrow-inner, .energy-label-outer .energy-label-inner .energy-label.A .label {
        background-color: #00a651; }
      .energy-label-outer .energy-label-inner .energy-label.B .arrow-inner, .energy-label-outer .energy-label-inner .energy-label.B .label {
        background-color: #50b848; }
      .energy-label-outer .energy-label-inner .energy-label.C .arrow-inner, .energy-label-outer .energy-label-inner .energy-label.C .label {
        background-color: #bfd730; }
      .energy-label-outer .energy-label-inner .energy-label.D .arrow-inner, .energy-label-outer .energy-label-inner .energy-label.D .label {
        background-color: #fff200; }
      .energy-label-outer .energy-label-inner .energy-label.E .arrow-inner, .energy-label-outer .energy-label-inner .energy-label.E .label {
        background-color: #fdb913; }
      .energy-label-outer .energy-label-inner .energy-label.F .arrow-inner, .energy-label-outer .energy-label-inner .energy-label.F .label {
        background-color: #f37021; }
      .energy-label-outer .energy-label-inner .energy-label.G .arrow-inner, .energy-label-outer .energy-label-inner .energy-label.G .label {
        background-color: #ed1c24; }
  .energy-label-outer .datasheet-link {
    font-size: 10px;
    display: block; }

@font-face {
  font-family: 'ra-icon';
  src: url("/out/ra-theme/fonts/icons/1743584751/ra-icon.woff2") format("woff2"), url("/out/ra-theme/fonts/icons/1743584751/ra-icon.woff") format("woff"), url("/out/ra-theme/fonts/icons/1743584751/ra-icon.ttf") format("truetype"), url("/out/ra-theme/fonts/icons/1743584751/ra-icon.svg?#ra-icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

i.ra-icon {
  font-family: 'ra-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block; }
  i.ra-icon.highlight {
    color: #00569d; }
    i.ra-icon.highlight:hover {
      color: #0064b7; }
  i.ra-icon.special {
    color: #dc371c; }
    i.ra-icon.special:hover {
      color: #e5472d; }
  i.ra-icon.confirm {
    color: #308136; }
    i.ra-icon.confirm:hover {
      color: #37933e; }
  i.ra-icon.white {
    color: #FFFFFF; }
    i.ra-icon.white:hover {
      color: white; }
  i.ra-icon.darkgrey {
    color: #575757; }
    i.ra-icon.darkgrey:hover {
      color: #646464; }
  i.ra-icon.lightgrey {
    color: #e3e3e3; }
    i.ra-icon.lightgrey:hover {
      color: #f0f0f0; }
  i.ra-icon.border {
    color: #aeaeae; }
    i.ra-icon.border:hover {
      color: #bbbbbb; }
  i.ra-icon:hover {
    transition: .3s all; }
  i.ra-icon.facebook:hover {
    color: #3B5998; }
  i.ra-icon.whatsapp:hover {
    color: #00e676; }
  i.ra-icon.googleplus:hover {
    color: #DC4E41; }
  i.ra-icon.instagram:hover {
    color: transparent;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background-clip: text;
    -webkit-background-clip: text; }
  i.ra-icon.pinterest:hover {
    color: #BD081C; }
  i.ra-icon.snapchat:hover {
    color: #FFFC00;
    text-shadow: 0 0 1px #000; }
  i.ra-icon.twitter:hover {
    color: #55ACEE; }
  i.ra-icon.vimeo:hover {
    color: #1AB7AE; }
  i.ra-icon.vk:hover {
    color: #4C75A3; }
  i.ra-icon.xing:hover {
    color: #026466; }
  i.ra-icon.youtube:hover {
    color: #B31217; }
  i.ra-icon.linkedin:hover {
    color: #0077B5; }
  i.ra-icon.tiktok:hover {
    color: transparent;
    background: linear-gradient(to left top, #ff0050 0%, #00f2ea 100%);
    background-clip: text;
    -webkit-background-clip: text; }
  i.ra-icon.signal:hover {
    color: #333DFE; }
  i.ra-icon.viber:hover {
    color: #665ca7; }
  i.ra-icon.twitter-x:hover {
    color: #000000; }
  i.ra-icon.bend:before {
    content: "\ea2f"; }
  i.ra-icon.indoor-outdoor:before {
    content: "\ea2e"; }
  i.ra-icon.payment-twint:before {
    content: "\e900"; }
  i.ra-icon.certificate-30:before {
    content: "\e901"; }
  i.ra-icon.reward-stars:before {
    content: "\e902"; }
  i.ra-icon.baggage-check-in-user:before {
    content: "\e903"; }
  i.ra-icon.travel:before {
    content: "\e904"; }
  i.ra-icon.experience-vkfinyears:before {
    content: "\e905"; }
  i.ra-icon.jahre40vkf:before {
    content: "\e906"; }
  i.ra-icon.years40vkf:before {
    content: "\e907"; }
  i.ra-icon.payment-klarna:before {
    content: "\e908"; }
  i.ra-icon.payment-bancontact:before {
    content: "\e909"; }
  i.ra-icon.charging-battery-full:before {
    content: "\e90a"; }
  i.ra-icon.charging-battery-low:before {
    content: "\e90b"; }
  i.ra-icon.dimmable:before {
    content: "\e90c"; }
  i.ra-icon.dimmable2:before {
    content: "\e90d"; }
  i.ra-icon.laptop:before {
    content: "\e90e"; }
  i.ra-icon.info-full:before {
    content: "\e90f"; }
  i.ra-icon.filter-slide-three:before {
    content: "\e910"; }
  i.ra-icon.arrange-asc:before {
    content: "\e911"; }
  i.ra-icon.cross-full:before {
    content: "\e912"; }
  i.ra-icon.minus-full:before {
    content: "\e913"; }
  i.ra-icon.dot:before {
    content: "\e914"; }
  i.ra-icon.messagebubble:before {
    content: "\e915"; }
  i.ra-icon.party:before {
    content: "\e916"; }
  i.ra-icon.check:before {
    content: "\e917"; }
  i.ra-icon.question:before {
    content: "\e918"; }
  i.ra-icon.filter-slide:before {
    content: "\e919"; }
  i.ra-icon.menu-full:before {
    content: "\e91a"; }
  i.ra-icon.twitter-x:before {
    content: "\e91b"; }
  i.ra-icon.copy:before {
    content: "\e91c"; }
  i.ra-icon.cross-simple:before {
    content: "\e91d"; }
  i.ra-icon.minus-simple:before {
    content: "\e91e"; }
  i.ra-icon.plus-simple:before {
    content: "\e91f"; }
  i.ra-icon.minimize:before {
    content: "\e920"; }
  i.ra-icon.compare:before {
    content: "\e921"; }
  i.ra-icon.car-front:before {
    content: "\e922"; }
  i.ra-icon.shirt-plain:before {
    content: "\e923"; }
  i.ra-icon.euroshop:before {
    content: "\e924"; }
  i.ra-icon.check-circle-full:before {
    content: "\e925"; }
  i.ra-icon.shrink:before {
    content: "\e926"; }
  i.ra-icon.before-after:before {
    content: "\e927"; }
  i.ra-icon.arrange-desc:before {
    content: "\e928"; }
  i.ra-icon.funnel:before {
    content: "\e929"; }
  i.ra-icon.add-link:before {
    content: "\e92a"; }
  i.ra-icon.familiy-house:before {
    content: "\e92b"; }
  i.ra-icon.gesture-swipe:before {
    content: "\e92c"; }
  i.ra-icon.payment-bank:before {
    content: "\e92d"; }
  i.ra-icon.dislike:before {
    content: "\e92e"; }
  i.ra-icon.like:before {
    content: "\e92f"; }
  i.ra-icon.heart-beat:before {
    content: "\e930"; }
  i.ra-icon.charging-e-car:before {
    content: "\e931"; }
  i.ra-icon.focus:before {
    content: "\e932"; }
  i.ra-icon.reply:before {
    content: "\e933"; }
  i.ra-icon.sun-rain:before {
    content: "\e934"; }
  i.ra-icon.co2-down:before {
    content: "\e935"; }
  i.ra-icon.lens-check:before {
    content: "\e936"; }
  i.ra-icon.traceability:before {
    content: "\e937"; }
  i.ra-icon.yarn:before {
    content: "\e938"; }
  i.ra-icon.color-bucket:before {
    content: "\e939"; }
  i.ra-icon.expand-diagonal:before {
    content: "\e93a"; }
  i.ra-icon.pen-write:before {
    content: "\e93b"; }
  i.ra-icon.self-adhesive:before {
    content: "\e93c"; }
  i.ra-icon.unbreakable:before {
    content: "\e93d"; }
  i.ra-icon.drag-drop-hand:before {
    content: "\e93e"; }
  i.ra-icon.email-btn:before {
    content: "\e93f"; }
  i.ra-icon.microphone:before {
    content: "\e940"; }
  i.ra-icon.redo:before {
    content: "\e941"; }
  i.ra-icon.tax-free:before {
    content: "\e942"; }
  i.ra-icon.chat-userlike:before {
    content: "\e943"; }
  i.ra-icon.date-calendar:before {
    content: "\e944"; }
  i.ra-icon.date-calendar-plus:before {
    content: "\e945"; }
  i.ra-icon.gallery:before {
    content: "\e946"; }
  i.ra-icon.sale-noborder:before {
    content: "\e947"; }
  i.ra-icon.tiktok:before {
    content: "\e948"; }
  i.ra-icon.flag:before {
    content: "\e949"; }
  i.ra-icon.notes:before {
    content: "\e94a"; }
  i.ra-icon.undercover:before {
    content: "\e94b"; }
  i.ra-icon.unlock:before {
    content: "\e94c"; }
  i.ra-icon.view:before {
    content: "\e94d"; }
  i.ra-icon.messaging:before {
    content: "\e94e"; }
  i.ra-icon.signal:before {
    content: "\e94f"; }
  i.ra-icon.signal-line:before {
    content: "\e950"; }
  i.ra-icon.undo:before {
    content: "\e951"; }
  i.ra-icon.viber:before {
    content: "\e952"; }
  i.ra-icon.bulky-xxl:before {
    content: "\e953"; }
  i.ra-icon.long-item:before {
    content: "\e954"; }
  i.ra-icon.mobile-phone:before {
    content: "\e955"; }
  i.ra-icon.temperature-triangle:before {
    content: "\e956"; }
  i.ra-icon.viber-line:before {
    content: "\e957"; }
  i.ra-icon.exploded-view:before {
    content: "\e958"; }
  i.ra-icon.image:before {
    content: "\e959"; }
  i.ra-icon.wifi:before {
    content: "\e95a"; }
  i.ra-icon.bulky:before {
    content: "\e95b"; }
  i.ra-icon.coffee:before {
    content: "\e95c"; }
  i.ra-icon.foto-camera:before {
    content: "\e95d"; }
  i.ra-icon.printable:before {
    content: "\e95e"; }
  i.ra-icon.rating-empty:before {
    content: "\e95f"; }
  i.ra-icon.rating-half:before {
    content: "\e960"; }
  i.ra-icon.glue:before {
    content: "\e961"; }
  i.ra-icon.heart-filled:before {
    content: "\e962"; }
  i.ra-icon.industry-sectors:before {
    content: "\e963"; }
  i.ra-icon.meeting-smartphone:before {
    content: "\e964"; }
  i.ra-icon.plug-n-play:before {
    content: "\e965"; }
  i.ra-icon.rating-full:before {
    content: "\e966"; }
  i.ra-icon.saved-basket:before {
    content: "\e967"; }
  i.ra-icon.bulb-light:before {
    content: "\e968"; }
  i.ra-icon.bulb-nolight:before {
    content: "\e969"; }
  i.ra-icon.degrees-minus-45:before {
    content: "\e96a"; }
  i.ra-icon.user-easter:before {
    content: "\e96b"; }
  i.ra-icon.virus:before {
    content: "\e96c"; }
  i.ra-icon.virus-no:before {
    content: "\e96d"; }
  i.ra-icon.degrees-45:before {
    content: "\e96e"; }
  i.ra-icon.file:before {
    content: "\e96f"; }
  i.ra-icon.move:before {
    content: "\e970"; }
  i.ra-icon.prob-65:before {
    content: "\e971"; }
  i.ra-icon.temperature:before {
    content: "\e972"; }
  i.ra-icon.zoom-out:before {
    content: "\e973"; }
  i.ra-icon.cube-add:before {
    content: "\e974"; }
  i.ra-icon.cube-construction:before {
    content: "\e975"; }
  i.ra-icon.cube-delete:before {
    content: "\e976"; }
  i.ra-icon.rain:before {
    content: "\e977"; }
  i.ra-icon.sun:before {
    content: "\e978"; }
  i.ra-icon.vegan:before {
    content: "\e979"; }
  i.ra-icon.zoom-in:before {
    content: "\e97a"; }
  i.ra-icon.chevron-up:before {
    content: "\e97b"; }
  i.ra-icon.foto-360:before {
    content: "\e97c"; }
  i.ra-icon.model-3d:before {
    content: "\e97d"; }
  i.ra-icon.open:before {
    content: "\e97e"; }
  i.ra-icon.qr:before {
    content: "\e97f"; }
  i.ra-icon.scan-qr:before {
    content: "\e980"; }
  i.ra-icon.ar:before {
    content: "\e981"; }
  i.ra-icon.certificate-2:before {
    content: "\e982"; }
  i.ra-icon.certificate-3:before {
    content: "\e983"; }
  i.ra-icon.certificate-5:before {
    content: "\e984"; }
  i.ra-icon.certificate-10:before {
    content: "\e985"; }
  i.ra-icon.cloud:before {
    content: "\e986"; }
  i.ra-icon.cooling:before {
    content: "\e987"; }
  i.ra-icon.hygienic:before {
    content: "\e988"; }
  i.ra-icon.payment-contactless:before {
    content: "\e989"; }
  i.ra-icon.up-down:before {
    content: "\e98a"; }
  i.ra-icon.basket-add-easter:before {
    content: "\e98b"; }
  i.ra-icon.basket-empty-easter:before {
    content: "\e98c"; }
  i.ra-icon.basket-full-easter:before {
    content: "\e98d"; }
  i.ra-icon.whatsapp:before {
    content: "\e98e"; }
  i.ra-icon.whatsapp-line:before {
    content: "\e98f"; }
  i.ra-icon.csv:before {
    content: "\e990"; }
  i.ra-icon.pdf:before {
    content: "\e991"; }
  i.ra-icon.print:before {
    content: "\e992"; }
  i.ra-icon.sustained:before {
    content: "\e993"; }
  i.ra-icon.user-reseller:before {
    content: "\e994"; }
  i.ra-icon.basket-empty-xmas:before {
    content: "\e995"; }
  i.ra-icon.basket-full-xmas:before {
    content: "\e996"; }
  i.ra-icon.cookie:before {
    content: "\e997"; }
  i.ra-icon.user-xmas:before {
    content: "\e998"; }
  i.ra-icon.xmas-tree:before {
    content: "\e999"; }
  i.ra-icon.basket-add-xmas:before {
    content: "\e99a"; }
  i.ra-icon.delivery-man-give:before {
    content: "\e99b"; }
  i.ra-icon.made-in:before {
    content: "\e99c"; }
  i.ra-icon.made-in-de:before {
    content: "\e99d"; }
  i.ra-icon.payment-iris:before {
    content: "\e99e"; }
  i.ra-icon.warehouse-cart-worker:before {
    content: "\e99f"; }
  i.ra-icon.battery-loading:before {
    content: "\e9a0"; }
  i.ra-icon.bike:before {
    content: "\e9a1"; }
  i.ra-icon.car:before {
    content: "\e9a2"; }
  i.ra-icon.fork-knife:before {
    content: "\e9a3"; }
  i.ra-icon.heart:before {
    content: "\e9a4"; }
  i.ra-icon.speaker-nosound:before {
    content: "\e9a5"; }
  i.ra-icon.camera:before {
    content: "\e9a6"; }
  i.ra-icon.led:before {
    content: "\e9a7"; }
  i.ra-icon.nature:before {
    content: "\e9a8"; }
  i.ra-icon.recycled:before {
    content: "\e9a9"; }
  i.ra-icon.rena:before {
    content: "\e9aa"; }
  i.ra-icon.touch:before {
    content: "\e9ab"; }
  i.ra-icon.fire-protection:before {
    content: "\e9ac"; }
  i.ra-icon.magnet:before {
    content: "\e9ad"; }
  i.ra-icon.share-android:before {
    content: "\e9ae"; }
  i.ra-icon.speaker:before {
    content: "\e9af"; }
  i.ra-icon.umbrella:before {
    content: "\e9b0"; }
  i.ra-icon.industries:before {
    content: "\e9b1"; }
  i.ra-icon.noticelist-filled:before {
    content: "\e9b2"; }
  i.ra-icon.payment-ship-step:before {
    content: "\e9b3"; }
  i.ra-icon.rss:before {
    content: "\e9b4"; }
  i.ra-icon.share-ios:before {
    content: "\e9b5"; }
  i.ra-icon.time:before {
    content: "\e9b6"; }
  i.ra-icon.check-order:before {
    content: "\e9b7"; }
  i.ra-icon.payment-amex:before {
    content: "\e9b8"; }
  i.ra-icon.payment-jcb:before {
    content: "\e9b9"; }
  i.ra-icon.payment-master:before {
    content: "\e9ba"; }
  i.ra-icon.payment-step:before {
    content: "\e9bb"; }
  i.ra-icon.user-budget:before {
    content: "\e9bc"; }
  i.ra-icon.exhibition:before {
    content: "\e9bd"; }
  i.ra-icon.payment-amazon:before {
    content: "\e9be"; }
  i.ra-icon.payment-ideal:before {
    content: "\e9bf"; }
  i.ra-icon.payment-visa:before {
    content: "\e9c0"; }
  i.ra-icon.payment-cashondelivery:before {
    content: "\e9c1"; }
  i.ra-icon.payment-creditcard:before {
    content: "\e9c2"; }
  i.ra-icon.payment-gopay:before {
    content: "\e9c3"; }
  i.ra-icon.payment-invoice:before {
    content: "\e9c4"; }
  i.ra-icon.payment-paypal:before {
    content: "\e9c5"; }
  i.ra-icon.payment-proforma:before {
    content: "\e9c6"; }
  i.ra-icon.desk:before {
    content: "\e9c7"; }
  i.ra-icon.printing:before {
    content: "\e9c8"; }
  i.ra-icon.school:before {
    content: "\e9c9"; }
  i.ra-icon.user_check:before {
    content: "\e9ca"; }
  i.ra-icon.warehouse:before {
    content: "\e9cb"; }
  i.ra-icon.carreer:before {
    content: "\e9cc"; }
  i.ra-icon.chevron-down:before {
    content: "\e9cd"; }
  i.ra-icon.circle:before {
    content: "\e9ce"; }
  i.ra-icon.floppy:before {
    content: "\e9cf"; }
  i.ra-icon.link:before {
    content: "\e9d0"; }
  i.ra-icon.locations:before {
    content: "\e9d1"; }
  i.ra-icon.arrowup:before {
    content: "\e9d2"; }
  i.ra-icon.billingaddress:before {
    content: "\e9d3"; }
  i.ra-icon.dropdown:before {
    content: "\e9d4"; }
  i.ra-icon.news:before {
    content: "\e9d5"; }
  i.ra-icon.oversize:before {
    content: "\e9d6"; }
  i.ra-icon.arrowdown:before {
    content: "\e9d7"; }
  i.ra-icon.arrowleft:before {
    content: "\e9d8"; }
  i.ra-icon.arrowright:before {
    content: "\e9d9"; }
  i.ra-icon.social-media:before {
    content: "\e9da"; }
  i.ra-icon.star-half:before {
    content: "\e9db"; }
  i.ra-icon.to-top:before {
    content: "\e9dc"; }
  i.ra-icon.filter:before {
    content: "\e9dd"; }
  i.ra-icon.graphic:before {
    content: "\e9de"; }
  i.ra-icon.note:before {
    content: "\e9df"; }
  i.ra-icon.shipment-arrived:before {
    content: "\e9e0"; }
  i.ra-icon.shipment-tracking:before {
    content: "\e9e1"; }
  i.ra-icon.account:before {
    content: "\e9e2"; }
  i.ra-icon.list:before {
    content: "\e9e3"; }
  i.ra-icon.menu:before {
    content: "\e9e4"; }
  i.ra-icon.myorders:before {
    content: "\e9e5"; }
  i.ra-icon.new:before {
    content: "\e9e6"; }
  i.ra-icon.sale-badge:before {
    content: "\e9e7"; }
  i.ra-icon.fax:before {
    content: "\e9e8"; }
  i.ra-icon.login:before {
    content: "\e9e9"; }
  i.ra-icon.logout:before {
    content: "\e9ea"; }
  i.ra-icon.minus:before {
    content: "\e9eb"; }
  i.ra-icon.sale:before {
    content: "\e9ec"; }
  i.ra-icon.certificate-bagde:before {
    content: "\e9ed"; }
  i.ra-icon.construction:before {
    content: "\e9ee"; }
  i.ra-icon.delete:before {
    content: "\e9ef"; }
  i.ra-icon.edit:before {
    content: "\e9f0"; }
  i.ra-icon.edit-circle:before {
    content: "\e9f1"; }
  i.ra-icon.reload:before {
    content: "\e9f2"; }
  i.ra-icon.calendar:before {
    content: "\e9f3"; }
  i.ra-icon.color-fan:before {
    content: "\e9f4"; }
  i.ra-icon.download:before {
    content: "\e9f5"; }
  i.ra-icon.earth:before {
    content: "\e9f6"; }
  i.ra-icon.individual-measurement:before {
    content: "\e9f7"; }
  i.ra-icon.upload:before {
    content: "\e9f8"; }
  i.ra-icon.construction-tool:before {
    content: "\e9f9"; }
  i.ra-icon.dinformat:before {
    content: "\e9fa"; }
  i.ra-icon.fileupload:before {
    content: "\e9fb"; }
  i.ra-icon.lexicon:before {
    content: "\e9fc"; }
  i.ra-icon.rma:before {
    content: "\e9fd"; }
  i.ra-icon.tabs:before {
    content: "\e9fe"; }
  i.ra-icon.buildings:before {
    content: "\e9ff"; }
  i.ra-icon.check-circle:before {
    content: "\ea00"; }
  i.ra-icon.express:before {
    content: "\ea01"; }
  i.ra-icon.handshake:before {
    content: "\ea02"; }
  i.ra-icon.maps-dimension:before {
    content: "\ea03"; }
  i.ra-icon.usergroup:before {
    content: "\ea04"; }
  i.ra-icon.help:before {
    content: "\ea05"; }
  i.ra-icon.location:before {
    content: "\ea06"; }
  i.ra-icon.location-circle:before {
    content: "\ea07"; }
  i.ra-icon.video-play:before {
    content: "\ea08"; }
  i.ra-icon.chevron-left:before {
    content: "\ea09"; }
  i.ra-icon.chevron-right:before {
    content: "\ea0a"; }
  i.ra-icon.document:before {
    content: "\ea0b"; }
  i.ra-icon.info:before {
    content: "\ea0c"; }
  i.ra-icon.phone:before {
    content: "\ea0d"; }
  i.ra-icon.plus:before {
    content: "\ea0e"; }
  i.ra-icon.bulky-full:before {
    content: "\ea0f"; }
  i.ra-icon.cross:before {
    content: "\ea10"; }
  i.ra-icon.mail:before {
    content: "\ea11"; }
  i.ra-icon.chat:before {
    content: "\ea12"; }
  i.ra-icon.contactquestion:before {
    content: "\ea13"; }
  i.ra-icon.home:before {
    content: "\ea14"; }
  i.ra-icon.hotline:before {
    content: "\ea15"; }
  i.ra-icon.snapchat:before {
    content: "\ea16"; }
  i.ra-icon.vimeo:before {
    content: "\ea17"; }
  i.ra-icon.vk:before {
    content: "\ea18"; }
  i.ra-icon.xing:before {
    content: "\ea19"; }
  i.ra-icon.youtube:before {
    content: "\ea1a"; }
  i.ra-icon.instagram:before {
    content: "\ea1b"; }
  i.ra-icon.linkedin:before {
    content: "\ea1c"; }
  i.ra-icon.pinterest:before {
    content: "\ea1d"; }
  i.ra-icon.twitter:before {
    content: "\ea1e"; }
  i.ra-icon.catorder:before {
    content: "\ea1f"; }
  i.ra-icon.facebook:before {
    content: "\ea20"; }
  i.ra-icon.googleplus:before {
    content: "\ea21"; }
  i.ra-icon.star-empty:before {
    content: "\ea22"; }
  i.ra-icon.star-full:before {
    content: "\ea23"; }
  i.ra-icon.certificate:before {
    content: "\ea24"; }
  i.ra-icon.customer-service:before {
    content: "\ea25"; }
  i.ra-icon.directorder-list:before {
    content: "\ea26"; }
  i.ra-icon.lock:before {
    content: "\ea27"; }
  i.ra-icon.basket-add:before {
    content: "\ea28"; }
  i.ra-icon.basket-empty:before {
    content: "\ea29"; }
  i.ra-icon.basket-full:before {
    content: "\ea2a"; }
  i.ra-icon.lens:before {
    content: "\ea2b"; }
  i.ra-icon.noticelist:before {
    content: "\ea2c"; }
  i.ra-icon.user:before {
    content: "\ea2d"; }
  i.ra-icon.x20 {
    font-size: 20px !important;
    width: 20px;
    height: 20px; }
  i.ra-icon.x24 {
    font-size: 24px !important;
    width: 24px;
    height: 24px; }
  i.ra-icon.x25 {
    font-size: 25px !important;
    width: 25px;
    height: 25px; }
  i.ra-icon.x36 {
    font-size: 36px !important;
    width: 36px;
    height: 36px; }
  i.ra-icon.x48 {
    font-size: 48px !important;
    width: 48px;
    height: 48px; }
  i.ra-icon.x64 {
    font-size: 64px !important;
    width: 64px;
    height: 64px; }
  i.ra-icon.x72 {
    font-size: 72px !important;
    width: 72px;
    height: 72px; }
  i.ra-icon.x96 {
    font-size: 96px !important;
    width: 96px;
    height: 96px; }
  i.ra-icon.x120 {
    font-size: 120px !important;
    width: 120px;
    height: 120px; }
  i.ra-icon.x128 {
    font-size: 128px !important;
    width: 128px;
    height: 128px; }

img.lazy {
  display: none !important;
  transition: opacity .4s; }
  img.lazy.enabled {
    display: inline-block !important; }
  img.lazy.preload {
    opacity: 0; }
  img.lazy.ready {
    opacity: 1; }

img.lazy-manual {
  transition: opacity .4s;
  opacity: 0; }
  img.lazy-manual.ready {
    opacity: 1; }

img.pointer-events-none {
  pointer-events: none; }

img.pointer-events-auto {
  pointer-events: auto; }

.btn {
  border-radius: 0;
  font-size: 1.4rem; }

.btn-highlight {
  color: #fff !important;
  background-color: #00569d;
  border-color: #004884;
  box-shadow: none !important; }
  .btn-highlight:focus, .btn-highlight.focus {
    color: #fff;
    background-color: #0064b7;
    border-color: #004884; }
  .btn-highlight:hover {
    color: #fff;
    background-color: #0064b7;
    border-color: #004884; }
  .btn-highlight.disabled, .btn-highlight.disabled:hover, .btn-highlight.disabled:focus, .btn-highlight.disabled.focus, .btn-highlight.disabled:active, .btn-highlight.disabled.active, .btn-highlight[disabled], .btn-highlight[disabled]:hover, .btn-highlight[disabled]:focus, .btn-highlight[disabled].focus, .btn-highlight[disabled]:active, .btn-highlight[disabled].active,
  fieldset[disabled] .btn-highlight,
  fieldset[disabled] .btn-highlight:hover,
  fieldset[disabled] .btn-highlight:focus,
  fieldset[disabled] .btn-highlight.focus,
  fieldset[disabled] .btn-highlight:active,
  fieldset[disabled] .btn-highlight.active {
    background-color: #00569d;
    border-color: #004884; }
  .btn-highlight .badge {
    color: #00569d;
    background-color: #fff; }

.btn-special {
  color: #fff !important;
  background-color: #dc371c;
  border-color: #c63119;
  box-shadow: none !important; }
  .btn-special:focus, .btn-special.focus {
    color: #fff;
    background-color: #e5472d;
    border-color: #c63119; }
  .btn-special:hover {
    color: #fff;
    background-color: #e5472d;
    border-color: #c63119; }
  .btn-special.disabled, .btn-special.disabled:hover, .btn-special.disabled:focus, .btn-special.disabled.focus, .btn-special.disabled:active, .btn-special.disabled.active, .btn-special[disabled], .btn-special[disabled]:hover, .btn-special[disabled]:focus, .btn-special[disabled].focus, .btn-special[disabled]:active, .btn-special[disabled].active,
  fieldset[disabled] .btn-special,
  fieldset[disabled] .btn-special:hover,
  fieldset[disabled] .btn-special:focus,
  fieldset[disabled] .btn-special.focus,
  fieldset[disabled] .btn-special:active,
  fieldset[disabled] .btn-special.active {
    background-color: #dc371c;
    border-color: #c63119; }
  .btn-special .badge {
    color: #dc371c;
    background-color: #fff; }

.btn-confirm {
  color: #fff !important;
  background-color: #308136;
  border-color: #296e2e;
  box-shadow: none !important; }
  .btn-confirm:focus, .btn-confirm.focus {
    color: #fff;
    background-color: #37933e;
    border-color: #296e2e; }
  .btn-confirm:hover {
    color: #fff;
    background-color: #37933e;
    border-color: #296e2e; }
  .btn-confirm.disabled, .btn-confirm.disabled:hover, .btn-confirm.disabled:focus, .btn-confirm.disabled.focus, .btn-confirm.disabled:active, .btn-confirm.disabled.active, .btn-confirm[disabled], .btn-confirm[disabled]:hover, .btn-confirm[disabled]:focus, .btn-confirm[disabled].focus, .btn-confirm[disabled]:active, .btn-confirm[disabled].active,
  fieldset[disabled] .btn-confirm,
  fieldset[disabled] .btn-confirm:hover,
  fieldset[disabled] .btn-confirm:focus,
  fieldset[disabled] .btn-confirm.focus,
  fieldset[disabled] .btn-confirm:active,
  fieldset[disabled] .btn-confirm.active {
    background-color: #308136;
    border-color: #296e2e; }
  .btn-confirm .badge {
    color: #308136;
    background-color: #fff; }

.btn-lightgrey {
  color: #575757 !important;
  background-color: #e3e3e3;
  border-color: #aeaeae;
  box-shadow: none !important; }
  .btn-lightgrey:focus, .btn-lightgrey.focus {
    color: #575757;
    background-color: #f0f0f0;
    border-color: #aeaeae; }
  .btn-lightgrey:hover {
    color: #575757;
    background-color: #f0f0f0;
    border-color: #aeaeae; }
  .btn-lightgrey.disabled, .btn-lightgrey.disabled:hover, .btn-lightgrey.disabled:focus, .btn-lightgrey.disabled.focus, .btn-lightgrey.disabled:active, .btn-lightgrey.disabled.active, .btn-lightgrey[disabled], .btn-lightgrey[disabled]:hover, .btn-lightgrey[disabled]:focus, .btn-lightgrey[disabled].focus, .btn-lightgrey[disabled]:active, .btn-lightgrey[disabled].active,
  fieldset[disabled] .btn-lightgrey,
  fieldset[disabled] .btn-lightgrey:hover,
  fieldset[disabled] .btn-lightgrey:focus,
  fieldset[disabled] .btn-lightgrey.focus,
  fieldset[disabled] .btn-lightgrey:active,
  fieldset[disabled] .btn-lightgrey.active {
    background-color: #e3e3e3;
    border-color: #aeaeae; }
  .btn-lightgrey .badge {
    color: #e3e3e3;
    background-color: #575757; }

.btn-dropdown-filter, .btn-dropdown-sort {
  color: #575757 !important;
  background-color: #FFFFFF;
  border-color: transparent;
  box-shadow: none !important; }
  .btn-dropdown-filter:focus, .btn-dropdown-filter.focus, .btn-dropdown-sort:focus, .btn-dropdown-sort.focus {
    color: #575757;
    background-color: white;
    border-color: transparent; }
  .btn-dropdown-filter:hover, .btn-dropdown-sort:hover {
    color: #575757;
    background-color: white;
    border-color: transparent; }
  .btn-dropdown-filter.disabled, .btn-dropdown-filter.disabled:hover, .btn-dropdown-filter.disabled:focus, .btn-dropdown-filter.disabled.focus, .btn-dropdown-filter.disabled:active, .btn-dropdown-filter.disabled.active, .btn-dropdown-filter[disabled], .btn-dropdown-filter[disabled]:hover, .btn-dropdown-filter[disabled]:focus, .btn-dropdown-filter[disabled].focus, .btn-dropdown-filter[disabled]:active, .btn-dropdown-filter[disabled].active,
  fieldset[disabled] .btn-dropdown-filter,
  fieldset[disabled] .btn-dropdown-filter:hover,
  fieldset[disabled] .btn-dropdown-filter:focus,
  fieldset[disabled] .btn-dropdown-filter.focus,
  fieldset[disabled] .btn-dropdown-filter:active,
  fieldset[disabled] .btn-dropdown-filter.active, .btn-dropdown-sort.disabled, .btn-dropdown-sort.disabled:hover, .btn-dropdown-sort.disabled:focus, .btn-dropdown-sort.disabled.focus, .btn-dropdown-sort.disabled:active, .btn-dropdown-sort.disabled.active, .btn-dropdown-sort[disabled], .btn-dropdown-sort[disabled]:hover, .btn-dropdown-sort[disabled]:focus, .btn-dropdown-sort[disabled].focus, .btn-dropdown-sort[disabled]:active, .btn-dropdown-sort[disabled].active,
  fieldset[disabled] .btn-dropdown-sort,
  fieldset[disabled] .btn-dropdown-sort:hover,
  fieldset[disabled] .btn-dropdown-sort:focus,
  fieldset[disabled] .btn-dropdown-sort.focus,
  fieldset[disabled] .btn-dropdown-sort:active,
  fieldset[disabled] .btn-dropdown-sort.active {
    background-color: #FFFFFF;
    border-color: transparent; }
  .btn-dropdown-filter .badge, .btn-dropdown-sort .badge {
    color: #FFFFFF;
    background-color: #575757; }

.btn-darkgrey {
  color: #FFFFFF !important;
  background-color: #575757;
  border-color: #575757;
  box-shadow: none !important; }
  .btn-darkgrey:focus, .btn-darkgrey.focus {
    color: #FFFFFF;
    background-color: #646464;
    border-color: #575757; }
  .btn-darkgrey:hover {
    color: #FFFFFF;
    background-color: #646464;
    border-color: #575757; }
  .btn-darkgrey.disabled, .btn-darkgrey.disabled:hover, .btn-darkgrey.disabled:focus, .btn-darkgrey.disabled.focus, .btn-darkgrey.disabled:active, .btn-darkgrey.disabled.active, .btn-darkgrey[disabled], .btn-darkgrey[disabled]:hover, .btn-darkgrey[disabled]:focus, .btn-darkgrey[disabled].focus, .btn-darkgrey[disabled]:active, .btn-darkgrey[disabled].active,
  fieldset[disabled] .btn-darkgrey,
  fieldset[disabled] .btn-darkgrey:hover,
  fieldset[disabled] .btn-darkgrey:focus,
  fieldset[disabled] .btn-darkgrey.focus,
  fieldset[disabled] .btn-darkgrey:active,
  fieldset[disabled] .btn-darkgrey.active {
    background-color: #575757;
    border-color: #575757; }
  .btn-darkgrey .badge {
    color: #575757;
    background-color: #FFFFFF; }

.btn-white {
  color: #575757 !important;
  background-color: #FFFFFF;
  border-color: #aeaeae;
  box-shadow: none !important; }
  .btn-white:focus, .btn-white.focus {
    color: #575757;
    background-color: white;
    border-color: #aeaeae; }
  .btn-white:hover {
    color: #575757;
    background-color: white;
    border-color: #aeaeae; }
  .btn-white.disabled, .btn-white.disabled:hover, .btn-white.disabled:focus, .btn-white.disabled.focus, .btn-white.disabled:active, .btn-white.disabled.active, .btn-white[disabled], .btn-white[disabled]:hover, .btn-white[disabled]:focus, .btn-white[disabled].focus, .btn-white[disabled]:active, .btn-white[disabled].active,
  fieldset[disabled] .btn-white,
  fieldset[disabled] .btn-white:hover,
  fieldset[disabled] .btn-white:focus,
  fieldset[disabled] .btn-white.focus,
  fieldset[disabled] .btn-white:active,
  fieldset[disabled] .btn-white.active {
    background-color: #FFFFFF;
    border-color: #aeaeae; }
  .btn-white .badge {
    color: #FFFFFF;
    background-color: #575757; }

.btn-rating {
  color: #FFFFFF !important;
  background-color: #f59100;
  border-color: #dc8200;
  box-shadow: none !important; }
  .btn-rating:focus, .btn-rating.focus {
    color: #FFFFFF;
    background-color: #ff9d10;
    border-color: #dc8200; }
  .btn-rating:hover {
    color: #FFFFFF;
    background-color: #ff9d10;
    border-color: #dc8200; }
  .btn-rating.disabled, .btn-rating.disabled:hover, .btn-rating.disabled:focus, .btn-rating.disabled.focus, .btn-rating.disabled:active, .btn-rating.disabled.active, .btn-rating[disabled], .btn-rating[disabled]:hover, .btn-rating[disabled]:focus, .btn-rating[disabled].focus, .btn-rating[disabled]:active, .btn-rating[disabled].active,
  fieldset[disabled] .btn-rating,
  fieldset[disabled] .btn-rating:hover,
  fieldset[disabled] .btn-rating:focus,
  fieldset[disabled] .btn-rating.focus,
  fieldset[disabled] .btn-rating:active,
  fieldset[disabled] .btn-rating.active {
    background-color: #f59100;
    border-color: #dc8200; }
  .btn-rating .badge {
    color: #f59100;
    background-color: #FFFFFF; }

.btn-transparent {
  color: #575757 !important;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none !important; }
  .btn-transparent:focus, .btn-transparent.focus {
    color: #575757;
    background-color: rgba(13, 13, 13, 0);
    border-color: transparent; }
  .btn-transparent:hover {
    color: #575757;
    background-color: rgba(13, 13, 13, 0);
    border-color: transparent; }
  .btn-transparent.disabled, .btn-transparent.disabled:hover, .btn-transparent.disabled:focus, .btn-transparent.disabled.focus, .btn-transparent.disabled:active, .btn-transparent.disabled.active, .btn-transparent[disabled], .btn-transparent[disabled]:hover, .btn-transparent[disabled]:focus, .btn-transparent[disabled].focus, .btn-transparent[disabled]:active, .btn-transparent[disabled].active,
  fieldset[disabled] .btn-transparent,
  fieldset[disabled] .btn-transparent:hover,
  fieldset[disabled] .btn-transparent:focus,
  fieldset[disabled] .btn-transparent.focus,
  fieldset[disabled] .btn-transparent:active,
  fieldset[disabled] .btn-transparent.active {
    background-color: transparent;
    border-color: transparent; }
  .btn-transparent .badge {
    color: transparent;
    background-color: #575757; }

.btn-black {
  color: #FFFFFF !important;
  background-color: #000000;
  border-color: #aeaeae;
  box-shadow: none !important; }
  .btn-black:focus, .btn-black.focus {
    color: #FFFFFF;
    background-color: #0d0d0d;
    border-color: #aeaeae; }
  .btn-black:hover {
    color: #FFFFFF;
    background-color: #0d0d0d;
    border-color: #aeaeae; }
  .btn-black.disabled, .btn-black.disabled:hover, .btn-black.disabled:focus, .btn-black.disabled.focus, .btn-black.disabled:active, .btn-black.disabled.active, .btn-black[disabled], .btn-black[disabled]:hover, .btn-black[disabled]:focus, .btn-black[disabled].focus, .btn-black[disabled]:active, .btn-black[disabled].active,
  fieldset[disabled] .btn-black,
  fieldset[disabled] .btn-black:hover,
  fieldset[disabled] .btn-black:focus,
  fieldset[disabled] .btn-black.focus,
  fieldset[disabled] .btn-black:active,
  fieldset[disabled] .btn-black.active {
    background-color: #000000;
    border-color: #aeaeae; }
  .btn-black .badge {
    color: #000000;
    background-color: #FFFFFF; }

.btn-highlight, .btn-special, .btn-confirm, .btn-lightgrey, .btn-danger, .btn-darkgrey, .btn-white, .btn-rating, .btn-transparent, .btn-black {
  min-height: 4rem;
  font-weight: bold;
  line-height: 3rem; }

.ar-block, .video-block {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem; }
  .ar-block .ar-btn, .ar-block .video-btn, .video-block .ar-btn, .video-block .video-btn {
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: -1.5rem;
    border-radius: 20px;
    border: 1px solid #e3e3e3;
    padding: 1rem;
    width: 100%; }
    .ar-block .ar-btn span, .ar-block .video-btn span, .video-block .ar-btn span, .video-block .video-btn span {
      display: flex;
      align-items: center;
      justify-content: center; }
      .ar-block .ar-btn span .ra-icon, .ar-block .video-btn span .ra-icon, .video-block .ar-btn span .ra-icon, .video-block .video-btn span .ra-icon {
        font-size: 2.5rem;
        color: #00569d;
        margin-right: .5rem; }

.pagination-group {
  margin-top: 1.5rem;
  margin-bottom: 1rem; }

input.form-control {
  border-radius: 0;
  border-color: #aeaeae;
  -webkit-appearance: none;
  box-shadow: none;
  height: 34px;
  font-size: 14px;
  padding: .6rem 1.2rem;
  transition: all .5s; }
  input.form-control:focus {
    border-color: #aeaeae; }
  input.form-control[type=checkbox] {
    -webkit-appearance: checkbox; }

input[type=checkbox]:required {
  box-shadow: none; }

input[type=number] {
  -moz-appearance: textfield; }
  input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none; }

label.req {
  font-weight: 700; }

.form-group-type-hidden {
  display: none; }

.help-block.ra-email-info {
  font-size: 1.1rem;
  font-style: italic; }

.bg-padding {
  padding: 1.5rem 1rem; }
  .bg-padding.form-group-type-country, .bg-padding.form-group-type-select {
    padding: 1.5rem 1rem 2.5rem 1rem; }
  .bg-padding.icon {
    padding: 1.5rem 0; }

h1, .h1 {
  font-size: 2.4rem;
  line-height: 1.3;
  font-weight: bold;
  color: #575757; }

h2, .h2 {
  font-size: 1.8rem;
  line-height: 1.3;
  font-weight: bold;
  color: #575757; }

h3, h4, .h3, .h4 {
  font-size: 1.6rem;
  line-height: 1.1;
  font-weight: bold;
  color: #575757; }

h5, h6, .h5, .h6 {
  font-size: 1.4rem;
  line-height: 1.1;
  font-weight: bold;
  color: #575757; }

.header-transparent {
  background-color: transparent !important;
  box-shadow: none !important;
  position: absolute; }
  .header-transparent .menu-dropdowns a {
    color: #FFFFFF; }

.sticky-header {
  padding-bottom: 1rem; }

.header-flex-center {
  display: flex;
  align-items: center; }

hr.middle-text, hr.middle-text-alt {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  margin-top: .7rem;
  margin-bottom: .7rem; }
  hr.middle-text:before, hr.middle-text-alt:before {
    content: '';
    background-color: #aeaeae;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px; }
  hr.middle-text:after, hr.middle-text-alt:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 .5em;
    line-height: 1.5em;
    background-color: #FFFFFF; }

.t-confirm {
  color: #308136; }

.t-highlight {
  color: #00569d; }

.t-black {
  color: #000000; }

.t-white {
  color: #FFFFFF; }
  .t-white h1, .t-white h2, .t-white h3, .t-white h4, .t-white h5, .t-white h6 {
    color: #FFFFFF !important; }

.t-darkgrey {
  color: #575757; }

.t-darkgrey-o {
  color: #747474; }

.t-lightgrey {
  color: #e3e3e3; }

.t-lightgrey-o {
  color: #f6f6f6; }

.t-border {
  color: #aeaeae; }

.t-special {
  color: #dc371c; }

.t-rating {
  color: #f59100; }

.bg-confirm {
  background-color: #308136; }

.bg-highlight {
  background-color: #00569d; }

.bg-black {
  background-color: #000000; }

.bg-white {
  background-color: #FFFFFF; }

.bg-darkgrey {
  background-color: #575757; }

.bg-darkgrey-o {
  background-color: #747474; }

.bg-lightgrey {
  background-color: #e3e3e3; }

.bg-lightgrey-o {
  background-color: #f6f6f6; }

.bg-border {
  background-color: #aeaeae; }

.bg-special {
  background-color: #dc371c; }

.bg-rating {
  background-color: #f59100; }

.bg-transparent {
  background-color: transparent; }

div.alert-info {
  background: none;
  border: none;
  color: #575757;
  font-weight: bold;
  padding: 0;
  margin: 0; }
  div.alert-info.passwdReset {
    font-weight: normal; }

.ra-upload-basket-motif-alert {
  padding: 1rem !important;
  border: 1px solid #e3e3e3 !important;
  background-color: #f6f6f6 !important;
  margin-bottom: 2rem !important;
  line-height: 1;
  font-weight: 400;
  text-align: center; }
  .ra-upload-basket-motif-alert .ra-icon {
    font-size: 2.2rem;
    vertical-align: sub; }

.table {
  color: #575757; }
  .table tbody tr td {
    border-top: none;
    border-bottom: 1px solid #e3e3e3;
    transition: all .2s; }
  .table thead tr th {
    border-bottom: 1px solid #e3e3e3;
    border-top: none; }
  .table.table-col-seperated td:first-child {
    font-weight: bold;
    background-color: #f6f6f6; }
  .table.table-striped tbody tr:hover td {
    background-color: #f6f6f6; }

a {
  color: #575757; }
  a:hover {
    text-decoration: none;
    color: #171717; }
  a.highlight {
    color: #00569d; }
    a.highlight:hover {
      text-decoration: none;
      color: #003a6a; }

.spinner {
  margin: 100px auto;
  width: 50px;
  height: 30px;
  text-align: center;
  font-size: 10px; }
  .spinner.small {
    margin: 50px auto; }

.spinner > div {
  background-color: #747474;
  height: 100%;
  width: 6px;
  display: inline-block;
  animation: sk-stretchdelay 1.2s infinite ease-in-out; }

.spinner .rect2 {
  animation-delay: -1.1s; }

.spinner .rect3 {
  animation-delay: -1.0s; }

.spinner .rect4 {
  animation-delay: -0.9s; }

.spinner .rect5 {
  animation-delay: -0.8s; }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

.raform-outer .form-group-none {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%; }

.raform-outer .raform-group-btn {
  margin: 1rem 0;
  width: 100%;
  background-color: #e3e3e3;
  color: #575757; }
  .raform-outer .raform-group-btn:hover, .raform-outer .raform-group-btn.active {
    background-color: #00569d;
    color: #FFFFFF; }

.raform-outer .raform-submit {
  margin: 1rem; }

.raform-outer input[type=file] {
  padding-bottom: 4rem; }

.raform-outer.static label {
  margin: 0; }

.raform-outer.static p {
  margin: 0 0 .5rem; }

.raform-outer label.req {
  font-weight: bold; }

.raform-outer .form-group:not(.form-heading) {
  margin-bottom: 2rem;
  min-height: 6rem; }
  .raform-outer .form-group:not(.form-heading).form-group-type-checkbox, .raform-outer .form-group:not(.form-heading).form-group-type-confirm, .raform-outer .form-group:not(.form-heading).form-group-type-cms {
    min-height: 0; }

.raform-outer .input-group-text {
  font-size: 1.4rem; }

.raform-outer .validation-icon {
  display: none;
  position: absolute;
  right: 1.5rem;
  top: 0;
  margin-top: 3.1rem;
  font-size: 2.3rem;
  z-index: 999;
  opacity: 0; }
  .raform-outer .validation-icon.select {
    right: 3.25rem; }
  .raform-outer .validation-icon.invalid {
    color: #dc371c;
    animation: fade-in-validation .5s forwards; }
  .raform-outer .validation-icon.valid {
    color: #308136;
    animation: fade-in-validation .5s forwards, fade-out-validation-icon 6s 3s forwards; }

.raform-outer .zip-validation-loading {
  display: block;
  position: absolute;
  right: 1.5rem;
  top: 0;
  margin-top: 3.1rem;
  font-size: 2.3rem;
  z-index: 1000;
  opacity: 0;
  background-color: #FFFFFF;
  animation: rotate 1s linear infinite, fade-in-validation .5s forwards, fade-out-validation-icon 6s 3s forwards; }

@keyframes rotate {
  0% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes fade-in-validation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out-validation-icon {
  0% {
    color: #308136; }
  100% {
    color: #aeaeae; } }

@keyframes fade-out-validation-border {
  0% {
    border-color: #308136; }
  100% {
    border-color: #aeaeae; } }

.raform-outer input.form-control:focus {
  border-color: #00569d;
  border-width: .2rem;
  box-shadow: none; }

.raform-outer .form-group.form-group-type-select p.error, .raform-outer .form-group.form-group-type-country p.error, .raform-outer .form-group.form-group-type-state p.error {
  margin-top: 1rem; }

.raform-outer .form-group p.error {
  font-size: 1.2rem;
  font-weight: bold;
  padding-left: .5rem;
  color: #dc371c;
  margin: 0;
  opacity: 0;
  animation: fade-in-validation .5s forwards; }

.raform-outer .form-group label {
  transition: all .5s;
  width: 100%; }

.raform-outer .form-group.has-error label {
  color: #dc371c; }

.raform-outer .form-group.has-error input.form-control {
  border-color: #dc371c; }
  .raform-outer .form-group.has-error input.form-control:focus {
    border-width: .1rem; }

.raform-outer .form-group.has-error .validation-icon.invalid {
  display: block; }

.raform-outer .form-group.has-error .validation-icon.valid {
  display: none; }

.raform-outer .form-group.has-error p.error {
  display: inline-block;
  width: 100%; }

.raform-outer .form-group.has-error p.custom-blockword-error {
  color: #dc371c;
  font-size: 1.2rem;
  font-weight: 700;
  padding-left: .5rem;
  margin: 0;
  opacity: 0;
  animation: fade-in-validation .5s forwards; }

.raform-outer .form-group.has-error.form-group-type-upload .ra-form-attachment {
  border: 1px solid #dc371c; }

.raform-outer .form-group.is-valid .alert-danger {
  display: none; }

.raform-outer .form-group.is-valid input.form-control {
  border-color: #308136;
  animation: fade-out-validation-border 6s 3s forwards; }
  .raform-outer .form-group.is-valid input.form-control:focus {
    border-width: .1rem; }

.raform-outer .form-group.is-valid .validation-icon.invalid {
  display: none; }

.raform-outer .form-group.is-valid .validation-icon.valid {
  display: block; }

.raform-outer .form-heading {
  background-color: #e3e3e3; }

.raform-outer .radio-outer .form-check-inline {
  width: 100%; }

.raform-outer .tooltip-info {
  text-align: right;
  float: right;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.1;
  display: inline-block; }
  .raform-outer .tooltip-info i {
    vertical-align: middle;
    font-size: 1.4rem; }

.raform-outer input.uppercase, .raform-outer textarea.uppercase {
  text-transform: uppercase; }

.raform-outer .hidden-for-reseller-registration, .raform-outer .hidden-for-vatexemption-registration {
  display: none; }

.raform-outer .ra-zip-example {
  display: block;
  color: #575757;
  font-size: 1.2rem;
  font-weight: 400; }

.raform-outer .ra-zip-validation-info {
  margin-top: -1rem; }
  .raform-outer .ra-zip-validation-info .inner {
    background-color: #f6f6f6;
    border: 1px solid #dc371c;
    padding: 1rem;
    text-align: center; }
    .raform-outer .ra-zip-validation-info .inner button {
      margin-top: 1rem; }
    .raform-outer .ra-zip-validation-info .inner input {
      visibility: hidden;
      height: 0 !important;
      margin: 0 !important;
      padding: 0 !important;
      display: block; }
    .raform-outer .ra-zip-validation-info .inner .error {
      display: none !important; }

.raform-outer .ra-form-attachment {
  background-color: #FFFFFF;
  border: 1px solid #aeaeae;
  padding: .5rem 1rem 2rem 1rem; }
  .raform-outer .ra-form-attachment .ra-form-attachment-fieldset legend {
    width: auto;
    font-size: 1.25rem; }
  .raform-outer .ra-form-attachment .ra-form-attachment-fieldset.required legend {
    font-weight: bold; }
  .raform-outer .ra-form-attachment .ra-form-attachment-fieldset input[type=file] {
    padding: 0; }

.product-item {
  display: block;
  outline: none;
  padding: 3rem 0 1rem 0;
  position: relative;
  height: 100%; }
  .product-item.recommendation, .product-item.bestseller {
    border-top: 2px solid #f59100; }
  .product-item .product-item-inner {
    padding: 0 1rem;
    height: 100%; }
    .product-item .product-item-inner .product-bestseller {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      font-size: 1.2rem;
      font-weight: 700;
      z-index: 2;
      display: flex;
      gap: .5rem;
      max-height: 3rem;
      overflow: hidden;
      align-items: center;
      align-content: center; }
      .product-item .product-item-inner .product-bestseller:hover {
        background-color: #FFFFFF; }
        .product-item .product-item-inner .product-bestseller:hover .product-bestseller-category-info {
          display: inline-flex; }
      .product-item .product-item-inner .product-bestseller .product-bestseller-inner {
        color: #FFFFFF;
        background-color: #f59100;
        display: inline-flex;
        height: 3rem;
        align-items: center;
        align-content: center;
        padding: 0 .5rem; }
        .product-item .product-item-inner .product-bestseller .product-bestseller-inner i {
          font-size: 1.8rem;
          font-weight: 400;
          vertical-align: text-bottom; }
        .product-item .product-item-inner .product-bestseller .product-bestseller-inner span {
          margin-left: .5rem; }
      .product-item .product-item-inner .product-bestseller .product-bestseller-category-info {
        display: none;
        align-items: center;
        align-content: center;
        gap: .5rem;
        cursor: pointer;
        height: 3rem; }
        .product-item .product-item-inner .product-bestseller .product-bestseller-category-info span {
          line-height: 1; }
    .product-item .product-item-inner .product-recommendation {
      position: absolute;
      top: .5rem;
      left: 1rem;
      text-align: left;
      font-size: 1.2rem;
      font-weight: 700;
      color: #f59100; }
      .product-item .product-item-inner .product-recommendation i {
        font-size: 1.8rem;
        font-weight: 400;
        vertical-align: text-bottom; }
      .product-item .product-item-inner .product-recommendation span {
        margin-left: .5rem; }
    .product-item .product-item-inner .product-variants-count {
      position: absolute;
      top: .5rem;
      right: 1rem;
      text-align: right;
      font-size: 1.2rem;
      z-index: 1; }
    .product-item .product-item-inner .product-img-wrapper {
      overflow: hidden;
      max-width: 225px;
      width: 100%;
      height: auto;
      aspect-ratio: 1;
      margin: 0 auto;
      position: relative; }
      .product-item .product-item-inner .product-img-wrapper img {
        display: block;
        width: 100%;
        height: auto;
        position: absolute; }
      .product-item .product-item-inner .product-img-wrapper .product-hover-img {
        position: absolute;
        -o-object-fit: contain;
           object-fit: contain;
        opacity: 0;
        transition: opacity .2s; }
    .product-item .product-item-inner .ribbons {
      position: absolute;
      margin-top: -3rem;
      display: flex;
      justify-content: flex-start;
      justify-items: flex-start;
      gap: .3rem; }
      .product-item .product-item-inner .ribbons .ribbon {
        height: 2.5rem; }
    .product-item .product-item-inner .product-item-overtitle-info {
      margin-top: .5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      vertical-align: middle;
      overflow: hidden; }
      .product-item .product-item-inner .product-item-overtitle-info .product-reviews i {
        font-size: 1rem !important;
        margin-right: -.4rem;
        vertical-align: middle; }
      .product-item .product-item-inner .product-item-overtitle-info .product-reviews span {
        font-size: 1rem;
        margin-left: .4rem; }
    .product-item .product-item-inner .product-item-title {
      text-align: left !important;
      height: 5.2rem;
      line-height: 1.7rem;
      font-weight: 700;
      margin-top: .5rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; }
      .product-item .product-item-inner .product-item-title.reduced {
        height: 3.5rem; }
      .product-item .product-item-inner .product-item-title.title-only {
        text-align: center !important; }
      .product-item .product-item-inner .product-item-title.no-reviews {
        margin-top: 1.5rem; }
    .product-item .product-item-inner .product-item-artnum {
      margin: 0 !important; }
      .product-item .product-item-inner .product-item-artnum span {
        font-size: 1rem;
        background: #e3e3e3;
        color: #575757;
        padding: .25rem .5rem; }
    .product-item .product-item-inner .product-item-attributes {
      text-align: left !important;
      margin: 1rem 0;
      height: 3rem;
      word-break: normal;
      line-height: 1.4rem;
      overflow: hidden; }
    .product-item .product-item-inner .pricebox {
      margin-top: auto; }
      .product-item .product-item-inner .pricebox.aboveTitle {
        margin-top: 1.5rem;
        margin-bottom: 2rem; }
      .product-item .product-item-inner .pricebox .price, .product-item .product-item-inner .pricebox .unit {
        text-align: left;
        line-height: 1.2; }
      .product-item .product-item-inner .pricebox .price {
        font-size: 1.6rem;
        font-weight: 700; }
        .product-item .product-item-inner .pricebox .price span {
          font-size: 1.2rem; }
        .product-item .product-item-inner .pricebox .price.reduced {
          border-radius: .4rem;
          padding: 0 .4rem; }
          .product-item .product-item-inner .pricebox .price.reduced.red {
            background: #dc371c;
            color: #FFFFFF; }
      .product-item .product-item-inner .pricebox .unit {
        font-size: 1.2rem; }
      .product-item .product-item-inner .pricebox .crossedOldPrice {
        font-weight: normal;
        position: relative; }
        .product-item .product-item-inner .pricebox .crossedOldPrice:before {
          position: absolute;
          content: " ";
          width: 100%;
          border-top: 1px solid #575757;
          top: 50%; }
    .product-item .product-item-inner .product-search-color {
      display: flex;
      align-content: center;
      justify-content: center;
      margin-top: 1.5rem; }
      .product-item .product-item-inner .product-search-color .color {
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid #aeaeae;
        padding: .2rem;
        margin: 0 .1rem; }
        .product-item .product-item-inner .product-search-color .color span {
          display: block;
          width: 100%;
          height: 100%; }
          .product-item .product-item-inner .product-search-color .color span.c_10 {
            background: #000000; }
            .product-item .product-item-inner .product-search-color .color span.c_10 i.ra-icon.check {
              color: #FFFFFF !important; }
          .product-item .product-item-inner .product-search-color .color span.c_20 {
            background: #FFFFFF; }
          .product-item .product-item-inner .product-search-color .color span.c_30 {
            background: #0000FF; }
            .product-item .product-item-inner .product-search-color .color span.c_30 i.ra-icon.check {
              color: #FFFFFF !important; }
          .product-item .product-item-inner .product-search-color .color span.c_40 {
            background: #22A2A4; }
          .product-item .product-item-inner .product-search-color .color span.c_50 {
            background: #47B734; }
            .product-item .product-item-inner .product-search-color .color span.c_50 i.ra-icon.check {
              color: #FFFFFF !important; }
          .product-item .product-item-inner .product-search-color .color span.c_60 {
            background: #FFFF00; }
          .product-item .product-item-inner .product-search-color .color span.c_70 {
            background: #F8E7AC; }
          .product-item .product-item-inner .product-search-color .color span.c_80 {
            background: #FF8B1C; }
          .product-item .product-item-inner .product-search-color .color span.c_90 {
            background: #FF0000; }
            .product-item .product-item-inner .product-search-color .color span.c_90 i.ra-icon.check {
              color: #FFFFFF !important; }
          .product-item .product-item-inner .product-search-color .color span.c_100 {
            background: #ED008C; }
          .product-item .product-item-inner .product-search-color .color span.c_110 {
            background: #733196; }
            .product-item .product-item-inner .product-search-color .color span.c_110 i.ra-icon.check {
              color: #FFFFFF !important; }
          .product-item .product-item-inner .product-search-color .color span.c_120 {
            background: #867326; }
            .product-item .product-item-inner .product-search-color .color span.c_120 i.ra-icon.check {
              color: #FFFFFF !important; }
          .product-item .product-item-inner .product-search-color .color span.c_130 {
            background: #B4B4B4; }
          .product-item .product-item-inner .product-search-color .color span.c_140 {
            background: #fceabb;
            /* Old browsers */
            /* FF3.6-15 */
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(135deg, #fceabb 0%, #fccd4d 50%, #f8b500 51%, #fbdf93 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$filter-color-variant-gold-part1', endColorstr='$filter-color-variant-gold-part4',GradientType=1 );
            /* IE6-9 fallback on horizontal gradient */ }
          .product-item .product-item-inner .product-search-color .color span.c_150 {
            background: #f5f6f6;
            /* Old browsers */
            /* FF3.6-15 */
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(135deg, #f5f6f6 0%, #dbdce2 21%, #b8bac6 49%, #dddfe3 80%, #f5f6f6 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$filter-color-variant-silver-part1', endColorstr='$filter-color-variant-silver-part5',GradientType=1 );
            /* IE6-9 fallback on horizontal gradient */ }
          .product-item .product-item-inner .product-search-color .color span.c_160 {
            background: #E1FFFF;
            /* Old browsers */
            /* FF3.6-15 */
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(135deg, #e1ffff 0%, #e1ffff 7%, #e1ffff 12%, #fdffff 12%, #e6f8fd 30%, #c8eefb 54%, #bee4f8 75%, #b1d8f5 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1ffff', endColorstr='#b1d8f5',GradientType=1 );
            /* IE6-9 fallback on horizontal gradient */ }
      .product-item .product-item-inner .product-search-color .more-colors {
        height: 1.5rem;
        border: 1px solid #aeaeae;
        padding: .2rem;
        margin: 0 .1rem;
        line-height: 1rem;
        font-size: 1rem; }
  .product-item .more-colors {
    color: #575757; }
  .product-item:hover .product-img-wrapper .product-hover-img {
    opacity: 1; }

.product-item-to-basket-layer, .product-item-noticelist-layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(87, 87, 87, 0.4);
  visibility: hidden;
  padding: 6rem 1rem 1rem 1rem; }
  .product-item-to-basket-layer button, .product-item-to-basket-layer a, .product-item-noticelist-layer button, .product-item-noticelist-layer a {
    margin-bottom: 1rem; }
  .product-item-to-basket-layer .product-item-to-basket-layer-info, .product-item-noticelist-layer .product-item-to-basket-layer-info {
    background-color: #FFFFFF;
    border: 1px solid #aeaeae;
    width: 100%;
    padding: 1rem; }
    .product-item-to-basket-layer .product-item-to-basket-layer-info span, .product-item-noticelist-layer .product-item-to-basket-layer-info span {
      width: 100%;
      display: block;
      font-size: 1.2rem; }
      .product-item-to-basket-layer .product-item-to-basket-layer-info span i, .product-item-noticelist-layer .product-item-to-basket-layer-info span i {
        font-size: 1.6rem; }

.product-item:hover > .product-item-to-basket-layer {
  visibility: visible; }

.product-item:hover > .product-item-noticelist-layer {
  visibility: visible; }

.to-article-btn {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
  width: 50%; }
  @media only screen and (max-width: 900px) {
    .to-article-btn {
      max-width: 90%; } }
  .to-article-btn.extra-margin {
    margin-top: 2.7rem; }

.more-variants-info {
  display: block;
  margin-top: 2rem;
  font-size: 1.2rem;
  font-style: italic; }
  .more-variants-info i {
    font-style: normal; }

.category-item {
  text-align: center;
  width: 100%; }
  .category-item .category-item-title {
    margin-top: 1.5rem;
    line-height: 1.7rem;
    margin-bottom: 1rem;
    font-weight: bold;
    overflow: hidden; }
  .category-item .customImage {
    max-width: 187px; }
  .category-item .category-link {
    display: block;
    width: 100%;
    height: 100%; }

.category-item-default {
  text-align: center; }
  .category-item-default .category-link {
    display: block;
    width: 100%;
    height: 100%; }
    .category-item-default .category-link .category-item-title {
      margin-top: 1.5rem;
      line-height: 1.7rem;
      margin-bottom: 2rem;
      font-weight: bold;
      overflow: hidden; }
    .category-item-default .category-link .image-lightgrey {
      background: #f6f6f6; }
      .category-item-default .category-link .image-lightgrey .categoryImage {
        mix-blend-mode: multiply; }
  .category-item-default .image-container {
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative; }
    .category-item-default .image-container .pictureFullHeight {
      height: 100%; }
    .category-item-default .image-container .pictureFullWidth {
      width: 100%; }
    .category-item-default .image-container .hoverPictureFullHeight {
      height: 100%; }
    .category-item-default .image-container .hoverPictureFullWidth {
      width: 100%; }

.category-item-img-button {
  transition: background 0.5s;
  height: 8rem; }
  .category-item-img-button:hover {
    background: #f6f6f6; }
  .category-item-img-button.box-bg-lightgrey {
    background: #f6f6f6;
    transition: background 0.5s; }
    .category-item-img-button.box-bg-lightgrey:hover {
      background: #FFFFFF !important; }
  .category-item-img-button .right {
    justify-content: space-between; }
  .category-item-img-button .category-link {
    display: flex;
    height: inherit;
    gap: 1rem;
    padding: 0 1rem 0 1rem; }
    .category-item-img-button .category-link .image-container {
      align-self: center;
      height: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative; }
    .category-item-img-button .category-link .image-lightgrey {
      background: #f6f6f6; }
      .category-item-img-button .category-link .image-lightgrey .categoryImage {
        mix-blend-mode: multiply; }
    .category-item-img-button .category-link .category-item-title {
      align-self: center;
      line-height: 1.7rem;
      margin-bottom: 0;
      font-weight: bold;
      overflow: hidden;
      max-height: 100%;
      max-width: 70%; }
  .category-item-img-button .image-container {
    aspect-ratio: 1; }

.image-container .pictureFullHeight {
  height: 100%; }

.image-container .pictureFullWidth {
  width: 100%; }

.image-container .hoverPictureFullHeight {
  height: 100%; }

.image-container .hoverPictureFullWidth {
  width: 100%; }

.category-button {
  padding-right: 1px;
  padding-left: 1px; }
  .category-button .category-item-button {
    width: inherit; }
    .category-button .category-item-button .category-link {
      width: inherit;
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
      .category-button .category-item-button .category-link .category-item-title {
        margin-bottom: 0; }
  .category-button .btn-highlight, .category-button .btn-darkgrey, .category-button .btn-black, .category-button .btn-special, .category-button .btn-confirm, .category-button .btn-rating {
    color: #FFFFFF; }
  .category-button .btn-white, .category-button .btn-lightgrey {
    color: #575757; }

.boxContour .category-item-img-button {
  border: 1px solid #e3e3e3;
  transition: background 0.5s; }
  .boxContour .category-item-img-button:hover {
    border: 1px solid #e3e3e3;
    background: #f6f6f6; }
  .boxContour .category-item-img-button.lightgrey {
    border: 1px solid #e3e3e3;
    transition: background 0.5s; }
    .boxContour .category-item-img-button.lightgrey:hover {
      border: 1px solid #e3e3e3;
      background: #FFFFFF; }

.boxContour .category-item-default {
  border: 1px solid #e3e3e3; }
  .boxContour .category-item-default.boxFullHeight {
    height: 100%; }

.imageContour .image-container {
  border: 1px solid #e3e3e3; }

.imageContour .category-item-img-button.lightgrey .categoryImage {
  border: 1px solid #e3e3e3; }

.imageContour .category-item-img-button.lightgrey:hover .categoryImage {
  border: 1px solid #e3e3e3; }

.circleImage .image-container {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative; }

.list-container.subcatList .gridView {
  margin-bottom: 4rem; }

.hoverImage {
  opacity: 0;
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain; }

.hasHoverImage:hover .hoverImage {
  opacity: 1; }

.hasHoverImage:hover .customImage {
  opacity: 0; }

.adviser-box {
  margin-bottom: 20px; }
  .adviser-box #adviserAnswers {
    margin: 10px 0 0 0; }
    .adviser-box #adviserAnswers .small {
      font-size: 0.8em; }
    .adviser-box #adviserAnswers .adviserSelect {
      text-align: center;
      font-weight: bold;
      line-height: 3rem;
      padding: 0 10px 10px 0; }
      .adviser-box #adviserAnswers .adviserSelect .wrapper {
        border: 1px solid #d0d0d0;
        background: #e3e3e3; }
      .adviser-box #adviserAnswers .adviserSelect a {
        display: block; }
      .adviser-box #adviserAnswers .adviserSelect i.ra-icon.info {
        position: absolute;
        right: 18px;
        top: 18px;
        font-size: 1.2em; }

.ribbons .ribbon {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center; }
  .ribbons .ribbon span {
    padding: 0 1rem; }
  .ribbons .ribbon.special {
    background-color: #dc371c;
    color: #FFFFFF; }
  .ribbons .ribbon.recommendation {
    background-color: #f59100;
    color: #FFFFFF; }
    .ribbons .ribbon.recommendation i.ra-icon {
      color: white;
      padding: .5rem !important;
      font-size: 1.6rem; }
    .ribbons .ribbon.recommendation span {
      padding: 0 0.5rem 0.5rem 0 !important; }
  .ribbons .ribbon.artnum {
    background-color: #747474;
    color: #FFFFFF; }
  .ribbons .ribbon.darkgrey {
    background-color: #575757;
    color: #FFFFFF; }
  .ribbons .ribbon.sustained {
    background-color: #FFFFFF;
    color: #308136;
    border: 0.1rem solid #308136; }
    .ribbons .ribbon.sustained .sustained-text {
      padding: 0 .5rem 0 0 !important;
      letter-spacing: 0.1rem;
      font-size: 1.4rem; }
    .ribbons .ribbon.sustained .sustained-icon {
      padding: 0 .5rem !important; }
    .ribbons .ribbon.sustained .ra-icon.sustained {
      font-size: 1.6rem; }
  .ribbons .ribbon.individual-measurement {
    background-color: #FFFFFF;
    border: 0.1rem solid #00569d;
    color: #00569d;
    max-height: 2.5rem;
    overflow: hidden;
    word-break: break-all; }
    .ribbons .ribbon.individual-measurement.inverse {
      background-color: #00569d;
      border: 0.1rem solid #00569d;
      color: #FFFFFF; }
    .ribbons .ribbon.individual-measurement .individual-measurement-text {
      padding: 0 .5rem !important; }
    .ribbons .ribbon.individual-measurement .individual-measurement-icon {
      font-size: 1.8rem;
      padding: 0 0 0 .5rem !important;
      vertical-align: sub; }

.ribbons .attribute-ribbon span {
  display: block;
  padding: 0 .25rem; }
  .ribbons .attribute-ribbon span i {
    font-size: 2rem;
    margin-top: .1rem;
    line-height: 2.4rem; }

.ribbons .attribute-ribbon.no-border {
  background-color: transparent;
  border: 1px solid transparent; }

.ribbons .attribute-ribbon.custom div.custom-svg {
  display: inline-block;
  height: 2.4rem;
  justify-content: center;
  align-items: center;
  padding: .5rem; }
  .ribbons .attribute-ribbon.custom div.custom-svg svg {
    margin: auto;
    height: 1.4rem;
    width: auto;
    vertical-align: middle;
    display: block; }

.ribbons .attribute-ribbon.lightgrey {
  color: #e3e3e3; }

.ribbons .attribute-ribbon.border-lightgrey {
  background-color: #FFFFFF;
  border: 1px solid #e3e3e3; }

.ribbons .attribute-ribbon.lightgrey-o {
  color: #f6f6f6; }

.ribbons .attribute-ribbon.border-lightgrey-o {
  background-color: #FFFFFF;
  border: 1px solid #f6f6f6; }

.ribbons .attribute-ribbon.darkgrey {
  color: #575757; }

.ribbons .attribute-ribbon.border-darkgrey {
  background-color: #FFFFFF;
  border: 1px solid #575757; }

.ribbons .attribute-ribbon.darkgrey-o {
  color: #747474; }

.ribbons .attribute-ribbon.border-darkgrey-o {
  background-color: #FFFFFF;
  border: 1px solid #747474; }

.ribbons .attribute-ribbon.border {
  color: #aeaeae; }

.ribbons .attribute-ribbon.border-border {
  background-color: #FFFFFF;
  border: 1px solid #aeaeae; }

.ribbons .attribute-ribbon.confirm {
  color: #308136; }

.ribbons .attribute-ribbon.border-confirm {
  background-color: #FFFFFF;
  border: 1px solid #308136; }

.ribbons .attribute-ribbon.highlight {
  color: #00569d; }

.ribbons .attribute-ribbon.border-highlight {
  background-color: #FFFFFF;
  border: 1px solid #00569d; }

.ribbons .attribute-ribbon.special {
  color: #dc371c; }

.ribbons .attribute-ribbon.border-special {
  background-color: #FFFFFF;
  border: 1px solid #dc371c; }

.ribbons .attribute-ribbon.rating {
  color: #f59100; }

.ribbons .attribute-ribbon.border-rating {
  background-color: #FFFFFF;
  border: 1px solid #f59100; }

#loginModal .modal-body, #liveChatModal .modal-body {
  padding: 0; }

#loginModal h2, #liveChatModal h2 {
  margin-bottom: 1rem; }

#loginModal label, #liveChatModal label {
  width: 100%;
  text-align: left;
  font-weight: bold;
  margin-top: .5rem; }

#loginModal .headline, #liveChatModal .headline {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem; }

#loginModal .btn, #liveChatModal .btn {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  padding-top: .7rem; }
  #loginModal .btn.btn-login, #liveChatModal .btn.btn-login {
    margin-top: 3rem; }
  #loginModal .btn .ra-icon, #liveChatModal .btn .ra-icon {
    font-size: 2rem; }
  #loginModal .btn span, #liveChatModal .btn span {
    display: inline-block;
    transform: translate(0, -0.3rem); }

#loginModal .top-area, #liveChatModal .top-area {
  padding: 2rem 0; }

#loginModal .bottom-area, #liveChatModal .bottom-area {
  background-color: #f6f6f6;
  padding: 3rem 0; }

#loginModal .password-lost, #liveChatModal .password-lost {
  padding: 3rem 0; }
  #loginModal .password-lost a, #liveChatModal .password-lost a {
    font-weight: bold; }

#loginModal .close-btn, #liveChatModal .close-btn {
  margin: .5rem;
  font-size: 2.3rem;
  cursor: pointer; }

#loginModal .loginError, #liveChatModal .loginError {
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #dc371c;
  color: #dc371c;
  background-color: #fbe6e2; }
  #loginModal .loginError .info, #liveChatModal .loginError .info {
    font-size: 1.4rem;
    line-height: 1.4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
  #loginModal .loginError .icon, #liveChatModal .loginError .icon {
    font-size: 4.2rem;
    line-height: 4.2rem;
    align-items: center;
    justify-content: center;
    display: inline-flex; }

#liveChatModal .modal-dialog {
  max-width: 70%; }
  #liveChatModal .modal-dialog .btn {
    max-width: 25%;
    margin: 1rem; }

#liveChatFakeIcon {
  position: fixed;
  right: calc(0% + 24px);
  bottom: calc(0% + 20px);
  width: 64px;
  height: 64px; }
  #liveChatFakeIcon button {
    color: #FFFFFF;
    background-color: #00569d;
    font-size: 4rem;
    width: 100%;
    height: 100%;
    /* Safari, Chrome and Opera > 12.1 */
    /* Firefox < 16 */
    /* Internet Explorer */
    /* Opera < 12.1 */
    animation: fadein 2s;
    /* Firefox < 16 */
    /* Safari, Chrome and Opera > 12.1 */
    /* Internet Explorer */
    /* Opera < 12.1 */ }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
    #liveChatFakeIcon button.btn-circle {
      border-radius: 50%; }

#mobile-order-modal .modal-dialog {
  max-width: 50rem;
  margin-top: 12rem; }

#mobile-order-modal .modal-content {
  border: 1px solid #aeaeae; }

#mobile-order-modal .modal-title {
  font-size: 1.6rem;
  font-weight: 700; }

#mobile-order-modal .modal-header {
  display: block;
  border-bottom: none; }
  #mobile-order-modal .modal-header button.close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2.4rem; }

#mobile-order-modal .modal-footer {
  background-color: #f6f6f6; }
  #mobile-order-modal .modal-footer button {
    width: 100%; }

.modal.direct .close-btn, .modal.scroll .close-btn, .modal.exitIntent .close-btn {
  font-size: 2.3rem;
  cursor: pointer; }

.modal.direct .modal-body > .close-btn, .modal.scroll .modal-body > .close-btn, .modal.exitIntent .modal-body > .close-btn {
  padding: .5rem;
  font-size: 2.3rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
  background: #FFFFFF; }

.modal .modal-dialog.ra-big-modal {
  max-width: 1400px; }

.modal.mobile {
  text-align: left;
  width: 70%;
  position: fixed;
  left: 15%; }
  .modal.mobile .modal-body {
    padding: 1rem;
    margin-bottom: 4rem; }
    .modal.mobile .modal-body.btn {
      background-color: #00569d; }
  .modal.mobile .close-btn {
    font-size: 2.3rem;
    cursor: pointer; }

#raroombuilder-modal-load .modal-content.multipage {
  min-height: 54rem; }

#raroombuilder-modal-load .not-available label {
  background-color: #f6f6f6;
  cursor: not-allowed !important;
  opacity: 0.6; }

#raroombuilder-modal-load .not-available-info {
  color: #dc371c;
  font-size: 1rem; }

#raroombuilder-modal-load .configuration-item label {
  position: relative;
  border: 1px solid #aeaeae;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  opacity: 0.9; }
  #raroombuilder-modal-load .configuration-item label .active-icon {
    color: #308136;
    position: absolute;
    top: .5rem;
    right: .5rem;
    display: none;
    font-size: 3rem; }
  #raroombuilder-modal-load .configuration-item label .configuration-title {
    padding-right: 2.5rem; }
  #raroombuilder-modal-load .configuration-item label.active {
    border: 1px solid #308136;
    opacity: 1; }
    #raroombuilder-modal-load .configuration-item label.active .active-icon {
      display: block; }
  #raroombuilder-modal-load .configuration-item label .save-date {
    position: absolute;
    right: 0;
    bottom: 0; }

#raroombuilder-modal-load #modal-load-pagination {
  height: 4rem;
  line-height: 3.5rem;
  border: 1px solid #aeaeae;
  display: inline-block;
  text-align: center;
  padding: 0 1rem;
  margin-top: .1rem; }

#raroombuilder-modal-load .btn-pagination {
  padding-left: 1rem; }

#raroombuilder-modal-load .modal-footer button i {
  vertical-align: middle; }

#raroombuilder-modal-save .overwrite-info {
  font-weight: bold;
  font-size: 1.2rem;
  color: #dc371c; }

#raroombuilder-modal-save label {
  padding: 1rem; }

#raroombuilder-modal-save .disabled {
  background-color: rgba(246, 246, 246, 0.5);
  -webkit-backdrop-filter: blur(1.25px);
          backdrop-filter: blur(1.25px);
  cursor: not-allowed; }
  #raroombuilder-modal-save .disabled input {
    cursor: not-allowed; }

#ra-details-scale-price-modal .modal-header .modal-title {
  display: block;
  width: 100%;
  text-align: center;
  color: #00569d; }

#ra-details-scale-price-modal .modal-header .close {
  font-size: 2rem;
  position: absolute;
  right: 1rem;
  top: 1rem; }

#ra-details-scale-price-modal .modal-body {
  padding: 2rem 4rem; }
  #ra-details-scale-price-modal .modal-body ul {
    list-style: none;
    padding: 0 1rem 1rem 1rem;
    display: table;
    margin: 0 auto; }
    #ra-details-scale-price-modal .modal-body ul li {
      display: table-row;
      margin: .5rem 0; }
  #ra-details-scale-price-modal .modal-body .left {
    padding-right: 3rem;
    display: table-cell; }
  #ra-details-scale-price-modal .modal-body .right {
    padding-left: 3rem;
    display: table-cell;
    text-align: right; }
  #ra-details-scale-price-modal .modal-body li {
    padding: .5rem 0; }
  #ra-details-scale-price-modal .modal-body .base-price {
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 400; }
  #ra-details-scale-price-modal .modal-body li.special-offer {
    color: #dc371c;
    font-weight: bold; }

#rabfc-modal .modal-footer {
  background-color: #e3e3e3;
  justify-content: center; }

#ra-article-attribute-modal i.close-btn {
  font-size: 2rem; }

#ra-article-attribute-modal table {
  width: 100%;
  margin-right: 0; }

#ra-shop-modal .modal-body {
  padding-top: 2rem; }
  #ra-shop-modal .modal-body .close {
    font-size: 2rem;
    position: absolute;
    top: .5rem;
    right: .5rem;
    font-weight: bold;
    outline: none; }
    #ra-shop-modal .modal-body .close:focus {
      outline: none; }
  #ra-shop-modal .modal-body .button-row {
    margin-top: 2rem; }

.rageoselect_modal_title {
  font-size: 1.6rem;
  font-weight: 700; }

#ra-print-modal .modal-dialog {
  max-width: 58rem; }
  #ra-print-modal .modal-dialog .modal-content {
    padding: 0; }
    #ra-print-modal .modal-dialog .modal-content .modal-body {
      padding: 0;
      background-color: #f6f6f6;
      background-clip: content-box; }
      #ra-print-modal .modal-dialog .modal-content .modal-body .container-fluid {
        padding: 0; }
      #ra-print-modal .modal-dialog .modal-content .modal-body .no-bg {
        background-color: #FFFFFF; }
  #ra-print-modal .modal-dialog input.has-error {
    border: 1px solid #dc371c; }
    #ra-print-modal .modal-dialog input.has-error::-moz-placeholder {
      color: #dc371c; }
    #ra-print-modal .modal-dialog input.has-error:-ms-input-placeholder {
      color: #dc371c; }
    #ra-print-modal .modal-dialog input.has-error::placeholder {
      color: #dc371c; }
  #ra-print-modal .modal-dialog #ra-print-motif-name-error {
    color: #dc371c; }
  #ra-print-modal .modal-dialog .headline {
    margin-top: 2rem;
    margin-bottom: 3rem;
    text-align: center; }
  #ra-print-modal .modal-dialog .info-text {
    margin-bottom: 4rem;
    text-align: center; }
  #ra-print-modal .modal-dialog .input-col {
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    text-align: center;
    background-color: #f6f6f6; }
    #ra-print-modal .modal-dialog .input-col h4 {
      margin-bottom: 1rem; }
    #ra-print-modal .modal-dialog .input-col label {
      margin-top: 1rem; }
      #ra-print-modal .modal-dialog .input-col label input {
        margin-right: .5rem; }
  #ra-print-modal .modal-dialog .ra-print-motif-no-name-info {
    margin-top: 2rem;
    margin-bottom: 2rem; }
    #ra-print-modal .modal-dialog .ra-print-motif-no-name-info .icon {
      font-size: 3.5rem;
      position: relative; }
      #ra-print-modal .modal-dialog .ra-print-motif-no-name-info .icon i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    #ra-print-modal .modal-dialog .ra-print-motif-no-name-info .text {
      text-align: left;
      font-size: 1.2rem;
      font-style: italic;
      line-height: 1.4rem; }
  #ra-print-modal .modal-dialog label.motif-selection {
    display: block; }
  #ra-print-modal .modal-dialog button.new-motif {
    margin: 2rem 0 1rem 0; }
  #ra-print-modal .modal-dialog .button-col {
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 2rem; }
    #ra-print-modal .modal-dialog .button-col a {
      display: block;
      font-size: 1.1rem;
      text-decoration: underline;
      margin-top: 1rem; }

#ra-delete-upload-modal .modal-header h2 {
  text-align: center;
  display: block;
  width: 100%; }

#ra-delete-upload-modal .modal-body {
  background-color: #f6f6f6;
  background-clip: content-box;
  height: 100%; }
  #ra-delete-upload-modal .modal-body .row {
    border: 1px solid #aeaeae;
    padding: 1rem 0; }
  #ra-delete-upload-modal .modal-body .preview {
    overflow: hidden;
    word-wrap: anywhere;
    text-align: center; }
    #ra-delete-upload-modal .modal-body .preview img {
      display: block; }
    #ra-delete-upload-modal .modal-body .preview .no-preview-icon {
      display: block;
      font-size: 5rem; }
    #ra-delete-upload-modal .modal-body .preview .no-preview-text {
      display: block;
      font-size: 1.6rem;
      font-weight: 700; }
  #ra-delete-upload-modal .modal-body .info .name {
    font-weight: 700;
    display: block;
    overflow: hidden;
    word-break: break-word; }
  #ra-delete-upload-modal .modal-body .info span {
    display: inline-block; }
    #ra-delete-upload-modal .modal-body .info span.upload-date {
      display: block;
      margin-top: 1rem;
      font-size: 1.1rem; }

#ra-delete-upload-modal .modal-footer a, #ra-delete-upload-modal .modal-footer button {
  display: inline-block;
  width: 40%; }

#raconfigurator-summary-modal .close-btn {
  font-size: 2rem; }

#raconfigurator-summary-modal h4 {
  color: #00569d; }

#raconfigurator-summary-modal table {
  width: 100%;
  margin: 0; }
  #raconfigurator-summary-modal table tr:first-child td, #raconfigurator-summary-modal table tr:first-child th {
    border: none; }

#ra-basket-shipping-modal .modal-body {
  padding: 1rem; }
  #ra-basket-shipping-modal .modal-body .row {
    margin-bottom: 2rem; }
  #ra-basket-shipping-modal .modal-body .row:last-child {
    margin-bottom: 0; }

#ra-basket-shipping-modal .modal-footer {
  justify-content: center; }

#userBlockedModal {
  text-align: center; }
  #userBlockedModal .top-area {
    margin-right: auto;
    margin-left: auto; }
    #userBlockedModal .top-area .h1 {
      color: #dc371c; }
  #userBlockedModal .modal-header {
    background-color: #f6f6f6; }
  #userBlockedModal .contact-info {
    background-color: #f6f6f6; }
    #userBlockedModal .contact-info .container {
      padding: 1rem;
      width: 75%; }
  #userBlockedModal a {
    width: 100%; }

#ra-share-modal .share-email .ra-icon, #ra-share-modal .share-menu-copy-link .ra-icon {
  font-size: 2rem;
  margin-right: 1rem;
  vertical-align: middle; }

#ra-contact-modal.modal.fade:not(.in).right .modal-dialog {
  transform: translate3d(0, 0, 0); }

#ra-contact-modal .modal-dialog {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  left: calc(100vw - 40rem); }
  @media screen and (max-width: 40rem) {
    #ra-contact-modal .modal-dialog {
      left: 0; } }
  #ra-contact-modal .modal-dialog .modal-content {
    min-height: 100%;
    height: auto;
    max-height: 200%; }

#ra-contact-modal .modal-header {
  border: none !important;
  background-color: #f6f6f6; }
  #ra-contact-modal .modal-header .close {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    gap: 0 1rem;
    cursor: pointer; }
    #ra-contact-modal .modal-header .close i {
      font-size: 2rem; }

#ra-contact-modal .modal-body {
  padding: 0;
  font-size: 1.6rem; }
  #ra-contact-modal .modal-body > div {
    padding: 1rem; }
  #ra-contact-modal .modal-body .h2 {
    font-weight: 700;
    font-size: 2rem; }
  #ra-contact-modal .modal-body .h4 {
    font-weight: 700;
    font-size: 1.6rem; }
  #ra-contact-modal .modal-body .contact-img-row {
    margin: 4rem 0; }
    #ra-contact-modal .modal-body .contact-img-row .contact-img {
      border-radius: 50%;
      border: 1px solid #aeaeae;
      overflow: hidden;
      z-index: 5;
      display: inline-block;
      margin: auto .75rem; }
      #ra-contact-modal .modal-body .contact-img-row .contact-img img {
        height: 7.2rem;
        width: 7.2rem;
        transform: scale(1);
        transition: all .4s linear; }
        #ra-contact-modal .modal-body .contact-img-row .contact-img img:hover {
          transform: scale(1.1);
          transition: all .4s linear; }
    #ra-contact-modal .modal-body .contact-img-row .no-contact-img i {
      font-size: 8rem; }
  #ra-contact-modal .modal-body ul {
    list-style: none;
    padding: 0 1rem; }
    #ra-contact-modal .modal-body ul li {
      display: flex;
      padding-top: 1rem;
      margin-top: 1rem;
      align-items: center;
      align-content: center;
      justify-items: flex-start;
      justify-content: flex-start;
      gap: 0 1rem;
      border-top: 1px solid #e3e3e3;
      transition: all 250ms ease-out; }
      #ra-contact-modal .modal-body ul li:hover {
        transition: all 250ms ease-in-out; }
        #ra-contact-modal .modal-body ul li:hover span.icon {
          color: #333333;
          background-color: transparent;
          border: 1px solid #00569d;
          border-radius: 50%;
          font-size: 2.6rem;
          animation-name: hoverIcon;
          animation-duration: 250ms;
          animation-direction: alternate; }

@keyframes hoverIcon {
  0% {
    border: 1px solid #00569d;
    scale: 1; }
  50% {
    border: 2px solid #00569d;
    scale: 1.02; }
  100% {
    border: 1px solid #00569d;
    scale: 1; } }
        #ra-contact-modal .modal-body ul li:hover span.icon-right {
          position: absolute;
          right: 1rem; }
          #ra-contact-modal .modal-body ul li:hover span.icon-right i {
            color: #00569d; }
            #ra-contact-modal .modal-body ul li:hover span.icon-right i.chevron-right {
              position: absolute;
              right: 0.8rem;
              animation-name: chevronIconIn;
              animation-duration: 250ms; }

@keyframes chevronIconIn {
  0% {
    right: 2rem; }
  100% {
    right: 0.8rem; } }
            #ra-contact-modal .modal-body ul li:hover span.icon-right i.minus-simple {
              position: absolute;
              right: 1.4rem;
              transform: scaleX(1);
              animation-name: arrowIconIn;
              animation-duration: 250ms; }

@keyframes arrowIconIn {
  0% {
    right: 1.8rem;
    transform: scaleX(0); }
  100% {
    right: 1.4rem;
    transform: scaleX(1); } }
      #ra-contact-modal .modal-body ul li:first-child {
        border: none; }
      #ra-contact-modal .modal-body ul li div {
        width: 75%;
        display: flex;
        justify-content: space-between;
        align-items: center; }
      #ra-contact-modal .modal-body ul li span.icon {
        display: flex;
        justify-content: center;
        justify-items: center;
        align-content: center;
        align-items: center;
        height: 4rem;
        max-width: 4rem;
        min-width: 4rem;
        border-radius: 50%;
        background-color: #f6f6f6;
        font-size: 2.6rem; }
      #ra-contact-modal .modal-body ul li span.icon-right {
        display: flex;
        justify-content: center;
        justify-items: center;
        align-content: center;
        align-items: center;
        position: absolute;
        right: 1rem; }
        #ra-contact-modal .modal-body ul li span.icon-right i {
          font-weight: 700;
          font-size: 1.8rem; }
          #ra-contact-modal .modal-body ul li span.icon-right i.chevron-right {
            position: absolute;
            right: 2rem;
            animation-name: chevronIconOut;
            animation-duration: 250ms; }

@keyframes chevronIconOut {
  0% {
    right: 0.8rem; }
  100% {
    right: 2rem; } }
          #ra-contact-modal .modal-body ul li span.icon-right i.minus-simple {
            position: absolute;
            right: 1.4rem;
            transform: scaleX(0);
            animation-name: arrowIconOut;
            animation-duration: 250ms; }

@keyframes arrowIconOut {
  0% {
    right: 1.4rem;
    transform: scaleX(1); }
  100% {
    right: 1.8rem;
    transform: scaleX(0); } }
  #ra-contact-modal .modal-body .ra-details-contact-additional-info {
    padding: 1rem;
    transition: all 1s; }

.modal .modal-fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-width: none;
  margin: 0; }

#zoomPicturesModal {
  z-index: 2147483646; }
  #zoomPicturesModal .controls-top-left, #zoomPicturesModal .controls-top-right {
    position: absolute;
    top: 1rem; }
    #zoomPicturesModal .controls-top-left .control-btn, #zoomPicturesModal .controls-top-right .control-btn {
      background-color: #00569d;
      border-radius: 100%;
      opacity: .75;
      margin: 3px;
      transition: .25s opacity;
      outline: none;
      color: #FFFFFF;
      padding: 0;
      font-size: 4rem;
      width: 40px;
      height: 40px;
      line-height: 0; }
      #zoomPicturesModal .controls-top-left .control-btn:disabled, #zoomPicturesModal .controls-top-right .control-btn:disabled {
        background-color: #e3e3e3;
        cursor: not-allowed; }
        #zoomPicturesModal .controls-top-left .control-btn:disabled:hover, #zoomPicturesModal .controls-top-right .control-btn:disabled:hover {
          background-color: #e3e3e3; }
      #zoomPicturesModal .controls-top-left .control-btn:hover, #zoomPicturesModal .controls-top-right .control-btn:hover {
        opacity: .9; }
      #zoomPicturesModal .controls-top-left .control-btn:focus, #zoomPicturesModal .controls-top-right .control-btn:focus {
        box-shadow: none !important; }
  #zoomPicturesModal .controls-top-left {
    left: 1rem; }
  #zoomPicturesModal .controls-top-right {
    right: 1rem; }
  #zoomPicturesModal .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 0; }
    #zoomPicturesModal .modal-body #zoom-picture-viewer {
      width: 100%;
      height: 100%;
      cursor: grab; }
      #zoomPicturesModal .modal-body #zoom-picture-viewer.active {
        cursor: grabbing; }
      #zoomPicturesModal .modal-body #zoom-picture-viewer .openseadragon-canvas {
        outline: none; }
    #zoomPicturesModal .modal-body img {
      max-height: 100%;
      max-width: 100%; }
    #zoomPicturesModal .modal-body #zoom-loading-box {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -50px;
      margin-top: -50px;
      height: 100px;
      width: 100px;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.75);
      color: #00569d;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.25);
      display: none; }
      #zoomPicturesModal .modal-body #zoom-loading-box.show {
        display: flex; }
      #zoomPicturesModal .modal-body #zoom-loading-box .ra-icon {
        animation: rotate-zoom-loader .75s linear infinite; }

@keyframes rotate-zoom-loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(-359deg); } }
  #zoomPicturesModal .modal-footer {
    justify-content: center;
    display: block;
    white-space: nowrap;
    overflow-x: auto; }
    #zoomPicturesModal .modal-footer .pic-desc-container > div:not(.hidden) {
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 1rem; }
    #zoomPicturesModal .modal-footer .more-img-container img {
      max-height: 10rem;
      cursor: pointer; }
      #zoomPicturesModal .modal-footer .more-img-container img.active {
        border: 2px solid #333333; }
  #zoomPicturesModal .btn-prev, #zoomPicturesModal .btn-next {
    position: absolute;
    top: 50%;
    margin-top: -80px;
    height: 50px;
    width: 50px;
    border: 1px solid #333333;
    background-color: #FFFFFF;
    opacity: .6;
    cursor: pointer;
    box-shadow: none !important;
    transition: .25s opacity;
    display: flex;
    justify-content: center;
    align-items: center; }
    #zoomPicturesModal .btn-prev:hover, #zoomPicturesModal .btn-next:hover {
      opacity: .8;
      color: #00569d; }
  #zoomPicturesModal .btn-prev {
    left: 1rem; }
  #zoomPicturesModal .btn-next {
    right: 1rem; }

#ra-article-review-modal .review-success-msg, #ra-article-review-modal .review-error-msg {
  margin: 3rem 0; }

#ra-article-review-modal .bootstrap-select {
  height: 4rem; }

#ra-article-review-modal .modal-title {
  margin-bottom: 2rem; }

#ra-article-review-modal #ra-review-form .has-error input[type="text"], #ra-article-review-modal #ra-review-form .has-error input[type="email"], #ra-article-review-modal #ra-review-form .has-error textarea {
  border: 1px solid #dc371c; }

#ra-article-review-modal #ra-review-form .error {
  color: #dc371c; }

#ra-article-review-modal #ra-review-form label {
  display: block;
  width: 100%; }
  #ra-article-review-modal #ra-review-form label:not(.privacy-label) {
    font-weight: bold; }
  #ra-article-review-modal #ra-review-form label span {
    font-weight: normal;
    float: right;
    font-size: 1.2rem; }

#ra-article-review-modal #ra-review-form .stars-group.has-error .stars .rating-info {
  color: #dc371c; }

#ra-article-review-modal #ra-review-form .stars-group.has-error .error {
  display: none !important; }

#ra-article-review-modal #ra-review-form .stars {
  display: flex;
  align-content: center;
  align-items: center; }
  #ra-article-review-modal #ra-review-form .stars input[type="radio"] {
    visibility: hidden;
    height: 1px;
    width: 1px; }
    #ra-article-review-modal #ra-review-form .stars input[type="radio"]::after {
      visibility: hidden;
      height: 1px;
      width: 1px; }
  #ra-article-review-modal #ra-review-form .stars .ra-icon {
    color: #f59100;
    font-size: 3rem;
    cursor: pointer; }
  #ra-article-review-modal #ra-review-form .stars span {
    margin-left: 2rem; }
  #ra-article-review-modal #ra-review-form .stars label {
    width: auto;
    margin: 0 !important; }

#ra-article-review-modal #ra-review-form .radio-group .headline {
  font-size: 1.4rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-weight: bold; }

#ra-article-review-modal #ra-review-form .radio-group label {
  display: inline-flex;
  width: auto;
  margin-right: 2rem;
  align-content: center;
  align-items: center; }

#ra-article-review-modal #ra-review-form .radio-group.helpful-group label {
  cursor: pointer; }

#ra-article-review-modal #ra-review-form .radio-group.helpful-group .ra-icon {
  font-size: 3rem;
  margin-right: 0.5rem;
  color: #aeaeae; }

#ra-article-review-modal #ra-review-form .radio-group.helpful-group input[type="radio"] {
  display: none; }
  #ra-article-review-modal #ra-review-form .radio-group.helpful-group input[type="radio"]:hover + .ra-icon.check-circle-full {
    color: #308136; }
  #ra-article-review-modal #ra-review-form .radio-group.helpful-group input[type="radio"]:hover + .ra-icon.minus-full {
    color: #00569d; }
  #ra-article-review-modal #ra-review-form .radio-group.helpful-group input[type="radio"]:hover + .ra-icon.cross-full {
    color: #dc371c; }
  #ra-article-review-modal #ra-review-form .radio-group.helpful-group input[type="radio"]:checked + .ra-icon.check-circle-full {
    color: #308136; }
  #ra-article-review-modal #ra-review-form .radio-group.helpful-group input[type="radio"]:checked + .ra-icon.minus-full {
    color: #00569d; }
  #ra-article-review-modal #ra-review-form .radio-group.helpful-group input[type="radio"]:checked + .ra-icon.cross-full {
    color: #dc371c; }

#ra-article-review-modal #ra-review-form span.info {
  display: inline-block;
  font-size: 1.2rem;
  margin-bottom: .5rem; }

#ra-article-review-modal #ra-review-form span.review-headline-length, #ra-article-review-modal #ra-review-form span.review-text-length, #ra-article-review-modal #ra-review-form span.recommendation-reason-length {
  display: inline-block;
  font-size: 1.2rem;
  float: right; }

#ra-article-review-modal #ra-review-form .privacy-group {
  margin-top: 2rem; }
  #ra-article-review-modal #ra-review-form .privacy-group .error {
    display: none !important; }
  #ra-article-review-modal #ra-review-form .privacy-group.has-error input[type="checkbox"]::after {
    border: 1px solid #dc371c; }
  #ra-article-review-modal #ra-review-form .privacy-group.has-error label {
    color: #dc371c; }

#ra-article-review-modal .no-margin {
  margin-bottom: 0px !important; }

#ra-article-review-modal #ra-review-form-submit {
  margin-top: 2rem; }

.ra-review-learn-more {
  cursor: pointer; }

#cookieNote {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999; }
  #cookieNote .alert {
    margin: 0;
    background-color: rgba(87, 87, 87, 0.6);
    color: #FFFFFF;
    font-size: 1.2rem;
    font-style: italic;
    height: 8rem; }
  @media (max-width: 767.98px) {
    #cookieNote .alert {
      height: auto; }
    #cookieNote button {
      margin-top: .5rem; } }
  #cookieNote a {
    color: #FFFFFF;
    font-size: 1.2rem;
    font-style: italic; }
  #cookieNote button {
    width: 8rem;
    height: 4rem;
    border: 1px solid #FFFFFF; }

input[type='checkbox'] + label {
  vertical-align: middle; }

input[type='checkbox']:after {
  width: 1.7rem;
  height: 1.7rem;
  background-color: #FFFFFF;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 0.1rem solid #aeaeae;
  border-radius: .3rem;
  vertical-align: sub; }

input[type='checkbox']:disabled:after {
  background-color: #e3e3e3 !important;
  border: 0.1rem solid #575757 !important;
  cursor: not-allowed !important; }

input[type='checkbox']:checked:disabled:after {
  background-image: url("/out/ra-theme/img/icon_check_dark.png") !important;
  background-color: #e3e3e3 !important;
  background-position: center;
  background-size: 1.7rem;
  border: 0.1rem solid #575757 !important;
  cursor: not-allowed !important;
  overflow: hidden;
  padding: .2rem; }

input[type='checkbox']:checked:after {
  background-color: #00569d;
  background-image: url("/out/ra-theme/img/icon_check.png");
  background-position: center;
  background-size: 1.7rem;
  overflow: hidden;
  border: 0.1rem solid #00569d;
  padding: .2rem; }

input[type='checkbox'], input[type='checkbox']:checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent; }

input[type='checkbox']:focus {
  outline: none; }

label.form-label {
  display: flex;
  flex-direction: row; }
  label.form-label input {
    margin-right: 2rem; }

input[type=radio] + label {
  margin-right: 1.5rem;
  margin-left: .5rem; }

input[type='radio'], input[type='radio']:checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent; }

input[type='radio']:after {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 1.7rem;
  background-color: #FFFFFF;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 0.1rem solid #aeaeae;
  vertical-align: sub; }

input[type='radio']:checked:after {
  background-color: #00569d;
  background-clip: content-box;
  padding: .3rem; }

input[type='radio']:focus {
  outline: none; }

input[type='radio']:required {
  box-shadow: none; }

.bootstrap-select select {
  height: 34px !important; }

textarea {
  font-size: 1.4rem !important;
  line-height: 1.4 !important;
  height: 10rem !important; }

@media (max-width: 767.98px) {
  textarea {
    height: 14rem !important; } }

#header-banner {
  min-height: 4.5rem;
  max-height: 16rem;
  line-height: 4.4rem;
  width: 100%; }
  @media (max-width: 767.98px) {
    #header-banner {
      padding: 0.8rem 0;
      line-height: 2.5rem; } }
  #header-banner .row {
    align-items: center; }
  #header-banner.full-row {
    cursor: pointer; }
  #header-banner a {
    color: inherit; }
  #header-banner .ra-icon.cross {
    font-size: 2rem;
    line-height: 4.5rem;
    cursor: pointer;
    vertical-align: middle; }
  #header-banner .nobr {
    white-space: nowrap; }

.header-banner.border {
  background-color: #aeaeae;
  color: #FFFFFF; }

.header-banner.lightgrey {
  background-color: #e3e3e3;
  color: #575757; }

.header-banner.lightgrey-o {
  background-color: #f6f6f6;
  color: #575757; }

.header-banner.darkgrey {
  background-color: #575757;
  color: #FFFFFF; }

.header-banner.darkgrey-o {
  background-color: #747474;
  color: #FFFFFF; }

.header-banner.highlight {
  background-color: #00569d;
  color: #FFFFFF; }

.header-banner.special {
  background-color: #dc371c;
  color: #FFFFFF; }

.header-banner.confirm {
  background-color: #308136;
  color: #FFFFFF; }

.header-banner.rating {
  background-color: #f59100;
  color: #FFFFFF; }

.header-banner.black {
  background-color: #000000;
  color: #FFFFFF; }

.header-banner-fade.border {
  background-image: linear-gradient(to right, #aeaeae, #d4d4d4);
  color: #FFFFFF; }

.header-banner-fade.lightgrey {
  background-image: linear-gradient(to right, #e3e3e3, #f0f0f0);
  color: #575757; }

.header-banner-fade.lightgrey-o {
  background-image: linear-gradient(to right, #f6f6f6, white);
  color: #575757; }

.header-banner-fade.darkgrey {
  background-image: linear-gradient(to right, #575757, #8a8a8a);
  color: #FFFFFF; }

.header-banner-fade.darkgrey-o {
  background-image: linear-gradient(to right, #747474, #a7a7a7);
  color: #FFFFFF; }

.header-banner-fade.highlight {
  background-image: linear-gradient(to right, #00569d, #0080ea);
  color: #FFFFFF; }

.header-banner-fade.special {
  background-image: linear-gradient(to right, #dc371c, #ea6f5a);
  color: #FFFFFF; }

.header-banner-fade.confirm {
  background-image: linear-gradient(to right, #308136, #45b84e);
  color: #FFFFFF; }

.header-banner-fade.rating {
  background-image: linear-gradient(to right, #f59100, #ffb243);
  color: #FFFFFF; }

.header-banner-fade.black {
  background-image: linear-gradient(to right, #000000, #575757);
  color: #FFFFFF; }

.cms-carousel {
  margin-bottom: 3rem; }
  @media (max-width: 767.98px) {
    .cms-carousel .carousel-indicators {
      margin-bottom: -1.5rem; }
      .cms-carousel .carousel-indicators li {
        width: 2rem; } }
  .cms-carousel .carousel-indicators li {
    height: 1rem;
    border: 1px solid white;
    background-color: #747474; }
    .cms-carousel .carousel-indicators li.active {
      border: 1px solid #0080ea;
      background-color: #00569d; }
  .cms-carousel .carousel-control-prev, .cms-carousel .carousel-control-next {
    width: 5%;
    display: none; }
    .cms-carousel .carousel-control-prev .ra-icon, .cms-carousel .carousel-control-next .ra-icon {
      color: #FFFFFF;
      transition: text-shadow .3s;
      text-shadow: 0 0 3px #4e4e4e; }
    .cms-carousel .carousel-control-prev:hover .ra-icon, .cms-carousel .carousel-control-next:hover .ra-icon {
      text-shadow: 0 0 3px #1b1b1b; }
  .cms-carousel .carousel-item {
    overflow: hidden; }
    @media (min-width: 576px) {
      .cms-carousel .carousel-item .link-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }
  @media (max-width: 767.98px) {
    .cms-carousel {
      margin: 0 -2rem; } }
  .cms-carousel .start-slider .text-box .highlight {
    color: #00569d; }
  .cms-carousel .start-slider .text-box-title span.highlight-text, .cms-carousel .start-slider p span.highlight-text {
    color: #00569d; }
  .cms-carousel .start-slider .text-box-title {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.3; }
  .cms-carousel .start-slider p {
    font-size: 1.6rem;
    margin: 2rem 0; }
  .cms-carousel .start-slider ul li {
    font-size: 1.6rem; }
  .cms-carousel .start-slider .bg-img {
    display: block;
    height: 30rem;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
    transition: all .4s; }
    .cms-carousel .start-slider .bg-img img {
      height: 30rem; }
  @media (max-width: 767.98px) {
    .cms-carousel .start-slider .bg-img {
      height: 30rem; }
      .cms-carousel .start-slider .bg-img img {
        height: 30rem; } }
  @media (max-width: 991.98px) {
    .cms-carousel .start-slider .text-box-title {
      font-size: 2.3rem; }
    .cms-carousel .start-slider p {
      font-size: 1.6rem;
      margin: 1rem 0; } }
  .cms-carousel .start-slider .slider-item.slider-mode-light {
    background-color: #e3e3e3; }
    @media (max-width: 767.98px) {
      .cms-carousel .start-slider .slider-item.slider-mode-light .text-box {
        background-color: #575757; }
        .cms-carousel .start-slider .slider-item.slider-mode-light .text-box .text-box-title, .cms-carousel .start-slider .slider-item.slider-mode-light .text-box p {
          color: #e3e3e3; }
          .cms-carousel .start-slider .slider-item.slider-mode-light .text-box .text-box-title .highlight-text, .cms-carousel .start-slider .slider-item.slider-mode-light .text-box p .highlight-text {
            color: #e3e3e3; } }
  .cms-carousel .start-slider .slider-item.slider-mode-dark {
    background-color: #575757; }
    .cms-carousel .start-slider .slider-item.slider-mode-dark .text-box-title, .cms-carousel .start-slider .slider-item.slider-mode-dark p, .cms-carousel .start-slider .slider-item.slider-mode-dark ul {
      color: #FFFFFF; }
      .cms-carousel .start-slider .slider-item.slider-mode-dark .text-box-title .highlight-text, .cms-carousel .start-slider .slider-item.slider-mode-dark p .highlight-text, .cms-carousel .start-slider .slider-item.slider-mode-dark ul .highlight-text {
        color: #FFFFFF; }
    @media (max-width: 767.98px) {
      .cms-carousel .start-slider .slider-item.slider-mode-dark .text-box {
        background-color: #e3e3e3; }
        .cms-carousel .start-slider .slider-item.slider-mode-dark .text-box .text-box-title, .cms-carousel .start-slider .slider-item.slider-mode-dark .text-box p {
          color: #575757; }
          .cms-carousel .start-slider .slider-item.slider-mode-dark .text-box .text-box-title .highlight-text, .cms-carousel .start-slider .slider-item.slider-mode-dark .text-box p .highlight-text {
            color: #00569d; } }
  .cms-carousel .start-slider .slider-item.image-left-text-right .bg-img {
    background-position: left top; }
    .cms-carousel .start-slider .slider-item.image-left-text-right .bg-img img {
      position: absolute;
      left: 0; }
  .cms-carousel .start-slider .slider-item.image-left-text-right .text-box .text-box-inner {
    margin-right: 4rem; }
    @media (max-width: 767.98px) {
      .cms-carousel .start-slider .slider-item.image-left-text-right .text-box .text-box-inner {
        margin-right: 0; } }
  .cms-carousel .start-slider .slider-item.image-right-text-left .bg-img {
    background-position: right top; }
    .cms-carousel .start-slider .slider-item.image-right-text-left .bg-img img {
      position: absolute;
      right: 0; }
  .cms-carousel .start-slider .slider-item.image-right-text-left .text-box .text-box-inner {
    padding-left: 8rem; }
    @media (max-width: 767.98px) {
      .cms-carousel .start-slider .slider-item.image-right-text-left .text-box .text-box-inner {
        padding-left: 0; } }
  .cms-carousel .start-slider .slider-item.image-only-box .bg-img.left {
    background-position: left top; }
    .cms-carousel .start-slider .slider-item.image-only-box .bg-img.left img {
      position: absolute;
      left: 0; }
  .cms-carousel .start-slider .slider-item.image-only-box .bg-img.middle {
    background-position: center top; }
    .cms-carousel .start-slider .slider-item.image-only-box .bg-img.middle img {
      position: absolute;
      left: 50%;
      margin-left: -58rem; }
      @media (max-width: 767.98px) {
        .cms-carousel .start-slider .slider-item.image-only-box .bg-img.middle img {
          margin-left: -43.5rem; }
          .cms-carousel .start-slider .slider-item.image-only-box .bg-img.middle img.img-mobile {
            margin-left: -37.55rem; } }
  .cms-carousel .start-slider .slider-item.image-only-box .bg-img.right {
    background-position: right top; }
    .cms-carousel .start-slider .slider-item.image-only-box .bg-img.right img {
      position: absolute;
      right: 0; }
  @media (max-width: 767.98px) {
    .cms-carousel .start-slider .slider-item.image-only-box .bg-img {
      height: 55rem; }
      .cms-carousel .start-slider .slider-item.image-only-box .bg-img img {
        height: 55rem; } }
  .cms-carousel .start-slider .slider-item .text-box {
    display: flex;
    align-items: center;
    height: 100%; }
    @media (max-width: 767.98px) {
      .cms-carousel .start-slider .slider-item .text-box {
        min-height: 25rem; } }
    .cms-carousel .start-slider .slider-item .text-box .text-box-inner {
      width: 100%; }
    @media (max-width: 767.98px) {
      .cms-carousel .start-slider .slider-item .text-box {
        margin: 0 -1rem;
        padding: 2rem 0;
        text-align: center !important; }
        .cms-carousel .start-slider .slider-item .text-box .text-box-title {
          font-size: 1.9rem; }
        .cms-carousel .start-slider .slider-item .text-box p {
          font-size: 1.6rem;
          width: 100%;
          padding: 1rem 3rem;
          margin-left: 0 !important; } }
  @media (min-width: 768px) {
    .cms-carousel .start-slider .slider-item .inner {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0; } }
  .cms-carousel .start-slider .slider-item .inner .row {
    height: 100%; }
  @media (max-width: 991.98px) {
    .cms-carousel .start-slider .slider-item.image-left-text-right .bg-img {
      background-position: left -10rem top; }
      .cms-carousel .start-slider .slider-item.image-left-text-right .bg-img img {
        position: absolute;
        left: -10rem; }
    .cms-carousel .start-slider .slider-item.image-right-text-left .bg-img {
      background-position: right -10rem top; }
      .cms-carousel .start-slider .slider-item.image-right-text-left .bg-img img {
        position: absolute;
        right: -10rem; } }
  @media (max-width: 767.98px) {
    .cms-carousel .start-slider .slider-item.image-left-text-right .bg-img {
      background-position: left -7.5rem top; }
      .cms-carousel .start-slider .slider-item.image-left-text-right .bg-img img {
        position: absolute;
        right: -7.5rem; }
    .cms-carousel .start-slider .slider-item.image-right-text-left .bg-img {
      background-position: right -7.5rem top; }
      .cms-carousel .start-slider .slider-item.image-right-text-left .bg-img img {
        position: absolute;
        right: -7.5rem; } }
  .cms-carousel .start-slider .ribbons {
    position: absolute;
    top: .75rem;
    transition: .5s all;
    opacity: 1;
    display: inline-block; }
    .cms-carousel .start-slider .ribbons .ribbon {
      font-size: 1.8rem;
      background-color: #dc371c;
      color: #FFFFFF;
      padding: .2rem .8rem;
      margin-left: .75rem;
      float: left; }
  .cms-carousel .start-slider .carousel-item-next .ribbons, .cms-carousel .start-slider .carousel-item-prev .ribbons {
    opacity: 0; }
  .cms-carousel .start-slider .slider-price {
    font-size: 2rem;
    padding-bottom: 2rem; }
    .cms-carousel .start-slider .slider-price small {
      font-size: 1.3rem;
      font-style: italic;
      padding-left: .4rem; }
    .cms-carousel .start-slider .slider-price span {
      font-weight: bold; }
    .cms-carousel .start-slider .slider-price .striked-price {
      position: relative;
      display: inline-block;
      font-weight: normal;
      font-size: 1.3rem;
      margin-right: .5rem; }
      .cms-carousel .start-slider .slider-price .striked-price:before {
        content: '';
        border-bottom: 2px solid #575757;
        width: 100%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: rotate(-5deg); }
    .cms-carousel .start-slider .slider-price.highlighted span:not(.striked-price) {
      color: #dc371c; }
  @media (max-width: 767.98px) {
    .cms-carousel .start-slider .slider-mode-light .slider-price {
      color: #FFFFFF; }
      .cms-carousel .start-slider .slider-mode-light .slider-price .striked-price:before {
        border-bottom: 2px solid #FFFFFF; }
      .cms-carousel .start-slider .slider-mode-light .slider-price.highlighted span:not(.striked-price) {
        color: #e85b43; }
    .cms-carousel .start-slider .slider-mode-light ul {
      color: #FFFFFF; } }
  .cms-carousel .start-slider .slider-mode-dark .slider-price {
    color: #FFFFFF; }
    .cms-carousel .start-slider .slider-mode-dark .slider-price.highlighted span {
      color: #e85b43; }
  @media (max-width: 767.98px) {
    .cms-carousel .start-slider .slider-mode-dark .slider-price {
      color: #575757; } }

.amount-selector {
  border: 1px solid #747474; }
  .amount-selector .btn {
    padding-top: .8rem; }
    .amount-selector .btn .ra-icon {
      font-size: 2rem; }
  .amount-selector input[type=number].form-control {
    height: 3.9rem;
    border: none; }

.error-cross {
  width: 80px;
  height: 115px;
  margin: 0 auto;
  opacity: 0;
  animation: show-error-cross .5s ease 0s forwards; }
  .error-cross .cross-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box; }
    .error-cross .cross-icon::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px; }
    .error-cross .cross-icon::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in; }
    .error-cross .cross-icon::before, .error-cross .cross-icon::after {
      content: '';
      height: 100px;
      position: absolute;
      background: #FFFFFF;
      transform: rotate(-45deg); }
    .error-cross .cross-icon .icon-line {
      height: 5px;
      background-color: #dc371c;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10; }
      .error-cross .cross-icon .icon-line.line-long-invert {
        top: 39px;
        left: 8px;
        width: 60px;
        transform: rotate(45deg);
        animation: icon-line-long 0.75s; }
      .error-cross .cross-icon .icon-line.line-long {
        top: 39px;
        left: 8px;
        width: 60px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.85s; }
    .error-cross .cross-icon .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(175, 76, 76, 0.5); }
    .error-cross .cross-icon .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: rgba(255, 255, 255, 0); }

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
  opacity: 0;
  animation: show-success-checkmark .5s ease 0s forwards; }
  .success-checkmark .check-icon {
    width: 80px !important;
    height: 80px !important;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #308136; }
    .success-checkmark .check-icon::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px; }
    .success-checkmark .check-icon::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in .5s forwards; }
    .success-checkmark .check-icon::before, .success-checkmark .check-icon::after {
      content: '';
      height: 100px;
      position: absolute;
      background: #FFFFFF;
      transform: rotate(-45deg); }
    .success-checkmark .check-icon .icon-line {
      height: 5px;
      background-color: #308136;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10; }
      .success-checkmark .check-icon .icon-line.line-tip {
        width: 0;
        left: 1px;
        top: 19px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s ease .5s forwards; }
      .success-checkmark .check-icon .icon-line.line-long {
        width: 0;
        right: 46px;
        top: 54px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s ease .5s forwards; }
    .success-checkmark .check-icon .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.5); }
    .success-checkmark .check-icon .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #FFFFFF; }
  .success-checkmark.buy-button {
    transform: scale(0.4);
    margin-top: -3.85rem; }
    .success-checkmark.buy-button.tab-buy-button {
      transform: scale(0.3); }
    .success-checkmark.buy-button .check-icon {
      border: 4px solid #FFFFFF; }
      .success-checkmark.buy-button .check-icon .icon-line {
        background-color: #FFFFFF; }
        .success-checkmark.buy-button .check-icon .icon-line.line-tip {
          width: 25px;
          left: 14px;
          top: 45px;
          animation: none; }
        .success-checkmark.buy-button .check-icon .icon-line.line-long {
          width: 47px;
          right: 8px;
          top: 38px;
          animation: none; }
      .success-checkmark.buy-button .check-icon .icon-circle {
        border-color: #FFFFFF; }
      .success-checkmark.buy-button .check-icon::before, .success-checkmark.buy-button .check-icon::after {
        background-color: transparent; }
      .success-checkmark.buy-button .check-icon::after {
        transform: rotate(-405deg);
        animation: none; }
      .success-checkmark.buy-button .check-icon .icon-fix {
        background-color: transparent; }

@keyframes show-error-cross {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes show-success-checkmark {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg); } }

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }
  54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }
  65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0px;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

.switch {
  position: absolute;
  width: 30px;
  height: 16px;
  margin-bottom: 0px;
  line-height: 1em;
  top: 50%;
  right: .5rem;
  transform: translateY(-50%); }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: #FFFFFF;
  transition: .4s; }

input:checked + .slider {
  background-color: #00569d; }

input:focus + .slider {
  box-shadow: 0 0 1px #00569d; }

input:checked + .slider:before {
  transform: translateX(14px); }

/* Rounded sliders */
.slider.round {
  border-radius: 8px; }

.slider.round:before {
  border-radius: 50%; }

.switch-slider {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  width: 3rem;
  height: 1.6rem;
  border-radius: .8rem;
  margin-right: 1rem; }
  .switch-slider input {
    opacity: 0;
    width: 0;
    height: 0; }
  .switch-slider .switch-slider-handle {
    border-radius: .8rem;
    position: absolute;
    cursor: pointer;
    inset: 0;
    background-color: #e3e3e3;
    transition: .4s; }
    .switch-slider .switch-slider-handle:before {
      border-radius: 50%;
      position: absolute;
      content: "";
      height: 1.2rem;
      width: 1.2rem;
      left: .2rem;
      bottom: .2rem;
      background-color: #FFFFFF;
      transition: .4s; }
  .switch-slider input:checked + .switch-slider-handle, .switch-slider input:checked + .error + .switch-slider-handle {
    background-color: #00569d; }
  .switch-slider input:focus + .switch-slider-handle {
    box-shadow: 0 0 1px #00569d; }
  .switch-slider input:checked + .switch-slider-handle:before, .switch-slider input:checked + .error + .switch-slider-handle:before {
    transform: translateX(14px); }
  .switch-slider.medium {
    width: 4rem;
    height: 2.1rem;
    border-radius: 1.05rem; }
    .switch-slider.medium .switch-slider-handle {
      border-radius: 1.05rem; }
      .switch-slider.medium .switch-slider-handle:before {
        height: 1.7rem;
        width: 1.7rem;
        left: .2rem;
        bottom: .2rem; }
    .switch-slider.medium input:checked + .switch-slider-handle:before, .switch-slider.medium input:checked + .error + .switch-slider-handle:before {
      transform: translateX(1.9rem); }

.alternate-searchterms {
  margin-top: 1em; }
  .alternate-searchterms ul {
    list-style: none;
    display: inline-block;
    padding: 0; }
    .alternate-searchterms ul li {
      display: inline-block; }
      .alternate-searchterms ul li a {
        font-style: italic;
        color: #00569d; }
        .alternate-searchterms ul li a:before {
          color: #575757;
          content: '\0022'; }
        .alternate-searchterms ul li a:after {
          color: #575757;
          content: '\0022\002c'; }
      .alternate-searchterms ul li:last-child a:after {
        content: '\0022'; }

.ra-details-buy-button, .ra-details-configurator-buy-button, .ra-details-configurator-tab-button {
  font-weight: bold;
  width: 100%;
  height: 5.5rem;
  font-size: 1.6rem;
  position: relative;
  overflow: hidden;
  z-index: 0; }
  .ra-details-buy-button:not(.simple)::before, .ra-details-configurator-buy-button:not(.simple)::before, .ra-details-configurator-tab-button:not(.simple)::before {
    content: "";
    position: absolute;
    background-color: #007de4;
    height: 400%;
    top: -50%;
    left: 0;
    right: 0;
    transform: rotate(25deg) translate(-110%, 60%);
    z-index: -1;
    transition: .35s; }
  .ra-details-buy-button:not(.simple)::after, .ra-details-configurator-buy-button:not(.simple)::after, .ra-details-configurator-tab-button:not(.simple)::after {
    content: "";
    position: absolute;
    background-color: #0072d0;
    height: 400%;
    top: -50%;
    left: 0;
    right: 0;
    transform: rotate(25deg) translate(-110%, 60%);
    z-index: -2;
    transition: .5s; }
  .ra-details-buy-button.btn-confirm:not(.simple)::before, .ra-details-configurator-buy-button.btn-confirm:not(.simple)::before, .ra-details-configurator-tab-button.btn-confirm:not(.simple)::before {
    background-color: #44b44c; }
  .ra-details-buy-button.btn-confirm:not(.simple)::after, .ra-details-configurator-buy-button.btn-confirm:not(.simple)::after, .ra-details-configurator-tab-button.btn-confirm:not(.simple)::after {
    background-color: #3ea646; }
  .ra-details-buy-button .ra-details-buy-button-inner, .ra-details-configurator-buy-button .ra-details-buy-button-inner, .ra-details-configurator-tab-button .ra-details-buy-button-inner {
    position: absolute;
    top: 25%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    opacity: 1;
    transition: opacity .175s; }
    .ra-details-buy-button .ra-details-buy-button-inner.hide, .ra-details-configurator-buy-button .ra-details-buy-button-inner.hide, .ra-details-configurator-tab-button .ra-details-buy-button-inner.hide {
      opacity: 0; }
  .ra-details-buy-button .ra-details-tab-buy-button-inner, .ra-details-configurator-buy-button .ra-details-tab-buy-button-inner, .ra-details-configurator-tab-button .ra-details-tab-buy-button-inner {
    position: absolute;
    top: 11%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    opacity: 1;
    transition: opacity .175s; }
    .ra-details-buy-button .ra-details-tab-buy-button-inner.hide, .ra-details-configurator-buy-button .ra-details-tab-buy-button-inner.hide, .ra-details-configurator-tab-button .ra-details-tab-buy-button-inner.hide {
      opacity: 0; }
  .ra-details-buy-button.confirm-add-basket::before, .ra-details-configurator-buy-button.confirm-add-basket::before, .ra-details-configurator-tab-button.confirm-add-basket::before {
    top: -200%;
    left: 0;
    right: 0;
    height: 500%;
    transform: rotate(0) translate(0, 0);
    transition: .75s;
    background-color: #308136; }
  .ra-details-buy-button:hover:not(:disabled):not(.confirm-add-basket)::before, .ra-details-configurator-buy-button:hover:not(:disabled):not(.confirm-add-basket)::before, .ra-details-configurator-tab-button:hover:not(:disabled):not(.confirm-add-basket)::before {
    transform: rotate(25deg) translate(-92%, 0); }
  .ra-details-buy-button:hover:not(:disabled):not(.confirm-add-basket)::after, .ra-details-configurator-buy-button:hover:not(:disabled):not(.confirm-add-basket)::after, .ra-details-configurator-tab-button:hover:not(:disabled):not(.confirm-add-basket)::after {
    transform: rotate(25deg) translate(-85%, 0); }
  .ra-details-buy-button:disabled, .ra-details-configurator-buy-button:disabled, .ra-details-configurator-tab-button:disabled {
    pointer-events: none;
    opacity: 1; }
  .ra-details-buy-button .ra-icon, .ra-details-configurator-buy-button .ra-icon, .ra-details-configurator-tab-button .ra-icon {
    font-size: 2.9rem !important;
    vertical-align: middle !important;
    margin-top: -.7rem; }

.toCheckoutBtn {
  width: 100%;
  color: #FFFFFF;
  font-weight: bold;
  height: 5.5rem;
  font-size: 1.6rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden; }
  .toCheckoutBtn::before {
    content: "";
    position: absolute;
    background-color: #007de4;
    width: 10rem;
    height: 200%;
    top: -80%;
    left: -20%;
    transform: rotate(25deg) translate(-100%, 0);
    z-index: 2;
    transition: .35s; }
  .toCheckoutBtn::after {
    content: "";
    position: absolute;
    background-color: #0072d0;
    width: 10rem;
    height: 200%;
    top: -80%;
    left: -20%;
    transform: rotate(25deg) translate(-100%, 0);
    z-index: 1;
    transition: .5s; }
  .toCheckoutBtn .ra-basket-button-inner {
    position: absolute;
    top: 25%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5; }
  .toCheckoutBtn:hover:not(:disabled)::before {
    transform: rotate(25deg) translate(0, 0); }
  .toCheckoutBtn:hover:not(:disabled)::after {
    transform: rotate(25deg) translate(30%, 0); }
  .toCheckoutBtn.disabled {
    pointer-events: none; }
  .toCheckoutBtn.plsCheckBasket {
    cursor: not-allowed; }

html {
  font-size: 10px;
  scroll-behavior: smooth; }
  html.no-scroll {
    overflow: hidden; }
  html body {
    font-size: 14px;
    font-family: "Open Sans", "Tahoma", "Verdana", "Arial";
    background: #ffffff;
    background-image: linear-gradient(to bottom, #ffffff, #ffffff 80vh, #f6f6f6 180vh, #f6f6f6);
    color: #575757;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh; }
    html body.auto-hyphens-in-item-box-title-and-headlines .product-item-title, html body.auto-hyphens-in-item-box-title-and-headlines .category-item-title {
      -webkit-hyphens: auto !important;
          -ms-hyphens: auto !important;
              hyphens: auto !important; }
    html body.auto-hyphens-in-item-box-title-and-headlines .ra-dashboard-box h2 {
      -webkit-hyphens: none !important;
          -ms-hyphens: none !important;
              hyphens: none !important; }
    html body.auto-hyphens-in-item-box-title-and-headlines h1, html body.auto-hyphens-in-item-box-title-and-headlines h2, html body.auto-hyphens-in-item-box-title-and-headlines h3, html body.auto-hyphens-in-item-box-title-and-headlines h4, html body.auto-hyphens-in-item-box-title-and-headlines h5, html body.auto-hyphens-in-item-box-title-and-headlines h6 {
      -webkit-hyphens: auto !important;
          -ms-hyphens: auto !important;
              hyphens: auto !important; }
    html body.first-row-fullscreen .scroll-icon-container {
      bottom: 0; }
    html body.first-row-fullscreen .menu-outer {
      margin-top: -2rem !important; }
    html body.first-row-fullscreen.hasTopBar .menu-outer {
      margin-top: -5rem !important; }
    html body.first-row-fullscreen.hide-shop-elements {
      padding-top: 0 !important; }
    html body.no-scroll {
      overflow: hidden !important; }
    html body ::-webkit-input-placeholder {
      font-style: italic; }
    html body :-moz-placeholder {
      font-style: italic; }
    html body ::-moz-placeholder {
      font-style: italic; }
    html body :-ms-input-placeholder {
      font-style: italic; }
    html body.a-only-view {
      padding-top: 0 !important;
      padding-bottom: 2rem; }
    html body.a-only-view #header, html body.a-only-view #header-banner, html body.a-only-view #breadcrumb, html body.a-only-view #footer {
      display: none !important; }
    html body div[id^=trustbadge-container] {
      z-index: 2147483646 !important; }
    html body.no-gradient {
      background-image: none; }
      html body.no-gradient .content-padding {
        padding-bottom: 0 !important; }
  @media (max-width: 991.98px) {
    html body.cl-racountryselection {
      padding-top: 1rem !important; } }

.checkout-header {
  margin-bottom: 2rem;
  box-shadow: 0 7px 5px rgba(100, 100, 100, 0.1); }

.row {
  margin-left: auto;
  margin-right: auto; }

.no-padding {
  padding: 0; }

.negative-margin-top {
  margin-top: -2rem !important; }

@media (max-width: 991.98px) {
  .inline-margin {
    margin-top: -2rem !important; } }

#header {
  width: 100%;
  background-color: #FFFFFF;
  z-index: 999;
  box-shadow: 0 7px 5px rgba(100, 100, 100, 0.1); }
  @media (max-width: 767.98px) {
    #header:not(.checkout-header) {
      box-shadow: none; }
    #header.checkout-header .logo-col {
      margin: 0; }
      #header.checkout-header .logo-col img#main-logo {
        max-height: 2rem !important; }
    #header.checkout-header .step-col {
      padding: 0; } }
  @media (max-width: 991.98px) {
    #header.checkout-header .logo-col {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      justify-items: center;
      margin: 0 0 1rem 0;
      padding: 0; } }
  #header .container-fluid {
    padding: 0;
    max-width: 1200px;
    position: relative; }
  #header .extendedHeader img#main-logo {
    max-height: 4rem !important; }
  @media (min-width: 768px) {
    #header .extendedHeader img#main-logo {
      max-height: 8rem !important; } }
  #header .extendedHeader .menus-col {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  #header .extendedHeader .search-col {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  #header .search-col {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  #header .menus-col {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  #header .header-box {
    padding: 1rem 0; }
    #header .header-box.usa {
      padding: 1rem 0 0 0; }
    @media (min-width: 768px) {
      #header .header-box.hideElements .logo-col {
        align-items: start !important; } }
    #header .header-box.hideElements .button-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end; }
      #header .header-box.hideElements .button-container .navbar-toggler {
        position: unset !important;
        top: unset !important;
        left: unset !important; }
    #header .header-box .logo-col {
      padding-left: 0;
      display: flex;
      align-items: center; }
      #header .header-box .logo-col.no-menu-logo {
        justify-items: center;
        justify-content: center; }
      @media (min-width: 992px) {
        #header .header-box .logo-col.logo-xl {
          padding-top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 60px !important; }
          #header .header-box .logo-col.logo-xl img {
            max-height: 6rem;
            width: 300px;
            height: 60px; } }
      #header .header-box .logo-col img {
        max-height: 4rem; }
        @media (min-width: 992px) {
          #header .header-box .logo-col img {
            width: 246px;
            height: 40px; } }
    #header .header-box .search-col button .ra-icon {
      padding-top: .2rem; }
    #header .header-box .menu-dropdowns div div {
      padding: 0; }
      #header .header-box .menu-dropdowns div div.custom-header.usa {
        display: flex;
        justify-content: center;
        align-items: center; }
      #header .header-box .menu-dropdowns div div a span {
        line-height: 1rem; }
        #header .header-box .menu-dropdowns div div a span.custom-header.usa {
          font-size: 1.5rem;
          line-height: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center; }
          @media (max-width: 991.98px) {
            #header .header-box .menu-dropdowns div div a span.custom-header.usa {
              font-size: 1.2rem; } }
    #header .header-box .menu-dropdowns div.custom-header.usa {
      max-height: 4rem; }
    #header .header-box .menu-dropdowns a:not(.extra-login-btn) span {
      display: block;
      width: 100%;
      font-size: 1rem; }
    #header .header-box .login-btn .logged-in {
      font-weight: bold; }
    #header .header-box .basket-button .badge {
      display: inline-block;
      font-style: normal;
      position: absolute;
      top: 1.5rem;
      font-size: 1rem;
      margin-left: -1.2rem;
      font-weight: normal; }
      #header .header-box .basket-button .badge.custom-header.usa {
        top: 2.5rem; }
      @media only screen and (min-width: 800px) {
        #header .header-box .basket-button .badge.costum-header.usa {
          top: 2.5rem; } }
    #header .header-box .basketFlyout {
      z-index: 2000;
      display: none;
      position: absolute;
      width: 45rem;
      margin-top: .5rem;
      right: 0;
      background-color: white;
      border: 1px solid #aeaeae; }
      #header .header-box .basketFlyout .hide-minibasket {
        display: none; }
      #header .header-box .basketFlyout .minibasketHeader {
        padding: 1rem;
        font-size: 1.8rem;
        font-weight: bold; }
      #header .header-box .basketFlyout.show {
        display: block; }
        #header .header-box .basketFlyout.show.custom-header-flyout.usa {
          top: 4rem; }
      #header .header-box .basketFlyout .minibasketItems {
        padding: 1rem; }
        #header .header-box .basketFlyout .minibasketItems.scrollable {
          max-height: 42rem;
          overflow: auto; }
        #header .header-box .basketFlyout .minibasketItems .basketItemTable {
          text-align: left;
          width: 100%; }
          #header .header-box .basketFlyout .minibasketItems .basketItemTable tr {
            display: block; }
            #header .header-box .basketFlyout .minibasketItems .basketItemTable tr .picture {
              position: relative; }
              #header .header-box .basketFlyout .minibasketItems .basketItemTable tr .picture .img-container {
                height: 10rem;
                width: 10rem; }
                #header .header-box .basketFlyout .minibasketItems .basketItemTable tr .picture .img-container a {
                  display: block;
                  width: 100%;
                  height: 100%; }
                  #header .header-box .basketFlyout .minibasketItems .basketItemTable tr .picture .img-container a img {
                    display: block;
                    max-width: 100%;
                    max-height: 100%; }
              #header .header-box .basketFlyout .minibasketItems .basketItemTable tr .picture .badge {
                display: inline-block;
                font-style: normal;
                position: absolute;
                top: .5rem;
                left: 0;
                font-size: 1rem;
                font-weight: normal; }
            #header .header-box .basketFlyout .minibasketItems .basketItemTable tr td.title {
              width: 23rem;
              padding-left: 1rem; }
            #header .header-box .basketFlyout .minibasketItems .basketItemTable tr td.price {
              width: 10rem; }
        #header .header-box .basketFlyout .minibasketItems .ra-bfc-size-info {
          font-size: 1.1rem; }
      #header .header-box .basketFlyout .mandatory-accessories .picture div, #header .header-box .basketFlyout .refinement .picture div {
        width: 10rem; }
      #header .header-box .basketFlyout .minibasketTotal {
        padding: 1rem; }
        #header .header-box .basketFlyout .minibasketTotal table {
          width: 100%;
          font-size: 1.6rem;
          font-weight: bold; }
      #header .header-box .basketFlyout .bottomBorder {
        margin: 0 1rem; }
      #header .header-box .basketFlyout .custom_details_additional_payment_buttons-wrapper .paypalExpressCheckoutMsg {
        padding: 0 !important;
        margin-bottom: -1.5rem; }
      #header .header-box .basketFlyout .minibasketButtons {
        width: 100%;
        background-color: #f6f6f6;
        text-align: center; }
        #header .header-box .basketFlyout .minibasketButtons div {
          padding: .5rem; }
        #header .header-box .basketFlyout .minibasketButtons .paypalExpressCheckoutBox {
          padding: 0;
          margin: 0;
          width: 100%; }
          #header .header-box .basketFlyout .minibasketButtons .paypalExpressCheckoutBox div {
            padding: .3rem 0 1.2rem 0; }
          #header .header-box .basketFlyout .minibasketButtons .paypalExpressCheckoutBox button {
            width: 100%; }
            #header .header-box .basketFlyout .minibasketButtons .paypalExpressCheckoutBox button img {
              width: 30%; }
        #header .header-box .basketFlyout .minibasketButtons .custom_details_additional_payment_buttons-wrapper {
          padding-left: 0;
          padding-right: 0; }
        #header .header-box .basketFlyout .minibasketButtons a {
          width: 100%; }
      #header .header-box .basketFlyout .minibasketEmpty {
        padding: 1rem;
        padding-bottom: 2rem; }
        #header .header-box .basketFlyout .minibasketEmpty .basketEmptyText {
          font-weight: bold;
          margin-bottom: 2rem;
          display: block; }
        #header .header-box .basketFlyout .minibasketEmpty hr {
          margin: 1.75rem 0; }
    #header .header-box .headerSnippet {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 767.98px) {
        #header .header-box .headerSnippet {
          min-height: 4rem; } }
    #header .header-box .row {
      width: 100%; }
  #header #mainnav {
    border: none; }
    #header #mainnav.compact-menu #navigation-outer {
      margin-top: -0.5rem; }
      #header #mainnav.compact-menu #navigation-outer ul li {
        margin-left: 0;
        margin-right: 2.5rem; }
      #header #mainnav.compact-menu #navigation-outer ul li.selected a {
        color: #00569d !important; }
    @media (min-width: 576px) and (max-width: 1199.98px) {
      #header #mainnav.action-menu-outside {
        margin-top: 2.5rem; } }
    #header #mainnav .flyout-trigger i.dropdown {
      margin-left: .1rem;
      vertical-align: middle; }
    #header #mainnav .navbar-toggler {
      position: absolute;
      padding: 0;
      top: -5rem;
      left: 1.1rem;
      line-height: 3rem;
      font-size: 2.5rem;
      z-index: 999;
      height: 4rem;
      transition: .2s all;
      width: 5rem; }
      #header #mainnav .navbar-toggler.usa {
        top: -4rem; }
      #header #mainnav .navbar-toggler:hover {
        background-color: #f6f6f6; }
      #header #mainnav .navbar-toggler span {
        font-size: 1rem;
        line-height: 1rem; }
      #header #mainnav .navbar-toggler.main-menu-toggler {
        color: #575757; }
        #header #mainnav .navbar-toggler.main-menu-toggler:focus {
          background: transparent; }
    #header #mainnav .navbar-header {
      margin: 0; }
    #header #mainnav .navbar-collapse {
      margin-right: 0; }
    @media (max-width: 767.98px) {
      #header #mainnav {
        margin-top: 0; } }
    #header #mainnav #navigation-outer {
      padding-bottom: .5rem;
      overflow: hidden; }
      #header #mainnav #navigation-outer ul#navigation {
        flex-shrink: 0; }
      #header #mainnav #navigation-outer ul.navbar-nav {
        white-space: nowrap;
        overflow: hidden; }
        #header #mainnav #navigation-outer ul.navbar-nav li span.t-highlight {
          color: #00569d; }
        #header #mainnav #navigation-outer ul.navbar-nav li span {
          display: inline-block; }
      #header #mainnav #navigation-outer ul.navigation-scrollable-nav {
        width: 100%; }
        #header #mainnav #navigation-outer ul.navigation-scrollable-nav li {
          transition: margin-left .5s; }
      #header #mainnav #navigation-outer ul.navigation-inline-nav {
        width: 100%;
        white-space: normal;
        display: block;
        overflow: hidden; }
        #header #mainnav #navigation-outer ul.navigation-inline-nav li {
          transition: margin-left .5s;
          display: inline-block; }
          #header #mainnav #navigation-outer ul.navigation-inline-nav li:hover, #header #mainnav #navigation-outer ul.navigation-inline-nav li.selected {
            border-bottom: 2px solid #FFFFFF; }
          #header #mainnav #navigation-outer ul.navigation-inline-nav li.is-hover {
            margin-bottom: -1.2rem; }
            #header #mainnav #navigation-outer ul.navigation-inline-nav li.is-hover .link-placeholder {
              display: block; }
            #header #mainnav #navigation-outer ul.navigation-inline-nav li.is-hover .flyout-trigger {
              position: absolute;
              height: 20px;
              display: block;
              background-color: #f7f7f7;
              margin-left: -.6rem;
              margin-top: -.6rem;
              margin-right: -.1rem;
              padding: .5rem;
              border-left: 1px solid #bbbbbb;
              border-top: 1px solid #bbbbbb;
              border-right: 1px solid #bbbbbb;
              border-radius: .3rem .3rem 0 0; }
            #header #mainnav #navigation-outer ul.navigation-inline-nav li.is-hover span, #header #mainnav #navigation-outer ul.navigation-inline-nav li.is-hover i {
              display: inline-block;
              height: 120%; }
      #header #mainnav #navigation-outer ul li .link-placeholder {
        display: none; }
      #header #mainnav #navigation-outer ul li.nav-side-navigation {
        display: none;
        width: 2rem;
        margin-right: .5rem;
        margin-left: .5rem; }
        #header #mainnav #navigation-outer ul li.nav-side-navigation.show {
          display: list-item; }
        #header #mainnav #navigation-outer ul li.nav-side-navigation:first-of-type {
          margin-right: 1rem; }
        #header #mainnav #navigation-outer ul li.nav-side-navigation .ra-icon {
          opacity: 0;
          font-weight: bold;
          cursor: pointer;
          transition: opacity .5s; }
          #header #mainnav #navigation-outer ul li.nav-side-navigation .ra-icon.show {
            opacity: 1; }
      #header #mainnav #navigation-outer li {
        margin-right: 2.8rem;
        border-bottom: 2px solid #FFFFFF;
        padding: .8rem 0;
        margin-bottom: -.9rem; }
        #header #mainnav #navigation-outer li:hover, #header #mainnav #navigation-outer li.selected {
          border-bottom: 2px solid #747474; }
        #header #mainnav #navigation-outer li a {
          color: #575757;
          text-decoration: none; }
          #header #mainnav #navigation-outer li a.sale-highlight {
            color: #dc371c; }
          #header #mainnav #navigation-outer li a.highlighted {
            padding: .15rem .8rem;
            transition: transform .3s; }
            #header #mainnav #navigation-outer li a.highlighted.special {
              color: #FFFFFF;
              background-color: #dc371c; }
            #header #mainnav #navigation-outer li a.highlighted.highlight {
              color: #FFFFFF;
              background-color: #00569d; }
    #header #mainnav i.ra-icon {
      font-size: 2rem;
      vertical-align: top; }
  #header .form.search {
    margin-top: 0; }
    #header .form.search #searchParam {
      border: 2px solid #00569d;
      border-right: none;
      box-shadow: none;
      height: 4rem;
      transition: none !important; }
    #header .form.search .btn {
      background-color: #00569d;
      color: #FFFFFF;
      border: 2px solid #00569d;
      border-left: none;
      height: 4rem; }
      #header .form.search .btn:focus {
        outline: none;
        box-shadow: none; }
      #header .form.search .btn .searchLoading {
        padding: 0;
        position: relative;
        -moz-animation-name: reload_rotate;
        -moz-animation-duration: 1s;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;
        -webkit-animation-name: reload_rotate;
        -webkit-animation-duration: 1s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        -ms-animation-name: reload_rotate;
        -ms-animation-duration: 1s;
        -ms-animation-iteration-count: infinite;
        -ms-animation-timing-function: linear;
        -o-animation-name: reload_rotate;
        -o-animation-duration: 1s;
        -o-animation-iteration-count: infinite;
        -o-animation-timing-function: linear;
        animation-name: reload_rotate;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear; }

@keyframes reload_rotate {
  0% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(0deg); } }
  #header .flyout-menu-app {
    transition: all .3s;
    height: 0;
    overflow: hidden;
    max-height: 80vh;
    position: absolute;
    background-color: #FFFFFF;
    width: 100%; }
    #header .flyout-menu-app .container-fluid {
      background-repeat: no-repeat;
      background-position: bottom right 2rem; }
      #header .flyout-menu-app .container-fluid .inner-menu {
        padding-top: 2rem;
        margin-right: 10px;
        margin-left: 10px; }
        @media (max-width: 991.98px) {
          #header .flyout-menu-app .container-fluid .inner-menu {
            background-image: none !important; } }
    #header .flyout-menu-app .ramenu-info-box .title {
      font-size: 1.8rem;
      font-weight: 700; }
    #header .flyout-menu-app.show {
      display: block;
      height: auto; }
      #header .flyout-menu-app.show .simplebar-offset .simplebar-content {
        padding-bottom: 5px !important; }
      @media screen and (max-height: 50rem) {
        #header .flyout-menu-app.show {
          height: 75vh; } }
    #header .flyout-menu-app.flyout-menu-compact {
      position: absolute;
      width: 100%;
      display: none;
      max-width: 25rem; }
      #header .flyout-menu-app.flyout-menu-compact.to-left .menu .menu-inner {
        transform: translateX(-100%);
        margin-right: 0;
        right: 0; }
      #header .flyout-menu-app.flyout-menu-compact .list-outer {
        height: inherit;
        padding-bottom: 0; }
      #header .flyout-menu-app.flyout-menu-compact.show {
        height: -moz-fit-content;
        height: fit-content;
        overflow: visible; }
      #header .flyout-menu-app.flyout-menu-compact .menu-outer {
        margin-top: -10px;
        padding-top: 10px; }
      #header .flyout-menu-app.flyout-menu-compact .menu {
        position: relative;
        margin-right: 1rem;
        padding: 1rem 0;
        height: 100%;
        width: 100%;
        background-color: #FFFFFF;
        border: 1px solid #aeaeae;
        border-bottom: 2px solid #00569d; }
        #header .flyout-menu-app.flyout-menu-compact .menu .menu-inner {
          position: absolute;
          left: 0;
          top: -1px;
          transform: translateX(100%);
          width: 100%;
          max-width: 25rem;
          height: auto;
          padding: 1rem 0;
          background-color: #FFFFFF;
          border: 1px solid #aeaeae;
          border-bottom: 2px solid #00569d; }
    #header .flyout-menu-app .list-outer {
      overflow: auto;
      max-height: 80vh;
      padding-bottom: 7vh; }
      @media screen and (max-height: 50rem) {
        #header .flyout-menu-app .list-outer {
          height: 73.2vh; } }
      #header .flyout-menu-app .list-outer ul {
        list-style: none;
        padding-left: 0; }
        #header .flyout-menu-app .list-outer ul li {
          padding: .5rem 0; }
          #header .flyout-menu-app .list-outer ul li.highlighted a {
            color: #00569d; }
          #header .flyout-menu-app .list-outer ul li .row {
            margin: 0; }
            #header .flyout-menu-app .list-outer ul li .row > div {
              padding: 0; }
          #header .flyout-menu-app .list-outer ul li a {
            display: block;
            width: 100%; }
            #header .flyout-menu-app .list-outer ul li a .menu-entry-underline {
              display: block;
              margin-bottom: -1px;
              height: 1px;
              transition: all .5s;
              width: 0; }
            #header .flyout-menu-app .list-outer ul li a:hover .menu-entry-underline {
              display: block;
              background-image: linear-gradient(to right, rgba(0, 86, 157, 0.75), 85%, transparent);
              width: 100%; }
        #header .flyout-menu-app .list-outer ul.flyouttop {
          border-bottom: 1px solid #aeaeae;
          margin-right: 1rem; }
        #header .flyout-menu-app .list-outer ul.flyoutbottom {
          border-top: 1px solid #aeaeae;
          margin-top: 1rem;
          margin-right: 1rem;
          margin-bottom: 0; }
    #header .flyout-menu-app .loading-anim {
      width: 5rem;
      height: 2rem;
      position: absolute;
      left: 50%;
      margin-left: -2.5rem;
      margin-top: 22vh; }
    #header .flyout-menu-app.inline.show {
      background-color: #f7f7f7;
      border-top: 1px solid #bbbbbb; }
    #header .flyout-menu-app .icon-col svg {
      text-align: center; }
    #header .flyout-menu-app .has-icon {
      padding-left: .5rem !important; }
  #header .navbar {
    background-color: transparent;
    min-height: auto; }
    @media (max-width: 767.98px) {
      #header .navbar {
        padding: 0 !important; } }
  #header .header-dropdown-menu {
    z-index: 2000;
    display: none;
    position: absolute;
    background-color: #FFFFFF;
    border: 1px solid #aeaeae;
    width: 28rem;
    margin-left: 50%;
    margin-top: .5rem;
    transform: translate(-50%, 0); }
    #header .header-dropdown-menu .header-inner {
      margin: 1rem; }
    #header .header-dropdown-menu .btn {
      display: inline-block;
      width: 100%;
      font-weight: bold;
      padding-top: .7rem; }
      #header .header-dropdown-menu .btn .ra-icon {
        font-size: 2rem; }
      #header .header-dropdown-menu .btn span {
        display: inline-block;
        transform: translate(0, -0.3rem); }
    #header .header-dropdown-menu .register-link {
      margin-top: 1rem;
      font-size: 1rem; }
    #header .header-dropdown-menu.show {
      display: block; }
      #header .header-dropdown-menu.show.custom-dropdown-menu.usa {
        top: 4rem; }
    #header .header-dropdown-menu ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      #header .header-dropdown-menu ul li {
        text-align: left;
        padding-bottom: .5rem; }
  #header #topbar {
    top: 0;
    left: 0;
    width: 100%;
    background-color: #e3e3e3;
    height: 2.5rem;
    z-index: 998; }
    #header #topbar .container-fluid {
      height: 2.5rem;
      line-height: 2.5rem;
      padding: 0 15px; }
    #header #topbar p, #header #topbar div.topbar-element {
      float: left;
      margin-right: 4rem;
      font-size: 1.2rem; }
    @media (max-width: 1199.98px) {
      #header #topbar p, #header #topbar div.topbar-element {
        margin-right: 1.8rem; } }
    #header #topbar i {
      font-size: 1.2rem; }
    #header #topbar .topbar-international-link {
      margin: 0 0 0 2rem;
      height: 2.5rem;
      width: 12rem;
      float: right;
      background-color: #575757;
      color: #FFFFFF; }
      #header #topbar .topbar-international-link a {
        height: 2.5rem;
        width: 12rem;
        text-align: center;
        display: block;
        color: #FFFFFF;
        font-size: 1rem; }
        #header #topbar .topbar-international-link a i {
          font-size: 1rem;
          vertical-align: middle; }
    #header #topbar.dark {
      background-color: #575757;
      color: #FFFFFF; }
      #header #topbar.dark a {
        color: #FFFFFF; }
      #header #topbar.dark .topbar-international-link {
        background-color: #e3e3e3;
        color: #575757; }
        #header #topbar.dark .topbar-international-link a {
          color: #575757; }
      #header #topbar.dark .languages-menu, #header #topbar.dark .vat-switch-menu {
        background-color: #575757; }
        #header #topbar.dark .languages-menu button, #header #topbar.dark .vat-switch-menu button {
          background-color: #575757;
          color: #FFFFFF; }
        #header #topbar.dark .languages-menu .dropdown-menu a, #header #topbar.dark .vat-switch-menu .dropdown-menu a {
          color: #575757; }
      #header #topbar.dark .currencies-menu {
        background-color: #575757; }
        #header #topbar.dark .currencies-menu .dropdown-menu a {
          color: #575757; }
  #header.fixed {
    position: fixed;
    animation: animate-to-fixed-menu .4s forwards; }
  #header.fixed-out {
    animation: animate-from-fixed-menu .4s; }
  #header .header-action-menu {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 999;
    transform: translateY(-100%); }
    #header .header-action-menu .languages-menu button {
      background-color: #575757;
      color: #FFFFFF; }
    @media screen and (max-width: 1400px) {
      #header .header-action-menu {
        left: unset;
        right: 1rem; } }
    @media (max-width: 767.98px) {
      #header .header-action-menu {
        display: none; } }
  #header .btn:focus {
    box-shadow: #e3e3e3; }
  #header .languages-menu, #header .vat-switch-menu {
    display: block;
    margin-top: 0;
    background-color: #e3e3e3;
    margin-bottom: 0;
    height: 2.5rem;
    margin-right: 0;
    float: right; }
    #header .languages-menu button, #header .vat-switch-menu button {
      display: block;
      height: 2.5rem;
      font-size: 1rem;
      background-color: #e3e3e3;
      box-shadow: none;
      margin-top: 0;
      font-weight: bold; }
      #header .languages-menu button i, #header .vat-switch-menu button i {
        font-size: 1.2rem;
        vertical-align: middle;
        margin-right: .5rem; }
    #header .languages-menu ul, #header .vat-switch-menu ul {
      background-color: #FFFFFF;
      border: 1px solid #aeaeae;
      border-radius: 0;
      width: 15rem;
      min-width: 0; }
      #header .languages-menu ul li, #header .vat-switch-menu ul li {
        height: 2.5rem;
        font-size: 1.2rem; }
        #header .languages-menu ul li a, #header .vat-switch-menu ul li a {
          display: block;
          padding: 0 1rem;
          margin: auto; }
          #header .languages-menu ul li a i, #header .vat-switch-menu ul li a i {
            margin-right: .5rem; }
      #header .languages-menu ul li:hover, #header .vat-switch-menu ul li:hover {
        background-color: #f6f6f6; }
  #header .vat-switch-menu button {
    font-size: 1.2rem; }
  #header .vat-switch-menu ul li {
    height: auto;
    line-height: 1.5; }
    #header .vat-switch-menu ul li a {
      padding: .5rem;
      font-size: 1.4rem; }
  #header .vat-switch-menu .vat-switch-note {
    background-color: #FFFFFF;
    color: #575757;
    z-index: 99;
    position: absolute;
    right: 0;
    top: 3rem;
    width: 300px;
    padding: .5rem;
    box-shadow: 0 0 15px #575757;
    animation: fade-in-vat-switch-note .75s forwards;
    opacity: 0; }
    #header .vat-switch-menu .vat-switch-note .vat-switch-note-header {
      color: #00569d;
      font-weight: bold;
      font-size: 1.6rem;
      margin-bottom: .5rem; }
    #header .vat-switch-menu .vat-switch-note .ra-icon.chevron-up {
      position: absolute;
      right: 1rem;
      top: 1rem;
      cursor: pointer;
      font-weight: bold; }
    #header .vat-switch-menu .vat-switch-note p {
      margin: 0;
      line-height: 1.5; }

@keyframes fade-in-vat-switch-note {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  #header .vat-switch-note-outer {
    position: absolute;
    width: 100vw;
    height: 500px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.410802) 36%, rgba(0, 212, 255, 0) 100%);
    z-index: 1;
    left: 0;
    animation: fade-in-vat-switch-note .75s forwards;
    opacity: 0;
    pointer-events: none;
    top: 25px; }
  #header .currencies-menu {
    display: block;
    margin-top: 0;
    background-color: #e3e3e3;
    margin-bottom: 0;
    height: 2.5rem;
    width: 8.5rem;
    margin-right: 0;
    float: right; }
    #header .currencies-menu button {
      display: block;
      height: 2.5rem;
      font-size: 1.4rem;
      line-height: 1.4rem;
      box-shadow: none;
      font-weight: bold;
      background-color: #00569d;
      color: #FFFFFF; }
    #header .currencies-menu ul {
      background-color: #FFFFFF;
      border: 1px solid #aeaeae;
      border-radius: 0;
      width: 8.5rem;
      min-width: 0; }
      #header .currencies-menu ul li {
        height: 2rem;
        font-size: 1.2rem;
        text-align: center; }
        #header .currencies-menu ul li a {
          display: block;
          margin: auto;
          padding: 0 1rem; }
      #header .currencies-menu ul li:hover {
        background-color: #f6f6f6; }

@keyframes animate-to-fixed-menu {
  0% {
    top: -14rem; }
  100% {
    top: 0; } }

@keyframes animate-from-fixed-menu {
  0% {
    position: fixed;
    top: 0; }
  100% {
    position: fixed;
    top: -14rem; } }

@media (min-width: 768px) {
  .mobile-menu-app {
    display: none; } }

@media (max-width: 991.98px) {
  .mobile-menu-app {
    position: fixed;
    top: 0;
    left: -110vw;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: #f6f6f6;
    transition: all .5s; }
  .mobile-menu-app.show {
    left: 0; }
    .mobile-menu-app.show .mobile-menu-inner {
      left: 0; }
  .mobile-menu-app .mobile-header {
    background-color: #FFFFFF;
    height: 6rem; }
    .mobile-menu-app .mobile-header .close-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 6rem;
      gap: .5rem; }
      .mobile-menu-app .mobile-header .close-btn .ra-icon {
        font-size: 3rem; }
    .mobile-menu-app .mobile-header .go-back-button {
      display: flex;
      align-content: center;
      align-items: center;
      height: 6rem;
      cursor: pointer; }
      .mobile-menu-app .mobile-header .go-back-button i {
        font-size: 1.4rem;
        margin-right: 1rem; }
      .mobile-menu-app .mobile-header .go-back-button i, .mobile-menu-app .mobile-header .go-back-button b {
        cursor: pointer; }
    .mobile-menu-app .mobile-header .mobile-header-logo-box {
      height: 6rem;
      display: flex;
      align-content: center;
      align-items: center; }
  .mobile-menu-app .mobile-menu-inner {
    overflow-y: auto;
    position: fixed;
    bottom: 0;
    left: -110vw;
    width: 100%;
    top: 6.5rem;
    transition: all .5s; }
    .mobile-menu-app .mobile-menu-inner .mobile-menu-box {
      display: none; }
    .mobile-menu-app .mobile-menu-inner .hide-desktop-icons i:not(.mobile-icon) {
      display: none !important; }
    .mobile-menu-app .mobile-menu-inner .mobile-menu-category-img {
      max-width: 4rem;
      height: auto;
      margin-right: 1rem; }
    .mobile-menu-app .mobile-menu-inner .to-category-btn {
      background-color: #f6f6f6;
      border: none !important; }
      .mobile-menu-app .mobile-menu-inner .to-category-btn b {
        display: flex;
        align-items: center;
        align-content: center; }
    .mobile-menu-app .mobile-menu-inner .mobile-menu-loading {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -4rem;
      margin-top: -4rem;
      font-size: 8rem;
      animation: animate-menu-loading .75s ease-in-out infinite; }
      .mobile-menu-app .mobile-menu-inner .mobile-menu-loading.hide {
        display: none; }
      .mobile-menu-app .mobile-menu-inner .mobile-menu-loading.was-loaded {
        top: auto;
        bottom: .5rem;
        margin-left: -2rem;
        margin-top: 0;
        font-size: 4rem; }
    .mobile-menu-app .mobile-menu-inner .mobile-menu-top-btn {
      display: none;
      background-color: #FFFFFF; }
      .mobile-menu-app .mobile-menu-inner .mobile-menu-top-btn.show {
        display: block; }
      .mobile-menu-app .mobile-menu-inner .mobile-menu-top-btn ul {
        margin: 0; }
        .mobile-menu-app .mobile-menu-inner .mobile-menu-top-btn ul li a, .mobile-menu-app .mobile-menu-inner .mobile-menu-top-btn ul li span {
          color: #575757; }
        .mobile-menu-app .mobile-menu-inner .mobile-menu-top-btn ul:last-child a, .mobile-menu-app .mobile-menu-inner .mobile-menu-top-btn ul:last-child span {
          border-bottom: 1px solid #e3e3e3; }
    .mobile-menu-app .mobile-menu-inner ul {
      background-color: #FFFFFF;
      margin: 0 0 .5rem 0;
      padding: 0;
      width: 100%;
      list-style: none; }
      .mobile-menu-app .mobile-menu-inner ul li {
        padding: 0 1.5rem; }
        .mobile-menu-app .mobile-menu-inner ul li > a, .mobile-menu-app .mobile-menu-inner ul li > span {
          display: block;
          padding: 1rem .5rem;
          border-bottom: 1px solid #e3e3e3; }
          .mobile-menu-app .mobile-menu-inner ul li > a.highlight, .mobile-menu-app .mobile-menu-inner ul li > span.highlight {
            color: #575757;
            font-weight: bold; }
          .mobile-menu-app .mobile-menu-inner ul li > a div, .mobile-menu-app .mobile-menu-inner ul li > span div {
            display: flex;
            align-content: center;
            align-items: center; }
          .mobile-menu-app .mobile-menu-inner ul li > a i:not(.chevron-right), .mobile-menu-app .mobile-menu-inner ul li > span i:not(.chevron-right) {
            font-size: 2.2rem;
            margin-right: 1rem; }
          .mobile-menu-app .mobile-menu-inner ul li > a svg, .mobile-menu-app .mobile-menu-inner ul li > span svg {
            height: 2.2rem;
            aspect-ratio: 1;
            margin-right: 1rem; }
        .mobile-menu-app .mobile-menu-inner ul li:last-child a, .mobile-menu-app .mobile-menu-inner ul li:last-child span {
          border-bottom: none; }
      .mobile-menu-app .mobile-menu-inner ul li.linkToShop > div {
        border-bottom: 1px solid #e3e3e3; }
      .mobile-menu-app .mobile-menu-inner ul li.linkToShop a {
        display: inline-flex;
        height: 100%;
        align-content: center;
        align-items: center;
        border-bottom: none;
        padding: 1rem 0; }
        .mobile-menu-app .mobile-menu-inner ul li.linkToShop a i {
          margin-right: 1rem; }
    .mobile-menu-app .mobile-menu-inner #mobile-menu-vat-switch {
      padding: 1rem 1.5rem;
      background-color: #FFFFFF;
      margin-bottom: .5rem; }
      .mobile-menu-app .mobile-menu-inner #mobile-menu-vat-switch a {
        display: flex;
        border: 1px solid #aeaeae;
        padding: .5rem;
        line-height: 1;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        min-height: 4rem; }
        .mobile-menu-app .mobile-menu-inner #mobile-menu-vat-switch a span {
          text-align: left; }
        .mobile-menu-app .mobile-menu-inner #mobile-menu-vat-switch a i {
          display: none;
          color: #308136;
          font-size: 2rem; }
        .mobile-menu-app .mobile-menu-inner #mobile-menu-vat-switch a.active {
          border: 1px solid #308136; }
          .mobile-menu-app .mobile-menu-inner #mobile-menu-vat-switch a.active i {
            display: block; }
    .mobile-menu-app .mobile-menu-inner #mobile-menu-countryselection, .mobile-menu-app .mobile-menu-inner #mobile-menu-directorder, .mobile-menu-app .mobile-menu-inner #mobile-menu-newproducts, .mobile-menu-app .mobile-menu-inner #mobile-menu-saleproducts, .mobile-menu-app .mobile-menu-inner #mobile-menu-allproducts {
      padding: 0 1.5rem;
      background-color: #FFFFFF;
      margin-bottom: .5rem; }
      .mobile-menu-app .mobile-menu-inner #mobile-menu-countryselection a, .mobile-menu-app .mobile-menu-inner #mobile-menu-directorder a, .mobile-menu-app .mobile-menu-inner #mobile-menu-newproducts a, .mobile-menu-app .mobile-menu-inner #mobile-menu-saleproducts a, .mobile-menu-app .mobile-menu-inner #mobile-menu-allproducts a {
        display: block;
        padding: 1rem .5rem; }
        .mobile-menu-app .mobile-menu-inner #mobile-menu-countryselection a .ra-icon:not(.chevron-right), .mobile-menu-app .mobile-menu-inner #mobile-menu-directorder a .ra-icon:not(.chevron-right), .mobile-menu-app .mobile-menu-inner #mobile-menu-newproducts a .ra-icon:not(.chevron-right), .mobile-menu-app .mobile-menu-inner #mobile-menu-saleproducts a .ra-icon:not(.chevron-right), .mobile-menu-app .mobile-menu-inner #mobile-menu-allproducts a .ra-icon:not(.chevron-right) {
          font-size: 2.2rem;
          margin-right: 1rem; }
        .mobile-menu-app .mobile-menu-inner #mobile-menu-countryselection a div, .mobile-menu-app .mobile-menu-inner #mobile-menu-directorder a div, .mobile-menu-app .mobile-menu-inner #mobile-menu-newproducts a div, .mobile-menu-app .mobile-menu-inner #mobile-menu-saleproducts a div, .mobile-menu-app .mobile-menu-inner #mobile-menu-allproducts a div {
          display: flex;
          align-content: center;
          align-items: center; }
        .mobile-menu-app .mobile-menu-inner #mobile-menu-countryselection a.sale-highlight, .mobile-menu-app .mobile-menu-inner #mobile-menu-directorder a.sale-highlight, .mobile-menu-app .mobile-menu-inner #mobile-menu-newproducts a.sale-highlight, .mobile-menu-app .mobile-menu-inner #mobile-menu-saleproducts a.sale-highlight, .mobile-menu-app .mobile-menu-inner #mobile-menu-allproducts a.sale-highlight {
          color: #dc371c;
          font-weight: bold; }
    .mobile-menu-app .mobile-menu-inner .mobile-menu-language-list {
      background-color: #FFFFFF; }
      .mobile-menu-app .mobile-menu-inner .mobile-menu-language-list ul {
        margin-bottom: .5rem;
        list-style: none; }
      .mobile-menu-app .mobile-menu-inner .mobile-menu-language-list li {
        height: inherit;
        padding: .6rem 3rem;
        overflow: hidden; }
      .mobile-menu-app .mobile-menu-inner .mobile-menu-language-list .mobile-language-select-item {
        display: inline-block;
        padding: 0;
        border: none;
        height: 3rem;
        width: 4rem;
        text-align: center;
        line-height: 3rem;
        font-size: 2rem;
        position: relative; }
        .mobile-menu-app .mobile-menu-inner .mobile-menu-language-list .mobile-language-select-item .active {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 1.5rem;
          height: 1.5rem;
          background-color: #308136;
          border-radius: 50%;
          line-height: 1.5rem;
          font-size: 1rem;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: center;
          justify-items: center; }
          .mobile-menu-app .mobile-menu-inner .mobile-menu-language-list .mobile-language-select-item .active i {
            font-size: 1rem;
            margin-right: 0 !important; }
      .mobile-menu-app .mobile-menu-inner .mobile-menu-language-list .mobile-currency-select-item {
        border: 1px solid #aeaeae;
        background-color: #e3e3e3; }
    .mobile-menu-app .mobile-menu-inner .mobile-menu-currency-list {
      background-color: #FFFFFF; }
      .mobile-menu-app .mobile-menu-inner .mobile-menu-currency-list ul {
        margin-bottom: .5rem;
        list-style: none; }
      .mobile-menu-app .mobile-menu-inner .mobile-menu-currency-list li {
        height: inherit;
        padding: .6rem 3rem;
        overflow: hidden; }
      .mobile-menu-app .mobile-menu-inner .mobile-menu-currency-list .mobile-currency-select-item {
        display: inline-block;
        padding: 0;
        border: none;
        height: inherit;
        height: 3rem;
        width: 4rem;
        text-align: center;
        line-height: 3rem;
        font-size: 2rem;
        position: relative; }
        .mobile-menu-app .mobile-menu-inner .mobile-menu-currency-list .mobile-currency-select-item .active {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 1.5rem;
          height: 1.5rem;
          background-color: #308136;
          border-radius: 50%;
          line-height: 1.5rem;
          font-size: 1rem;
          color: #FFFFFF; }
    .mobile-menu-app .mobile-menu-inner .additional-entries .newproducts-link, .mobile-menu-app .mobile-menu-inner .additional-entries .saleproducts-link {
      padding-left: 1.5rem; } }

.mobile-menu-app:not(.show) .mobile-menu-logo {
  display: none; }

@keyframes animate-menu-loading {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(-359deg); } }

#header.header-transparent #navigation-outer.force-white-text-color *  a, #header.header-transparent #navigation-outer.force-white-text-color *  a span, #header.header-transparent #navigation-outer.force-white-text-color *  a .ra-icon {
  color: #FFFFFF !important; }

#header.header-transparent .force-white-text-color {
  color: #FFFFFF !important; }

#basketModal .modal-dialog {
  max-width: 106rem;
  margin-top: 12rem; }
  #basketModal .modal-dialog .modal-content {
    border: 1px solid #aeaeae; }
    #basketModal .modal-dialog .modal-content .modal-header {
      display: block;
      border-bottom: none; }
      #basketModal .modal-dialog .modal-content .modal-header #basketModalLabel {
        padding-top: 2rem;
        padding-bottom: 1rem;
        font-size: 2.4rem;
        text-align: center; }
      #basketModal .modal-dialog .modal-content .modal-header button.close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 2.4rem; }
    #basketModal .modal-dialog .modal-content .modal-body {
      padding: 0; }
      #basketModal .modal-dialog .modal-content .modal-body div.basketInfo {
        background-color: #f6f6f6;
        padding: 3rem;
        text-align: center;
        margin-bottom: 3rem; }
        #basketModal .modal-dialog .modal-content .modal-body div.basketInfo .container {
          padding: 0rem 18rem; }
        #basketModal .modal-dialog .modal-content .modal-body div.basketInfo .item-box {
          padding: 1rem 6rem;
          background-color: #FFFFFF;
          border: 1px solid #aeaeae;
          line-height: 2rem;
          text-align: left; }
          #basketModal .modal-dialog .modal-content .modal-body div.basketInfo .item-box .img-box {
            display: flex;
            justify-content: center; }
            #basketModal .modal-dialog .modal-content .modal-body div.basketInfo .item-box .img-box img {
              width: auto;
              max-width: 100%; }
          #basketModal .modal-dialog .modal-content .modal-body div.basketInfo .item-box .text-box {
            padding: 1rem; }
            #basketModal .modal-dialog .modal-content .modal-body div.basketInfo .item-box .text-box p.title {
              font-size: 1.6rem;
              font-weight: bold; }
            #basketModal .modal-dialog .modal-content .modal-body div.basketInfo .item-box .text-box p.variant {
              font-size: 1.4rem;
              font-weight: bold; }
        @media (max-width: 767.98px) {
          #basketModal .modal-dialog .modal-content .modal-body div.basketInfo .container {
            padding: 0rem 1rem; }
          #basketModal .modal-dialog .modal-content .modal-body div.basketInfo .item-box {
            padding: 1rem; }
            #basketModal .modal-dialog .modal-content .modal-body div.basketInfo .item-box .text-box {
              text-align: center; }
            #basketModal .modal-dialog .modal-content .modal-body div.basketInfo .item-box .img-box {
              margin-bottom: 1.5rem; } }
        #basketModal .modal-dialog .modal-content .modal-body div.basketInfo p.articleAdded {
          font-size: 1.6rem;
          font-weight: bold; }
        #basketModal .modal-dialog .modal-content .modal-body div.basketInfo a.addArticleComment {
          display: block;
          color: #00569d; }
        #basketModal .modal-dialog .modal-content .modal-body div.basketInfo div.basketModalButtons {
          max-width: 50rem;
          width: 100%;
          margin-top: 3rem; }
          @media (max-width: 575.98px) {
            #basketModal .modal-dialog .modal-content .modal-body div.basketInfo div.basketModalButtons button {
              margin-bottom: 1rem; } }
      #basketModal .modal-dialog .modal-content .modal-body .basketCrosseling {
        margin-top: 4rem;
        margin-bottom: 4rem; }
        #basketModal .modal-dialog .modal-content .modal-body .basketCrosseling .minibasketControl {
          width: 4rem; }
        #basketModal .modal-dialog .modal-content .modal-body .basketCrosseling .carousel-inner {
          padding: 0 4rem; }
        #basketModal .modal-dialog .modal-content .modal-body .basketCrosseling .crossSellingHeader {
          font-size: 1.8rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 1rem; }
        #basketModal .modal-dialog .modal-content .modal-body .basketCrosseling .productBox {
          border: 1px solid #e3e3e3;
          border-top: none;
          border-right: none;
          padding: 0; }
          #basketModal .modal-dialog .modal-content .modal-body .basketCrosseling .productBox:nth-child(4n) {
            border-right: 1px solid #e3e3e3; }
          #basketModal .modal-dialog .modal-content .modal-body .basketCrosseling .productBox:last-of-type {
            border-right: 1px solid #e3e3e3; }
          #basketModal .modal-dialog .modal-content .modal-body .basketCrosseling .productBox:nth-of-type(-n+4) {
            border-top: 1px solid #e3e3e3; }

.main-container {
  background-color: #FFFFFF;
  max-width: 1200px;
  padding: 0 1rem;
  flex: 1 1 auto; }
  @media (max-width: 767.98px) {
    .main-container {
      width: 100vw;
      overflow-x: hidden; } }

.main-container.extra-space {
  margin-top: 0; }

@media (max-width: 991.98px) {
  .main-container.hideElements.compact-menu {
    margin-top: 0 !important; } }

.main-container.darkFooter {
  padding-bottom: 2rem; }

.main-container .content-padding-bottom {
  padding-bottom: 1rem; }

.main-container .content-padding {
  padding-bottom: 5.5rem; }

.main-container #breadcrumb {
  align-items: center;
  font-size: 2rem;
  margin: 1rem 0 2.5rem 0;
  padding: 0; }
  @media (max-width: 767.98px) {
    .main-container #breadcrumb {
      margin-bottom: 0;
      margin-top: 0; } }
  .main-container #breadcrumb .breadcrumb-element.active {
    display: none; }
  .main-container #breadcrumb .breadcrumb-element:before {
    font-weight: normal;
    font-size: 1.4rem;
    content: "»";
    vertical-align: middle;
    color: #575757;
    padding: 0 .5rem;
    padding-top: 2px; }
  @media (max-width: 767.98px) {
    .main-container #breadcrumb {
      background-color: #f6f6f6;
      margin-left: -1rem;
      margin-right: -1rem;
      padding: 1.5rem;
      line-height: 1rem;
      align-items: unset; } }
  .main-container #breadcrumb li a {
    font-size: 1.4rem;
    color: #575757; }
  .main-container #breadcrumb li .ra-icon, .main-container #breadcrumb li .breadcrumb-text {
    vertical-align: middle;
    color: #575757; }
  .main-container #breadcrumb li .breadcrumb-text {
    font-size: 1.4rem; }

.main-container .flyout-background-cover {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1; }

.main-container .accountHeader {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem; }

.main-container .card {
  border: none !important; }

.main-container .sidebar-menu {
  padding-left: 0; }
  @media (max-width: 767.98px) {
    .main-container .sidebar-menu {
      padding-left: 1rem; } }

.main-container .sidebar-menu .content-menu-list {
  padding-left: 0; }
  .main-container .sidebar-menu .content-menu-list ul {
    padding-left: 0; }
  .main-container .sidebar-menu .content-menu-list li {
    list-style: none;
    line-height: 1.8; }
    .main-container .sidebar-menu .content-menu-list li a {
      display: inline-block; }
      .main-container .sidebar-menu .content-menu-list li a.highlighted {
        font-weight: bold; }
      .main-container .sidebar-menu .content-menu-list li a.level-1 {
        padding-left: 1rem; }
      .main-container .sidebar-menu .content-menu-list li a.level-2 {
        padding-left: 2rem; }
      .main-container .sidebar-menu .content-menu-list li a.level-3 {
        padding-left: 3rem; }
      .main-container .sidebar-menu .content-menu-list li a.level-4 {
        padding-left: 4rem; }

.main-container .sidebar-menu.mobile {
  margin-top: 4rem; }
  .main-container .sidebar-menu.mobile ul li {
    line-height: 4rem; }
    .main-container .sidebar-menu.mobile ul li a {
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid #aeaeae; }
      .main-container .sidebar-menu.mobile ul li a.level-0 {
        padding-left: 1rem; }
      .main-container .sidebar-menu.mobile ul li a.level-1 {
        padding-left: 2rem; }
      .main-container .sidebar-menu.mobile ul li a.level-2 {
        padding-left: 3rem; }
      .main-container .sidebar-menu.mobile ul li a.level-3 {
        padding-left: 4rem; }
      .main-container .sidebar-menu.mobile ul li a.level-4 {
        padding-left: 5rem; }
  .main-container .sidebar-menu.mobile ul li:last-of-type a.level-0 {
    border: none; }

.main-container #accountDashboard .ra-account-dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 2rem; }
  @media (max-width: 991.98px) {
    .main-container #accountDashboard .ra-account-dashboard {
      grid-template-columns: 1fr 1fr 1fr; } }
  @media (max-width: 767.98px) {
    .main-container #accountDashboard .ra-account-dashboard {
      grid-template-columns: 1fr 1fr; } }
  .main-container #accountDashboard .ra-account-dashboard .ra-dashboard-box {
    aspect-ratio: 1; }
    .main-container #accountDashboard .ra-account-dashboard .ra-dashboard-box .box-inner {
      height: 100%;
      background-color: #f6f6f6;
      border: 1px solid #aeaeae;
      padding: .5rem;
      position: relative; }
      .main-container #accountDashboard .ra-account-dashboard .ra-dashboard-box .box-inner > div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        .main-container #accountDashboard .ra-account-dashboard .ra-dashboard-box .box-inner > div .box-inner-text {
          text-align: center; }
          .main-container #accountDashboard .ra-account-dashboard .ra-dashboard-box .box-inner > div .box-inner-text h2 {
            font-size: 1.6rem; }
            @media (max-width: 767.98px) {
              .main-container #accountDashboard .ra-account-dashboard .ra-dashboard-box .box-inner > div .box-inner-text h2 {
                font-size: 1.2rem; } }
        .main-container #accountDashboard .ra-account-dashboard .ra-dashboard-box .box-inner > div .box-inner-icon {
          text-align: center; }
          .main-container #accountDashboard .ra-account-dashboard .ra-dashboard-box .box-inner > div .box-inner-icon .ra-icon {
            font-size: 8rem;
            color: #575757; }

.main-container #accountDashboard .account_dashboard_lastOrder_container {
  margin-top: 2rem; }
  .main-container #accountDashboard .account_dashboard_lastOrder_container .account_dashboard_lastOrder_table {
    margin-bottom: 2rem;
    padding-left: 0; }
  @media (max-width: 767.98px) {
    .main-container #accountDashboard .account_dashboard_lastOrder_container .order_header_row th:last-child, .main-container #accountDashboard .account_dashboard_lastOrder_container .order_header_row td:last-child, .main-container #accountDashboard .account_dashboard_lastOrder_container .order_content_row th:last-child, .main-container #accountDashboard .account_dashboard_lastOrder_container .order_content_row td:last-child {
      text-align: right; } }
  .main-container #accountDashboard .account_dashboard_lastOrder_container .order_content_row {
    vertical-align: top; }
  .main-container #accountDashboard .account_dashboard_lastOrder_container .order_header_row th {
    padding-left: 0; }

.main-container #accountOrder .account_order_history_container, .main-container #rainquiryorder-account .account_order_history_container {
  margin-top: 2.5rem; }
  .main-container #accountOrder .account_order_history_container .ordered-without-print-info, .main-container #rainquiryorder-account .account_order_history_container .ordered-without-print-info {
    font-size: 1.2rem; }
  .main-container #accountOrder .account_order_history_container #orderSummary, .main-container #rainquiryorder-account .account_order_history_container #orderSummary {
    height: 5rem;
    border-bottom: 0.3rem solid #e3e3e3; }
    .main-container #accountOrder .account_order_history_container #orderSummary .summaryDesc, .main-container #rainquiryorder-account .account_order_history_container #orderSummary .summaryDesc {
      vertical-align: middle;
      font-weight: bold;
      font-size: 1.2rem;
      margin-right: 2rem;
      margin-top: 3rem; }
  .main-container #accountOrder .account_order_history_container .order-status, .main-container #rainquiryorder-account .account_order_history_container .order-status {
    float: right;
    line-height: 2rem;
    display: block;
    font-size: 1.4rem; }
    .main-container #accountOrder .account_order_history_container .order-status .ra-icon, .main-container #rainquiryorder-account .account_order_history_container .order-status .ra-icon {
      font-size: 2rem;
      vertical-align: middle;
      margin-right: .5rem; }
    .main-container #accountOrder .account_order_history_container .order-status.shipped .ra-icon, .main-container #rainquiryorder-account .account_order_history_container .order-status.shipped .ra-icon {
      font-size: 2.5rem; }
    .main-container #accountOrder .account_order_history_container .order-status.delivered, .main-container #rainquiryorder-account .account_order_history_container .order-status.delivered {
      color: #308136; }
    @media (max-width: 767.98px) {
      .main-container #accountOrder .account_order_history_container .order-status, .main-container #rainquiryorder-account .account_order_history_container .order-status {
        width: 100%;
        text-align: center;
        margin: 1rem;
        float: none; } }
  .main-container #accountOrder .account_order_history_container .order_details, .main-container #rainquiryorder-account .account_order_history_container .order_details {
    margin-top: 2rem;
    margin-bottom: 6rem;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 4rem; }
    .main-container #accountOrder .account_order_history_container .order_details:nth-last-of-type(2), .main-container #rainquiryorder-account .account_order_history_container .order_details:nth-last-of-type(2) {
      border-bottom: none;
      margin-top: 0px;
      margin-bottom: 0px; }
    .main-container #accountOrder .account_order_history_container .order_details table, .main-container #rainquiryorder-account .account_order_history_container .order_details table {
      width: 100%; }
      .main-container #accountOrder .account_order_history_container .order_details table tr, .main-container #rainquiryorder-account .account_order_history_container .order_details table tr {
        margin-bottom: 0; }
        .main-container #accountOrder .account_order_history_container .order_details table tr td, .main-container #rainquiryorder-account .account_order_history_container .order_details table tr td {
          margin-bottom: 0;
          width: 50%; }
    @media (max-width: 767.98px) {
      .main-container #accountOrder .account_order_history_container .order_details a, .main-container #accountOrder .account_order_history_container .order_details button, .main-container #rainquiryorder-account .account_order_history_container .order_details a, .main-container #rainquiryorder-account .account_order_history_container .order_details button {
        margin-bottom: 1rem; }
      .main-container #accountOrder .account_order_history_container .order_details .order-article-row, .main-container #rainquiryorder-account .account_order_history_container .order_details .order-article-row {
        margin-bottom: 2rem; } }

@media (max-width: 767.98px) and (max-width: 767.98px) {
  .main-container #accountOrder .account_order_history_container .order_details .showMoreArticles, .main-container #rainquiryorder-account .account_order_history_container .order_details .showMoreArticles {
    margin-bottom: 2rem;
    display: block; } }
  .main-container #accountOrder .account_order_history_container .showMoreArticles, .main-container #rainquiryorder-account .account_order_history_container .showMoreArticles {
    margin-bottom: 2rem; }
  .main-container #accountOrder .account_order_history_container .tooltip-inner, .main-container #rainquiryorder-account .account_order_history_container .tooltip-inner {
    text-align: left; }
  .main-container #accountOrder .account_order_history_container .order_tracking_header, .main-container #rainquiryorder-account .account_order_history_container .order_tracking_header {
    margin-top: 2rem; }
  .main-container #accountOrder .account_order_history_container .order_label, .main-container #rainquiryorder-account .account_order_history_container .order_label {
    vertical-align: top; }
  .main-container #accountOrder .account_order_history_container .order_redo, .main-container #rainquiryorder-account .account_order_history_container .order_redo {
    margin-top: 2rem; }
  .main-container #accountOrder .account_order_history_container .btn-row, .main-container #rainquiryorder-account .account_order_history_container .btn-row {
    margin-top: 3rem; }
  .main-container #accountOrder .account_order_history_container .order_line, .main-container #rainquiryorder-account .account_order_history_container .order_line {
    border-bottom: 0.3rem solid #e3e3e3; }
  .main-container #accountOrder .account_order_history_container .order_line:last-child, .main-container #rainquiryorder-account .account_order_history_container .order_line:last-child {
    border: none; }
  .main-container #accountOrder .account_order_history_container .order_articles, .main-container #rainquiryorder-account .account_order_history_container .order_articles {
    margin-top: 2rem;
    margin-bottom: 2rem; }
    .main-container #accountOrder .account_order_history_container .order_articles ul, .main-container #rainquiryorder-account .account_order_history_container .order_articles ul {
      list-style: none;
      padding-left: 0; }
      .main-container #accountOrder .account_order_history_container .order_articles ul a, .main-container #rainquiryorder-account .account_order_history_container .order_articles ul a {
        color: #00569d; }
        .main-container #accountOrder .account_order_history_container .order_articles ul a p, .main-container #rainquiryorder-account .account_order_history_container .order_articles ul a p {
          color: #575757; }
      .main-container #accountOrder .account_order_history_container .order_articles ul figure, .main-container #rainquiryorder-account .account_order_history_container .order_articles ul figure {
        margin-bottom: 1rem;
        max-width: auto;
        max-height: auto; }
        .main-container #accountOrder .account_order_history_container .order_articles ul figure img, .main-container #rainquiryorder-account .account_order_history_container .order_articles ul figure img {
          max-height: 100%;
          max-width: 100%; }
  .main-container #accountOrder .account_order_history_container .raconfigurator-account-info, .main-container #rainquiryorder-account .account_order_history_container .raconfigurator-account-info {
    font-size: 1.1rem;
    margin-bottom: 1rem; }
    .main-container #accountOrder .account_order_history_container .raconfigurator-account-info td, .main-container #rainquiryorder-account .account_order_history_container .raconfigurator-account-info td {
      padding: 0 .5rem; }
  .main-container #accountOrder .account_order_history_container .ra-bfc-size-info, .main-container #rainquiryorder-account .account_order_history_container .ra-bfc-size-info {
    font-size: 1.1rem; }
  .main-container #accountOrder .account_order_history_container .to-upload, .main-container #rainquiryorder-account .account_order_history_container .to-upload {
    margin-bottom: 2rem; }
    .main-container #accountOrder .account_order_history_container .to-upload i, .main-container #rainquiryorder-account .account_order_history_container .to-upload i {
      font-size: 2.5rem;
      vertical-align: middle;
      margin-right: .5rem; }
  .main-container #accountOrder .account_order_history_container .list-no-style, .main-container #rainquiryorder-account .account_order_history_container .list-no-style {
    list-style: none; }
  @media (max-width: 767.98px) {
    .main-container #accountOrder .account_order_history_container .order_information, .main-container #rainquiryorder-account .account_order_history_container .order_information {
      flex-direction: column;
      align-content: center;
      justify-content: center; }
    .main-container #accountOrder .account_order_history_container .order-redo-button, .main-container #rainquiryorder-account .account_order_history_container .order-redo-button {
      padding: 3.75px 7.5px;
      margin-bottom: 10px; }
    .main-container #accountOrder .account_order_history_container .no-padding-left, .main-container #rainquiryorder-account .account_order_history_container .no-padding-left {
      padding-left: 0px; }
    .main-container #accountOrder .account_order_history_container .mobile-padding-bottom, .main-container #rainquiryorder-account .account_order_history_container .mobile-padding-bottom {
      padding-bottom: 4rem; } }
  .main-container #accountOrder .account_order_history_container .account_order_details, .main-container #rainquiryorder-account .account_order_history_container .account_order_details {
    margin-top: 2rem; }
  .main-container #accountOrder .account_order_history_container .align-text-bottom, .main-container #rainquiryorder-account .account_order_history_container .align-text-bottom {
    vertical-align: text-bottom; }

.main-container #accountOrder #order-feedback-container, .main-container #rainquiryorder-account #order-feedback-container {
  padding-left: 2rem;
  padding-right: 0px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  margin-top: 2rem;
  flex-direction: column; }
  @media (max-width: 767.98px) {
    .main-container #accountOrder #order-feedback-container, .main-container #rainquiryorder-account #order-feedback-container {
      padding: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      text-align: center; } }

.main-container #accountOrder #textarea-order-feedback, .main-container #rainquiryorder-account #textarea-order-feedback {
  resize: none;
  height: 6rem !important;
  margin-bottom: 1rem; }

.main-container #accountOrder.details .accountMenu {
  margin-top: 7rem; }

.main-container #accountOrder .order_details img, .main-container #rainquiryorder-account .order_details img {
  max-width: 100%; }

.main-container #accountOrder .orderEndLine, .main-container #rainquiryorder-account .orderEndLine {
  border-top: 1px solid #575757; }

.main-container .accountDeleteSuccess a {
  margin: 1rem;
  width: 30rem; }

.main-container .account-form button[type='submit'] {
  width: 100%; }

.main-container .account-form #shipping-address-select .panel {
  position: relative;
  border: 1px solid #aeaeae;
  margin: 0;
  width: 100%;
  height: 100%; }

.main-container .account-form #shipping-address-select .addresses-available.selected .panel {
  border: 1px solid #00569d; }

.main-container .account-form #shipping-address-select .panel-body {
  padding: 1rem;
  margin-bottom: 8rem; }

.main-container .account-form #shipping-address-select .panel-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; }

.main-container .account-form #shipping-address-select .edit-shipping-address, .main-container .account-form #shipping-address-select .delete-shipping-address {
  width: 100%;
  min-height: 3rem;
  line-height: 2rem; }

.main-container .account-form #shipping-address-select .select-shipping-address, .main-container .account-form #shipping-address-select .add-shipping-address {
  margin: 1rem 0;
  min-height: 3rem;
  height: 3rem;
  line-height: 2rem; }

.main-container .account-form #shipping-address-select .addresses-available {
  min-height: 22rem;
  padding: 1rem; }

.main-container .account-form .card-header, .main-container .account-form .card-footer {
  background-color: transparent; }

.main-container .account-form .card-footer {
  background-color: transparent;
  margin-top: 1rem; }

.main-container .raorder-overview-box .redo-order-header {
  margin-top: 2rem; }

.main-container .raorder-overview-box a, .main-container .raorder-overview-box button {
  margin: 2rem 0; }

.main-container #rareviews-account .account-review-list {
  padding: 0;
  list-style: none; }
  .main-container #rareviews-account .account-review-list .account-review-row {
    padding: 1rem 0;
    margin: 1rem 0;
    border-bottom: 1px solid #e3e3e3; }
    .main-container #rareviews-account .account-review-list .account-review-row:last-child {
      border: none; }
    .main-container #rareviews-account .account-review-list .account-review-row p {
      margin-top: 2rem;
      margin-bottom: 0; }
    .main-container #rareviews-account .account-review-list .account-review-row .account-review-article-varsel {
      margin-top: 0; }
    .main-container #rareviews-account .account-review-list .account-review-row .account-review-show-or-edit {
      color: #00569d;
      display: block;
      margin-top: 2rem; }
      .main-container #rareviews-account .account-review-list .account-review-row .account-review-show-or-edit:hover {
        color: #00569d; }
    .main-container #rareviews-account .account-review-list .account-review-row .account-review-helpful-row span {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      align-content: center; }
      .main-container #rareviews-account .account-review-list .account-review-row .account-review-helpful-row span .ra-icon {
        font-size: 1.8rem;
        margin-right: .5rem; }
        .main-container #rareviews-account .account-review-list .account-review-row .account-review-helpful-row span .ra-icon.dislike {
          margin-left: 2rem; }
    .main-container #rareviews-account .account-review-list .account-review-row .account-review-rating-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      margin-bottom: 2rem; }
      .main-container #rareviews-account .account-review-list .account-review-row .account-review-rating-row span {
        margin-left: 1rem; }

.main-container #noticelistProductList .row {
  padding: 3rem 0; }

.main-container #noticelistProductList .removeFromWishlist, .main-container #noticelistProductList .addToBasket, .main-container #noticelistProductList .toArticle {
  display: block;
  margin-top: 1rem;
  width: 100%; }

.main-container #noticelistProductList .productBox {
  border: 1px solid #e3e3e3;
  border-top: none;
  border-right: none;
  padding: 0; }
  .main-container #noticelistProductList .productBox:nth-child(3n) {
    border-right: 1px solid #e3e3e3; }
  .main-container #noticelistProductList .productBox:last-of-type {
    border-right: 1px solid #e3e3e3; }
  .main-container #noticelistProductList .productBox:nth-of-type(-n+3) {
    border-top: 1px solid #e3e3e3; }

.main-container .accountLoginView #loginAccount {
  margin: 2rem 0; }

.main-container .accountLoginView #account-login, .main-container .accountLoginView #account-register {
  border: 1px solid #e3e3e3; }
  .main-container .accountLoginView #account-login form, .main-container .accountLoginView #account-login .card, .main-container .accountLoginView #account-register form, .main-container .accountLoginView #account-register .card {
    margin: auto;
    max-width: 40rem; }
  .main-container .accountLoginView #account-login .card-header, .main-container .accountLoginView #account-register .card-header {
    height: 10rem;
    padding-top: 4rem; }
  .main-container .accountLoginView #account-login .card-body, .main-container .accountLoginView #account-register .card-body {
    height: 18rem; }
  .main-container .accountLoginView #account-login .card-footer, .main-container .accountLoginView #account-register .card-footer {
    height: 8rem; }

.main-container .accountLoginView #account-register {
  background-color: #f6f6f6; }
  .main-container .accountLoginView #account-register ul {
    list-style: none; }
    .main-container .accountLoginView #account-register ul i.check {
      color: #00569d; }

@media (max-width: 767.98px) {
  .main-container .ra-account-menu hr {
    display: none;
    margin: 0; } }

.main-container .ra-account-menu .panel a {
  height: 3rem;
  display: flex;
  align-content: center;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem; }
  .main-container .ra-account-menu .panel a .ra-icon {
    font-size: 2rem;
    color: #575757; }
    .main-container .ra-account-menu .panel a .ra-icon.chevron-right {
      display: none; }
  @media (max-width: 767.98px) {
    .main-container .ra-account-menu .panel a {
      border-top: 1px solid #e3e3e3;
      height: 4rem;
      font-size: 1.4rem; }
      .main-container .ra-account-menu .panel a .ra-icon.chevron-right {
        display: block;
        margin-left: auto; } }

.main-container .ra-account-menu .active {
  font-weight: bold; }

.main-container ul.account-menu-mobile {
  list-style: none;
  padding: 0;
  margin-top: 1rem; }
  .main-container ul.account-menu-mobile li.mobile-menu-entry {
    width: 100%;
    border-bottom: 1px solid #e3e3e3;
    display: block;
    font-size: 1.4rem;
    line-height: 1.5;
    vertical-align: middle; }
    .main-container ul.account-menu-mobile li.mobile-menu-entry i.ra-icon {
      float: right;
      font-size: 1.4rem;
      vertical-align: middle;
      line-height: 1.5; }
    .main-container ul.account-menu-mobile li.mobile-menu-entry a {
      padding: 1rem .5rem;
      display: block; }
  .main-container ul.account-menu-mobile li.mobile-menu-entry:last-child {
    border: none; }
  .main-container ul.account-menu-mobile li.mobile-menu-entry.active {
    font-weight: bold; }
    .main-container ul.account-menu-mobile li.mobile-menu-entry.active i.ra-icon {
      display: none; }

.main-container #raroombuilder-account #raroombuilder-account-info {
  margin-bottom: 3rem; }

.main-container #raroombuilder-account #raroombuilder-account-overview #btn-sort {
  border: none;
  background-color: transparent;
  float: right;
  height: 2rem;
  line-height: 1.8rem;
  margin-bottom: 2rem; }
  .main-container #raroombuilder-account #raroombuilder-account-overview #btn-sort:focus {
    border: none;
    box-shadow: none;
    outline: none; }

.main-container #raroombuilder-account #raroombuilder-account-overview .dropdown-menu {
  font-size: 1.4rem;
  border-bottom: 1px solid #aeaeae; }

.main-container #raroombuilder-account #raroombuilder-account-overview ul {
  padding-left: 0;
  list-style: none; }
  .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 1rem;
    margin-bottom: 1rem; }
    .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item.headline {
      border-bottom: 1px solid #aeaeae;
      padding-bottom: .5rem;
      margin-bottom: 1rem;
      font-weight: bold; }
    .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item.not-available .overview {
      opacity: 0.5; }
    .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .not-available-info {
      color: #dc371c; }
    .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .details-col {
      position: relative; }
      .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .details-col .save-date {
        position: absolute;
        bottom: 0;
        left: 1rem;
        font-size: 1.2rem; }
    .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .price {
      text-align: right; }
    @media (max-width: 991.98px) {
      .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .to-basket {
        margin-top: .5rem;
        margin-bottom: .5rem; }
      .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .price {
        margin: 2rem 0 1rem 0;
        text-align: center; } }
    .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .btn-col {
      text-align: right; }
      @media (max-width: 991.98px) {
        .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .btn-col {
          text-align: center; } }
      .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .btn-col a, .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .btn-col button {
        display: inline-block;
        border: none;
        background-color: transparent;
        color: inherit;
        height: 2rem;
        font-size: 1.2rem;
        line-height: 1.8rem;
        padding-right: 0;
        margin: .25rem 0; }
        .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .btn-col a:focus, .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .btn-col button:focus {
          outline: none;
          box-shadow: none; }
        .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .btn-col a.disabled, .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .btn-col a:disabled, .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .btn-col button.disabled, .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .btn-col button:disabled {
          cursor: not-allowed; }
        .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .btn-col a i, .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .btn-col button i {
          font-size: 2rem;
          vertical-align: middle; }
        @media (max-width: 991.98px) {
          .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .btn-col a, .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .btn-col button {
            margin: .25rem 1rem; } }
    .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .article-list {
      display: none;
      margin-top: 2rem; }
      .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .article-list .head {
        border-bottom: 1px solid #aeaeae;
        font-weight: bold;
        padding-bottom: .5rem;
        margin-bottom: .5rem; }
      .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .article-list .amount {
        margin-top: 1rem; }
      .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .article-list .article {
        border-bottom: 1px solid #e3e3e3;
        margin-bottom: .5rem;
        padding-bottom: .5rem; }
        .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .article-list .article .title {
          font-weight: bold; }
        .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .article-list .article .total-price, .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .article-list .article .unit-price, .main-container #raroombuilder-account #raroombuilder-account-overview ul .raroombuilder-configuration-item .article-list .article .title {
          width: 100%;
          display: block; }

.main-container #accountVatExemptionRequest #attachment {
  border: 1px solid #aeaeae;
  padding: .5rem 1rem 2rem 1rem; }
  .main-container #accountVatExemptionRequest #attachment legend {
    width: auto;
    font-size: 1.25rem; }
  .main-container #accountVatExemptionRequest #attachment.attachment-required legend {
    font-weight: bold; }
  .main-container #accountVatExemptionRequest #attachment .file-info {
    position: absolute;
    bottom: 1.5rem;
    right: 2rem;
    font-style: italic;
    font-size: 1rem; }

.main-container #accountVatExemptionRequest .custom-error {
  display: none;
  color: #dc371c;
  font-size: 1.2rem;
  font-weight: bold;
  padding-left: .5rem;
  margin-top: .5rem; }

.main-container #accountVatExemptionRequest .has-error #attachment {
  border: 1px solid #dc371c; }
  .main-container #accountVatExemptionRequest .has-error #attachment .error {
    display: none !important; }
  .main-container #accountVatExemptionRequest .has-error #attachment .custom-error {
    display: block; }
  .main-container #accountVatExemptionRequest .has-error #attachment legend {
    color: #dc371c; }

.main-container #accountVatExemptionRequest hr {
  margin: 2rem 0; }

.main-container #accountVatExemptionRequest .coffee {
  font-size: 4rem;
  vertical-align: middle; }

.main-container form#rma-form .dropdown {
  width: 100%; }

.main-container form#rma-form #productHolder {
  margin: 3rem 0; }
  .main-container form#rma-form #productHolder .row {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #aeaeae; }

.main-container form#rma-form label.req {
  font-weight: bold; }

.main-container form#rma-form .help-block {
  margin-bottom: 1rem; }

.main-container form#rma-form .rmaProductList {
  margin-bottom: 2rem; }

.main-container form#rma-form .help-block {
  display: block;
  width: 100%; }

.main-container form#rma-form #private_label {
  margin-left: 2rem; }

.main-container form#rma-form .form-help-box {
  margin-bottom: 2rem; }

.main-container form#rma-form .rma-login button, .main-container form#rma-form .rma-login a {
  width: 100%; }
  .main-container form#rma-form .rma-login button i, .main-container form#rma-form .rma-login a i {
    font-size: 18px;
    margin-right: .5rem;
    vertical-align: sub; }

.main-container form#rma-form .rma-login label {
  font-weight: bold; }

.main-container form#rma-form .rma-login .panel-heading {
  height: 10rem;
  padding-top: 4rem; }

.main-container form#rma-form .rma-login .panel-body {
  height: 18rem; }

.main-container form#rma-form .rma-login .panel-footer {
  height: 8rem; }

.main-container form#rma-form .agbLink {
  color: #00569d; }

.main-container form#rma-form .has-error input {
  border: 1px solid #dc371c; }

.main-container form#rma-form .has-error .error, .main-container form#rma-form .has-error label {
  color: #dc371c; }

.main-container form#rma-form .has-error .rma-input-number {
  border: solid 1px #dc371c !important; }

.main-container form#rma-form .rma-package-row {
  border: 1px solid #e3e3e3;
  padding: 1rem;
  margin: 1rem 0;
  width: 100%; }
  .main-container form#rma-form .rma-package-row .choose-package-size {
    margin-left: auto; }
  .main-container form#rma-form .rma-package-row .same-size-button {
    gap: 1rem; }

.main-container form#rma-form .rma-dimensions .rma-input-number {
  margin: 0.5rem 0;
  width: 100%;
  border: solid 1px #aeaeae; }
  .main-container form#rma-form .rma-dimensions .rma-input-number.has-error {
    border: solid 1px #dc371c; }

.main-container form#rma-form #returnShipmentAnnounced-error {
  min-width: 20rem;
  margin-top: 2rem; }

.main-container form#rma-form #inputFields div {
  width: 100%; }

.main-container form#rma-form .same-size-button {
  margin-bottom: .5rem; }

.main-container form#rma-form .modal-dialog {
  max-width: 75rem; }
  .main-container form#rma-form .modal-dialog .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem; }
    .main-container form#rma-form .modal-dialog .modal-body a {
      width: 100%; }

.main-container #checkout-ssl-info {
  float: right;
  margin: 1rem; }
  .main-container #checkout-ssl-info i {
    font-size: 24px;
    vertical-align: sub; }

.main-container #registerForm h3 {
  margin-top: 3rem; }

.main-container form.directorder ul.directOrderForm {
  list-style: none;
  padding: 0;
  margin: 3rem 0; }

.main-container form.directorder .inputDirectOrder input[type="text"],
.main-container form.directorder .inputDirectOrder input[type="number"] {
  -moz-appearance: textfield; }

.main-container form.directorder .inputDirectOrder input {
  height: 4rem; }

.main-container form.directorder div.extendInputs {
  margin-bottom: 1rem; }

.main-container form.directorder hr.directOrderLine {
  margin: 1.5rem 0; }

.main-container form.directorder li#directOrderHeadings div {
  margin-bottom: 1rem; }

.main-container form.directorder a#extendInputFields {
  width: 100%; }

.main-container form.directorder button#directOrderToBasket {
  width: 100%; }
  .main-container form.directorder button#directOrderToBasket:disabled {
    background-color: #e3e3e3;
    border: 1px solid #aeaeae;
    pointer-events: none;
    cursor: not-allowed;
    color: #575757 !important; }

.main-container form.directorder .widthLabel, .main-container form.directorder .lengthLabel {
  font-weight: bold;
  margin-top: 5px; }
  .main-container form.directorder .widthLabel .info, .main-container form.directorder .lengthLabel .info {
    position: absolute;
    right: 1.5rem;
    color: #dc371c; }

.main-container form.directorder .red {
  color: #dc371c !important;
  font-weight: bold; }

.main-container form.directorder .green {
  color: #308136 !important;
  font-weight: bold; }

.main-container form.directorder .artOption.clearrow {
  cursor: pointer;
  float: right; }
  .main-container form.directorder .artOption.clearrow i {
    font-size: 32px;
    vertical-align: center;
    margin-right: 1rem; }

.main-container form.directorder button#directOrderToBasket i, .main-container form.directorder button#directOrderToBasket span, .main-container form.directorder .artOption i, .main-container form.directorder .artOption span {
  vertical-align: middle; }

.main-container form.directorder button#directOrderToBasket i {
  margin-right: 1rem; }

.main-container form.directorder .artNumTypeSelect, .main-container form.directorder .directorderInfo {
  padding-left: 2rem; }

.main-container form.directorder .artNumTypeSelect {
  margin-top: 4rem; }
  .main-container form.directorder .artNumTypeSelect input {
    margin-left: 1rem; }

.main-container form.directorder .artShortInfo a {
  font-weight: bold; }

@media (max-width: 991.98px) {
  .main-container form.directorder .inputDirectOrder input {
    height: auto;
    margin-bottom: 1rem; }
  .main-container form.directorder .artOption.clearrow {
    float: none;
    display: block;
    margin-top: 1rem; } }

.main-container form.directorder .output.suggestions {
  position: absolute;
  margin-top: 4rem;
  z-index: 10;
  height: auto;
  max-height: 35rem;
  overflow-y: auto;
  border: 1px solid #aeaeae;
  border-top: 0;
  padding: 0;
  background-color: #FFFFFF; }
  .main-container form.directorder .output.suggestions .head {
    padding: .7rem  4.5rem .7rem .7rem;
    width: 100%;
    background-color: #00569d;
    color: #FFFFFF;
    margin-bottom: 0; }
    .main-container form.directorder .output.suggestions .head .info {
      position: absolute;
      right: 1rem;
      top: 1rem;
      color: #FFFFFF;
      font-size: 32px; }
    @media (max-width: 767.98px) {
      .main-container form.directorder .output.suggestions .head .info {
        display: none; } }
  .main-container form.directorder .output.suggestions .subhead {
    padding: .7rem;
    background-color: #e3e3e3;
    color: #575757;
    margin-bottom: 0;
    float: left;
    width: 100%;
    border-bottom: 1px solid #aeaeae; }
  .main-container form.directorder .output.suggestions .article {
    float: left;
    border-bottom: 0.1rem solid #aeaeae;
    min-height: 5rem;
    width: 100%;
    padding: .5rem; }
    .main-container form.directorder .output.suggestions .article a {
      font-weight: bold; }
    @media (max-width: 767.98px) {
      .main-container form.directorder .output.suggestions .article .artShortInfo {
        margin-left: .5rem; } }
    .main-container form.directorder .output.suggestions .article .artShortInfo {
      padding-left: 0; }
      .main-container form.directorder .output.suggestions .article .artShortInfo h3 {
        margin-top: .5rem; }
      .main-container form.directorder .output.suggestions .article .artShortInfo .title {
        padding-top: .5rem;
        padding-left: 1rem; }
      .main-container form.directorder .output.suggestions .article .artShortInfo .hidden-suggestions {
        display: none; }
      .main-container form.directorder .output.suggestions .article .artShortInfo .red {
        color: #dc371c;
        font-weight: bold; }
    .main-container form.directorder .output.suggestions .article .artImage {
      margin: .5rem;
      width: 10rem; }
      .main-container form.directorder .output.suggestions .article .artImage figure img {
        margin: auto 0;
        display: block;
        border: 0;
        height: 5rem; }
  .main-container form.directorder .output.suggestions .article:hover {
    color: #00569d !important;
    cursor: pointer; }
    .main-container form.directorder .output.suggestions .article:hover a {
      color: #00569d !important; }

.main-container form.directorder .reloadDirectOrder {
  position: absolute;
  top: 0.5rem;
  left: 24.5rem;
  font-size: 32px;
  -moz-animation-name: reload_rotate;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -webkit-animation-name: reload_rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -ms-animation-name: reload_rotate;
  -ms-animation-duration: 1s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: reload_rotate;
  -o-animation-duration: 1s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
  animation-name: reload_rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes reload_rotate {
  0% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(0deg); } }

.main-container form#catorder-form div.catalog-item {
  margin-bottom: 2rem; }
  .main-container form#catorder-form div.catalog-item .catalog-item-outer {
    width: 16rem;
    height: 22.7rem;
    perspective: 180rem;
    margin: 1.5rem auto; }
  .main-container form#catorder-form div.catalog-item .catalog-item-outer > .catalog-item-inner {
    position: absolute;
    transform-style: preserve-3d;
    transform: rotate3d(0, 1, 0, 0);
    transition: transform .3s;
    box-shadow: -2rem 0 2rem rgba(255, 255, 255, 0.5);
    z-index: 1; }
  .main-container form#catorder-form div.catalog-item .catalog-item-outer > .catalog-item-inner.normal {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
  .main-container form#catorder-form div.catalog-item .catalog-item-outer > .catalog-item-inner.slim {
    left: 0;
    right: 0;
    width: 10.8rem;
    height: 22.9rem;
    margin: auto; }
  .main-container form#catorder-form div.catalog-item .catalog-item-outer > .catalog-item-inner.square {
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 3.4rem;
    width: 16rem;
    height: 16rem; }
  .main-container form#catorder-form div.catalog-item .catalog-item-outer > .catalog-item-inner.cross {
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 5.7rem;
    width: 16.2rem;
    height: 11.5rem; }
  .main-container form#catorder-form div.catalog-item .catalog-item-side {
    position: absolute;
    top: 0;
    right: -5rem;
    height: 100%;
    width: 5rem;
    background-color: #4F4F4F;
    transform-style: flat;
    transform-origin: 0 50%;
    transform: rotate3d(0, 1, 0, -86deg) translate3d(-100%, 0, 0); }
  .main-container form#catorder-form div.catalog-item .catalog-item-info {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.75);
    width: 120%;
    z-index: 2;
    bottom: -1rem;
    left: -10%;
    transition: all .3s;
    opacity: 0;
    padding: 1rem 0; }
    .main-container form#catorder-form div.catalog-item .catalog-item-info p.catalogNotOrderable {
      color: #dc371c; }
  .main-container form#catorder-form div.catalog-item .catalog-item-info.active {
    opacity: 1; }
  .main-container form#catorder-form div.catalog-item .catalog-item-outer:hover > .catalog-item-info {
    opacity: 1; }
  @media (min-width: 992px) {
    .main-container form#catorder-form div.catalog-item .catalog-item-inner.active {
      transform: rotate3d(0, 1, 0, -30deg); }
    .main-container form#catorder-form div.catalog-item .catalog-item-outer:hover > .catalog-item-inner {
      transform: rotate3d(0, 1, 0, -30deg); } }
  .main-container form#catorder-form div.catalog-item .catalog-item-ribbons-triangle {
    position: absolute;
    left: -.5rem;
    top: 2.5rem;
    width: 0;
    height: 0;
    border-top: 0.5rem solid #575757;
    border-left: 1rem solid transparent; }
  .main-container form#catorder-form div.catalog-item .catalog-item-ribbons {
    position: relative;
    height: 2.5rem;
    top: 0;
    left: -.5rem;
    float: left;
    margin-right: .3rem;
    text-transform: uppercase;
    z-index: 500; }
    .main-container form#catorder-form div.catalog-item .catalog-item-ribbons .special {
      background-color: #dc371c;
      color: #fff; }
    .main-container form#catorder-form div.catalog-item .catalog-item-ribbons span {
      display: inline-block;
      vertical-align: middle;
      padding: 0 1rem;
      padding-top: .3rem; }
  .main-container form#catorder-form div.catalog-item .catalog-item-description {
    margin-top: 1rem;
    text-align: center; }
  .main-container form#catorder-form div.catalog-item .catalog-item-options button, .main-container form#catorder-form div.catalog-item .catalog-item-options a {
    display: block;
    width: 100%;
    margin: .2rem; }
  .main-container form#catorder-form div.catalog-item .catalog-item-title {
    text-align: center; }
  .main-container form#catorder-form div.catalog-item .catalog-item-page-count {
    display: block;
    font-style: italic;
    font-size: 1.2rem; }

.main-container form#catorder-form div.catalog-single .catalog-item-title {
  margin-top: 3rem; }

.main-container form#catorder-form div.catalog-single .catalog-item-pdf, .main-container form#catorder-form div.catalog-single .catalog-item-epaper-link, .main-container form#catorder-form div.catalog-single .catalog-item-order-link {
  margin: 1rem auto auto auto;
  display: block; }

.main-container form#catorder-form div.catalog-single .catalog-item-ribbons {
  top: 2rem; }
  .main-container form#catorder-form div.catalog-single .catalog-item-ribbons .catalog-item-ribbons-triangle {
    top: 4.5rem; }

.main-container form#catorder-form div#catorderDropBox {
  background-color: #e3e3e3;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem; }
  .main-container form#catorder-form div#catorderDropBox .buttonContainer {
    display: none; }
  .main-container form#catorder-form div#catorderDropBox .catalog-item {
    float: left;
    margin-right: 2rem; }
  .main-container form#catorder-form div#catorderDropBox .catalog-item-image {
    width: 100%;
    height: 100%;
    border: 1px solid #aeaeae; }
  .main-container form#catorder-form div#catorderDropBox .catalog-item:hover .removeLayer {
    display: block; }
  .main-container form#catorder-form div#catorderDropBox .catalog-item-outer {
    width: 8.1rem;
    height: 11.4rem; }
  .main-container form#catorder-form div#catorderDropBox .catalog-item-inner.slim {
    width: 5.5rem;
    height: 11.4rem; }
  .main-container form#catorder-form div#catorderDropBox .catalog-item-inner.square {
    margin-top: 1.7rem;
    width: 8.1rem;
    height: 8.1rem; }
  .main-container form#catorder-form div#catorderDropBox .catalog-item-inner.cross {
    margin-top: 2.8rem;
    height: 5.8rem;
    width: 8.2rem; }
  .main-container form#catorder-form div#catorderDropBox .catalog-item-outer:hover > .catalog-item-inner {
    transform: rotate3d(0, 1, 0, 0); }
  .main-container form#catorder-form div#catorderDropBox .catalogContainer {
    min-height: 15rem; }
  .main-container form#catorder-form div#catorderDropBox .removeLayer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 8.1rem;
    height: 11.4rem;
    text-align: center;
    vertical-align: middle;
    perspective: 180rem;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;
    display: none;
    cursor: pointer; }
    .main-container form#catorder-form div#catorderDropBox .removeLayer i.delete {
      font-size: 7.5rem;
      color: #575757;
      margin-top: 1.5rem; }
  .main-container form#catorder-form div#catorderDropBox .buttonContainer {
    position: relative;
    margin-top: 3rem; }
    .main-container form#catorder-form div#catorderDropBox .buttonContainer #removeAllCatalogues {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 2.4rem; }
    .main-container form#catorder-form div#catorderDropBox .buttonContainer #orderCatalogues {
      position: absolute;
      left: 0;
      bottom: 0; }
  .main-container form#catorder-form div#catorderDropBox .moreItemsLayer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 8.1rem;
    height: 11.4rem;
    text-align: center;
    vertical-align: middle;
    perspective: 180rem;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 3;
    display: block; }
    .main-container form#catorder-form div#catorderDropBox .moreItemsLayer i.catorder {
      font-size: 7.5rem;
      color: #575757; }
    .main-container form#catorder-form div#catorderDropBox .moreItemsLayer .catBasketCnt {
      color: #575757;
      font-weight: bold;
      font-size: 1.8rem; }

.main-container form#catorder-form div#catorderDropBox.sticky {
  position: fixed;
  top: 20rem;
  right: 0;
  width: 34rem;
  max-width: 75rem;
  height: auto;
  min-height: 15rem;
  overflow: hidden; }
  .main-container form#catorder-form div#catorderDropBox.sticky .buttonContainer {
    display: block;
    height: 2.5rem; }

.main-container form#catorder-form div#otherCompany {
  border-top: 1px solid #e3e3e3;
  margin-top: 2rem;
  padding: 3rem 1rem 0 1rem; }

.main-container form#catorder-form #catOrderModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999; }
  .main-container form#catorder-form #catOrderModal #modalForm {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    width: 89rem;
    max-width: 95%;
    max-height: 95%; }
    @media (max-width: 1199.98px) {
      .main-container form#catorder-form #catOrderModal #modalForm {
        overflow-y: scroll; } }
    .main-container form#catorder-form #catOrderModal #modalForm h4 {
      margin: 2rem;
      font-size: 2.8rem; }
    .main-container form#catorder-form #catOrderModal #modalForm i.cross {
      position: absolute;
      top: 1rem;
      right: 1rem;
      float: right;
      cursor: pointer;
      font-weight: bold;
      font-size: 2.8rem; }
  .main-container form#catorder-form #catOrderModal button[type=submit] {
    margin-bottom: 2rem; }

.main-container form#catorder-form #catorderDropBox-mobileButton {
  position: fixed;
  left: -1.6rem;
  bottom: 14rem;
  z-index: 1039;
  transform: translate(-30%, -50%) rotate(-90deg);
  background-color: #575757;
  color: #FFFFFF;
  transition: all .5s;
  display: none; }
  @media (max-width: 1199.98px) {
    .main-container form#catorder-form #catorderDropBox-mobileButton {
      display: block;
      transform: translate(-30%, -80%) rotate(-90deg); } }
  .main-container form#catorder-form #catorderDropBox-mobileButton h2 {
    margin: 0;
    background-color: #575757;
    color: #FFFFFF;
    font-size: 1.5rem;
    text-align: center;
    padding: .7rem .7rem;
    line-height: 1.3rem; }

.main-container form#catorder-form #catalog-language-select {
  margin-bottom: 3rem; }
  .main-container form#catorder-form #catalog-language-select label {
    margin: 0 1rem 0 .5rem;
    line-height: 1.5;
    font-size: 1.4rem;
    vertical-align: middle; }

.main-container form#catorder-form .catorder-info {
  margin-bottom: 2rem; }

.main-container form#catorder-form .catalog-group-separator {
  border-top: 1px solid #e3e3e3;
  margin-top: 1rem;
  padding-top: 3rem; }
  .main-container form#catorder-form .catalog-group-separator h2 {
    margin-top: -1rem; }

.main-container .dl-info {
  font-size: 2.8rem;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem; }

.main-container #countryselection-box .logo, .main-container #countryselection-box .description {
  margin-bottom: 2rem; }

.main-container #countryselection-box .worldcontain img {
  width: 100%;
  max-width: 100%;
  height: auto; }

.main-container #countryselection-box .top-elements {
  margin-bottom: 1rem; }

.main-container #countryselection-box .countryselection-flag {
  margin-right: 1rem; }

.main-container div.rss {
  width: 100%;
  text-align: right; }
  .main-container div.rss a {
    line-height: 2.5rem; }
  .main-container div.rss i {
    font-size: 2rem;
    display: inline;
    vertical-align: middle; }
  .main-container div.rss span {
    font-size: 1.2rem;
    vertical-align: middle; }

.main-container .breadcrumb {
  overflow-x: scroll;
  text-align: right;
  flex-wrap: nowrap !important; }
  .main-container .breadcrumb li {
    white-space: nowrap; }

.main-container .breadcrumb::-webkit-scrollbar {
  display: none; }

.main-container .breadcrumb {
  -ms-overflow-style: none;
  scrollbar-width: none; }

.main-container .mobile-margin-top-negative {
  margin-top: -4.5rem; }

.main-container .pageHead.margin-header {
  margin-top: 2rem; }

.main-container div#raroombuilder-app button:focus {
  outline: none !important; }

.main-container div#raroombuilder-app #raroombuilder-save-success, .main-container div#raroombuilder-app #raroombuilder-save-fail, .main-container div#raroombuilder-app #raroombuilder-load-fail {
  background-color: #FFFFFF;
  border: 1px solid #aeaeae;
  box-shadow: 0 0 1rem 0 #aeaeae;
  padding: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .main-container div#raroombuilder-app #raroombuilder-save-success .text, .main-container div#raroombuilder-app #raroombuilder-save-fail .text, .main-container div#raroombuilder-app #raroombuilder-load-fail .text {
    font-size: 1.6rem; }
  .main-container div#raroombuilder-app #raroombuilder-save-success svg, .main-container div#raroombuilder-app #raroombuilder-save-fail svg, .main-container div#raroombuilder-app #raroombuilder-load-fail svg {
    width: 100px;
    display: block;
    margin: 2rem auto; }
  .main-container div#raroombuilder-app #raroombuilder-save-success .path, .main-container div#raroombuilder-app #raroombuilder-save-fail .path, .main-container div#raroombuilder-app #raroombuilder-load-fail .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0; }
    .main-container div#raroombuilder-app #raroombuilder-save-success .path.circle, .main-container div#raroombuilder-app #raroombuilder-save-fail .path.circle, .main-container div#raroombuilder-app #raroombuilder-load-fail .path.circle {
      animation: dash .9s ease-in-out; }
    .main-container div#raroombuilder-app #raroombuilder-save-success .path.line, .main-container div#raroombuilder-app #raroombuilder-save-fail .path.line, .main-container div#raroombuilder-app #raroombuilder-load-fail .path.line {
      stroke-dashoffset: 1000;
      animation: dash .9s .35s ease-in-out forwards; }
    .main-container div#raroombuilder-app #raroombuilder-save-success .path.check, .main-container div#raroombuilder-app #raroombuilder-save-fail .path.check, .main-container div#raroombuilder-app #raroombuilder-load-fail .path.check {
      stroke-dashoffset: -100;
      animation: dash-check .9s .35s ease-in-out forwards; }

@keyframes dash {
  0% {
    stroke-dashoffset: 1000; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100; }
  100% {
    stroke-dashoffset: 900; } }

.main-container div#raroombuilder-app #raroombuilder-save-success svg {
  stroke: #308136; }

.main-container div#raroombuilder-app #raroombuilder-save-fail svg, .main-container div#raroombuilder-app #raroombuilder-load-fail svg {
  stroke: #dc371c; }

.main-container div#raroombuilder-app #raroombuilder-floor-helper, .main-container div#raroombuilder-app #raroombuilder-element-helper, .main-container div#raroombuilder-app #raroombuilder-menu-helper {
  z-index: 1001;
  position: absolute;
  border: 1px solid #aeaeae;
  box-shadow: 0 0 1rem 0 #aeaeae;
  background-color: #FFFFFF;
  padding: 1rem;
  font-size: 1.4rem; }
  .main-container div#raroombuilder-app #raroombuilder-floor-helper .headline, .main-container div#raroombuilder-app #raroombuilder-element-helper .headline, .main-container div#raroombuilder-app #raroombuilder-menu-helper .headline {
    color: #00569d;
    text-transform: uppercase;
    margin-bottom: .5rem;
    font-weight: bold; }
  .main-container div#raroombuilder-app #raroombuilder-floor-helper b, .main-container div#raroombuilder-app #raroombuilder-element-helper b, .main-container div#raroombuilder-app #raroombuilder-menu-helper b {
    display: block; }

.main-container div#raroombuilder-app #raroombuilder-floor-helper {
  right: -100%;
  width: 100%;
  bottom: 0; }

.main-container div#raroombuilder-app #raroombuilder-element-helper {
  right: -100%;
  width: 100%;
  top: 0; }

.main-container div#raroombuilder-app #raroombuilder-menu-helper {
  top: 100%; }

.main-container div#raroombuilder-app .disabled-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  z-index: 1000;
  background-color: rgba(246, 246, 246, 0.5);
  cursor: not-allowed; }
  .main-container div#raroombuilder-app .disabled-layer.blur {
    -webkit-backdrop-filter: blur(1.25px);
            backdrop-filter: blur(1.25px);
    background-color: rgba(246, 246, 246, 0.5); }

.main-container div#raroombuilder-app #raroombuilder-element-selection.disabled .disabled-layer {
  display: block; }

.main-container div#raroombuilder-app #raroombuilder-info {
  margin-bottom: 2rem; }

.main-container div#raroombuilder-app .raroombuilder-to-basket-top {
  margin-bottom: 1rem;
  font-weight: bold;
  height: 4rem;
  line-height: 4rem; }
  .main-container div#raroombuilder-app .raroombuilder-to-basket-top .total-price {
    text-align: right;
    position: relative;
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 991.98px) {
      .main-container div#raroombuilder-app .raroombuilder-to-basket-top .total-price {
        text-align: center; } }
  .main-container div#raroombuilder-app .raroombuilder-to-basket-top .subtotal-inner {
    display: flex;
    height: 4rem; }
  .main-container div#raroombuilder-app .raroombuilder-to-basket-top .subtotal-value {
    width: auto;
    display: flex;
    height: 4rem;
    line-height: 4rem;
    margin-right: 1rem;
    font-weight: 400; }
  .main-container div#raroombuilder-app .raroombuilder-to-basket-top .subtotal {
    width: auto;
    display: flex;
    margin-right: 1rem;
    font-weight: bold;
    height: 4rem;
    line-height: 4rem; }
  .main-container div#raroombuilder-app .raroombuilder-to-basket-top #total-price-spinner-top {
    margin: 0 !important;
    height: 4rem; }
  .main-container div#raroombuilder-app .raroombuilder-to-basket-top .btn-col {
    padding: 0; }
    .main-container div#raroombuilder-app .raroombuilder-to-basket-top .btn-col i {
      font-size: 2.4rem;
      vertical-align: sub;
      margin-right: 0.5rem; }
    .main-container div#raroombuilder-app .raroombuilder-to-basket-top .btn-col button:disabled {
      cursor: not-allowed; }

.main-container div#raroombuilder-app #raroombuilder-menu {
  background-color: #f6f6f6;
  padding: 2rem 1rem;
  margin-bottom: 2rem; }
  .main-container div#raroombuilder-app #raroombuilder-menu .menu-headline {
    margin-bottom: 1rem; }
  .main-container div#raroombuilder-app #raroombuilder-menu .raroombuilder-floor-info, .main-container div#raroombuilder-app #raroombuilder-menu .raroombuilder-element-info, .main-container div#raroombuilder-app #raroombuilder-menu .raroombuilder-area-info {
    display: block;
    font-size: 1.1rem;
    margin-bottom: 1rem; }
  .main-container div#raroombuilder-app #raroombuilder-menu #raroombuilder-area-selection > div {
    padding: 0; }
  .main-container div#raroombuilder-app #raroombuilder-menu #raroombuilder-area-selection input[type="range"] {
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    height: .5rem;
    background: #aeaeae;
    outline: none;
    margin-bottom: 3rem; }
    .main-container div#raroombuilder-app #raroombuilder-menu #raroombuilder-area-selection input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: .5rem;
      height: 2rem;
      background: #00569d;
      cursor: pointer; }
    .main-container div#raroombuilder-app #raroombuilder-menu #raroombuilder-area-selection input[type="range"]::-moz-range-thumb {
      width: .5rem;
      height: 2rem;
      background: #00569d;
      cursor: pointer; }
  .main-container div#raroombuilder-app #raroombuilder-menu #raroombuilder-floor-selection .raroombuilder-attribute {
    border: none; }
  .main-container div#raroombuilder-app #raroombuilder-menu #raroombuilder-floor-selection .floor-selection {
    text-align: center; }
    .main-container div#raroombuilder-app #raroombuilder-menu #raroombuilder-floor-selection .floor-selection label {
      width: 8rem;
      height: 8rem;
      position: relative;
      border: 1px solid #aeaeae;
      color: #308136;
      display: inline-block;
      cursor: pointer;
      overflow: hidden;
      padding: .5rem;
      background-color: #FFFFFF;
      margin: 0 auto; }
      .main-container div#raroombuilder-app #raroombuilder-menu #raroombuilder-floor-selection .floor-selection label .active {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 3rem;
        height: 3rem;
        text-align: center;
        display: none;
        font-size: 2rem;
        background-color: rgba(255, 255, 255, 0.5); }
      .main-container div#raroombuilder-app #raroombuilder-menu #raroombuilder-floor-selection .floor-selection label.active {
        border: 1px solid #308136; }
        .main-container div#raroombuilder-app #raroombuilder-menu #raroombuilder-floor-selection .floor-selection label.active .active {
          display: block; }
  .main-container div#raroombuilder-app #raroombuilder-menu .element-selection {
    padding: 1rem .5rem 2rem .5rem;
    text-align: center; }
    .main-container div#raroombuilder-app #raroombuilder-menu .element-selection.active {
      background-color: #FFFFFF;
      box-shadow: 0.5rem 0.5rem 0.5rem 0 #aeaeae; }
    .main-container div#raroombuilder-app #raroombuilder-menu .element-selection .selection-title {
      font-size: 1.1rem;
      margin-top: .5rem;
      line-height: 1.2;
      display: block; }
    .main-container div#raroombuilder-app #raroombuilder-menu .element-selection .selection-sub-title {
      font-size: 1.1rem;
      margin-top: .5rem;
      line-height: 1.2;
      display: block; }
    .main-container div#raroombuilder-app #raroombuilder-menu .element-selection .btn-roombuilder-selection-element {
      margin: 0 auto;
      width: 8rem;
      height: 8rem;
      border: 1px solid #aeaeae;
      background-color: #FFFFFF;
      display: block;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      color: #308136; }
      .main-container div#raroombuilder-app #raroombuilder-menu .element-selection .btn-roombuilder-selection-element .active {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 3rem;
        height: 3rem;
        text-align: center;
        display: none;
        font-size: 2rem;
        background-color: rgba(255, 255, 255, 0.5); }
      .main-container div#raroombuilder-app #raroombuilder-menu .element-selection .btn-roombuilder-selection-element:focus {
        outline: none;
        box-shadow: none; }
      .main-container div#raroombuilder-app #raroombuilder-menu .element-selection .btn-roombuilder-selection-element.active {
        border: 1px solid #308136; }
        .main-container div#raroombuilder-app #raroombuilder-menu .element-selection .btn-roombuilder-selection-element.active .active {
          display: block; }
  .main-container div#raroombuilder-app #raroombuilder-menu .element-attribute-selection {
    padding: 1rem;
    background-color: #FFFFFF;
    margin-bottom: 2rem;
    box-shadow: 0.5rem 0.5rem 0.5rem 0 #aeaeae; }
    .main-container div#raroombuilder-app #raroombuilder-menu .element-attribute-selection .headline {
      font-weight: bold;
      font-size: 1.4rem; }
      .main-container div#raroombuilder-app #raroombuilder-menu .element-attribute-selection .headline span {
        font-size: 1.1rem;
        margin-left: .5rem; }
  .main-container div#raroombuilder-app #raroombuilder-menu .raroombuilder-attribute {
    padding: 0; }
  .main-container div#raroombuilder-app #raroombuilder-menu .raroombuilder-accessoires {
    border-left: 1px solid #e3e3e3; }
  .main-container div#raroombuilder-app #raroombuilder-menu .no-accessoire {
    font-size: 1.1rem; }
  .main-container div#raroombuilder-app #raroombuilder-menu #remove-accessoires {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.5rem;
    width: 2rem;
    height: 2rem;
    text-align: center;
    line-height: 2rem;
    cursor: pointer; }
    .main-container div#raroombuilder-app #raroombuilder-menu #remove-accessoires:hover {
      color: #dc371c; }
  .main-container div#raroombuilder-app #raroombuilder-menu .accessoire-selection {
    padding: .5rem 0;
    margin-bottom: 0;
    border-bottom: 1px solid #e3e3e3;
    cursor: pointer; }
    .main-container div#raroombuilder-app #raroombuilder-menu .accessoire-selection:last-of-type {
      border: none; }
    .main-container div#raroombuilder-app #raroombuilder-menu .accessoire-selection .active {
      position: absolute;
      left: 1rem;
      right: 1rem;
      top: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.5);
      display: none; }
      .main-container div#raroombuilder-app #raroombuilder-menu .accessoire-selection .active i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 2rem;
        line-height: 1;
        color: #308136; }
    .main-container div#raroombuilder-app #raroombuilder-menu .accessoire-selection.disabled {
      cursor: not-allowed; }
      .main-container div#raroombuilder-app #raroombuilder-menu .accessoire-selection.disabled .disabled-layer {
        display: block; }
    .main-container div#raroombuilder-app #raroombuilder-menu .accessoire-selection.active img {
      border: 1px solid #308136; }
    .main-container div#raroombuilder-app #raroombuilder-menu .accessoire-selection.active .active {
      display: block; }
    .main-container div#raroombuilder-app #raroombuilder-menu .accessoire-selection img {
      border: 1px solid #aeaeae; }
    .main-container div#raroombuilder-app #raroombuilder-menu .accessoire-selection .info {
      padding: 0; }
      .main-container div#raroombuilder-app #raroombuilder-menu .accessoire-selection .info .title, .main-container div#raroombuilder-app #raroombuilder-menu .accessoire-selection .info .varsel {
        font-size: 1.1rem;
        line-height: 1.2; }
  .main-container div#raroombuilder-app #raroombuilder-menu .raroombuilder-spacer {
    width: 100%;
    border: 0.5px solid #aeaeae;
    margin: 2rem 1rem; }

.main-container div#raroombuilder-app #raroombuilder-container.mouse-pointer canvas {
  cursor: pointer; }

.main-container div#raroombuilder-app #raroombuilder-container canvas {
  border: 1px solid #aeaeae;
  outline: none;
  box-shadow: none; }

.main-container div#raroombuilder-app label.attribute-selection-height {
  cursor: pointer;
  width: 100%; }

.main-container div#raroombuilder-app label.attribute-selection-color {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  border: 1px solid #aeaeae;
  color: #308136;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 1.6rem;
  margin: 0 1rem 1rem 0;
  cursor: pointer; }
  .main-container div#raroombuilder-app label.attribute-selection-color span {
    display: none; }
  .main-container div#raroombuilder-app label.attribute-selection-color.active {
    border: 1px solid #308136; }
    .main-container div#raroombuilder-app label.attribute-selection-color.active span {
      display: block; }

.main-container div#raroombuilder-app label.attribute-selection-motif {
  width: 5.5rem;
  height: 5.5rem;
  line-height: 5.5rem;
  border: 1px solid #aeaeae;
  color: #308136;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 2rem;
  margin: 0 1rem 1rem 0;
  cursor: pointer;
  position: relative; }
  .main-container div#raroombuilder-app label.attribute-selection-motif img {
    display: block;
    z-index: 998; }
  .main-container div#raroombuilder-app label.attribute-selection-motif span {
    display: none;
    line-height: 5.5rem;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999; }
  .main-container div#raroombuilder-app label.attribute-selection-motif.active {
    border: 1px solid #308136; }
    .main-container div#raroombuilder-app label.attribute-selection-motif.active span {
      display: block; }

.main-container div#raroombuilder-app #raroombuilder-summary {
  margin-top: 3rem; }
  .main-container div#raroombuilder-app #raroombuilder-summary i {
    font-size: 2.4rem;
    vertical-align: sub;
    margin-right: 0.5rem; }
  .main-container div#raroombuilder-app #raroombuilder-summary ul {
    padding-left: 0; }
    .main-container div#raroombuilder-app #raroombuilder-summary ul li.headline {
      font-weight: bold; }
    .main-container div#raroombuilder-app #raroombuilder-summary ul li {
      margin-bottom: .5rem;
      border-bottom: 1px solid #aeaeae; }
      @media (max-width: 991.98px) {
        .main-container div#raroombuilder-app #raroombuilder-summary ul li {
          padding-bottom: .5rem; } }
      .main-container div#raroombuilder-app #raroombuilder-summary ul li .subtotal-value {
        width: auto;
        display: flex;
        height: 4rem;
        line-height: 4rem;
        margin-right: 1rem; }
      .main-container div#raroombuilder-app #raroombuilder-summary ul li .subtotal {
        width: auto;
        display: flex;
        margin-right: 1rem;
        font-weight: bold;
        height: 4rem;
        line-height: 4rem; }
      .main-container div#raroombuilder-app #raroombuilder-summary ul li span {
        width: 100%;
        display: block; }
      .main-container div#raroombuilder-app #raroombuilder-summary ul li .title {
        font-weight: bold; }
    .main-container div#raroombuilder-app #raroombuilder-summary ul li.button-row {
      border: none; }
      .main-container div#raroombuilder-app #raroombuilder-summary ul li.button-row .total-price {
        text-align: right;
        position: relative;
        display: flex;
        justify-content: flex-end; }
        @media (max-width: 991.98px) {
          .main-container div#raroombuilder-app #raroombuilder-summary ul li.button-row .total-price {
            text-align: center; } }

.main-container div#raroombuilder-app #raroombuilder-container-outer {
  position: relative;
  margin-bottom: 2rem; }
  .main-container div#raroombuilder-app #raroombuilder-container-outer #raroombuilder-container-inner {
    position: sticky;
    top: 1rem; }
  .main-container div#raroombuilder-app #raroombuilder-container-outer #raroombuilder-container-menu {
    position: absolute;
    top: 0;
    left: 1rem;
    right: 1rem;
    padding: 1rem; }
    .main-container div#raroombuilder-app #raroombuilder-container-outer #raroombuilder-container-menu button:disabled, .main-container div#raroombuilder-app #raroombuilder-container-outer #raroombuilder-container-menu a:disabled {
      cursor: not-allowed !important;
      opacity: .35;
      background-color: #FFFFFF !important;
      color: #aeaeae !important; }
    .main-container div#raroombuilder-app #raroombuilder-container-outer #raroombuilder-container-menu .menu-section {
      display: inline-block;
      margin-right: 4rem; }
      .main-container div#raroombuilder-app #raroombuilder-container-outer #raroombuilder-container-menu .menu-section.right {
        float: right;
        margin-right: 0; }
      .main-container div#raroombuilder-app #raroombuilder-container-outer #raroombuilder-container-menu .menu-section button {
        width: 4rem;
        line-height: 3.8rem;
        font-size: 2.5rem;
        vertical-align: middle;
        padding: 0 !important; }
        @media (max-width: 991.98px) {
          .main-container div#raroombuilder-app #raroombuilder-container-outer #raroombuilder-container-menu .menu-section button {
            margin-bottom: 1rem; } }
      .main-container div#raroombuilder-app #raroombuilder-container-outer #raroombuilder-container-menu .menu-section .raroombuilder-switch-mode.active:not(:disabled) {
        background-color: #308136;
        color: #FFFFFF !important; }
  .main-container div#raroombuilder-app #raroombuilder-container-outer #raroombuilder-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    color: #575757; }
    .main-container div#raroombuilder-app #raroombuilder-container-outer #raroombuilder-loading i {
      font-size: 8rem;
      animation: rotating 1s linear infinite; }
  .main-container div#raroombuilder-app #raroombuilder-container-outer #raroombuilder-saving {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    color: #575757; }
    .main-container div#raroombuilder-app #raroombuilder-container-outer #raroombuilder-saving i {
      font-size: 8rem;
      animation: flickerAnimation 3s infinite; }

@keyframes flickerAnimation {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1; } }

@keyframes rotating {
  from {
    transform: rotate(360deg); }
  to {
    transform: rotate(0deg); } }

.main-container #raroombuilder-alert {
  position: fixed;
  text-align: center;
  padding: 1rem;
  max-width: 40rem;
  transform: translateY(-50%);
  background-color: #fbe6e2;
  border: 1px solid #dc371c;
  color: #dc371c;
  z-index: 998;
  visibility: hidden; }
  @media (max-width: 767.98px) {
    .main-container #raroombuilder-alert {
      left: 1rem !important;
      right: 1rem !important;
      top: 50% !important; } }
  .main-container #raroombuilder-alert i {
    vertical-align: middle;
    font-size: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.main-container #total-price-spinner {
  margin: 0 !important;
  height: 4rem; }

.main-container #article-list-form-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 4rem; }
  .main-container #article-list-form-loader i {
    font-size: 8rem;
    animation: rotating 1s linear infinite; }

.main-container #article-list-form.loading {
  opacity: .75;
  background-color: #f6f6f6; }

.main-container #article-list-form .subtotal-inner {
  display: flex;
  height: 4rem; }

.main-container #article-list-form-btn:disabled {
  cursor: not-allowed; }

.main-container #raroombuilder-element-info-tooltip {
  position: fixed;
  background-color: transparent;
  display: block;
  z-index: 1111;
  visibility: hidden;
  max-width: 14.5rem; }
  .main-container #raroombuilder-element-info-tooltip .inner-tooltip {
    border: 1px solid #aeaeae;
    background-color: #FFFFFF;
    padding: 1rem 0; }
    .main-container #raroombuilder-element-info-tooltip .inner-tooltip .title {
      display: block;
      font-weight: bold;
      font-size: 1.1rem; }
    .main-container #raroombuilder-element-info-tooltip .inner-tooltip .varsel {
      display: block;
      font-size: 1.1rem; }
    .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoires {
      padding-top: .5rem;
      margin-top: .5rem;
      font-size: 1.1rem;
      border-top: 1px solid #e3e3e3; }
      .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoires .accessoire {
        padding-top: .5rem;
        margin-top: .5rem; }
    .main-container #raroombuilder-element-info-tooltip .inner-tooltip .selection label, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection label {
      display: block;
      width: 100%;
      font-size: 1.1rem;
      cursor: pointer; }
      .main-container #raroombuilder-element-info-tooltip .inner-tooltip .selection label input, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label input, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection label input {
        margin-right: .5rem; }
      .main-container #raroombuilder-element-info-tooltip .inner-tooltip .selection label.color, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .selection label.motif, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label.color, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label.motif, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection label.color, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection label.motif {
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        display: inline-block;
        margin: .5rem;
        border: 1px solid #aeaeae;
        text-align: center;
        font-size: 1.6rem;
        position: relative; }
        .main-container #raroombuilder-element-info-tooltip .inner-tooltip .selection label.color img, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .selection label.motif img, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label.color img, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label.motif img, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection label.color img, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection label.motif img {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: block;
          z-index: 990; }
        .main-container #raroombuilder-element-info-tooltip .inner-tooltip .selection label.color span, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .selection label.motif span, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label.color span, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label.motif span, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection label.color span, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection label.motif span {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: block;
          visibility: hidden;
          line-height: 3rem;
          z-index: 999;
          background-color: rgba(255, 255, 255, 0.5); }
        .main-container #raroombuilder-element-info-tooltip .inner-tooltip .selection label.color input, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .selection label.motif input, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label.color input, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label.motif input, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection label.color input, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection label.motif input {
          display: none; }
        .main-container #raroombuilder-element-info-tooltip .inner-tooltip .selection label.color.active, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .selection label.motif.active, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label.color.active, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label.motif.active, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection label.color.active, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection label.motif.active {
          border: 1px solid #308136;
          color: #308136; }
          .main-container #raroombuilder-element-info-tooltip .inner-tooltip .selection label.color.active span, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .selection label.motif.active span, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label.color.active span, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label.motif.active span, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection label.color.active span, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection label.motif.active span {
            visibility: visible; }
    .main-container #raroombuilder-element-info-tooltip .inner-tooltip .floor-selection {
      border-bottom: 1px solid #e3e3e3;
      margin-bottom: 1rem; }
    .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection {
      border-top: 1px solid #e3e3e3;
      padding-top: 1rem; }
      .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label {
        margin-top: .5rem; }
        .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label .disabled {
          background-color: rgba(246, 246, 246, 0.5);
          cursor: not-allowed;
          opacity: .5; }
        .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection label div {
          padding: 0;
          line-height: 1.4rem; }
      .main-container #raroombuilder-element-info-tooltip .inner-tooltip .accessoire-selection .no-accessoires {
        display: block;
        margin-top: .5rem;
        font-size: 1.1rem;
        line-height: 1.4rem; }
    .main-container #raroombuilder-element-info-tooltip .inner-tooltip .btn-col {
      margin-top: 2rem;
      display: flex;
      justify-self: center;
      justify-content: center; }
      .main-container #raroombuilder-element-info-tooltip .inner-tooltip .btn-col button, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .btn-col a {
        border: none;
        background-color: transparent;
        color: #575757;
        line-height: 2rem;
        font-size: 2rem;
        cursor: pointer;
        height: 2rem; }
        .main-container #raroombuilder-element-info-tooltip .inner-tooltip .btn-col button:disabled, .main-container #raroombuilder-element-info-tooltip .inner-tooltip .btn-col a:disabled {
          border: none;
          background-color: transparent;
          color: #e3e3e3;
          cursor: not-allowed; }
      .main-container #raroombuilder-element-info-tooltip .inner-tooltip .btn-col .spacer {
        border: 1px solid #e3e3e3;
        width: 0;
        height: 2rem;
        margin: 0 1rem; }
  .main-container #raroombuilder-element-info-tooltip.bottom {
    transform: translate(-50%, 2rem); }
    .main-container #raroombuilder-element-info-tooltip.bottom .inner-tooltip:before {
      border-left: solid transparent 1rem;
      border-right: solid transparent 1rem;
      border-bottom: solid #575757 1rem;
      content: "";
      height: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 0;
      top: -10px; }
  .main-container #raroombuilder-element-info-tooltip.top {
    transform: translate(-50%, -110%); }
    .main-container #raroombuilder-element-info-tooltip.top .inner-tooltip:after {
      border-left: solid transparent 1rem;
      border-right: solid transparent 1rem;
      border-top: solid #575757 1rem;
      content: "";
      height: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 0;
      bottom: -10px; }

.main-container #raroombuilder-deletion-tooltip {
  border: 1px solid #aeaeae;
  background-color: #FFFFFF;
  position: fixed;
  z-index: 1001;
  padding: .5rem;
  transform: translate(2rem, -50%); }
  .main-container #raroombuilder-deletion-tooltip i {
    line-height: 2.2rem;
    font-size: 2rem;
    vertical-align: middle; }
  .main-container #raroombuilder-deletion-tooltip div {
    font-size: 1.1rem;
    line-height: 2.2rem; }

.main-container #raconfigurator-outer {
  padding-bottom: 20px; }
  .main-container #raconfigurator-outer #raconfigurator-main-values-alert {
    border: 1px solid #dc371c;
    background-color: #fbe6e2;
    color: #dc371c;
    padding: 1rem;
    margin: 2rem 0; }
    .main-container #raconfigurator-outer #raconfigurator-main-values-alert i {
      font-size: 2.4rem;
      margin-right: 1rem;
      vertical-align: middle;
      line-height: 1; }
  .main-container #raconfigurator-outer .raconfigurator-main-values {
    margin-bottom: 2rem; }
    .main-container #raconfigurator-outer .raconfigurator-main-values .input-group-text {
      font-size: 1.4rem; }
  .main-container #raconfigurator-outer .raconfigurator-main-values-container {
    display: flex;
    width: 100%; }
    .main-container #raconfigurator-outer .raconfigurator-main-values-container .raconfigurator-main-value-desc {
      font-size: 1.2rem; }
    .main-container #raconfigurator-outer .raconfigurator-main-values-container .raconfigurator-main-values {
      margin-bottom: 2rem;
      width: 100%; }
      .main-container #raconfigurator-outer .raconfigurator-main-values-container .raconfigurator-main-values .input-group-text {
        font-size: 1.4rem; }
      .main-container #raconfigurator-outer .raconfigurator-main-values-container .raconfigurator-main-values label {
        font-weight: 400; }
  .main-container #raconfigurator-outer #raconfigurator-form {
    margin: 2rem 0; }
  @media (max-width: 991.98px) {
    .main-container #raconfigurator-outer .section-desc {
      margin-top: .5rem;
      padding-top: .5rem;
      border-top: 1px solid #aeaeae; } }
  .main-container #raconfigurator-outer .form-check {
    padding: 0;
    text-align: center; }
  .main-container #raconfigurator-outer .custom-text {
    display: none; }
  .main-container #raconfigurator-outer .custom-text-label {
    display: none;
    margin-top: 1rem; }
  .main-container #raconfigurator-outer .section-add-charge {
    font-weight: bold;
    display: inline-block;
    color: #00569d; }
  .main-container #raconfigurator-outer #raconfigurator-main-warning {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #e3e3e3; }
    .main-container #raconfigurator-outer #raconfigurator-main-warning .inner {
      margin-bottom: 1rem; }
  .main-container #raconfigurator-outer #raconfigurator-main-panel {
    display: none; }
    .main-container #raconfigurator-outer #raconfigurator-main-panel.show-full-selection {
      display: block; }
  .main-container #raconfigurator-outer #raconfigurator-summary {
    display: none;
    padding-right: 1.5rem;
    position: sticky;
    top: 14rem; }
    .main-container #raconfigurator-outer #raconfigurator-summary h1 {
      color: #00569d; }
    @media (max-width: 991.98px) {
      .main-container #raconfigurator-outer #raconfigurator-summary {
        margin-top: 3rem; } }
  .main-container #raconfigurator-outer .raconfigurator-section-header {
    border: 1px solid #aeaeae;
    padding: 1.5rem 1rem;
    margin: 1rem 0;
    background-color: #f6f6f6;
    cursor: pointer;
    color: #00569d; }
  .main-container #raconfigurator-outer .raconfigurator-inner {
    display: none; }
    .main-container #raconfigurator-outer .raconfigurator-inner.show-full-selection {
      display: block; }
  .main-container #raconfigurator-outer .raconfigurator-answer, .main-container #raconfigurator-outer .raconfigurator-answer-img, .main-container #raconfigurator-outer .raconfigurator-answer-color {
    cursor: pointer;
    margin: 0 auto .5rem auto; }
  .main-container #raconfigurator-outer img.raconfigurator-answer-img {
    pointer-events: none; }
  .main-container #raconfigurator-outer .raconfigurator-additional-information-top .row div {
    padding: 0; }
  .main-container #raconfigurator-outer .raconfigurator-additional-information-bottom {
    padding-right: 3rem; }
  .main-container #raconfigurator-outer #raconfigurator-main-panel h1, .main-container #raconfigurator-outer #raconfigurator-main-panel h2 {
    margin: 0; }
  .main-container #raconfigurator-outer .raconfigurator-total-price {
    font-size: 2.5rem;
    margin: 1rem 0 !important; }
  .main-container #raconfigurator-outer .raconfigurator-price-breaks-table {
    width: 70%;
    margin: 0 auto;
    margin-top: 1rem; }
    .main-container #raconfigurator-outer .raconfigurator-price-breaks-table td, .main-container #raconfigurator-outer .raconfigurator-price-breaks-table th {
      text-align: center; }
    @media (max-width: 991.98px) {
      .main-container #raconfigurator-outer .raconfigurator-price-breaks-table {
        margin-bottom: 2rem; } }
  .main-container #raconfigurator-outer .raconfigurator-page-header {
    text-transform: uppercase;
    font-size: 1.8rem;
    padding: 1.5rem;
    font-weight: bold;
    background-color: #f6f6f6;
    border-bottom: 1px solid #aeaeae;
    margin: 0 0 2rem 0; }
  .main-container #raconfigurator-outer #raconfigurator-start-btn {
    margin-top: 4rem; }

.main-container #raconfigurator-outer.details-page-style {
  padding-bottom: 1rem; }
  .main-container #raconfigurator-outer.details-page-style #raconfigurator-summary {
    position: relative;
    top: unset; }
    .main-container #raconfigurator-outer.details-page-style #raconfigurator-summary table {
      text-align: left; }
  .main-container #raconfigurator-outer.details-page-style #raconfigurator-form {
    margin: 0; }
  .main-container #raconfigurator-outer.details-page-style .raconfigurator-main-description {
    margin: 1rem 0; }
  .main-container #raconfigurator-outer.details-page-style #raconfigurator-main-panel {
    margin-top: 0;
    padding-top: 0;
    border-top: none; }
    .main-container #raconfigurator-outer.details-page-style #raconfigurator-main-panel .raconfigurator-section-header {
      border: none;
      border-top: 1px solid #e3e3e3;
      color: #575757;
      padding: 2rem 0 1rem 0; }
      .main-container #raconfigurator-outer.details-page-style #raconfigurator-main-panel .raconfigurator-section-header:first-of-type {
        border-top: none; }
      .main-container #raconfigurator-outer.details-page-style #raconfigurator-main-panel .raconfigurator-section-header.no-toggle {
        cursor: default; }
  .main-container #raconfigurator-outer.details-page-style .raconfigurator-price-breaks-table {
    width: 100%;
    display: block; }

.main-container #raconfigurator-outer #raconfigurator-main-panel {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e3e3e3; }
  .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-segment-option {
    text-align: left;
    line-height: 1.4rem;
    margin-bottom: 1rem; }
    .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-segment-option span {
      font-weight: 400; }
    .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-segment-option.thumb, .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-segment-option.color {
      padding-left: 0; }
      .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-segment-option.thumb span, .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-segment-option.color span {
        font-size: 1.1rem; }
      .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-segment-option.thumb input.raconfigurator-answer, .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-segment-option.color input.raconfigurator-answer {
        display: none; }
      .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-segment-option.thumb .section-add-charge, .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-segment-option.color .section-add-charge {
        display: block; }
  .main-container #raconfigurator-outer #raconfigurator-main-panel .section-desc {
    margin-bottom: 1rem;
    padding: 0;
    font-size: 1.2rem; }
  .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-color-section .parent-box, .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-thumb-section .parent-box, .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-choice-section .parent-box, .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-choice-multiple-section .parent-box, .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-select-section .parent-box {
    padding: 0; }
  .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-select-section .select-col {
    padding: 0;
    margin-bottom: 2rem; }
  .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-select-section .section-add-charge {
    height: 3.4rem;
    line-height: 3.4rem;
    display: block; }
  .main-container #raconfigurator-outer #raconfigurator-main-panel .raconfigurator-select-section .custom-text {
    margin: 2rem 0 0 0; }

.main-container #raconfigurator-outer .raconfigurator-segment-option {
  display: block;
  width: 100%; }
  .main-container #raconfigurator-outer .raconfigurator-segment-option input {
    margin-right: 1rem; }
  .main-container #raconfigurator-outer .raconfigurator-segment-option label {
    padding: 0; }
  .main-container #raconfigurator-outer .raconfigurator-segment-option.thumb {
    text-align: center;
    cursor: pointer; }
    .main-container #raconfigurator-outer .raconfigurator-segment-option.thumb img {
      min-width: 100%;
      aspect-ratio: 1;
      border: 1px solid #aeaeae; }
    .main-container #raconfigurator-outer .raconfigurator-segment-option.thumb input.raconfigurator-answer {
      margin: 0 auto 2rem auto;
      transform: translateX(-50%); }
  .main-container #raconfigurator-outer .raconfigurator-segment-option.active img, .main-container #raconfigurator-outer .raconfigurator-segment-option.active .raconfigurator-answer-color {
    border: 1px solid #308136; }
  .main-container #raconfigurator-outer .raconfigurator-segment-option.active .raconfigurator-check-icon {
    display: block; }
  .main-container #raconfigurator-outer .raconfigurator-segment-option .section-add-charge {
    font-size: 1.2rem; }
  .main-container #raconfigurator-outer .raconfigurator-segment-option .raconfigurator-answer-color {
    width: 100%;
    height: 4rem;
    border: 1px solid #aeaeae; }
    .main-container #raconfigurator-outer .raconfigurator-segment-option .raconfigurator-answer-color.img-as-color {
      background-position: center;
      overflow: hidden; }
    .main-container #raconfigurator-outer .raconfigurator-segment-option .raconfigurator-answer-color._silver {
      background: #f5f6f6;
      /* Old browsers */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #f5f6f6 0%, #dbdce2 21%, #b8bac6 49%, #dddfe3 80%, #f5f6f6 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$filter-color-variant-silver-part1', endColorstr='$filter-color-variant-silver-part5',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
    .main-container #raconfigurator-outer .raconfigurator-segment-option .raconfigurator-answer-color._gold {
      background: #fceabb;
      /* Old browsers */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #fceabb 0%, #fccd4d 50%, #f8b500 51%, #fbdf93 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$filter-color-variant-gold-part1', endColorstr='$filter-color-variant-gold-part4',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
    .main-container #raconfigurator-outer .raconfigurator-segment-option .raconfigurator-answer-color._transparent {
      background: #E1FFFF;
      /* Old browsers */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg, #e1ffff 0%, #e1ffff 7%, #e1ffff 12%, #fdffff 12%, #e6f8fd 30%, #c8eefb 54%, #bee4f8 75%, #b1d8f5 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1ffff', endColorstr='#b1d8f5',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }

.main-container #raconfigurator-outer .raconfigurator-check-icon {
  position: absolute;
  margin-top: -3rem;
  right: 1.5rem;
  z-index: 500;
  color: #FFFFFF;
  background-color: #308136;
  display: none;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: .5rem;
  text-align: center; }
  .main-container #raconfigurator-outer .raconfigurator-check-icon i {
    font-size: 1.5rem !important;
    vertical-align: middle !important;
    margin-top: -.3rem; }

.main-container .raconfigurator-is-disabled-box {
  color: #e3e3e3;
  cursor: not-allowed; }
  .main-container .raconfigurator-is-disabled-box .raconfigurator-answer-addtext small {
    color: #575757; }
  .main-container .raconfigurator-is-disabled-box input, .main-container .raconfigurator-is-disabled-box span {
    cursor: not-allowed !important; }
  .main-container .raconfigurator-is-disabled-box img {
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: alpha(opacity=40);
    opacity: 0.4;
    cursor: not-allowed; }

.main-container #raconfigurator-disabled-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-backdrop-filter: blur(1.25px);
          backdrop-filter: blur(1.25px);
  background-color: rgba(246, 246, 246, 0.5);
  cursor: not-allowed;
  z-index: 997; }
  .main-container #raconfigurator-disabled-layer .raconfigurator-disabled-layer-inner {
    background-color: #FFFFFF;
    border: 1px solid #aeaeae;
    margin: 2rem;
    padding: 2rem;
    z-index: 998;
    cursor: unset; }

.main-container .raconfigurator-image-layer {
  position: absolute; }

.main-container #raconfigurator-canvas {
  display: none; }

.main-container #raconfigurator-loading-layer {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center; }

.main-container .configurator-konva-display-row {
  visibility: hidden;
  height: 0;
  overflow: hidden; }
  .main-container .configurator-konva-display-row #configurator-display {
    aspect-ratio: 1; }
    .main-container .configurator-konva-display-row #configurator-display #configurator-display-canvas {
      display: block;
      height: 100%;
      border: 1px solid #aeaeae; }

.main-container #bfc-app h1 {
  margin: 2rem 0; }

.main-container #bfc-app #bfc-print-display {
  display: none; }

.main-container #bfc-app #bfc-display {
  width: 100%;
  min-height: 50rem;
  padding: 0;
  position: relative; }
  .main-container #bfc-app #bfc-display #bfc-display-canvas {
    display: block;
    height: 100%;
    border: 1px solid #aeaeae; }
  .main-container #bfc-app #bfc-display #bfc-mini-menu {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.9);
    padding-top: 1rem; }
    .main-container #bfc-app #bfc-display #bfc-mini-menu #bfc-toggle-menu i.rotated {
      transform: rotate(180deg); }
    .main-container #bfc-app #bfc-display #bfc-mini-menu label, .main-container #bfc-app #bfc-display #bfc-mini-menu button {
      margin-right: 1rem;
      height: 4rem;
      line-height: 4rem;
      border: 1px solid #aeaeae;
      color: #575757;
      cursor: pointer;
      padding: 0 1rem;
      font-weight: bold;
      background-color: transparent;
      outline: none; }
      .main-container #bfc-app #bfc-display #bfc-mini-menu label:focus, .main-container #bfc-app #bfc-display #bfc-mini-menu button:focus {
        outline: none; }
      .main-container #bfc-app #bfc-display #bfc-mini-menu label i, .main-container #bfc-app #bfc-display #bfc-mini-menu button i {
        font-size: 2rem;
        vertical-align: sub; }
      .main-container #bfc-app #bfc-display #bfc-mini-menu label input, .main-container #bfc-app #bfc-display #bfc-mini-menu button input {
        display: none; }
      .main-container #bfc-app #bfc-display #bfc-mini-menu label.active, .main-container #bfc-app #bfc-display #bfc-mini-menu button.active {
        color: #FFFFFF;
        background-color: #308136; }
    .main-container #bfc-app #bfc-display #bfc-mini-menu #bfc-info {
      font-size: 2rem;
      display: inline-block;
      height: 4rem;
      line-height: 4rem; }
      .main-container #bfc-app #bfc-display #bfc-mini-menu #bfc-info .ra-bfc-tooltip {
        position: absolute;
        max-width: 30rem;
        min-width: 30rem;
        height: auto;
        z-index: 1000;
        transform: translateX(-100%);
        font-weight: 400;
        margin: 3rem 0 0 2.5rem;
        line-height: 1.5; }
        .main-container #bfc-app #bfc-display #bfc-mini-menu #bfc-info .ra-bfc-tooltip .tooltip-arrow {
          border-left: .5rem solid transparent;
          border-right: .5rem solid transparent;
          border-bottom: 0.5rem solid #575757;
          margin-right: 1rem;
          margin-left: auto; }
        .main-container #bfc-app #bfc-display #bfc-mini-menu #bfc-info .ra-bfc-tooltip .tooltip-inner, .main-container #bfc-app #bfc-display #bfc-mini-menu #bfc-info .ra-bfc-tooltip .tooltip-arrow {
          display: none;
          font-size: 1.2rem; }
          .main-container #bfc-app #bfc-display #bfc-mini-menu #bfc-info .ra-bfc-tooltip .tooltip-inner .tooltip-moreinfo, .main-container #bfc-app #bfc-display #bfc-mini-menu #bfc-info .ra-bfc-tooltip .tooltip-arrow .tooltip-moreinfo {
            display: block;
            width: 100%;
            margin-top: 1rem; }
        .main-container #bfc-app #bfc-display #bfc-mini-menu #bfc-info .ra-bfc-tooltip:hover .tooltip-inner, .main-container #bfc-app #bfc-display #bfc-mini-menu #bfc-info .ra-bfc-tooltip:hover .tooltip-arrow {
          display: block; }
      .main-container #bfc-app #bfc-display #bfc-mini-menu #bfc-info:hover .ra-bfc-tooltip .tooltip-inner, .main-container #bfc-app #bfc-display #bfc-mini-menu #bfc-info:hover .ra-bfc-tooltip .tooltip-arrow, .main-container #bfc-app #bfc-display #bfc-mini-menu #bfc-info i:hover .ra-bfc-tooltip .tooltip-inner, .main-container #bfc-app #bfc-display #bfc-mini-menu #bfc-info i:hover .ra-bfc-tooltip .tooltip-arrow {
        display: block; }
  .main-container #bfc-app #bfc-display #bfc-total-weight {
    position: absolute;
    bottom: 1px;
    right: 1px;
    padding: 1rem;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.9); }

.main-container #bfc-app #bfc-sidebar-menu {
  background-color: #f6f6f6;
  padding: 1rem 1rem 10rem 1rem;
  position: relative;
  min-height: 70rem; }
  .main-container #bfc-app #bfc-sidebar-menu .min-max-info {
    font-style: normal;
    color: #dc371c;
    display: none; }
  .main-container #bfc-app #bfc-sidebar-menu .min-max-error {
    border: 1px solid #dc371c;
    background-color: #fbe6e2; }
    .main-container #bfc-app #bfc-sidebar-menu .min-max-error input {
      border: 1px solid #dc371c;
      color: #dc371c; }
    .main-container #bfc-app #bfc-sidebar-menu .min-max-error .min-max-info {
      display: block; }
  .main-container #bfc-app #bfc-sidebar-menu h4 {
    margin-bottom: 1rem; }
  .main-container #bfc-app #bfc-sidebar-menu .bfc-measurement-types {
    margin-bottom: 1rem; }
  .main-container #bfc-app #bfc-sidebar-menu .measurement-type {
    padding-top: 1rem; }
    .main-container #bfc-app #bfc-sidebar-menu .measurement-type label {
      font-weight: bold;
      margin: 0 1rem 1rem 0; }
  .main-container #bfc-app #bfc-sidebar-menu .bfc-type-frame .row, .main-container #bfc-app #bfc-sidebar-menu .bfc-type-banner .row {
    padding: .5rem 0; }
  .main-container #bfc-app #bfc-sidebar-menu .bfc-type-frame .bfc-measurement-label, .main-container #bfc-app #bfc-sidebar-menu .bfc-type-banner .bfc-measurement-label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    margin: 0; }
    .main-container #bfc-app #bfc-sidebar-menu .bfc-type-frame .bfc-measurement-label small, .main-container #bfc-app #bfc-sidebar-menu .bfc-type-banner .bfc-measurement-label small {
      font-style: italic; }
  .main-container #bfc-app #bfc-sidebar-menu #bfc-banner-frame-size-info, .main-container #bfc-app #bfc-sidebar-menu #bfc-frame-banner-size-info {
    font-size: 1.2rem; }
  .main-container #bfc-app #bfc-sidebar-menu .selection {
    border-top: 1px solid #aeaeae;
    padding: 2rem 0; }
    .main-container #bfc-app #bfc-sidebar-menu .selection .selection-info-text, .main-container #bfc-app #bfc-sidebar-menu .selection .mount-accessories-info-text {
      font-size: 1.1rem;
      margin-top: -1rem;
      margin-bottom: 1rem; }
    .main-container #bfc-app #bfc-sidebar-menu .selection label {
      cursor: pointer;
      padding: 0; }
      .main-container #bfc-app #bfc-sidebar-menu .selection label .img-col {
        position: relative; }
      .main-container #bfc-app #bfc-sidebar-menu .selection label .selection-identifier {
        position: absolute;
        right: 1.5rem;
        bottom: .5rem;
        background-color: #308136;
        color: #FFFFFF;
        width: 2rem;
        height: 2rem;
        text-align: center;
        vertical-align: middle;
        line-height: 2rem;
        border-radius: .5rem;
        display: none; }
      .main-container #bfc-app #bfc-sidebar-menu .selection label.selected .selection-identifier {
        display: block; }
    .main-container #bfc-app #bfc-sidebar-menu .selection img {
      border: 1px solid #aeaeae;
      margin: 0 auto; }
    .main-container #bfc-app #bfc-sidebar-menu .selection .selection-title {
      display: block;
      padding: 0;
      font-weight: bold;
      font-size: 1rem;
      margin: .5rem 0; }
    .main-container #bfc-app #bfc-sidebar-menu .selection .selection-varsel {
      display: block;
      padding: 0;
      font-size: 1rem; }
      .main-container #bfc-app #bfc-sidebar-menu .selection .selection-varsel.no-title {
        font-weight: bold; }
    .main-container #bfc-app #bfc-sidebar-menu .selection .selection-amount {
      padding: 0; }
      .main-container #bfc-app #bfc-sidebar-menu .selection .selection-amount input {
        height: 4rem; }
      .main-container #bfc-app #bfc-sidebar-menu .selection .selection-amount b {
        vertical-align: middle;
        display: block; }
        .main-container #bfc-app #bfc-sidebar-menu .selection .selection-amount b.no-info {
          line-height: 4rem; }
      .main-container #bfc-app #bfc-sidebar-menu .selection .selection-amount span.selection-amount-info {
        font-size: 1rem;
        line-height: 1.1rem;
        display: block; }
    .main-container #bfc-app #bfc-sidebar-menu .selection .mount-selection-accessories-row {
      padding: .5rem 0; }
      .main-container #bfc-app #bfc-sidebar-menu .selection .mount-selection-accessories-row label {
        line-height: 3.4rem;
        font-weight: 700;
        margin: 0; }
  .main-container #bfc-app #bfc-sidebar-menu .bfc-info {
    font-style: italic;
    font-size: 1.2rem; }
    .main-container #bfc-app #bfc-sidebar-menu .bfc-info .banner-width-info, .main-container #bfc-app #bfc-sidebar-menu .bfc-info .banner-height-info, .main-container #bfc-app #bfc-sidebar-menu .bfc-info .frame-width-info, .main-container #bfc-app #bfc-sidebar-menu .bfc-info .frame-height-info {
      display: block; }
  .main-container #bfc-app #bfc-sidebar-menu .menu-to-basket {
    position: absolute;
    bottom: 2rem;
    left: 1rem;
    right: 1rem;
    padding-top: 1rem; }
    .main-container #bfc-app #bfc-sidebar-menu .menu-to-basket label {
      font-weight: bold;
      vertical-align: middle;
      line-height: 4rem;
      height: 4rem;
      margin: 0; }
    .main-container #bfc-app #bfc-sidebar-menu .menu-to-basket input, .main-container #bfc-app #bfc-sidebar-menu .menu-to-basket button {
      height: 4rem; }
      .main-container #bfc-app #bfc-sidebar-menu .menu-to-basket input i, .main-container #bfc-app #bfc-sidebar-menu .menu-to-basket button i {
        font-size: 2rem;
        vertical-align: middle; }
    .main-container #bfc-app #bfc-sidebar-menu .menu-to-basket #bfc-menu-to-basket:disabled {
      cursor: not-allowed;
      background-color: #575757;
      color: #FFFFFF;
      border: 1px solid #575757; }
    .main-container #bfc-app #bfc-sidebar-menu .menu-to-basket b, .main-container #bfc-app #bfc-sidebar-menu .menu-to-basket .total-sum {
      line-height: 4rem;
      vertical-align: middle;
      text-align: right;
      display: inline-block;
      height: 4rem; }
    .main-container #bfc-app #bfc-sidebar-menu .menu-to-basket .spinner {
      display: inline-block;
      height: 4rem;
      margin: 0; }
      .main-container #bfc-app #bfc-sidebar-menu .menu-to-basket .spinner div {
        margin-right: .1rem; }

.main-container #bfc-app #bfc-article-list ul {
  padding: 0; }
  .main-container #bfc-app #bfc-article-list ul .header-row {
    border-bottom: 1px solid #aeaeae;
    padding: .5rem 0;
    font-weight: bold; }
  .main-container #bfc-app #bfc-article-list ul .article-row {
    border-bottom: 1px solid #aeaeae;
    padding: .5rem 0; }
    .main-container #bfc-app #bfc-article-list ul .article-row img {
      border: 1px solid #aeaeae; }
    .main-container #bfc-app #bfc-article-list ul .article-row .bfc-identifier {
      position: relative; }
      .main-container #bfc-app #bfc-article-list ul .article-row .bfc-identifier span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        color: #dc371c; }
    .main-container #bfc-app #bfc-article-list ul .article-row .article-info {
      font-size: 1.1rem; }
  .main-container #bfc-app #bfc-article-list ul .button-row {
    padding: .5rem 0; }
    .main-container #bfc-app #bfc-article-list ul .button-row label {
      font-weight: bold;
      vertical-align: middle;
      line-height: 4rem;
      height: 4rem;
      margin: 0; }
    .main-container #bfc-app #bfc-article-list ul .button-row b, .main-container #bfc-app #bfc-article-list ul .button-row .total-sum {
      line-height: 4rem;
      vertical-align: middle;
      text-align: right;
      display: inline-block;
      height: 4rem; }
    .main-container #bfc-app #bfc-article-list ul .button-row i {
      font-size: 2rem;
      vertical-align: middle; }
    .main-container #bfc-app #bfc-article-list ul .button-row #bfc-amount {
      height: 4rem; }
    .main-container #bfc-app #bfc-article-list ul .button-row button:disabled:not(.btn-print-bfc) {
      cursor: not-allowed;
      background-color: #575757;
      color: #FFFFFF;
      border: 1px solid #575757; }
    .main-container #bfc-app #bfc-article-list ul .button-row .spinner {
      display: inline-block;
      height: 4rem;
      margin: 0; }
      .main-container #bfc-app #bfc-article-list ul .button-row .spinner div {
        margin-right: .1rem; }

.main-container #bfc-app .bfc-type-selection {
  position: relative; }

.main-container #bfc-app #bfc-measurement-info {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  vertical-align: middle;
  text-align: center; }
  .main-container #bfc-app #bfc-measurement-info:hover .ra-bfc-tooltip .tooltip-inner, .main-container #bfc-app #bfc-measurement-info:hover .ra-bfc-tooltip .tooltip-arrow, .main-container #bfc-app #bfc-measurement-info i:hover .ra-bfc-tooltip .tooltip-inner, .main-container #bfc-app #bfc-measurement-info i:hover .ra-bfc-tooltip .tooltip-arrow {
    display: block; }

.main-container #bfc-app #bfc-type-info {
  position: absolute;
  right: 0;
  top: 1rem;
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  vertical-align: middle;
  text-align: center; }
  .main-container #bfc-app #bfc-type-info:hover .ra-bfc-tooltip .tooltip-inner, .main-container #bfc-app #bfc-type-info:hover .ra-bfc-tooltip .tooltip-arrow, .main-container #bfc-app #bfc-type-info i:hover .ra-bfc-tooltip .tooltip-inner, .main-container #bfc-app #bfc-type-info i:hover .ra-bfc-tooltip .tooltip-arrow {
    display: block; }

.main-container #bfc-app .ra-bfc-tooltip-trigger:hover .ra-bfc-tooltip .tooltip-inner, .main-container #bfc-app .ra-bfc-tooltip-trigger:hover .ra-bfc-tooltip .tooltip-arrow {
  display: block; }

.main-container #bfc-app .ra-bfc-tooltip-trigger .ra-bfc-tooltip {
  position: absolute;
  max-width: 30rem;
  min-width: 30rem;
  height: auto;
  z-index: 1000;
  transform: translateX(-50%);
  font-weight: 400;
  margin-left: 1.5rem;
  margin-top: -.5rem; }
  .main-container #bfc-app .ra-bfc-tooltip-trigger .ra-bfc-tooltip .tooltip-inner, .main-container #bfc-app .ra-bfc-tooltip-trigger .ra-bfc-tooltip .tooltip-arrow {
    display: none;
    font-size: 1.2rem; }
    .main-container #bfc-app .ra-bfc-tooltip-trigger .ra-bfc-tooltip .tooltip-inner .tooltip-moreinfo, .main-container #bfc-app .ra-bfc-tooltip-trigger .ra-bfc-tooltip .tooltip-arrow .tooltip-moreinfo {
      display: block;
      width: 100%;
      margin-top: 1rem; }
  .main-container #bfc-app .ra-bfc-tooltip-trigger .ra-bfc-tooltip:hover .tooltip-inner, .main-container #bfc-app .ra-bfc-tooltip-trigger .ra-bfc-tooltip:hover .tooltip-arrow {
    display: block; }

.main-container #bfc-app #bfc-print-info-attributes {
  list-style: none;
  page-break-inside: avoid !important; }

.main-container #bfc-app #ra-bfc-assembly-clockwise {
  page-break-inside: avoid; }
  .main-container #bfc-app #ra-bfc-assembly-clockwise p {
    font-weight: 700;
    font-size: 1.6rem;
    margin: 0; }
  .main-container #bfc-app #ra-bfc-assembly-clockwise div {
    margin-bottom: .5rem; }
  .main-container #bfc-app #ra-bfc-assembly-clockwise ul {
    list-style: none;
    padding: 0; }

.main-container #bfc-app #ra-bfc-assembly-clockwise {
  margin-bottom: 15rem; }
  .main-container #bfc-app #ra-bfc-assembly-clockwise p {
    margin: 0 !important;
    font-weight: 700; }
  .main-container #bfc-app #ra-bfc-assembly-clockwise span {
    margin-right: 1rem; }
  .main-container #bfc-app #ra-bfc-assembly-clockwise .index {
    margin-right: .5rem;
    font-weight: 700; }

.main-container #ra-frontpage-overview {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 4rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto; }
  .main-container #ra-frontpage-overview h1 {
    margin-bottom: 2rem;
    color: #FFFFFF; }
  .main-container #ra-frontpage-overview #ra-frontpage-language-selection {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 2rem 1rem 1rem 1rem; }
    .main-container #ra-frontpage-overview #ra-frontpage-language-selection .ra-frontpage-language-btn {
      margin-bottom: 1rem;
      height: 5rem;
      line-height: 4rem; }
      .main-container #ra-frontpage-overview #ra-frontpage-language-selection .ra-frontpage-language-btn .icon {
        font-size: 3rem;
        text-align: left;
        height: 100%; }
      .main-container #ra-frontpage-overview #ra-frontpage-language-selection .ra-frontpage-language-btn .name {
        text-align: left;
        font-size: 1.6rem;
        height: 100%; }

.main-container .modelviewer {
  width: 100%;
  height: 60vh; }
  .main-container .modelviewer:focus {
    outline: none; }

.main-container .model-viewer-error {
  width: 100%;
  height: 60vh;
  text-align: center; }
  .main-container .model-viewer-error p {
    margin: 2rem;
    text-align: center; }

.main-container .model-viewer-button-box {
  background-color: #e3e3e3;
  background-image: none;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60vh;
  cursor: pointer; }
  @media (min-width: 768px) {
    .main-container .model-viewer-button-box {
      background-image: url("/out/ra-theme/img/model-3d_fallback.jpg");
      background-position: left; } }
  .main-container .model-viewer-button-box.has-custom-cover {
    background-position: center; }
    .main-container .model-viewer-button-box.has-custom-cover .btn {
      color: #FFFFFF;
      background-color: rgba(87, 87, 87, 0.5); }
  .main-container .model-viewer-button-box .btn {
    height: 100%;
    width: 100%; }
    .main-container .model-viewer-button-box .btn .ra-icon {
      font-size: 8rem; }
    .main-container .model-viewer-button-box .btn span {
      transition: transform .3s;
      display: inline-block; }
    .main-container .model-viewer-button-box .btn:hover span {
      transform: scale(1.2); }

.main-container .model-viewer-additional-button {
  position: absolute;
  right: 2rem;
  top: 1rem;
  cursor: pointer;
  height: 3rem;
  font-weight: bold; }
  .main-container .model-viewer-additional-button i {
    vertical-align: middle;
    font-size: 2rem; }

.main-container .model-viewer-right-outer .qr-close-btn {
  visibility: hidden;
  cursor: pointer;
  font-size: 2rem; }
  .main-container .model-viewer-right-outer .qr-close-btn.visible {
    visibility: visible !important; }

.main-container .model-viewer-right-outer .qr-scan-icon {
  color: #CCCCCC;
  font-size: 4rem; }

.main-container #article-reviews {
  padding-top: 4rem; }
  .main-container #article-reviews .review-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; }
    @media (max-width: 991.98px) {
      .main-container #article-reviews .review-grid {
        grid-template-columns: 1fr; } }

.main-container i.ra-icon.rating-full.grey, .main-container i.ra-icon.rating-empty.grey, .main-container i.ra-icon.rating-half.grey {
  font-size: 2rem;
  color: #575757; }

.main-container i.ra-icon.rating-full, .main-container i.ra-icon.rating-empty, .main-container i.ra-icon.rating-half {
  font-size: 2rem;
  color: #f59100; }

.main-container .no-reviews i.ra-icon.rating-full, .main-container .no-reviews i.ra-icon.rating-empty, .main-container .no-reviews i.ra-icon.rating-half {
  color: #e3e3e3; }

.main-container .star-ratings {
  display: inline-block;
  margin-bottom: 1rem;
  position: relative;
  width: 100%;
  height: 2.4rem; }
  .main-container .star-ratings > .row {
    align-content: center; }
  .main-container .star-ratings .stars {
    display: inline-block;
    position: relative;
    margin-right: 1rem; }

@media (max-width: 767.98px) {
  .main-container #btn-create-own-review {
    margin: 2rem 0; } }

.main-container .ra-review-variant-only-col {
  align-items: center;
  align-content: center; }
  @media (max-width: 991.98px) {
    .main-container .ra-review-variant-only-col {
      justify-content: center;
      justify-items: center; } }

.main-container .sort-col, .main-container .filter-col {
  display: flex;
  flex-direction: row;
  align-content: center; }
  @media (max-width: 991.98px) {
    .main-container .sort-col, .main-container .filter-col {
      margin-top: 2rem; } }
  .main-container .sort-col .review-filter:not(.ra-selectpicker), .main-container .filter-col .review-filter:not(.ra-selectpicker) {
    justify-self: flex-end;
    width: 100%; }
  .main-container .sort-col .review-sort:not(.ra-selectpicker), .main-container .filter-col .review-sort:not(.ra-selectpicker) {
    justify-self: flex-start;
    width: 100%; }
  .main-container .sort-col .prefix, .main-container .sort-col .review-filter:not(.ra-selectpicker), .main-container .sort-col .review-sort:not(.ra-selectpicker), .main-container .filter-col .prefix, .main-container .filter-col .review-filter:not(.ra-selectpicker), .main-container .filter-col .review-sort:not(.ra-selectpicker) {
    display: inline-block; }
  .main-container .sort-col .prefix, .main-container .filter-col .prefix {
    height: 4rem;
    width: 5rem;
    text-align: center;
    line-height: 4rem;
    font-size: 2rem;
    border: 1px solid #aeaeae;
    border-right: none; }
  .main-container .sort-col .review-filter:not(.ra-selectpicker), .main-container .sort-col .review-sort:not(.ra-selectpicker), .main-container .filter-col .review-filter:not(.ra-selectpicker), .main-container .filter-col .review-sort:not(.ra-selectpicker) {
    max-width: 100% !important; }
    .main-container .sort-col .review-filter:not(.ra-selectpicker) button, .main-container .sort-col .review-sort:not(.ra-selectpicker) button, .main-container .filter-col .review-filter:not(.ra-selectpicker) button, .main-container .filter-col .review-sort:not(.ra-selectpicker) button {
      border-left: none; }
    .main-container .sort-col .review-filter:not(.ra-selectpicker) .dropdown-toggle, .main-container .sort-col .review-sort:not(.ra-selectpicker) .dropdown-toggle, .main-container .filter-col .review-filter:not(.ra-selectpicker) .dropdown-toggle, .main-container .filter-col .review-sort:not(.ra-selectpicker) .dropdown-toggle {
      display: block;
      width: 100%;
      overflow: hidden;
      height: 4rem;
      line-height: 3rem; }

.main-container .ra-rating-stars {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .main-container .ra-rating-stars.no-reviews {
    margin-bottom: 1rem; }
  .main-container .ra-rating-stars .average-rating {
    font-size: 3rem;
    font-weight: 700;
    margin-right: 2rem; }
  .main-container .ra-rating-stars .rating-count {
    font-size: 1.6rem; }
  .main-container .ra-rating-stars .ra-icon {
    margin-right: .5rem; }

.main-container .ra-review-learn-more {
  color: #00569d;
  font-weight: 700;
  margin-left: 1rem; }
  .main-container .ra-review-learn-more:hover, .main-container .ra-review-learn-more:focus {
    color: #00569d; }

.main-container .ra-rating-overview {
  list-style: none;
  cursor: default;
  margin: 0; }
  .main-container .ra-rating-overview li {
    max-height: 3rem;
    height: 3rem;
    line-height: 2rem;
    padding: .5rem 0;
    display: flex;
    gap: 1rem; }
    .main-container .ra-rating-overview li .rating-amount {
      justify-self: flex-end;
      display: inline-flex; }
    .main-container .ra-rating-overview li .star-amount {
      justify-self: flex-start;
      display: inline-flex;
      align-content: center;
      align-items: center;
      gap: .5rem; }
      .main-container .ra-rating-overview li .star-amount i {
        font-size: 1.6rem;
        color: #f59100; }
    .main-container .ra-rating-overview li .rating-graph {
      background-color: #e3e3e3;
      width: 100%; }
      .main-container .ra-rating-overview li .rating-graph .rating-graph-inner {
        display: block;
        background-color: #00569d;
        height: 2rem;
        width: 0; }

.main-container .rating-default {
  cursor: default !important; }

.main-container .rating-pointer {
  cursor: pointer !important; }

.main-container .ra-recommendation-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #308136;
  font-weight: 700;
  font-size: 3rem; }
  .main-container .ra-recommendation-info .ra-icon {
    margin-right: .5rem; }
  .main-container .ra-recommendation-info .ra-recommendation-info-text {
    color: #575757;
    font-size: 1.5rem;
    margin-left: 1rem; }

.main-container .review-filter-dropdown {
  width: 100%; }
  .main-container .review-filter-dropdown .dropdown-item {
    display: flex;
    flex-direction: row;
    height: 3rem;
    padding: .5rem;
    line-height: 3rem;
    align-items: center;
    font-size: 1.4rem; }
    .main-container .review-filter-dropdown .dropdown-item span {
      margin-left: 2rem; }
    .main-container .review-filter-dropdown .dropdown-item .ra-icon {
      font-size: 1.8rem; }
      .main-container .review-filter-dropdown .dropdown-item .ra-icon.star-full {
        color: #f59100; }
      .main-container .review-filter-dropdown .dropdown-item .ra-icon.like {
        color: #308136; }
      .main-container .review-filter-dropdown .dropdown-item .ra-icon.dislike {
        color: #dc371c; }

.main-container .ra-review-variant-only {
  margin: 0; }

.main-container .load-more-reviews {
  order: 1;
  padding: 1rem; }
  .main-container .load-more-reviews .load-more-reviews-inner {
    display: grid;
    place-content: center;
    border: 1px solid #aeaeae;
    padding: 1rem;
    height: 100%;
    text-align: center;
    cursor: pointer; }
    .main-container .load-more-reviews .load-more-reviews-inner i {
      font-size: 3rem; }
    .main-container .load-more-reviews .load-more-reviews-inner span {
      display: block;
      margin-top: 2rem; }

.main-container .review-info {
  background-color: #f6f6f6;
  height: 100%;
  text-align: center;
  align-content: center;
  align-items: center; }
  @media (max-width: 767.98px) {
    .main-container .review-info {
      margin: 2rem 0; } }

.main-container .review-element-outer {
  padding: 1rem;
  order: 1; }
  .main-container .review-element-outer.hide-other-variant-review, .main-container .review-element-outer.filtered-hidden, .main-container .review-element-outer.hidden-on-page-load {
    display: none; }
  .main-container .review-element-outer .review-element-inner {
    border: 1px solid #aeaeae;
    padding: 1rem;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content 1fr min-content;
    gap: 1rem 0;
    grid-auto-flow: row;
    grid-template-areas: "review-element-stars review-element-actions" "review-element-body review-element-body" "review-element-footer review-element-footer"; }
  .main-container .review-element-outer.own-review {
    order: 0; }
    .main-container .review-element-outer.own-review .review-element-inner {
      border: 1px solid #00569d; }
  .main-container .review-element-outer .review-element-stars {
    grid-area: review-element-stars;
    display: flex;
    align-items: center;
    height: 3rem;
    line-height: 3rem; }
    .main-container .review-element-outer .review-element-stars span {
      margin-left: .5rem;
      font-weight: 700; }
    .main-container .review-element-outer .review-element-stars .ra-icon {
      margin-right: .5rem;
      vertical-align: middle; }
  .main-container .review-element-outer .mobile-action-buttons {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem; }
    .main-container .review-element-outer .mobile-action-buttons .btn-block {
      margin: 0; }
  .main-container .review-element-outer .review-element-actions {
    grid-area: review-element-actions;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 3rem;
    line-height: 3rem;
    text-align: right; }
    .main-container .review-element-outer .review-element-actions .review-actions {
      height: 3rem; }
      .main-container .review-element-outer .review-element-actions .review-actions:not(.btn) {
        border: none;
        background-color: transparent; }
      .main-container .review-element-outer .review-element-actions .review-actions:disabled {
        cursor: not-allowed; }
      .main-container .review-element-outer .review-element-actions .review-actions.rated .like {
        color: #308136; }
      .main-container .review-element-outer .review-element-actions .review-actions.rated .dislike {
        color: #dc371c; }
      .main-container .review-element-outer .review-element-actions .review-actions.btn-not-helpful.rated {
        background-color: #dc371c;
        color: #FFFFFF;
        border-color: #FFFFFF; }
        .main-container .review-element-outer .review-element-actions .review-actions.btn-not-helpful.rated .dislike, .main-container .review-element-outer .review-element-actions .review-actions.btn-not-helpful.rated .review-action-text {
          color: #FFFFFF; }
      .main-container .review-element-outer .review-element-actions .review-actions.btn-helpful.rated {
        background-color: #308136;
        color: #FFFFFF;
        border-color: #FFFFFF; }
        .main-container .review-element-outer .review-element-actions .review-actions.btn-helpful.rated .like, .main-container .review-element-outer .review-element-actions .review-actions.btn-helpful.rated .review-action-text {
          color: #FFFFFF; }
    .main-container .review-element-outer .review-element-actions .ra-icon {
      font-size: 2rem;
      margin: 0 .5rem;
      vertical-align: middle; }
    .main-container .review-element-outer .review-element-actions a:not(.disabled) .ra-icon.like:hover, .main-container .review-element-outer .review-element-actions a:not(.disabled) .ra-icon .selected {
      color: #308136; }
    .main-container .review-element-outer .review-element-actions a:not(.disabled) .ra-icon.dislike:hover, .main-container .review-element-outer .review-element-actions a:not(.disabled) .ra-icon .selected {
      color: #dc371c; }
    .main-container .review-element-outer .review-element-actions a:not(.disabled) .ra-icon.flag:hover:not(:disabled), .main-container .review-element-outer .review-element-actions a:not(.disabled) .ra-icon .selected {
      color: #dc371c; }
    .main-container .review-element-outer .review-element-actions a {
      cursor: pointer; }
      .main-container .review-element-outer .review-element-actions a.disabled {
        cursor: not-allowed; }
    @media (max-width: 767.98px) {
      .main-container .review-element-outer .review-element-actions {
        display: block;
        height: 100%; }
        .main-container .review-element-outer .review-element-actions .review-action-mobile {
          margin-bottom: 1rem; } }
  .main-container .review-element-outer .review-element-body {
    grid-area: review-element-body; }
    .main-container .review-element-outer .review-element-body .review-origin {
      margin-top: -1rem;
      display: flex;
      gap: .5rem;
      align-items: center;
      align-content: center;
      justify-content: flex-start;
      justify-items: flex-start;
      font-size: 1.2rem;
      font-style: italic; }
      .main-container .review-element-outer .review-element-body .review-origin img {
        max-height: 1.6rem;
        width: auto; }
    .main-container .review-element-outer .review-element-body .review-element-text.truncated {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical; }
    .main-container .review-element-outer .review-element-body .review-truncate-read-more {
      font-size: 1.1rem;
      color: #00569d;
      margin-top: -1rem;
      cursor: pointer;
      display: block; }
    .main-container .review-element-outer .review-element-body .review-truncate-checkbox {
      pointer-events: none;
      display: none;
      opacity: 0;
      position: absolute; }
      .main-container .review-element-outer .review-element-body .review-truncate-checkbox:checked + p.review-element-text {
        -webkit-line-clamp: unset; }
        .main-container .review-element-outer .review-element-body .review-truncate-checkbox:checked + p.review-element-text + .review-truncate-read-more {
          display: none; }
    .main-container .review-element-outer .review-element-body .review-element-answer {
      border-left: 0.5rem solid #aeaeae;
      margin-left: 3rem;
      padding-left: 1rem;
      margin-bottom: 2rem; }
      .main-container .review-element-outer .review-element-body .review-element-answer .review-element-answer-headline {
        font-weight: 700;
        display: block; }
    .main-container .review-element-outer .review-element-body .review-translation-info {
      color: #aeaeae;
      font-size: 1.1rem; }
      .main-container .review-element-outer .review-element-body .review-translation-info a {
        cursor: pointer; }
        .main-container .review-element-outer .review-element-body .review-translation-info a:hover {
          color: #00569d; }
  .main-container .review-element-outer .review-element-footer {
    grid-area: review-element-footer; }
    .main-container .review-element-outer .review-element-footer .review-element-recommendation {
      height: 3rem;
      line-height: 3rem;
      display: flex;
      align-items: center;
      margin-bottom: 2rem; }
      .main-container .review-element-outer .review-element-footer .review-element-recommendation .ra-icon {
        font-size: 2rem;
        color: #dc371c; }
      .main-container .review-element-outer .review-element-footer .review-element-recommendation .recommendation-text {
        margin-left: 1rem; }
        @media (max-width: 767.98px) {
          .main-container .review-element-outer .review-element-footer .review-element-recommendation .recommendation-text {
            font-size: 1.2rem !important; } }
      .main-container .review-element-outer .review-element-footer .review-element-recommendation.positive .ra-icon {
        font-size: 3rem;
        color: #308136; }
      .main-container .review-element-outer .review-element-footer .review-element-recommendation.positive .recommendation-text {
        font-weight: 700;
        font-size: 1.8rem; }
    .main-container .review-element-outer .review-element-footer .review-element-variant span {
      margin-left: .5rem; }
  @media (max-width: 767.98px) {
    .main-container .review-element-outer .review-element-inner {
      grid-template-areas: "review-element-stars review-element-stars" "review-element-body review-element-body" "review-element-footer review-element-footer" "review-element-actions review-element-actions"; } }

.main-container .ra-rating-stars-top {
  display: inline-block; }
  .main-container .ra-rating-stars-top:hover .ra-rating-stars-top-tooltip .tooltip-inner, .main-container .ra-rating-stars-top:hover .ra-rating-stars-top-tooltip .tooltip-arrow {
    display: block; }
  .main-container .ra-rating-stars-top .ra-rating-stars-top-tooltip {
    position: absolute;
    max-width: 30rem;
    min-width: 30rem;
    height: auto;
    z-index: 1000;
    font-weight: 400;
    margin-left: .75rem; }
    .main-container .ra-rating-stars-top .ra-rating-stars-top-tooltip .tooltip-arrow {
      margin-left: 1rem;
      margin-right: auto; }
    .main-container .ra-rating-stars-top .ra-rating-stars-top-tooltip .tooltip-inner, .main-container .ra-rating-stars-top .ra-rating-stars-top-tooltip .tooltip-arrow {
      display: none;
      text-align: left;
      font-size: 1.2rem; }
      .main-container .ra-rating-stars-top .ra-rating-stars-top-tooltip .tooltip-inner .ra-rating-overview, .main-container .ra-rating-stars-top .ra-rating-stars-top-tooltip .tooltip-arrow .ra-rating-overview {
        margin: 2rem 0; }
        .main-container .ra-rating-stars-top .ra-rating-stars-top-tooltip .tooltip-inner .ra-rating-overview li:not(.no-data), .main-container .ra-rating-stars-top .ra-rating-stars-top-tooltip .tooltip-arrow .ra-rating-overview li:not(.no-data) {
          cursor: pointer; }
      .main-container .ra-rating-stars-top .ra-rating-stars-top-tooltip .tooltip-inner .ra-icon, .main-container .ra-rating-stars-top .ra-rating-stars-top-tooltip .tooltip-arrow .ra-icon {
        margin-right: 0; }
    .main-container .ra-rating-stars-top .ra-rating-stars-top-tooltip:hover .tooltip-inner, .main-container .ra-rating-stars-top .ra-rating-stars-top-tooltip:hover .tooltip-arrow {
      display: block; }

.main-container .margin-bottom-2 {
  margin-bottom: 2.5rem; }

body.cl-start #start-carousel .carousel-indicators li {
  height: .75rem;
  width: 1.5rem;
  border: 1px solid #FFFFFF;
  background-color: rgba(116, 116, 116, 0.5) !important;
  transition: all .5s ease-in-out;
  position: relative;
  opacity: 1 !important;
  overflow: hidden; }
  body.cl-start #start-carousel .carousel-indicators li .carousel-indicator-bg {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #00569d; }
  body.cl-start #start-carousel .carousel-indicators li.active {
    width: 2.5rem; }
    body.cl-start #start-carousel .carousel-indicators li.active.transition .carousel-indicator-bg {
      transition: transform 5s linear;
      transform: translateX(100%); }
    body.cl-start #start-carousel .carousel-indicators li.active.transition.pause .carousel-indicator-bg {
      left: 0;
      transform: none !important;
      transition: transform 0s; }

@media (max-width: 767.98px) {
  body.cl-start #start-carousel .carousel-indicators {
    margin-bottom: -1.5rem; } }

body.cl-start #start-carousel .carousel-item {
  overflow: hidden; }
  @media (min-width: 576px) {
    body.cl-start #start-carousel .carousel-item .link-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } }
  body.cl-start #start-carousel .carousel-item .link-overlay-image-only {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  body.cl-start #start-carousel .carousel-item .ribbon.bg-highlight {
    background-color: #00569d; }

@media (max-width: 767.98px) {
  body.cl-start #start-carousel {
    margin: 0 -2rem; } }

body.cl-start #start-carousel .start-slider .text-box .highlight {
  color: #00569d; }

body.cl-start #start-carousel .start-slider .text-box-title span.highlight-text, body.cl-start #start-carousel .start-slider p span.highlight-text {
  color: #00569d; }

body.cl-start #start-carousel .start-slider .text-box-title {
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.3; }

body.cl-start #start-carousel .start-slider p {
  font-size: 1.8rem;
  margin: 2rem 0; }

body.cl-start #start-carousel .start-slider ul li {
  font-size: 1.8rem; }

body.cl-start #start-carousel .start-slider .bg-img {
  display: block;
  height: 40rem;
  background-size: cover;
  background-repeat: no-repeat; }
  body.cl-start #start-carousel .start-slider .bg-img:not(.bg-img-0) {
    opacity: 0;
    transition: all .4s; }
  body.cl-start #start-carousel .start-slider .bg-img img {
    height: 40rem; }

@media (max-width: 767.98px) {
  body.cl-start #start-carousel .start-slider .bg-img {
    height: 30rem; }
    body.cl-start #start-carousel .start-slider .bg-img img {
      height: 30rem; } }

@media (max-width: 991.98px) {
  body.cl-start #start-carousel .start-slider .text-box-title {
    font-size: 2.3rem; }
  body.cl-start #start-carousel .start-slider p {
    font-size: 1.6rem;
    margin: 1rem 0; }
  body.cl-start #start-carousel .start-slider .btn-highlight-mobile {
    color: #FFFFFF !important;
    background-color: #00569d;
    border-color: #004884;
    box-shadow: none !important; }
  body.cl-start #start-carousel .start-slider .btn-lightgrey-mobile {
    color: #333333 !important;
    background-color: #e3e3e3;
    border-color: #aeaeae;
    box-shadow: none !important; }
  body.cl-start #start-carousel .start-slider .btn-darkgrey-mobile {
    color: #FFFFFF !important;
    background-color: #575757;
    border-color: #575757;
    box-shadow: none !important; }
  body.cl-start #start-carousel .start-slider .btn-black-mobile {
    color: #FFFFFF !important;
    background-color: #000000;
    border-color: #000000;
    box-shadow: none !important; }
  body.cl-start #start-carousel .start-slider .btn-white-mobile {
    color: #575757 !important;
    background-color: #FFFFFF;
    border-color: #aeaeae;
    box-shadow: none !important; }
  body.cl-start #start-carousel .start-slider .btn-special-mobile {
    color: #FFFFFF !important;
    background-color: #dc371c;
    border-color: #c63119;
    box-shadow: none !important; }
  body.cl-start #start-carousel .start-slider .btn-confirm-mobile {
    color: #FFFFFF !important;
    background-color: #308136;
    border-color: #296e2e;
    box-shadow: none !important; }
  body.cl-start #start-carousel .start-slider .btn-rating-mobile {
    color: #FFFFFF !important;
    background-color: #f59100;
    border-color: #dc8200;
    box-shadow: none !important; } }

body.cl-start #start-carousel .start-slider .slider-item.slider-mode-light {
  background-color: #e3e3e3; }
  @media (max-width: 767.98px) {
    body.cl-start #start-carousel .start-slider .slider-item.slider-mode-light .text-box {
      background-color: #575757; }
      body.cl-start #start-carousel .start-slider .slider-item.slider-mode-light .text-box .text-box-title, body.cl-start #start-carousel .start-slider .slider-item.slider-mode-light .text-box p {
        color: #e3e3e3; }
        body.cl-start #start-carousel .start-slider .slider-item.slider-mode-light .text-box .text-box-title .highlight-text, body.cl-start #start-carousel .start-slider .slider-item.slider-mode-light .text-box p .highlight-text {
          color: #e3e3e3; }
        body.cl-start #start-carousel .start-slider .slider-item.slider-mode-light .text-box .text-box-title .highlight, body.cl-start #start-carousel .start-slider .slider-item.slider-mode-light .text-box p .highlight {
          color: #006bc3; } }

body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark {
  background-color: #575757; }
  body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark .text-box-title, body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark p, body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark ul {
    color: #FFFFFF; }
    body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark .text-box-title .highlight-text, body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark p .highlight-text, body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark ul .highlight-text {
      color: #FFFFFF; }
    body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark .text-box-title .highlight, body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark p .highlight, body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark ul .highlight {
      color: #00569d; }
  @media (max-width: 767.98px) {
    body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark .text-box {
      background-color: #e3e3e3; }
      body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark .text-box .text-box-title, body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark .text-box p {
        color: #575757; }
        body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark .text-box .text-box-title .highlight-text, body.cl-start #start-carousel .start-slider .slider-item.slider-mode-dark .text-box p .highlight-text {
          color: #00569d; } }

body.cl-start #start-carousel .start-slider .slider-item.image-left-text-right .bg-img {
  background-position: left top; }
  body.cl-start #start-carousel .start-slider .slider-item.image-left-text-right .bg-img img {
    position: absolute;
    left: 0; }

body.cl-start #start-carousel .start-slider .slider-item.image-left-text-right .text-box .text-box-inner {
  margin-right: 4rem; }
  @media (max-width: 767.98px) {
    body.cl-start #start-carousel .start-slider .slider-item.image-left-text-right .text-box .text-box-inner {
      margin-right: 0; } }

body.cl-start #start-carousel .start-slider .slider-item.image-right-text-left .bg-img {
  background-position: right top; }
  body.cl-start #start-carousel .start-slider .slider-item.image-right-text-left .bg-img img {
    position: absolute;
    right: 0; }

body.cl-start #start-carousel .start-slider .slider-item.image-right-text-left .text-box .text-box-inner {
  padding-left: 8rem; }
  @media (max-width: 767.98px) {
    body.cl-start #start-carousel .start-slider .slider-item.image-right-text-left .text-box .text-box-inner {
      padding-left: 0; } }

body.cl-start #start-carousel .start-slider .slider-item.image-only-box .bg-img.left {
  background-position: left top; }
  body.cl-start #start-carousel .start-slider .slider-item.image-only-box .bg-img.left img {
    position: absolute;
    left: 0; }

body.cl-start #start-carousel .start-slider .slider-item.image-only-box .bg-img.middle {
  background-position: center top; }
  body.cl-start #start-carousel .start-slider .slider-item.image-only-box .bg-img.middle img {
    position: absolute;
    left: 50%;
    margin-left: -58rem; }
    @media (max-width: 767.98px) {
      body.cl-start #start-carousel .start-slider .slider-item.image-only-box .bg-img.middle img {
        margin-left: -43.5rem; }
        body.cl-start #start-carousel .start-slider .slider-item.image-only-box .bg-img.middle img.img-mobile {
          margin-left: -37.55rem; } }

body.cl-start #start-carousel .start-slider .slider-item.image-only-box .bg-img.right {
  background-position: right top; }
  body.cl-start #start-carousel .start-slider .slider-item.image-only-box .bg-img.right img {
    position: absolute;
    right: 0; }

@media (max-width: 767.98px) {
  body.cl-start #start-carousel .start-slider .slider-item.image-only-box .bg-img {
    height: 55rem; }
    body.cl-start #start-carousel .start-slider .slider-item.image-only-box .bg-img img {
      height: 55rem; } }

body.cl-start #start-carousel .start-slider .slider-item .text-box {
  display: flex;
  align-items: center;
  height: 100%; }
  @media (max-width: 767.98px) {
    body.cl-start #start-carousel .start-slider .slider-item .text-box {
      min-height: 25rem; } }
  body.cl-start #start-carousel .start-slider .slider-item .text-box .text-box-inner {
    width: 100%; }
    body.cl-start #start-carousel .start-slider .slider-item .text-box .text-box-inner .btn {
      font-size: 1.866rem;
      padding: .5rem 2rem; }
  @media (max-width: 767.98px) {
    body.cl-start #start-carousel .start-slider .slider-item .text-box {
      margin: 0 -1rem;
      padding: 2rem 0;
      text-align: center !important; }
      body.cl-start #start-carousel .start-slider .slider-item .text-box .text-box-title {
        font-size: 1.9rem; }
      body.cl-start #start-carousel .start-slider .slider-item .text-box p {
        font-size: 1.6rem;
        width: 100%;
        padding: 1rem 3rem;
        margin-left: 0 !important; } }

@media (min-width: 768px) {
  body.cl-start #start-carousel .start-slider .slider-item .inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0; } }

body.cl-start #start-carousel .start-slider .slider-item .inner .row {
  height: 100%; }

@media (max-width: 991.98px) {
  body.cl-start #start-carousel .start-slider .slider-item.image-left-text-right .bg-img {
    background-position: left -10rem top; }
    body.cl-start #start-carousel .start-slider .slider-item.image-left-text-right .bg-img img {
      position: absolute;
      left: -10rem; }
  body.cl-start #start-carousel .start-slider .slider-item.image-right-text-left .bg-img {
    background-position: right -10rem top; }
    body.cl-start #start-carousel .start-slider .slider-item.image-right-text-left .bg-img img {
      position: absolute;
      right: -10rem; } }

@media (max-width: 767.98px) {
  body.cl-start #start-carousel .start-slider .slider-item.image-left-text-right .bg-img {
    background-position: left -7.5rem top; }
    body.cl-start #start-carousel .start-slider .slider-item.image-left-text-right .bg-img img {
      position: absolute;
      right: -7.5rem;
      left: -7.5rem; }
  body.cl-start #start-carousel .start-slider .slider-item.image-right-text-left .bg-img {
    background-position: right -7.5rem top; }
    body.cl-start #start-carousel .start-slider .slider-item.image-right-text-left .bg-img img {
      position: absolute;
      right: -7.5rem; } }

body.cl-start #start-carousel .start-slider .ribbons {
  position: absolute;
  top: .75rem;
  left: .75rem;
  transition: .5s all;
  opacity: 1;
  display: inline-block; }
  body.cl-start #start-carousel .start-slider .ribbons .ribbon {
    font-size: 1.866rem;
    padding: .2rem .8rem;
    margin-right: .75rem;
    float: left; }
    body.cl-start #start-carousel .start-slider .ribbons .ribbon:not(.ribbon-without-background-color) {
      background-color: #dc371c; }
    body.cl-start #start-carousel .start-slider .ribbons .ribbon:not(.ribbon-without-font-color) {
      color: #FFFFFF; }

body.cl-start #start-carousel .start-slider .carousel-item-next .ribbons, body.cl-start #start-carousel .start-slider .carousel-item-prev .ribbons {
  opacity: 0; }

body.cl-start #start-carousel .start-slider .slider-price {
  font-size: 2rem;
  padding-bottom: 2rem; }
  body.cl-start #start-carousel .start-slider .slider-price small {
    font-size: 1.3rem;
    font-style: italic;
    padding-left: .4rem; }
  body.cl-start #start-carousel .start-slider .slider-price span {
    font-weight: bold; }
  body.cl-start #start-carousel .start-slider .slider-price .striked-price {
    position: relative;
    display: inline-block;
    font-weight: normal;
    font-size: 1.3rem;
    margin-right: .5rem; }
    body.cl-start #start-carousel .start-slider .slider-price .striked-price:before {
      content: '';
      border-bottom: 2px solid #575757;
      width: 100%;
      position: absolute;
      right: 0;
      top: 50%;
      transform: rotate(-5deg); }
  body.cl-start #start-carousel .start-slider .slider-price.highlighted span:not(.striked-price) {
    color: #dc371c; }

@media (max-width: 767.98px) {
  body.cl-start #start-carousel .start-slider .slider-mode-light .slider-price {
    color: #FFFFFF; }
    body.cl-start #start-carousel .start-slider .slider-mode-light .slider-price .striked-price:before {
      border-bottom: 2px solid #FFFFFF; }
    body.cl-start #start-carousel .start-slider .slider-mode-light .slider-price.highlighted span:not(.striked-price) {
      color: #e85b43; }
  body.cl-start #start-carousel .start-slider .slider-mode-light ul {
    color: #FFFFFF; } }

body.cl-start #start-carousel .start-slider .slider-mode-dark .slider-price {
  color: #FFFFFF; }
  body.cl-start #start-carousel .start-slider .slider-mode-dark .slider-price.highlighted span {
    color: #e85b43; }

@media (max-width: 767.98px) {
  body.cl-start #start-carousel .start-slider .slider-mode-dark .slider-price {
    color: #575757; } }

body.cl-start #start-carousel .start-slider .fade-in-duration {
  animation-duration: 1s;
  animation-fill-mode: both; }

body.cl-start #start-carousel .start-slider .fade-in-bottom {
  animation-name: fadeInBottom; }

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%); }
  to {
    opacity: 1; } }

body.cl-start #start-carousel .start-slider .icon-alignment-middle {
  vertical-align: middle;
  margin-bottom: 3px; }

@media (min-width: 576px) {
  body.cl-start #start-carousel .start-slider .headline.size-50-percent {
    font-size: 2.4rem !important; }
  body.cl-start #start-carousel .start-slider .headline.size-75-percent {
    font-size: 3.6rem !important; }
  body.cl-start #start-carousel .start-slider .headline.size-125-percent {
    font-size: 7rem !important; }
  body.cl-start #start-carousel .start-slider .headline.size-150-percent {
    font-size: 7.2rem !important; } }

@media (min-width: 576px) {
  body.cl-start #start-carousel .start-slider .text.size-50-percent {
    font-size: 1rem !important; }
  body.cl-start #start-carousel .start-slider .text.size-75-percent {
    font-size: 1.50rem !important; }
  body.cl-start #start-carousel .start-slider .text.size-125-percent {
    font-size: 2.5rem !important; }
  body.cl-start #start-carousel .start-slider .text.size-150-percent {
    font-size: 3rem !important; } }

body.cl-start #start-carousel .start-slider-compact {
  margin-top: -2rem; }

@media (min-width: 768px) {
  body.cl-start #start-carousel.carousel-small {
    height: 28rem; }
    body.cl-start #start-carousel.carousel-small .bg-img {
      height: 28rem; }
      body.cl-start #start-carousel.carousel-small .bg-img img {
        height: 28rem; }
    body.cl-start #start-carousel.carousel-small .ml-small-banner .text-box {
      margin-left: 7rem; }
  body.cl-start #start-carousel.carousel-big {
    height: 30.2rem; }
    body.cl-start #start-carousel.carousel-big .bg-img {
      height: 30.2rem !important; }
      body.cl-start #start-carousel.carousel-big .bg-img img {
        height: 30.2rem !important; }
  body.cl-start #start-carousel.carousel-big, body.cl-start #start-carousel.carousel-small {
    max-width: 100vw;
    width: 116rem;
    right: 50%;
    left: 50%;
    transform: translateX(-50%); }
    body.cl-start #start-carousel.carousel-big .ribbons, body.cl-start #start-carousel.carousel-small .ribbons {
      left: 1.5rem !important; }
    body.cl-start #start-carousel.carousel-big .start-slider p, body.cl-start #start-carousel.carousel-small .start-slider p {
      font-size: 2rem;
      line-height: 2.6rem;
      margin: 2rem 0; }
    body.cl-start #start-carousel.carousel-big .start-slider ul li, body.cl-start #start-carousel.carousel-small .start-slider ul li {
      font-size: 2rem; }
    body.cl-start #start-carousel.carousel-big .text-box-inner .btn, body.cl-start #start-carousel.carousel-small .text-box-inner .btn {
      font-size: 1.6rem;
      padding: .5rem 1.5rem; }
    body.cl-start #start-carousel.carousel-big .bg-img, body.cl-start #start-carousel.carousel-small .bg-img {
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      transition: all .4s; }
  body.cl-start #start-carousel.carousel-full-width {
    width: 100vw;
    height: 70vh;
    right: 50%;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw; }
    body.cl-start #start-carousel.carousel-full-width .ribbons {
      left: 1.5rem !important; }
    body.cl-start #start-carousel.carousel-full-width .chevron-background-left {
      left: 0;
      width: 80%;
      height: 20%;
      background-color: #e3e3e3;
      position: absolute;
      z-index: 1000; }
    body.cl-start #start-carousel.carousel-full-width .chevron-background-right {
      right: 0;
      width: 80%;
      height: 20%;
      background-color: #e3e3e3;
      position: absolute;
      z-index: 1000; }
    body.cl-start #start-carousel.carousel-full-width .chevron-left-full-width {
      z-index: 1050; }
    body.cl-start #start-carousel.carousel-full-width .chevron-right-full-width {
      z-index: 1050; }
    body.cl-start #start-carousel.carousel-full-width .text-box-inner-full-width {
      margin-top: 20rem;
      transition: margin 500ms; } }
  @media (min-width: 768px) and (min-height: 850px) {
    body.cl-start #start-carousel.carousel-full-width .text-box-inner-full-width {
      margin-top: 35rem; } }

@media (min-width: 768px) {
  body.cl-start #start-carousel .start-slider-full-width .bg-img {
    display: block;
    height: 70vh !important;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all .4s; }
    body.cl-start #start-carousel .start-slider-full-width .bg-img img {
      height: 70vh !important; }
  body.cl-start #start-carousel .start-slider-compact {
    margin-top: -1rem; }
  body.cl-start #start-carousel .banner-text-centered {
    width: 100%; }
  body.cl-start #start-carousel .fullwidth-banner-text-centered {
    width: 100%; }
  body.cl-start #start-carousel .text-box-title-centered {
    display: table;
    margin-left: auto;
    margin-right: auto;
    padding: 0 2rem;
    font-size: 5rem !important;
    color: #FFFFFF !important;
    background-color: #00569d !important; }
    body.cl-start #start-carousel .text-box-title-centered span {
      color: #FFFFFF !important; }
  body.cl-start #start-carousel .text-box-centered {
    font-size: 2.4rem !important;
    font-weight: 700 !important;
    color: #FFFFFF !important;
    background-color: #575757 !important;
    width: 40% !important;
    margin-left: auto !important;
    margin-right: auto !important; }
  body.cl-start #start-carousel .btn-text-centered {
    font-size: 1.8rem !important;
    color: #FFFFFF !important; } }

body.cl-start #start-carousel.carousel-small .start-slider .text-box-title {
  font-size: 3.2rem !important;
  line-height: 3.8rem !important; }

body.cl-start #start-carousel.carousel-small .start-slider .btn {
  font-size: 1.6rem !important; }

@media (min-width: 1200px) {
  body.cl-start #start-carousel.carousel-big {
    height: 50rem; }
    body.cl-start #start-carousel.carousel-big .bg-img {
      height: 50rem !important; }
      body.cl-start #start-carousel.carousel-big .bg-img img {
        height: 50rem !important; }
  body.cl-start #start-carousel.carousel-small {
    height: 37.5rem; }
    body.cl-start #start-carousel.carousel-small .bg-img {
      height: 37.5rem !important; }
      body.cl-start #start-carousel.carousel-small .bg-img img {
        height: 37.5rem !important; }
  body.cl-start #start-carousel.carousel-big, body.cl-start #start-carousel.carousel-small {
    max-width: 100vw;
    width: 192rem;
    right: 50%;
    left: 50%;
    transform: translateX(-50%); }
    body.cl-start #start-carousel.carousel-big .ribbons, body.cl-start #start-carousel.carousel-small .ribbons {
      left: 1.5rem !important; }
    body.cl-start #start-carousel.carousel-big .start-slider p, body.cl-start #start-carousel.carousel-small .start-slider p {
      font-size: 2rem;
      line-height: 2.8rem;
      margin: 2rem 0 4rem 0; }
    body.cl-start #start-carousel.carousel-big .start-slider ul li, body.cl-start #start-carousel.carousel-small .start-slider ul li {
      font-size: 3.6rem; }
    body.cl-start #start-carousel.carousel-big .start-slider .text-box-inner .btn, body.cl-start #start-carousel.carousel-small .start-slider .text-box-inner .btn {
      font-size: 1.8rem;
      padding: .5rem 2rem; }
    body.cl-start #start-carousel.carousel-big .start-slider .slider-price, body.cl-start #start-carousel.carousel-small .start-slider .slider-price {
      font-size: 2.5rem; }
      body.cl-start #start-carousel.carousel-big .start-slider .slider-price small, body.cl-start #start-carousel.carousel-small .start-slider .slider-price small {
        font-size: 1.8rem; }
      body.cl-start #start-carousel.carousel-big .start-slider .slider-price .striked-price, body.cl-start #start-carousel.carousel-small .start-slider .slider-price .striked-price {
        font-size: 1.8rem; }
    body.cl-start #start-carousel.carousel-big .bg-img, body.cl-start #start-carousel.carousel-small .bg-img {
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      transition: all .4s; } }

@media (max-width: 767.98px) {
  body.cl-start #start-carousel .centered-banner-display-block {
    display: block; } }

@media (min-width: 768px) {
  body.cl-start #start-carousel.carousel-big .start-slider .text-box-title {
    font-size: 3.4rem;
    line-height: 4rem; } }

@media (min-width: 1200px) {
  body.cl-start #start-carousel.carousel-big .start-slider .text-box-title {
    font-size: 4.8rem;
    line-height: 5.8rem; } }

@media only screen and (max-width: 1500px) and (min-width: 1200px) {
  body.cl-start #start-carousel.carousel-big .slider-item.image-left-text-right .bg-img, body.cl-start #start-carousel.carousel-small .slider-item.image-left-text-right .bg-img {
    background-position: left -16rem top; }
    body.cl-start #start-carousel.carousel-big .slider-item.image-left-text-right .bg-img img, body.cl-start #start-carousel.carousel-small .slider-item.image-left-text-right .bg-img img {
      position: absolute;
      left: -16rem; }
  body.cl-start #start-carousel.carousel-big .slider-item.image-right-text-left .bg-img, body.cl-start #start-carousel.carousel-small .slider-item.image-right-text-left .bg-img {
    background-position: right -16rem top; }
    body.cl-start #start-carousel.carousel-big .slider-item.image-right-text-left .bg-img img, body.cl-start #start-carousel.carousel-small .slider-item.image-right-text-left .bg-img img {
      position: absolute;
      right: -16rem; } }

body.cl-start #start-carousel.carousel-big .slider-item.image-only-box .bg-img.middle, body.cl-start #start-carousel.carousel-small .slider-item.image-only-box .bg-img.middle {
  background-position: center top; }
  body.cl-start #start-carousel.carousel-big .slider-item.image-only-box .bg-img.middle img, body.cl-start #start-carousel.carousel-small .slider-item.image-only-box .bg-img.middle img {
    position: absolute;
    left: 50%;
    right: 50%;
    margin-left: unset;
    margin-right: unset;
    transform: translateX(-50%); }
    @media (max-width: 767.98px) {
      body.cl-start #start-carousel.carousel-big .slider-item.image-only-box .bg-img.middle img.img-mobile, body.cl-start #start-carousel.carousel-small .slider-item.image-only-box .bg-img.middle img.img-mobile {
        margin-left: unset;
        margin-right: unset; } }

body.cl-start #start-carousel.carousel-big .slider-item.image-only-box .bg-img.right, body.cl-start #start-carousel.carousel-small .slider-item.image-only-box .bg-img.right {
  background-position: right top; }
  body.cl-start #start-carousel.carousel-big .slider-item.image-only-box .bg-img.right img, body.cl-start #start-carousel.carousel-small .slider-item.image-only-box .bg-img.right img {
    position: absolute;
    right: 0; }

#sidebar.with-header {
  animation: animate-to-header-margin .4s forwards; }

#sidebar.margin-header {
  margin-top: 2rem; }

#sidebar.with-header-out {
  animation: animate-from-header-margin .4s forwards; }

@media (max-width: 1199.98px) {
  #sidebar {
    overflow-y: scroll;
    height: 100vh; } }

@media (max-width: 767.98px) {
  #sidebar {
    overflow-y: hidden;
    height: 0; } }

#sidebar .categorytree {
  margin-top: 1rem; }
  #sidebar .categorytree ol.adviserSteps, #sidebar .categorytree ol.moreAdvisers {
    list-style: none;
    padding: 0;
    margin: 0 0 3rem 0; }
  #sidebar .categorytree ol.cat-tree > li.active > a, #sidebar .categorytree ol.cat-tree > li.exp > a {
    font-weight: bold;
    font-size: 1.2em; }
    #sidebar .categorytree ol.cat-tree > li.active > a.rootCat, #sidebar .categorytree ol.cat-tree > li.exp > a.rootCat {
      line-height: 1.8rem; }
  #sidebar .categorytree ol.cat-tree ul.nav li.exp > a {
    font-weight: normal;
    font-size: inherit; }
  #sidebar .categorytree ol.cat-tree ul.nav li ul li {
    padding: 0 0 0 16px; }
  #sidebar .categorytree ol.cat-tree .nav {
    display: block; }
    #sidebar .categorytree ol.cat-tree .nav li {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-start;
      align-content: center; }
      #sidebar .categorytree ol.cat-tree .nav li a {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: stretch;
        align-content: center;
        line-height: 1.5rem;
        height: 3.5rem;
        width: 100%; }
        #sidebar .categorytree ol.cat-tree .nav li a .row {
          margin: 0 !important; }
      #sidebar .categorytree ol.cat-tree .nav li ul {
        display: block;
        width: 100%; }
      #sidebar .categorytree ol.cat-tree .nav li.active > a {
        font-weight: bold; }
  #sidebar .categorytree .cat-text span, #sidebar .categorytree .cat-icon span {
    display: flex;
    align-items: flex-start;
    height: 100%; }
    #sidebar .categorytree .cat-text span svg, #sidebar .categorytree .cat-icon span svg {
      max-height: 100%; }
  #sidebar .categorytree .cat-icon {
    padding: 0; }

#sidebar #categories ul, #sidebar #categories ol, #sidebar #categories-found ul, #sidebar #categories-found ol {
  list-style: none;
  padding-left: 10px;
  margin-bottom: 30px; }
  #sidebar #categories ul:first-child, #sidebar #categories ol:first-child, #sidebar #categories-found ul:first-child, #sidebar #categories-found ol:first-child {
    padding: 0; }
  #sidebar #categories ul li, #sidebar #categories ol li, #sidebar #categories-found ul li, #sidebar #categories-found ol li {
    white-space: normal;
    font-weight: normal;
    font-size: 14px; }
    #sidebar #categories ul li.selected, #sidebar #categories ol li.selected, #sidebar #categories-found ul li.selected, #sidebar #categories-found ol li.selected {
      font-weight: bold; }
    #sidebar #categories ul li.active.lvl1, #sidebar #categories ol li.active.lvl1, #sidebar #categories-found ul li.active.lvl1, #sidebar #categories-found ol li.active.lvl1 {
      font-size: 1.2em; }
    #sidebar #categories ul li .item-count, #sidebar #categories ol li .item-count, #sidebar #categories-found ul li .item-count, #sidebar #categories-found ol li .item-count {
      font-size: 1.1rem;
      opacity: .6;
      background-color: #e3e3e3;
      color: #000000;
      margin-left: .5rem;
      padding: 0 .5rem;
      border-radius: .5rem; }

#sidebar .boxtitle {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 8px; }

@keyframes animate-to-header-margin {
  0% {
    top: 0rem; }
  100% {
    top: 15rem; } }

@keyframes animate-from-header-margin {
  0% {
    top: 15rem; }
  100% {
    top: 0rem; } }

body.is-checkout .cancel-order-container {
  margin-bottom: 2rem; }

.checkout-steps .row {
  padding: 0; }
  .checkout-steps .row .circle {
    height: 5rem;
    width: 5rem;
    line-height: 5rem;
    border-radius: 50%;
    background-color: #aeaeae;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    color: #FFFFFF;
    margin: auto; }
    .checkout-steps .row .circle .passed {
      position: relative;
      bottom: 2rem;
      left: 3.5rem;
      width: 2rem;
      height: 2rem;
      background-color: #308136;
      border-radius: 50%;
      line-height: 2rem;
      font-size: 1.2rem; }
  .checkout-steps .row .text {
    text-align: center;
    font-size: 1.4rem;
    width: 100%; }
  @media (max-width: 575.98px) {
    .checkout-steps .row .circle {
      height: 3rem;
      width: 3rem;
      line-height: 3rem;
      font-size: 1.5rem; }
      .checkout-steps .row .circle .passed {
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
        font-size: 1rem;
        bottom: 1.5rem;
        left: 2rem; }
    .checkout-steps .row .text {
      font-size: 1rem; } }
  .checkout-steps .row.active .circle {
    background-color: #00569d; }
  .checkout-steps .row .spacer {
    text-align: center;
    line-height: 5rem;
    font-size: 3rem; }
  .checkout-steps .row.disabled .item {
    opacity: .45; }

.checkout-steps #mobile-checkout-steps {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between; }
  .checkout-steps #mobile-checkout-steps .progress-col {
    position: relative;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center; }
    .checkout-steps #mobile-checkout-steps .progress-col .progress-circle {
      position: absolute;
      inset: 0;
      display: flex;
      justify-items: center;
      align-items: center;
      justify-content: center;
      align-content: center; }
  .checkout-steps #mobile-checkout-steps .headline-col {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-content: flex-end;
    align-items: flex-end;
    padding: 0 1rem; }
  .checkout-steps #mobile-checkout-steps svg {
    height: 60px;
    transform: rotate(-90deg);
    width: 60px; }
  .checkout-steps #mobile-checkout-steps .progress-bar__background {
    fill: none;
    stroke: #e3e3e3;
    stroke-width: 2; }
  .checkout-steps #mobile-checkout-steps .progress-bar__progress {
    fill: none;
    stroke: #00569d;
    stroke-dasharray: 100 100;
    stroke-dashoffset: 100;
    stroke-linecap: round;
    stroke-width: 2;
    transition: stroke-dashoffset 1s ease-in-out; }

#footer {
  border: 0;
  padding: 0; }

#footer ul {
  padding: 0; }
  #footer ul li {
    list-style: none; }

#footer .footer-header {
  margin-top: 0;
  font-size: 1.6rem;
  line-height: 1.1;
  font-weight: bold;
  color: #575757;
  display: block; }
  #footer .footer-header.uppercase {
    text-transform: uppercase; }
  @media (max-width: 767.98px) {
    #footer .footer-header {
      margin-top: 3rem; } }

#footer .container-fluid {
  max-width: 1200px;
  padding: 10rem 0;
  background-color: #FFFFFF; }

#footer .padding-information-bar {
  padding-top: 5rem !important; }

#footer.fullwidthFooter .container-fluid {
  max-width: 100%;
  padding: 0 0 4rem 0;
  background-color: #e3e3e3; }

#footer.darkFooter .service-container,
#footer.darkFooter .additional-information,
#footer.darkFooter .social-media-container,
#footer.darkFooter .legal-notice-container,
#footer.darkFooter .social-media-container-mobile,
#footer.darkFooter .carrier-info-container,
#footer.darkFooter .cancel-order-container,
#footer.darkFooter .additional-information #accordion .card-header button {
  color: #FFFFFF; }
  #footer.darkFooter .service-container h3, #footer.darkFooter .service-container h4, #footer.darkFooter .service-container h5, #footer.darkFooter .service-container h6, #footer.darkFooter .service-container a, #footer.darkFooter .service-container .footer-header,
  #footer.darkFooter .additional-information h3,
  #footer.darkFooter .additional-information h4,
  #footer.darkFooter .additional-information h5,
  #footer.darkFooter .additional-information h6,
  #footer.darkFooter .additional-information a,
  #footer.darkFooter .additional-information .footer-header,
  #footer.darkFooter .social-media-container h3,
  #footer.darkFooter .social-media-container h4,
  #footer.darkFooter .social-media-container h5,
  #footer.darkFooter .social-media-container h6,
  #footer.darkFooter .social-media-container a,
  #footer.darkFooter .social-media-container .footer-header,
  #footer.darkFooter .legal-notice-container h3,
  #footer.darkFooter .legal-notice-container h4,
  #footer.darkFooter .legal-notice-container h5,
  #footer.darkFooter .legal-notice-container h6,
  #footer.darkFooter .legal-notice-container a,
  #footer.darkFooter .legal-notice-container .footer-header,
  #footer.darkFooter .social-media-container-mobile h3,
  #footer.darkFooter .social-media-container-mobile h4,
  #footer.darkFooter .social-media-container-mobile h5,
  #footer.darkFooter .social-media-container-mobile h6,
  #footer.darkFooter .social-media-container-mobile a,
  #footer.darkFooter .social-media-container-mobile .footer-header,
  #footer.darkFooter .carrier-info-container h3,
  #footer.darkFooter .carrier-info-container h4,
  #footer.darkFooter .carrier-info-container h5,
  #footer.darkFooter .carrier-info-container h6,
  #footer.darkFooter .carrier-info-container a,
  #footer.darkFooter .carrier-info-container .footer-header,
  #footer.darkFooter .cancel-order-container h3,
  #footer.darkFooter .cancel-order-container h4,
  #footer.darkFooter .cancel-order-container h5,
  #footer.darkFooter .cancel-order-container h6,
  #footer.darkFooter .cancel-order-container a,
  #footer.darkFooter .cancel-order-container .footer-header,
  #footer.darkFooter .additional-information #accordion .card-header button h3,
  #footer.darkFooter .additional-information #accordion .card-header button h4,
  #footer.darkFooter .additional-information #accordion .card-header button h5,
  #footer.darkFooter .additional-information #accordion .card-header button h6,
  #footer.darkFooter .additional-information #accordion .card-header button a,
  #footer.darkFooter .additional-information #accordion .card-header button .footer-header {
    color: #FFFFFF; }

#footer.darkFooter .container-fluid {
  background-color: #575757; }

#footer.fullwidthFooter .service-container,
#footer.fullwidthFooter .additional-information,
#footer.fullwidthFooter .social-media-container,
#footer.fullwidthFooter .legal-notice-container,
#footer.fullwidthFooter .social-media-container-mobile,
#footer.fullwidthFooter .carrier-info-container,
#footer.fullwidthFooter .additional-information #accordion .card-header button,
#footer.fullwidthFooter .information-bar-row {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto; }

#footer.fullwidthFooter .information-bar-full {
  background-color: #575757;
  color: #e3e3e3; }
  #footer.fullwidthFooter .information-bar-full a .text-bold {
    color: #e3e3e3; }
  #footer.fullwidthFooter .information-bar-full .text-left {
    color: #e3e3e3; }

#footer.fullwidthFooter .information-box-footer {
  border-left: 1px solid #aeaeae; }
  #footer.fullwidthFooter .information-box-footer h4, #footer.fullwidthFooter .information-box-footer .footer-header {
    margin-bottom: 1rem; }
  #footer.fullwidthFooter .information-box-footer li a {
    line-height: 1.8; }

#footer .information-bar {
  min-height: 6rem;
  background-color: #e3e3e3; }
  #footer .information-bar .row.information-bar-row {
    min-height: inherit; }
    #footer .information-bar .row.information-bar-row div.information-bar-col {
      min-height: inherit;
      padding-top: 2rem;
      padding-bottom: 2rem; }
      #footer .information-bar .row.information-bar-row div.information-bar-col .ra-icon, #footer .information-bar .row.information-bar-row div.information-bar-col strong {
        vertical-align: middle; }
      #footer .information-bar .row.information-bar-row div.information-bar-col .trustedShopsLogo {
        position: absolute;
        left: 0;
        width: 100%;
        margin-top: -1rem; }
        #footer .information-bar .row.information-bar-row div.information-bar-col .trustedShopsLogo .trustedshops-icon {
          margin-right: -3rem; }
        #footer .information-bar .row.information-bar-row div.information-bar-col .trustedShopsLogo .ra-icon.rating-full, #footer .information-bar .row.information-bar-row div.information-bar-col .trustedShopsLogo .ra-icon.rating-half, #footer .information-bar .row.information-bar-row div.information-bar-col .trustedShopsLogo .ra-icon.rating-empty {
          color: #f59100;
          font-size: 18px; }
      #footer .information-bar .row.information-bar-row div.information-bar-col .webwinkelkeurLogo {
        position: absolute;
        left: 0;
        width: 100%;
        margin-top: -1rem; }
        #footer .information-bar .row.information-bar-row div.information-bar-col .webwinkelkeurLogo img {
          width: 4.5rem;
          height: 4.5rem; }
        #footer .information-bar .row.information-bar-row div.information-bar-col .webwinkelkeurLogo span {
          line-height: 2rem;
          vertical-align: middle; }
        #footer .information-bar .row.information-bar-row div.information-bar-col .webwinkelkeurLogo .ra-icon.rating-full, #footer .information-bar .row.information-bar-row div.information-bar-col .webwinkelkeurLogo .ra-icon.rating-half, #footer .information-bar .row.information-bar-row div.information-bar-col .webwinkelkeurLogo .ra-icon.rating-empty {
          color: #f59100;
          font-size: 1.8rem;
          line-height: 2rem;
          vertical-align: middle; }

#footer .service-container {
  margin-top: 5rem;
  margin-bottom: 5rem; }
  #footer .service-container p {
    padding-top: 1rem; }
  #footer .service-container .input-group {
    padding: 1rem 0; }
  @media (max-width: 767.98px) {
    #footer .service-container {
      margin-bottom: 3rem;
      margin-top: 1rem; } }

#footer .additional-information {
  margin: 5rem 0; }
  @media (max-width: 767.98px) {
    #footer .additional-information {
      margin-bottom: 3rem;
      margin: 0; } }
  #footer .additional-information #accordion h4, #footer .additional-information #accordion .footer-header {
    display: none; }
  #footer .additional-information #accordion .card {
    background-color: transparent;
    border: none; }
  #footer .additional-information #accordion .card-header {
    background-color: transparent; }
    #footer .additional-information #accordion .card-header button {
      width: 100%;
      height: 100%;
      color: #575757;
      line-height: 2.4rem;
      padding: .5rem 1rem; }
      #footer .additional-information #accordion .card-header button i {
        float: right;
        font-size: 2.4rem; }
      #footer .additional-information #accordion .card-header button span {
        float: left;
        font-weight: bold;
        font-size: 1.6rem; }
      #footer .additional-information #accordion .card-header button i.minus {
        display: block; }
      #footer .additional-information #accordion .card-header button i.plus {
        display: none; }
    #footer .additional-information #accordion .card-header button.collapsed {
      border-bottom: 1px solid #aeaeae; }
      #footer .additional-information #accordion .card-header button.collapsed i.minus {
        display: none; }
      #footer .additional-information #accordion .card-header button.collapsed i.plus {
        display: block; }
  #footer .additional-information #accordion .card-body {
    border-bottom: 1px solid #aeaeae; }

#footer .social-media-container {
  margin-top: 5rem; }
  @media (max-width: 575.98px) {
    #footer .social-media-container .ra-icon {
      font-size: 4.8rem;
      width: 4.8rem;
      height: 4.8rem; } }
  #footer .social-media-container .ra-icon:hover {
    transform: scale(1.3); }

#footer .social-media-container-mobile h4 {
  display: none; }

#footer .social-media-container-mobile i, #footer .social-media-container-mobile a {
  display: inline;
  font-size: 4rem; }

@media (max-width: 767.98px) {
  #footer .search-container {
    background-color: #e3e3e3;
    height: 8rem;
    margin: 0;
    margin-top: 4rem;
    text-align: center;
    display: flex; }
    #footer .search-container .row {
      width: 100%; } }

#footer .search-container .form.search {
  margin: 5rem 0; }
  @media (max-width: 767.98px) {
    #footer .search-container .form.search {
      margin: 0; } }
  #footer .search-container .form.search #searchParam-footer {
    border: 2px solid #00569d;
    border-right: none;
    box-shadow: none;
    height: 4rem;
    transition: none !important; }
  #footer .search-container .form.search .btn {
    display: flex;
    align-items: center;
    height: 4rem; }

#footer .legal-notice-container, #footer .checkout-footer-info {
  font-size: 1.2rem;
  margin-bottom: 5rem;
  padding: 0 1rem; }

@media (max-width: 767.98px) {
  #footer .card-body {
    text-align: left; }
  #footer table {
    margin: 0 0 0 0; }
  #footer li {
    border-bottom: 1px solid #e3e3e3;
    padding: 0 1rem;
    margin: 0 1rem; }
    #footer li a {
      display: block;
      width: 100%;
      line-height: 4rem; }
  #footer li:last-child {
    border: none; } }

#footer .newsletter-widget input {
  height: 4rem; }

#footer .mobileContactBox {
  display: block;
  color: #747474;
  text-align: center;
  padding: 2rem 0;
  margin-bottom: 5rem;
  margin-bottom: 1rem; }
  #footer .mobileContactBox .btn {
    margin: auto auto;
    color: #575757;
    font-size: 1.2rem;
    padding: .5rem 0;
    white-space: normal;
    width: 100%;
    transition: all .2s; }
    #footer .mobileContactBox .btn:active, #footer .mobileContactBox .btn:focus {
      box-shadow: none; }
    #footer .mobileContactBox .btn .ra-icon {
      font-size: 4rem;
      display: block; }
    #footer .mobileContactBox .btn:hover {
      color: #00569d; }

@media (min-width: 992px) {
  #footer .seasonContainer {
    margin-top: 5rem; } }

#footer .seasonContainer div {
  background: transparent;
  background-repeat: repeat-x;
  background-position: center bottom; }
  #footer .seasonContainer div img {
    visibility: hidden; }
    #footer .seasonContainer div img.mobileFooter {
      visibility: visible;
      margin-top: 5rem; }

.contact-box {
  position: fixed;
  z-index: 1039;
  left: -999px;
  top: 40%;
  transform: translate(0, -50%);
  width: auto;
  max-width: 9rem;
  background-color: #f6f6f6;
  border-top: 1px solid #aeaeae;
  border-right: 1px solid #aeaeae;
  border-bottom: 1px solid #aeaeae;
  transition: all .5s;
  padding-bottom: 1px; }
  .contact-box .contact-box-inner {
    display: block;
    color: #747474;
    text-align: center;
    padding: 1rem 1rem; }
    .contact-box .contact-box-inner .btn {
      color: #575757;
      font-size: 1.2rem;
      padding: .5rem 0;
      white-space: normal;
      width: 100%;
      background-color: transparent;
      transition: all .2s;
      border-bottom: 1px solid #aeaeae; }
      .contact-box .contact-box-inner .btn:active, .contact-box .contact-box-inner .btn:focus {
        box-shadow: none; }
      .contact-box .contact-box-inner .btn:last-child {
        border: none; }
      .contact-box .contact-box-inner .btn .ra-icon {
        font-size: 4rem;
        display: block; }
      .contact-box .contact-box-inner .btn:hover {
        color: #00569d; }
  .contact-box .close-btn {
    position: absolute;
    right: -2rem;
    width: 2rem;
    height: 3rem;
    text-align: center;
    top: 50%;
    margin-top: -1.5rem;
    padding: 2px 0 0 0;
    background-color: #575757;
    color: #FFFFFF; }
    .contact-box .close-btn:focus {
      outline: none; }
  .contact-box .information-box {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 100%;
    padding: 0;
    background-color: #FFFFFF;
    color: #747474;
    min-height: 27.6rem;
    z-index: 999;
    display: none;
    overflow-x: hidden;
    overflow-y: auto; }
    @media (min-width: 992px) {
      .contact-box .information-box {
        bottom: 0; } }
    .contact-box .information-box .information-box-top-bar {
      height: 2.7rem;
      width: 100%;
      background-color: #e3e3e3;
      text-align: right;
      padding-right: 2px;
      padding-top: 3px; }
      .contact-box .information-box .information-box-top-bar .ra-icon.cross {
        cursor: pointer;
        font-size: 2rem; }
    .contact-box .information-box .information-box-inner {
      padding: 1rem;
      display: none; }
      .contact-box .information-box .information-box-inner h2 {
        margin: 0;
        font-size: 1.5rem; }
      .contact-box .information-box .information-box-inner p {
        padding: 1rem 0;
        font-weight: bold;
        margin: 0; }
      .contact-box .information-box .information-box-inner table {
        width: 100%; }
      .contact-box .information-box .information-box-inner.show {
        display: block; }
    .contact-box .information-box.show {
      min-width: 25rem;
      border: 1px solid #aeaeae;
      display: block; }
  .contact-box.show {
    left: 0 !important; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .contact-box {
      top: auto;
      bottom: .5rem;
      transform: translate(0, 0);
      height: 10rem;
      max-width: none; }
      .contact-box .contact-box-header {
        display: none; }
      .contact-box .close-btn {
        right: -3rem;
        width: 3rem; }
      .contact-box .contact-box-inner .btn {
        display: inline-block;
        width: auto;
        border-bottom: none;
        border-right: 1px solid #aeaeae;
        padding: 0 1rem;
        margin-left: -.5rem;
        height: 7.5rem; }
        .contact-box .contact-box-inner .btn:last-child {
          margin-left: 0; }
      .contact-box .information-box {
        left: -100%;
        right: auto;
        top: 0;
        min-height: unset;
        transform: translate(0%, -100%); }
        .contact-box .information-box .information-box-top-bar {
          display: none; }
        .contact-box .information-box .information-box-inner {
          min-width: 25.6rem; } }

.contact-box-header {
  margin: 0;
  background-color: #00569d;
  color: #FFFFFF;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem .7rem;
  line-height: 1.3rem; }

.contact-box-fade-in-btn {
  position: fixed;
  z-index: 1039;
  left: -100%;
  top: 50%;
  transform: translate(-30%, -50%) rotate(-90deg);
  background-color: #00569d;
  color: #FFFFFF;
  transition: all .5s; }
  .contact-box-fade-in-btn.show {
    left: 0 !important; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .contact-box-fade-in-btn {
      top: auto;
      bottom: 0;
      transform: translate(-30%, -80%) rotate(-90deg); } }

@media (max-width: 575.98px) {
  .contact-box-background.show {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1038;
    width: 100%;
    height: 100%;
    background-color: #575757;
    opacity: 0.5; } }

.privatesales-contact-box {
  font-size: 1.2rem; }
  @media (max-width: 767.98px) {
    .privatesales-contact-box {
      margin-top: 2rem;
      margin-bottom: 2rem; } }
  .privatesales-contact-box p {
    margin-bottom: 0; }

.extra-box-header {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem .7rem;
  line-height: 1.3rem; }

.extra-box-fade-in-btn {
  position: fixed;
  z-index: 999;
  left: -999px;
  transform: translate(-30%, -50%) rotate(-90deg);
  transition: all .5s;
  border: 1px solid #aeaeae; }
  .extra-box-fade-in-btn.show {
    left: 0 !important; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .extra-box-fade-in-btn {
      top: auto;
      transform: translate(-30%, -80%) rotate(-90deg); } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .mobileextraBox {
    text-align: center; } }

@media (max-width: 575.98px) {
  .extra-box-background.show {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1039;
    width: 100%;
    height: 100%;
    background-color: #575757;
    opacity: 0.5; } }

.extra-box {
  position: fixed;
  z-index: 1039;
  left: -999px;
  top: 65%;
  transform: translate(0, -50%);
  width: auto;
  max-width: 12rem;
  padding-bottom: 1px;
  transition: all .5s; }
  @media (min-width: 1200px) {
    .extra-box {
      top: 60%; } }
  .extra-box .information-box {
    background-color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 100%;
    min-height: 27.6rem;
    z-index: 1039;
    transition: all .5s; }
    .extra-box .information-box .information-box-inner {
      padding: 1rem; }
    .extra-box .information-box .information-box-top-bar {
      font-weight: bold;
      height: 2.7rem;
      width: 100%;
      text-align: right;
      padding-right: 2px;
      padding-top: 3px;
      border-bottom: 1px solid #aeaeae; }
      .extra-box .information-box .information-box-top-bar .ra-icon.cross {
        cursor: pointer;
        font-size: 2rem; }
    .extra-box .information-box.show {
      min-width: 34rem;
      border: 1px solid #aeaeae;
      display: block; }
  .extra-box.show {
    left: 0 !important; }

.c-white {
  background-color: #FFFFFF;
  color: #333333; }

.c-black {
  background-color: #000000;
  color: #FFFFFF; }

.c-special {
  background-color: #dc371c;
  color: #FFFFFF; }

.c-highlight {
  background-color: #00569d;
  color: #FFFFFF; }

.c-confirm {
  background-color: #308136;
  color: #FFFFFF; }

.c-lightgrey {
  background-color: #e3e3e3;
  color: #000000; }

.c-darkgrey {
  background-color: #575757;
  color: #FFFFFF; }

#directorder-widget {
  flex-shrink: 0;
  margin-left: 2rem; }
  #directorder-widget li {
    margin-right: 0 !important;
    width: 100%; }
  #directorder-widget .directorder-widget-button {
    background: none;
    border: none;
    height: 2rem;
    line-height: 2rem;
    padding-left: 0; }
    #directorder-widget .directorder-widget-button i {
      font-size: 2rem;
      color: #575757;
      vertical-align: middle; }
  #directorder-widget .directorder-widget-button:disabled i {
    color: #e3e3e3; }
  #directorder-widget .directorder-widget-artnum {
    height: 2rem;
    min-width: 10rem;
    font-size: 1.2rem;
    text-align: center;
    font-style: italic;
    color: #747474;
    border-radius: 0;
    border: 1px solid #aeaeae; }
  #directorder-widget .directorder-widget-amount {
    font-size: 1.2rem;
    height: 2rem;
    min-width: 2rem;
    text-align: center;
    font-style: italic;
    color: #747474;
    border-radius: 0;
    border: 1px solid #aeaeae; }
  #directorder-widget .directorder-label {
    font-size: 1.2rem;
    margin-right: .5rem; }

#directorder-header-button {
  line-height: 2.4rem;
  flex-shrink: 0; }
  #directorder-header-button li {
    margin-right: 0 !important;
    padding: .5rem 0 !important; }
  #directorder-header-button a {
    margin-right: 1rem; }
  @media (max-width: 991.98px) {
    #directorder-header-button a {
      margin-right: 3rem; } }
  #directorder-header-button i {
    vertical-align: middle; }
  #directorder-header-button span {
    font-size: 1.2rem;
    font-weight: bold;
    vertical-align: middle; }

.cms-elements > .row, .cms-elements > .categoryDescription > .row {
  margin-top: 2rem; }
  @media (min-width: 768px) {
    .cms-elements > .row > .no-outer-padding:first-child, .cms-elements > .categoryDescription > .row > .no-outer-padding:first-child {
      padding-left: 0; }
    .cms-elements > .row > .no-outer-padding:last-child, .cms-elements > .categoryDescription > .row > .no-outer-padding:last-child {
      padding-right: 0; } }
  @media (max-width: 991.98px) {
    .cms-elements > .row > div, .cms-elements > .categoryDescription > .row > div {
      padding-left: 5px;
      padding-right: 5px;
      margin-top: 1rem; } }

.cms-elements .hasBgImage.bgFixed {
  background-attachment: fixed; }
  @supports (-webkit-overflow-scrolling: touch) {
    .cms-elements .hasBgImage.bgFixed {
      background-attachment: unset; } }

.cms-elements .hasBgImage .box-text {
  text-shadow: #575757 2px 2px 5px; }
  .cms-elements .hasBgImage .box-text h2 {
    font-size: 2.8rem; }
  .cms-elements .hasBgImage .box-text h3 {
    font-size: 2rem; }
  .cms-elements .hasBgImage .box-text .btn {
    text-shadow: none; }

.cms-elements .box-text.dark, .cms-elements .box-text.highlight, .cms-elements .box-text.light, .cms-elements .box-text.darkgrey, .cms-elements .box-text.lightgrey {
  padding: 1rem !important; }

.cms-elements .dark .box-text, .cms-elements .darkgrey .box-text {
  padding: 1rem !important; }

.cms-elements .preload-img {
  width: 0;
  height: 0; }

.cms-elements .row.full-height {
  min-height: 101vh;
  align-items: center;
  justify-content: center; }
  .cms-elements .row.full-height.parallaxBg {
    padding-top: 50%;
    padding-bottom: 50%;
    background-attachment: fixed; }
    @supports (-webkit-overflow-scrolling: touch) {
      .cms-elements .row.full-height.parallaxBg {
        background-attachment: unset; } }
    .cms-elements .row.full-height.parallaxBg.bgFixed {
      background-attachment: fixed; }
      @supports (-webkit-overflow-scrolling: touch) {
        .cms-elements .row.full-height.parallaxBg.bgFixed {
          background-attachment: unset; } }

.cms-elements .box1 .box-inner {
  position: relative;
  background-size: cover;
  overflow: hidden;
  display: flex;
  align-items: flex-start; }
  .cms-elements .box1 .box-inner img {
    display: flex; }
  .cms-elements .box1 .box-inner.bg-left {
    justify-content: flex-start;
    height: 100%; }
  .cms-elements .box1 .box-inner.bg-center {
    justify-content: center;
    height: 100%; }
  .cms-elements .box1 .box-inner.bg-right {
    justify-content: flex-end;
    height: 100%; }

.cms-elements .box1 .box-inner-text {
  position: absolute;
  bottom: 20%; }
  .cms-elements .box1 .box-inner-text.text-left {
    left: 0; }
    .cms-elements .box1 .box-inner-text.text-left > h1, .cms-elements .box1 .box-inner-text.text-left > h2, .cms-elements .box1 .box-inner-text.text-left > h3, .cms-elements .box1 .box-inner-text.text-left > h4, .cms-elements .box1 .box-inner-text.text-left > h5, .cms-elements .box1 .box-inner-text.text-left > h6 {
      float: left;
      clear: left; }
  .cms-elements .box1 .box-inner-text.text-center {
    text-align: center;
    width: 100%; }
    .cms-elements .box1 .box-inner-text.text-center > h1, .cms-elements .box1 .box-inner-text.text-center > h2, .cms-elements .box1 .box-inner-text.text-center > h3, .cms-elements .box1 .box-inner-text.text-center > h4, .cms-elements .box1 .box-inner-text.text-center > h5, .cms-elements .box1 .box-inner-text.text-center > h6 {
      display: inline-block; }
  .cms-elements .box1 .box-inner-text.text-right {
    right: 0; }
    .cms-elements .box1 .box-inner-text.text-right > h1, .cms-elements .box1 .box-inner-text.text-right > h2, .cms-elements .box1 .box-inner-text.text-right > h3, .cms-elements .box1 .box-inner-text.text-right > h4, .cms-elements .box1 .box-inner-text.text-right > h5, .cms-elements .box1 .box-inner-text.text-right > h6 {
      float: right;
      clear: right; }
  .cms-elements .box1 .box-inner-text h1, .cms-elements .box1 .box-inner-text h2, .cms-elements .box1 .box-inner-text h3, .cms-elements .box1 .box-inner-text h4, .cms-elements .box1 .box-inner-text h5, .cms-elements .box1 .box-inner-text h6 {
    display: block;
    color: #FFFFFF;
    padding: .5rem 1rem;
    line-height: 1.3; }
    @media (max-width: 767.98px) {
      .cms-elements .box1 .box-inner-text h1, .cms-elements .box1 .box-inner-text h2, .cms-elements .box1 .box-inner-text h3, .cms-elements .box1 .box-inner-text h4, .cms-elements .box1 .box-inner-text h5, .cms-elements .box1 .box-inner-text h6 {
        line-height: 1.5; } }
  .cms-elements .box1 .box-inner-text h1, .cms-elements .box1 .box-inner-text h2, .cms-elements .box1 .box-inner-text h3, .cms-elements .box1 .box-inner-text h4, .cms-elements .box1 .box-inner-text h5, .cms-elements .box1 .box-inner-text h6 {
    background-color: rgba(0, 86, 157, 0.85);
    font-weight: bold; }
  .cms-elements .box1 .box-inner-text h2 {
    font-size: 1.866rem; }
  .cms-elements .box1 .box-inner-text h3.text {
    background-color: rgba(87, 87, 87, 0.85);
    font-weight: normal; }
  .cms-elements .box1 .box-inner-text .background-highlight {
    background-color: #00569d;
    color: #FFFFFF; }
  .cms-elements .box1 .box-inner-text .background-darkgrey {
    background-color: #575757;
    color: #FFFFFF; }
  .cms-elements .box1 .box-inner-text .background-lightgrey {
    background-color: #e3e3e3;
    color: #575757; }
  .cms-elements .box1 .box-inner-text .background-white {
    background-color: #FFFFFF;
    color: #575757; }

.cms-elements .box1 .box-link {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.cms-elements .box1 .bgi-top-left {
  top: 0;
  left: 0;
  position: absolute; }

.cms-elements .box1 .bgi-top-center {
  top: 0;
  transform: translateX(-50%);
  left: 50%;
  position: absolute; }

.cms-elements .box1 .bgi-top-right {
  top: 0;
  right: 0;
  position: absolute; }

.cms-elements .box1 .bgi-center-left {
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  position: absolute; }

.cms-elements .box1 .bgi-center {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute; }

.cms-elements .box1 .bgi-center-right {
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  position: absolute; }

.cms-elements .box1 .bgi-bottom-left {
  bottom: 0;
  left: 0;
  position: absolute; }

.cms-elements .box1 .bgi-bottom-center {
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
  position: absolute; }

.cms-elements .box1 .bgi-bottom-right {
  bottom: 0;
  right: 0;
  position: absolute; }

.cms-elements .box2 {
  text-align: center;
  padding-bottom: 1.5rem; }

.cms-elements .box2 .box-inner-img {
  background-size: cover;
  display: flex;
  align-items: flex-start;
  overflow: hidden; }
  .cms-elements .box2 .box-inner-img img {
    display: flex; }
  .cms-elements .box2 .box-inner-img.bg-left {
    justify-content: flex-start;
    height: 100%; }
  .cms-elements .box2 .box-inner-img.bg-center {
    justify-content: center;
    height: 100%; }
  .cms-elements .box2 .box-inner-img.bg-right {
    justify-content: flex-end;
    height: 100%; }

.cms-elements .box2 .box-inner-text h1, .cms-elements .box2 .box-inner-text h2, .cms-elements .box2 .box-inner-text h3, .cms-elements .box2 .box-inner-text h4, .cms-elements .box2 .box-inner-text h5, .cms-elements .box2 .box-inner-text h6, .cms-elements .box2 .box-inner-text p {
  line-height: 1.5;
  padding: 0; }

.cms-elements .box2 .box-inner-text h1, .cms-elements .box2 .box-inner-text h2, .cms-elements .box2 .box-inner-text h3, .cms-elements .box2 .box-inner-text h4, .cms-elements .box2 .box-inner-text h5, .cms-elements .box2 .box-inner-text h6 {
  font-weight: bold;
  padding: 1rem 0; }

.cms-elements .box2 .box-inner-text h2 {
  font-size: 1.866rem; }

.cms-elements .box2 .box-inner-text p {
  font-weight: normal;
  padding: .5rem 0; }

.cms-elements .box2 .box-inner-text .background-highlight {
  background-color: #00569d;
  color: #FFFFFF; }

.cms-elements .box2 .box-inner-text .background-darkgrey {
  background-color: #575757;
  color: #FFFFFF; }

.cms-elements .box2 .box-inner-text .background-lightgrey {
  background-color: #e3e3e3; }

.cms-elements .box2 .box-inner-text .background-white {
  background-color: #FFFFFF; }

.cms-elements .box2 .box-link {
  color: #00569d;
  font-weight: bold; }

.cms-elements .box2 .x24, .cms-elements .box2 .x48 {
  vertical-align: middle; }

.cms-elements .box2 .bgi-top-left {
  top: 0;
  left: 0;
  position: absolute; }

.cms-elements .box2 .bgi-top-center {
  top: 0;
  transform: translateX(-50%);
  left: 50%;
  position: absolute; }

.cms-elements .box2 .bgi-top-right {
  top: 0;
  right: 0;
  position: absolute; }

.cms-elements .box2 .bgi-center-left {
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  position: absolute; }

.cms-elements .box2 .bgi-center {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute; }

.cms-elements .box2 .bgi-center-right {
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  position: absolute; }

.cms-elements .box2 .bgi-bottom-left {
  bottom: 0;
  left: 0;
  position: absolute; }

.cms-elements .box2 .bgi-bottom-center {
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
  position: absolute; }

.cms-elements .box2 .bgi-bottom-right {
  bottom: 0;
  right: 0;
  position: absolute; }

@media (max-width: 991.98px) {
  .cms-elements .box3 {
    margin-top: 0 !important; } }

.cms-elements .box3 .box-inner {
  position: relative;
  height: auto;
  overflow: hidden; }
  .cms-elements .box3 .box-inner.orientation-left .box-inner-text {
    left: 0; }
    @media (min-width: 576px) {
      .cms-elements .box3 .box-inner.orientation-left .box-inner-text {
        margin-left: 1.5rem; } }
    .cms-elements .box3 .box-inner.orientation-left .box-inner-text div {
      margin: 1.5rem 0; }
  @media (min-width: 576px) {
    .cms-elements .box3 .box-inner.orientation-right .box-inner-text {
      margin-left: 36.25%;
      margin-right: 1.5rem; } }
  .cms-elements .box3 .box-inner.orientation-right .box-inner-text div {
    margin: 1.5rem 0; }
  @media (min-width: 576px) {
    .cms-elements .box3 .box-inner.orientation-vertical-top .box-inner-text {
      top: 0;
      position: absolute; } }
  @media (min-width: 576px) {
    .cms-elements .box3 .box-inner.orientation-vertical-middle .box-inner-text {
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      margin-top: 0;
      max-height: 100%; } }
  @media (min-width: 576px) {
    .cms-elements .box3 .box-inner.orientation-vertical-bottom .box-inner-text {
      bottom: 0;
      position: absolute; } }

.cms-elements .box3 .box-inner-img {
  display: flex;
  align-items: flex-start;
  overflow: hidden; }
  @media (min-width: 576px) {
    .cms-elements .box3 .box-inner-img {
      height: 100%; }
      .cms-elements .box3 .box-inner-img.bg-left {
        position: absolute;
        right: 50%; }
      .cms-elements .box3 .box-inner-img.bg-right {
        position: absolute;
        left: 50%; }
      .cms-elements .box3 .box-inner-img.bg-center {
        position: absolute;
        right: 0;
        left: 0;
        width: unset; } }
  .cms-elements .box3 .box-inner-img img {
    display: flex; }
  .cms-elements .box3 .box-inner-img.bg-left {
    justify-content: flex-start; }
  .cms-elements .box3 .box-inner-img.bg-center {
    justify-content: center; }
  .cms-elements .box3 .box-inner-img.bg-right {
    justify-content: flex-end; }

.cms-elements .box3 .box-inner-text {
  background-color: #FFFFFF; }
  @media (min-width: 576px) {
    .cms-elements .box3 .box-inner-text {
      position: relative;
      width: 62.5%;
      padding: 1rem 2rem;
      align-self: center;
      margin: 1.5rem; } }
  @media (max-width: 767.98px) {
    .cms-elements .box3 .box-inner-text {
      margin: 0;
      padding-bottom: 1.5rem; } }
  .cms-elements .box3 .box-inner-text h1, .cms-elements .box3 .box-inner-text h2, .cms-elements .box3 .box-inner-text h3, .cms-elements .box3 .box-inner-text h4, .cms-elements .box3 .box-inner-text h5, .cms-elements .box3 .box-inner-text h6, .cms-elements .box3 .box-inner-text p {
    line-height: 1.5;
    padding: 0; }
  .cms-elements .box3 .box-inner-text h1, .cms-elements .box3 .box-inner-text h2, .cms-elements .box3 .box-inner-text h3, .cms-elements .box3 .box-inner-text h4, .cms-elements .box3 .box-inner-text h5, .cms-elements .box3 .box-inner-text h6 {
    font-weight: bold;
    padding-top: 1rem;
    text-align: center; }
  .cms-elements .box3 .box-inner-text h2 {
    font-size: 1.866rem; }
  .cms-elements .box3 .box-inner-text p {
    font-weight: normal;
    padding: .5rem 0;
    text-align: justify; }
  .cms-elements .box3 .box-inner-text .background-highlight {
    background-color: #00569d;
    color: #FFFFFF; }
  .cms-elements .box3 .box-inner-text .background-darkgrey {
    background-color: #575757;
    color: #FFFFFF; }
  .cms-elements .box3 .box-inner-text .background-lightgrey {
    background-color: #e3e3e3; }
  .cms-elements .box3 .box-inner-text .background-white {
    background-color: #FFFFFF; }

@media (min-width: 576px) {
  .cms-elements .box3 .bgi-top-left {
    top: 0;
    left: 0;
    position: absolute; }
  .cms-elements .box3 .bgi-top-center {
    top: 0;
    transform: translateX(-50%);
    left: 50%;
    position: absolute; }
  .cms-elements .box3 .bgi-top-right {
    top: 0;
    right: 0;
    position: absolute; }
  .cms-elements .box3 .bgi-center-left {
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    position: absolute; }
  .cms-elements .box3 .bgi-center {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute; }
  .cms-elements .box3 .bgi-center-right {
    transform: translateY(-50%);
    top: 50%;
    right: 0;
    position: absolute; }
  .cms-elements .box3 .bgi-bottom-left {
    bottom: 0;
    left: 0;
    position: absolute; }
  .cms-elements .box3 .bgi-bottom-center {
    bottom: 0;
    transform: translateX(-50%);
    left: 50%;
    position: absolute; }
  .cms-elements .box3 .bgi-bottom-right {
    bottom: 0;
    right: 0;
    position: absolute; } }

.cms-elements .box4 .box-inner {
  position: relative;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  justify-content: center; }
  .cms-elements .box4 .box-inner img {
    display: flex; }

.cms-elements .box4 .box-inner-text {
  position: absolute;
  bottom: 0;
  left: 3rem;
  right: 3rem;
  padding: 2rem 0;
  text-align: center;
  background-color: rgba(87, 87, 87, 0.85); }
  .cms-elements .box4 .box-inner-text h1, .cms-elements .box4 .box-inner-text h2, .cms-elements .box4 .box-inner-text h3, .cms-elements .box4 .box-inner-text h4, .cms-elements .box4 .box-inner-text h5, .cms-elements .box4 .box-inner-text h6, .cms-elements .box4 .box-inner-text p {
    display: block;
    color: #FFFFFF;
    line-height: 1.5;
    padding: 0; }
  .cms-elements .box4 .box-inner-text h1, .cms-elements .box4 .box-inner-text h2, .cms-elements .box4 .box-inner-text h3, .cms-elements .box4 .box-inner-text h4, .cms-elements .box4 .box-inner-text h5, .cms-elements .box4 .box-inner-text h6 {
    font-weight: bold;
    padding-bottom: 1rem; }
  .cms-elements .box4 .box-inner-text h2 {
    font-size: 1.866rem; }
  .cms-elements .box4 .box-inner-text p {
    font-weight: normal;
    padding: 0 1rem;
    padding-bottom: 1rem; }
  .cms-elements .box4 .box-inner-text .btn {
    font-weight: bold; }
    .cms-elements .box4 .box-inner-text .btn .x24, .cms-elements .box4 .box-inner-text .btn .x48 {
      vertical-align: middle; }

.cms-elements .box4 .bgi-top-left {
  top: 0;
  left: 0;
  position: absolute; }

.cms-elements .box4 .bgi-top-center {
  top: 0;
  transform: translateX(-50%);
  left: 50%;
  position: absolute; }

.cms-elements .box4 .bgi-top-right {
  top: 0;
  right: 0;
  position: absolute; }

.cms-elements .box4 .bgi-center-left {
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  position: absolute; }

.cms-elements .box4 .bgi-center {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute; }

.cms-elements .box4 .bgi-center-right {
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  position: absolute; }

.cms-elements .box4 .bgi-bottom-left {
  bottom: 0;
  left: 0;
  position: absolute; }

.cms-elements .box4 .bgi-bottom-center {
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
  position: absolute; }

.cms-elements .box4 .bgi-bottom-right {
  bottom: 0;
  right: 0;
  position: absolute; }

@media (max-width: 991.98px) {
  .cms-elements .box5 .hasBgImage {
    background-image: none; } }

.cms-elements .box5 .outer-row {
  background-size: cover;
  background-repeat: no-repeat; }
  .cms-elements .box5 .outer-row .bg-left {
    background-position: left; }
  .cms-elements .box5 .outer-row .bg-center {
    background-position: center; }
  .cms-elements .box5 .outer-row .bg-right {
    background-position: right; }
  .cms-elements .box5 .outer-row.transparent .img-box {
    background-color: transparent; }

.cms-elements .box5.light .text-box, .cms-elements .box5.lightgrey .text-box {
  background-color: #e3e3e3;
  color: #575757; }
  .cms-elements .box5.light .text-box a:not(.btn), .cms-elements .box5.lightgrey .text-box a:not(.btn) {
    color: #575757; }
  .cms-elements .box5.light .text-box a:hover, .cms-elements .box5.lightgrey .text-box a:hover {
    text-decoration: underline; }
  .cms-elements .box5.light .text-box a .x24, .cms-elements .box5.light .text-box a .x48, .cms-elements .box5.lightgrey .text-box a .x24, .cms-elements .box5.lightgrey .text-box a .x48 {
    vertical-align: middle; }
  .cms-elements .box5.light .text-box h1, .cms-elements .box5.light .text-box h2, .cms-elements .box5.light .text-box h3, .cms-elements .box5.light .text-box h4, .cms-elements .box5.light .text-box h5, .cms-elements .box5.light .text-box h6, .cms-elements .box5.light .text-box p, .cms-elements .box5.light .text-box li, .cms-elements .box5.lightgrey .text-box h1, .cms-elements .box5.lightgrey .text-box h2, .cms-elements .box5.lightgrey .text-box h3, .cms-elements .box5.lightgrey .text-box h4, .cms-elements .box5.lightgrey .text-box h5, .cms-elements .box5.lightgrey .text-box h6, .cms-elements .box5.lightgrey .text-box p, .cms-elements .box5.lightgrey .text-box li {
    color: #575757; }
  .cms-elements .box5.light .text-box.transparent, .cms-elements .box5.lightgrey .text-box.transparent {
    background-color: rgba(227, 227, 227, 0.85); }

.cms-elements .box5.light .img-box, .cms-elements .box5.lightgrey .img-box {
  background-color: #e3e3e3; }
  .cms-elements .box5.light .img-box.transparent, .cms-elements .box5.lightgrey .img-box.transparent {
    background-color: transparent; }

.cms-elements .box5.dark, .cms-elements .box5.darkgrey {
  background-color: transparent; }
  .cms-elements .box5.dark .text-box, .cms-elements .box5.darkgrey .text-box {
    background-color: #575757;
    color: #FFFFFF; }
    .cms-elements .box5.dark .text-box a:not(.btn), .cms-elements .box5.darkgrey .text-box a:not(.btn) {
      color: #FFFFFF; }
    .cms-elements .box5.dark .text-box a:hover, .cms-elements .box5.darkgrey .text-box a:hover {
      text-decoration: underline; }
    .cms-elements .box5.dark .text-box a .x24, .cms-elements .box5.dark .text-box a .x48, .cms-elements .box5.darkgrey .text-box a .x24, .cms-elements .box5.darkgrey .text-box a .x48 {
      vertical-align: middle; }
    .cms-elements .box5.dark .text-box.transparent, .cms-elements .box5.darkgrey .text-box.transparent {
      background-color: rgba(87, 87, 87, 0.85); }
  .cms-elements .box5.dark .img-box, .cms-elements .box5.darkgrey .img-box {
    background-color: #575757; }
    .cms-elements .box5.dark .img-box.transparent, .cms-elements .box5.darkgrey .img-box.transparent {
      background-color: transparent; }

.cms-elements .box5 .zoom-effect .img-box {
  transition: transform 1s ease-in-out; }

.cms-elements .box5 .zoom-effect:hover .img-box {
  transform: scale(1.1); }

.cms-elements .box5 .img-box.zoom-effect {
  transition: transform 1s ease-in-out; }
  .cms-elements .box5 .img-box.zoom-effect:hover {
    transform: scale(1.1); }

.cms-elements .box5.highlight .text-box {
  background-color: #00569d;
  color: #FFFFFF; }
  .cms-elements .box5.highlight .text-box a:not(.btn) {
    color: #FFFFFF; }
  .cms-elements .box5.highlight .text-box a:hover {
    text-decoration: underline; }
  .cms-elements .box5.highlight .text-box a .x24, .cms-elements .box5.highlight .text-box a .x48 {
    vertical-align: middle; }
  .cms-elements .box5.highlight .text-box.transparent {
    background-color: rgba(0, 86, 157, 0.85); }

.cms-elements .box5.highlight .img-box {
  background-color: #00569d; }
  .cms-elements .box5.highlight .img-box.transparent {
    background-color: transparent; }

.cms-elements .box5.white .text-box {
  background-color: #FFFFFF;
  color: #575757; }
  .cms-elements .box5.white .text-box a:not(.btn) {
    color: #00569d; }
  .cms-elements .box5.white .text-box a:hover {
    text-decoration: underline; }
  .cms-elements .box5.white .text-box a .x24, .cms-elements .box5.white .text-box a .x48 {
    vertical-align: middle; }
  .cms-elements .box5.white .text-box a.highlight .t-highlight {
    color: #00569d; }
  .cms-elements .box5.white .text-box h1, .cms-elements .box5.white .text-box h2, .cms-elements .box5.white .text-box h3, .cms-elements .box5.white .text-box h4, .cms-elements .box5.white .text-box h5, .cms-elements .box5.white .text-box h6, .cms-elements .box5.white .text-box p, .cms-elements .box5.white .text-box li {
    color: #575757; }
  .cms-elements .box5.white .text-box.transparent {
    background-color: rgba(227, 227, 227, 0.85); }

.cms-elements .box5.white .img-box {
  background-color: #333333; }
  .cms-elements .box5.white .img-box.transparent {
    background-color: transparent; }

.cms-elements .box5 .text-box {
  display: flex;
  background-color: #575757;
  color: #FFFFFF;
  padding: 2rem 0;
  order: 1; }
  .cms-elements .box5 .text-box a:not(.btn) {
    color: #FFFFFF; }
  .cms-elements .box5 .text-box a:hover {
    text-decoration: underline; }
  .cms-elements .box5 .text-box a .x24, .cms-elements .box5 .text-box a .x48 {
    vertical-align: middle; }
  .cms-elements .box5 .text-box h1, .cms-elements .box5 .text-box h2, .cms-elements .box5 .text-box h3, .cms-elements .box5 .text-box h4, .cms-elements .box5 .text-box h5, .cms-elements .box5 .text-box h6, .cms-elements .box5 .text-box p {
    color: #FFFFFF;
    padding-bottom: 1rem; }
  .cms-elements .box5 .text-box h2 {
    font-size: 1.866rem; }
  .cms-elements .box5 .text-box ul {
    text-align: left; }
  .cms-elements .box5 .text-box .btn {
    font-weight: bold; }
    .cms-elements .box5 .text-box .btn:hover {
      text-decoration: none !important; }
  .cms-elements .box5 .text-box .text-box-inner {
    align-self: center;
    width: 100%;
    padding: 0 4rem; }
    .cms-elements .box5 .text-box .text-box-inner .btn-highlight {
      background-color: #00569d; }
  .cms-elements .box5 .text-box.transparent {
    background-color: rgba(87, 87, 87, 0.85); }
  @media (max-width: 991.98px) {
    .cms-elements .box5 .text-box {
      text-align: center !important;
      order: 2; } }

.cms-elements .box5 .img-box {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #575757;
  order: 1; }
  .cms-elements .box5 .img-box.transparent {
    background-color: transparent; }
  @media (max-width: 991.98px) {
    .cms-elements .box5 .img-box {
      order: 1; } }

.cms-elements .box5 .bgi-top-left {
  background-position: left top; }

.cms-elements .box5 .bgi-top-center {
  background-position: center top; }

.cms-elements .box5 .bgi-top-right {
  background-position: right top; }

.cms-elements .box5 .bgi-center-left {
  background-position: left center; }

.cms-elements .box5 .bgi-center {
  background-position: center center; }

.cms-elements .box5 .bgi-center-right {
  background-position: right top; }

.cms-elements .box5 .bgi-bottom-left {
  background-position: left bottom; }

.cms-elements .box5 .bgi-bottom-center {
  background-position: center bottom; }

.cms-elements .box5 .bgi-bottom-right {
  background-position: right bottom; }

.cms-elements .box6 .box-inner {
  position: relative;
  max-width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  display: block; }
  .cms-elements .box6 .box-inner .bg-layer {
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #000000; }
    .cms-elements .box6 .box-inner .bg-layer.opacity-0 {
      opacity: 0; }
    .cms-elements .box6 .box-inner .bg-layer.opacity-10 {
      opacity: .1; }
    .cms-elements .box6 .box-inner .bg-layer.opacity-20 {
      opacity: .2; }
    .cms-elements .box6 .box-inner .bg-layer.opacity-30 {
      opacity: 0.3; }
    .cms-elements .box6 .box-inner .bg-layer.opacity-40 {
      opacity: 0.4; }
    .cms-elements .box6 .box-inner .bg-layer.opacity-50 {
      opacity: 0.5; }
    .cms-elements .box6 .box-inner .bg-layer.opacity-60 {
      opacity: 0.6; }
    .cms-elements .box6 .box-inner .bg-layer.opacity-70 {
      opacity: 0.7; }
    .cms-elements .box6 .box-inner .bg-layer.opacity-80 {
      opacity: 0.8; }
    .cms-elements .box6 .box-inner .bg-layer.opacity-90 {
      opacity: 0.9; }
    .cms-elements .box6 .box-inner .bg-layer.opacity-100 {
      opacity: 1; }
  .cms-elements .box6 .box-inner.zoom-effect .img-wrapper {
    transition: transform .5s ease-in-out; }
  .cms-elements .box6 .box-inner.has-hover-img .img-wrapper .normal-img, .cms-elements .box6 .box-inner.has-hover-img .img-wrapper .hover-img {
    transition: display 1s ease-in-out; }
  .cms-elements .box6 .box-inner:hover .bg-layer.hover-opacity-0 {
    opacity: 0; }
  .cms-elements .box6 .box-inner:hover .bg-layer.hover-opacity-10 {
    opacity: .1; }
  .cms-elements .box6 .box-inner:hover .bg-layer.hover-opacity-20 {
    opacity: .2; }
  .cms-elements .box6 .box-inner:hover .bg-layer.hover-opacity-30 {
    opacity: 0.3; }
  .cms-elements .box6 .box-inner:hover .bg-layer.hover-opacity-40 {
    opacity: 0.4; }
  .cms-elements .box6 .box-inner:hover .bg-layer.hover-opacity-50 {
    opacity: 0.5; }
  .cms-elements .box6 .box-inner:hover .bg-layer.hover-opacity-60 {
    opacity: 0.6; }
  .cms-elements .box6 .box-inner:hover .bg-layer.hover-opacity-70 {
    opacity: 0.7; }
  .cms-elements .box6 .box-inner:hover .bg-layer.hover-opacity-80 {
    opacity: 0.8; }
  .cms-elements .box6 .box-inner:hover .bg-layer.hover-opacity-90 {
    opacity: 0.9; }
  .cms-elements .box6 .box-inner:hover .bg-layer.hover-opacity-100 {
    opacity: 1; }
  .cms-elements .box6 .box-inner:hover.has-hover-img .img-wrapper .normal-img {
    display: none; }
  .cms-elements .box6 .box-inner:hover.has-hover-img .img-wrapper .hover-img {
    display: block; }
  .cms-elements .box6 .box-inner:hover.zoom-effect .img-wrapper {
    transform: scale(1.1); }
  .cms-elements .box6 .box-inner:hover .hover-bg-confirm {
    background-color: #308136 !important; }
  .cms-elements .box6 .box-inner:hover .hover-bg-highlight {
    background-color: #00569d !important; }
  .cms-elements .box6 .box-inner:hover .hover-bg-black {
    background-color: #000000 !important; }
  .cms-elements .box6 .box-inner:hover .hover-bg-white {
    background-color: #FFFFFF !important; }
  .cms-elements .box6 .box-inner:hover .hover-bg-darkgrey {
    background-color: #575757 !important; }
  .cms-elements .box6 .box-inner:hover .hover-bg-darkgrey-o {
    background-color: #747474 !important; }
  .cms-elements .box6 .box-inner:hover .hover-bg-lightgrey {
    background-color: #e3e3e3 !important; }
  .cms-elements .box6 .box-inner:hover .hover-bg-lightgrey-o {
    background-color: #f6f6f6 !important; }
  .cms-elements .box6 .box-inner:hover .hover-bg-border {
    background-color: #aeaeae !important; }
  .cms-elements .box6 .box-inner:hover .hover-bg-special {
    background-color: #dc371c !important; }
  .cms-elements .box6 .box-inner:hover .hover-bg-rating {
    background-color: #f59100 !important; }
  .cms-elements .box6 .box-inner:hover .hover-bg-transparent {
    background-color: transparent !important; }
  .cms-elements .box6 .box-inner:hover .hover-t-confirm {
    color: #308136; }
  .cms-elements .box6 .box-inner:hover .hover-t-highlight {
    color: #00569d; }
  .cms-elements .box6 .box-inner:hover .hover-t-black {
    color: #000000; }
  .cms-elements .box6 .box-inner:hover .hover-t-white {
    color: #FFFFFF; }
  .cms-elements .box6 .box-inner:hover .hover-t-darkgrey {
    color: #575757; }
  .cms-elements .box6 .box-inner:hover .hover-t-darkgrey-o {
    color: #747474; }
  .cms-elements .box6 .box-inner:hover .hover-t-lightgrey {
    color: #e3e3e3; }
  .cms-elements .box6 .box-inner:hover .hover-t-lightgrey-o {
    color: #f6f6f6; }
  .cms-elements .box6 .box-inner:hover .hover-t-border {
    color: #aeaeae; }
  .cms-elements .box6 .box-inner:hover .hover-t-special {
    color: #dc371c; }
  .cms-elements .box6 .box-inner:hover .hover-t-rating {
    color: #f59100; }
  .cms-elements .box6 .box-inner:hover .normal-img.hover-layer-b-w-0, .cms-elements .box6 .box-inner:hover .hover-img.hover-layer-b-w-0 {
    filter: grayscale(0%); }
  .cms-elements .box6 .box-inner:hover .normal-img.hover-layer-b-w-10, .cms-elements .box6 .box-inner:hover .hover-img.hover-layer-b-w-10 {
    filter: grayscale(10%); }
  .cms-elements .box6 .box-inner:hover .normal-img.hover-layer-b-w-20, .cms-elements .box6 .box-inner:hover .hover-img.hover-layer-b-w-20 {
    filter: grayscale(20%); }
  .cms-elements .box6 .box-inner:hover .normal-img.hover-layer-b-w-30, .cms-elements .box6 .box-inner:hover .hover-img.hover-layer-b-w-30 {
    filter: grayscale(30%); }
  .cms-elements .box6 .box-inner:hover .normal-img.hover-layer-b-w-40, .cms-elements .box6 .box-inner:hover .hover-img.hover-layer-b-w-40 {
    filter: grayscale(40%); }
  .cms-elements .box6 .box-inner:hover .normal-img.hover-layer-b-w-50, .cms-elements .box6 .box-inner:hover .hover-img.hover-layer-b-w-50 {
    filter: grayscale(50%); }
  .cms-elements .box6 .box-inner:hover .normal-img.hover-layer-b-w-60, .cms-elements .box6 .box-inner:hover .hover-img.hover-layer-b-w-60 {
    filter: grayscale(60%); }
  .cms-elements .box6 .box-inner:hover .normal-img.hover-layer-b-w-70, .cms-elements .box6 .box-inner:hover .hover-img.hover-layer-b-w-70 {
    filter: grayscale(70%); }
  .cms-elements .box6 .box-inner:hover .normal-img.hover-layer-b-w-80, .cms-elements .box6 .box-inner:hover .hover-img.hover-layer-b-w-80 {
    filter: grayscale(80%); }
  .cms-elements .box6 .box-inner:hover .normal-img.hover-layer-b-w-90, .cms-elements .box6 .box-inner:hover .hover-img.hover-layer-b-w-90 {
    filter: grayscale(90%); }
  .cms-elements .box6 .box-inner:hover .normal-img.hover-layer-b-w-100, .cms-elements .box6 .box-inner:hover .hover-img.hover-layer-b-w-100 {
    filter: grayscale(100%); }
  .cms-elements .box6 .box-inner .img-wrapper {
    width: 100%;
    height: 100%; }
    .cms-elements .box6 .box-inner .img-wrapper > div {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center; }
      .cms-elements .box6 .box-inner .img-wrapper > div.bg-top-left {
        background-position: top left; }
      .cms-elements .box6 .box-inner .img-wrapper > div.bg-top-center {
        background-position: top center; }
      .cms-elements .box6 .box-inner .img-wrapper > div.bg-top-right {
        background-position: top right; }
      .cms-elements .box6 .box-inner .img-wrapper > div.bg-center-left {
        background-position: center left; }
      .cms-elements .box6 .box-inner .img-wrapper > div.bg-center-center {
        background-position: center center; }
      .cms-elements .box6 .box-inner .img-wrapper > div.bg-center-right {
        background-position: center right; }
      .cms-elements .box6 .box-inner .img-wrapper > div.bg-bottom-left {
        background-position: bottom left; }
      .cms-elements .box6 .box-inner .img-wrapper > div.bg-bottom-center {
        background-position: bottom center; }
      .cms-elements .box6 .box-inner .img-wrapper > div.bg-bottom-right {
        background-position: bottom right; }
      .cms-elements .box6 .box-inner .img-wrapper > div.layer-b-w-0 {
        filter: grayscale(0%); }
      .cms-elements .box6 .box-inner .img-wrapper > div.layer-b-w-10 {
        filter: grayscale(10%); }
      .cms-elements .box6 .box-inner .img-wrapper > div.layer-b-w-20 {
        filter: grayscale(20%); }
      .cms-elements .box6 .box-inner .img-wrapper > div.layer-b-w-30 {
        filter: grayscale(30%); }
      .cms-elements .box6 .box-inner .img-wrapper > div.layer-b-w-40 {
        filter: grayscale(40%); }
      .cms-elements .box6 .box-inner .img-wrapper > div.layer-b-w-50 {
        filter: grayscale(50%); }
      .cms-elements .box6 .box-inner .img-wrapper > div.layer-b-w-60 {
        filter: grayscale(60%); }
      .cms-elements .box6 .box-inner .img-wrapper > div.layer-b-w-70 {
        filter: grayscale(70%); }
      .cms-elements .box6 .box-inner .img-wrapper > div.layer-b-w-80 {
        filter: grayscale(80%); }
      .cms-elements .box6 .box-inner .img-wrapper > div.layer-b-w-90 {
        filter: grayscale(90%); }
      .cms-elements .box6 .box-inner .img-wrapper > div.layer-b-w-100 {
        filter: grayscale(100%); }
      .cms-elements .box6 .box-inner .img-wrapper > div.hover-img {
        display: none; }
  .cms-elements .box6 .box-inner.aspect-1-1 {
    aspect-ratio: 1 / 1; }
  .cms-elements .box6 .box-inner.aspect-3-4 {
    aspect-ratio: 3 / 4; }
  .cms-elements .box6 .box-inner.aspect-4-3 {
    aspect-ratio: 4 / 3; }
  .cms-elements .box6 .box-inner.aspect-9-16 {
    aspect-ratio: 9 / 16; }
  .cms-elements .box6 .box-inner.aspect-16-9 {
    aspect-ratio: 16 / 9; }
  .cms-elements .box6 .box-inner.aspect-auto-height {
    aspect-ratio: unset;
    height: 100%; }
  .cms-elements .box6 .box-inner.aspect-fixed-height {
    aspect-ratio: unset; }
  .cms-elements .box6 .box-inner .text-box {
    position: absolute;
    bottom: 2rem;
    left: 3.5rem;
    right: 3.5rem;
    min-height: 4.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    line-height: 1.8rem;
    border-radius: 5px;
    padding: 1rem;
    z-index: 2;
    overflow: hidden; }
    @media (max-width: 767.98px) {
      .cms-elements .box6 .box-inner .text-box {
        left: 1.5rem;
        right: 1.5rem;
        bottom: 1rem; } }
    .cms-elements .box6 .box-inner .text-box .headline {
      text-align: center;
      font-size: 1.6rem;
      margin-bottom: 0 !important; }
      @media (max-width: 767.98px) {
        .cms-elements .box6 .box-inner .text-box .headline {
          font-size: 1.3rem !important; } }
      .cms-elements .box6 .box-inner .text-box .headline.text-bold-italic {
        font-weight: 700;
        font-style: italic; }
      .cms-elements .box6 .box-inner .text-box .headline {
        font-weight: bold; }
    .cms-elements .box6 .box-inner .text-box .text {
      text-align: center;
      font-size: 1.4rem;
      margin-top: .5rem;
      margin-bottom: 0 !important;
      word-break: break-word; }
      @media (max-width: 767.98px) {
        .cms-elements .box6 .box-inner .text-box .text {
          font-size: 1.2rem !important; } }
      .cms-elements .box6 .box-inner .text-box .text.text-bold-italic {
        font-weight: 700;
        font-style: italic; }
      .cms-elements .box6 .box-inner .text-box .text {
        font-weight: normal; }

.cms-elements .box-text {
  padding: 1rem; }

.cms-elements .box-text p {
  padding: .5rem 0; }

.cms-elements .box-text .has-bg {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone; }

.cms-elements .box-text .btn-highlight {
  background-color: #00569d; }

.cms-elements .shop-by-room-container .hover-point {
  visibility: hidden;
  /* preload image to prevent load on hover */
  width: 25px;
  height: 25px;
  background-size: contain;
  background-image: url("/out/ra-theme/img/shop-by-room/icon_shop-the-look.png"); }
  .cms-elements .shop-by-room-container .hover-point::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url("/out/ra-theme/img/shop-by-room/icon_shop-the-look_over.png"); }
  .cms-elements .shop-by-room-container .hover-point:hover {
    background-image: url("/out/ra-theme/img/shop-by-room/icon_shop-the-look_over.png"); }
  @media (max-width: 767.98px) {
    .cms-elements .shop-by-room-container .hover-point {
      width: 15px;
      height: 15px; } }
  .cms-elements .shop-by-room-container .hover-point.show {
    visibility: visible; }

.cms-elements .box-counter .counter-inner {
  text-align: center;
  font-size: 0; }
  .cms-elements .box-counter .counter-inner .ra-counter-value {
    font-size: 3rem;
    visibility: hidden; }
    .cms-elements .box-counter .counter-inner .ra-counter-value.visible {
      visibility: visible; }
  .cms-elements .box-counter .counter-inner h3 {
    font-size: 2.5rem; }
  .cms-elements .box-counter .counter-inner p {
    font-size: 2rem; }

.cms-elements .box-image.image-middle img:not(.zoom-effect) {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.cms-elements .box-image.image-bottom img {
  position: relative;
  top: 100%;
  transform: translateY(-100%); }

.cms-elements .box-image.image-top img {
  position: relative;
  top: 0; }

.cms-elements .box-image-before-after {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .cms-elements .box-image-before-after .beforeAfterImageContainer {
    position: relative;
    height: inherit;
    overflow: hidden; }
  .cms-elements .box-image-before-after .imageBefore, .cms-elements .box-image-before-after .imageAfter {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    pointer-events: none;
    transform: translate(-50%, -50%); }
  .cms-elements .box-image-before-after .sliderBeforeAfter {
    width: 100%;
    height: 100%;
    position: absolute; }
    .cms-elements .box-image-before-after .sliderBeforeAfter .handle-input {
      position: absolute;
      top: 0 !important;
      left: 0 !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0);
      outline: none;
      margin: 0;
      transition: all .2s;
      cursor: pointer; }
      .cms-elements .box-image-before-after .sliderBeforeAfter .handle-input.vertical {
        position: absolute;
        left: 100% !important;
        transform: rotate(90deg);
        transform-origin: left top; }
      .cms-elements .box-image-before-after .sliderBeforeAfter .handle-input::-webkit-slider-thumb {
        visibility: hidden;
        cursor: pointer; }
      .cms-elements .box-image-before-after .sliderBeforeAfter .handle-input::-moz-range-thumb {
        visibility: hidden;
        cursor: pointer; }
    .cms-elements .box-image-before-after .sliderBeforeAfter .handle-button {
      pointer-events: none;
      position: absolute;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      background: #00569d;
      left: calc(50% - 25px);
      top: calc(50% - 25px);
      cursor: pointer;
      transform-style: preserve-3d;
      display: flex; }
      .cms-elements .box-image-before-after .sliderBeforeAfter .handle-button i {
        font-size: 4.5rem;
        height: 100%;
        width: 100%;
        color: #FFFFFF;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center; }
        .cms-elements .box-image-before-after .sliderBeforeAfter .handle-button i.before-after {
          font-size: 5rem; }
        .cms-elements .box-image-before-after .sliderBeforeAfter .handle-button i.vertical {
          transform: rotate(-90deg); }
      .cms-elements .box-image-before-after .sliderBeforeAfter .handle-button.vertical {
        transform: rotate(90deg); }
        .cms-elements .box-image-before-after .sliderBeforeAfter .handle-button.vertical.animate {
          transform: rotate(90deg);
          animation-name: anim-v;
          animation-duration: 4s; }
      .cms-elements .box-image-before-after .sliderBeforeAfter .handle-button.divider {
        position: absolute; }
        .cms-elements .box-image-before-after .sliderBeforeAfter .handle-button.divider::before {
          position: absolute;
          left: 2.35rem;
          content: ' ';
          display: block;
          background: #FFFFFF;
          width: 3px;
          height: 9999px;
          transform: translateY(-9994px) translateZ(-1px); }
        .cms-elements .box-image-before-after .sliderBeforeAfter .handle-button.divider::after {
          position: absolute;
          left: 2.35rem;
          top: 4.4rem;
          content: ' ';
          display: block;
          background: #FFFFFF;
          width: 3px;
          height: 9999px;
          transform: translateZ(-1px); }
      .cms-elements .box-image-before-after .sliderBeforeAfter .handle-button.animate {
        animation-name: anim;
        animation-duration: 4s; }

@keyframes anim {
  0% {
    transform: translateX(0%); }
  25% {
    transform: translateX(20%); }
  50% {
    transform: translateX(-30%); }
  75% {
    transform: translateX(10%); }
  100% {
    transform: translateX(0%); } }

@keyframes anim-v {
  0% {
    transform: translateY(0px) rotate(90deg); }
  25% {
    transform: translateY(20px) rotate(90deg); }
  50% {
    transform: translateY(-30px) rotate(90deg); }
  75% {
    transform: translateY(10px) rotate(90deg); }
  100% {
    transform: translateY(0px) rotate(90deg); } }
    .cms-elements .box-image-before-after .sliderBeforeAfter .handle-help {
      border: 1px solid #00569d;
      background-color: #e3e3e3;
      padding: .5rem;
      width: 200px;
      height: auto;
      font-size: 1.2rem;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 65%;
      left: 50%;
      opacity: 0;
      transition: opacity 1s; }
      .cms-elements .box-image-before-after .sliderBeforeAfter .handle-help .icon-col {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 5rem; }
        .cms-elements .box-image-before-after .sliderBeforeAfter .handle-help .icon-col.vertical {
          transform: rotate(-90deg); }
      @media (max-width: 767.98px) {
        .cms-elements .box-image-before-after .sliderBeforeAfter .handle-help {
          top: 80%;
          width: 70%;
          font-size: 1rem; }
          .cms-elements .box-image-before-after .sliderBeforeAfter .handle-help .icon-col {
            font-size: 3rem; } }

.cms-elements .image-middle {
  padding-left: 0;
  padding-right: 0; }

.cms-elements .cms-slider {
  padding-left: 4rem;
  padding-right: 4rem; }
  @media (min-width: 992px) {
    .cms-elements .cms-slider.showPrevNextArticle .cms-slider-left, .cms-elements .cms-slider.showPrevNextArticle .cms-slider-right {
      top: 45%;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column; } }
  .cms-elements .cms-slider .cms-slider-left, .cms-elements .cms-slider .cms-slider-right {
    cursor: pointer;
    position: absolute;
    top: 50%;
    font-size: 4.5rem;
    transition: .3s font-weight;
    margin-top: -2rem; }
    .cms-elements .cms-slider .cms-slider-left:hover, .cms-elements .cms-slider .cms-slider-right:hover {
      font-weight: bold; }
    @media (max-width: 575.98px) {
      .cms-elements .cms-slider .cms-slider-left, .cms-elements .cms-slider .cms-slider-right {
        display: none; } }
  .cms-elements .cms-slider .cms-slider-left {
    left: -.5rem; }
    @media (max-width: 767.98px) {
      .cms-elements .cms-slider .cms-slider-left {
        left: -3.5rem; } }
  .cms-elements .cms-slider .cms-slider-right {
    right: -.5rem; }
    @media (max-width: 767.98px) {
      .cms-elements .cms-slider .cms-slider-right {
        right: -3.5rem; } }
  @media (max-width: 767.98px) {
    .cms-elements .cms-slider {
      padding: 0; } }
  .cms-elements .cms-slider .cms-slider-item {
    display: none;
    position: relative; }
    .cms-elements .cms-slider .cms-slider-item:hover .product-item-noticelist {
      visibility: visible; }
    .cms-elements .cms-slider .cms-slider-item .product-item {
      margin: 0 auto;
      width: 100%; }
    @media (max-width: 767.98px) {
      .cms-elements .cms-slider .cms-slider-item .product-item {
        width: 100%; } }
    .cms-elements .cms-slider .cms-slider-item.active {
      display: flex;
      transition: .5s opacity;
      opacity: 0; }
      .cms-elements .cms-slider .cms-slider-item.active.instant-fade {
        transition: none;
        opacity: 1; }
      .cms-elements .cms-slider .cms-slider-item.active.faded-in {
        opacity: 1; }
  .cms-elements .cms-slider .cms-slider-indicators {
    width: 100%;
    text-align: center; }
    .cms-elements .cms-slider .cms-slider-indicators span {
      display: inline-block;
      margin: 0 auto;
      height: .5rem;
      width: 1.5rem;
      background-color: #e3e3e3;
      margin: 0 .3rem;
      transition: .3s background-color;
      cursor: pointer; }
      @media (max-width: 767.98px) {
        .cms-elements .cms-slider .cms-slider-indicators span {
          margin: 2.5rem .5rem 0 .5rem;
          height: .8rem; } }
      .cms-elements .cms-slider .cms-slider-indicators span.active {
        background-color: #00569d; }
  .cms-elements .cms-slider .display-none {
    display: none; }

@media (min-width: 992px) {
  .cms-elements .full-width-bg .article-slider {
    margin-right: 0;
    margin-left: 0; } }

@media (max-width: 991.98px) {
  .cms-elements .article-slider .row {
    padding-left: 5px; } }

.cms-elements .article-slider .slider-item-box {
  padding: 0 !important; }

.cms-elements .article-slider .slider-item-box, .cms-elements .article-slider .cms-slider-item {
  border: 1px solid #e3e3e3;
  margin: -1px 0 0 -1px; }

.cms-elements .article-slider .product-bestseller-inner span {
  display: none !important; }

.cms-elements .t-white .product-item {
  color: #FFFFFF; }

.cms-elements .t-white .pricebox {
  color: #FFFFFF; }

.cms-elements .shadow-left, .cms-elements .shadow-right {
  height: 100%;
  position: absolute;
  z-index: 10; }

.cms-elements .shadow-left {
  left: 0; }

.cms-elements .shadow-right {
  right: 0; }

@media (min-width: 992px) {
  .cms-elements .showPrevNextArticle {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 2rem; }
    .cms-elements .showPrevNextArticle .cms-slider-indicators {
      display: none; }
    .cms-elements .showPrevNextArticle .slider-item-box, .cms-elements .showPrevNextArticle .cms-slider-item {
      border: none;
      margin: 10px 5px 0 5px;
      background-color: #FFFFFF; }
      .cms-elements .showPrevNextArticle .slider-item-box .product-item, .cms-elements .showPrevNextArticle .cms-slider-item .product-item {
        color: #575757 !important; }
      .cms-elements .showPrevNextArticle .slider-item-box .pricebox, .cms-elements .showPrevNextArticle .cms-slider-item .pricebox {
        color: #575757 !important; }
        .cms-elements .showPrevNextArticle .slider-item-box .pricebox span, .cms-elements .showPrevNextArticle .cms-slider-item .pricebox span {
          color: #575757 !important; }
          .cms-elements .showPrevNextArticle .slider-item-box .pricebox span.red, .cms-elements .showPrevNextArticle .cms-slider-item .pricebox span.red {
            color: #FFFFFF !important; }
      .cms-elements .showPrevNextArticle .slider-item-box p, .cms-elements .showPrevNextArticle .cms-slider-item p {
        color: #575757 !important; }
    .cms-elements .showPrevNextArticle .chevron-toggle-left {
      height: 100%;
      position: absolute;
      z-index: 99; }
    .cms-elements .showPrevNextArticle .chevron-toggle-right {
      height: 100%;
      position: absolute;
      z-index: 99; } }

.cms-elements .hasBgImage .cms-slider-item {
  background-color: #FFFFFF; }

.cms-elements .cms-list {
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: center; }
  .cms-elements .cms-list .col-12 {
    padding-left: 0 !important; }
  .cms-elements .cms-list .list-item {
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 1rem; }
    .cms-elements .cms-list .list-item .list-icon {
      margin: 0 0.5rem 0 0.5rem; }
    .cms-elements .cms-list .list-item.list-transparent {
      color: transparent !important; }
      .cms-elements .cms-list .list-item.list-transparent i {
        color: transparent !important; }
      .cms-elements .cms-list .list-item.list-transparent span {
        color: transparent !important; }
      .cms-elements .cms-list .list-item.list-transparent a {
        color: transparent !important; }
    .cms-elements .cms-list .list-item.list-animation {
      animation: fade-in-validation 2s forwards; }
  @media (max-width: 767.98px) {
    .cms-elements .cms-list .desktop {
      display: none !important; }
    .cms-elements .cms-list .mobile-100 {
      width: 100%; }
    .cms-elements .cms-list .mobile-75 {
      width: 75%; }
    .cms-elements .cms-list .mobile-50 {
      width: 50%; } }
  @media (min-width: 768px) {
    .cms-elements .cms-list .mobile {
      display: none !important; }
    .cms-elements .cms-list .desktop-100 {
      width: 100%; }
    .cms-elements .cms-list .desktop-75 {
      width: 75%; }
    .cms-elements .cms-list .desktop-50 {
      width: 50%; } }

.cms-elements div.dark, .cms-elements div.darkgrey {
  background-color: #575757; }
  .cms-elements div.dark, .cms-elements div.dark h1, .cms-elements div.dark h2, .cms-elements div.dark h3, .cms-elements div.dark h4, .cms-elements div.dark h5, .cms-elements div.dark h6, .cms-elements div.darkgrey, .cms-elements div.darkgrey h1, .cms-elements div.darkgrey h2, .cms-elements div.darkgrey h3, .cms-elements div.darkgrey h4, .cms-elements div.darkgrey h5, .cms-elements div.darkgrey h6 {
    color: #FFFFFF; }
  .cms-elements div.dark a.product-item, .cms-elements div.darkgrey a.product-item {
    color: #FFFFFF; }
    .cms-elements div.dark a.product-item .price, .cms-elements div.darkgrey a.product-item .price {
      color: #FFFFFF; }
  .cms-elements div.dark.box3 div.box-inner-text, .cms-elements div.dark .box3 div.box-inner-text, .cms-elements div.darkgrey.box3 div.box-inner-text, .cms-elements div.darkgrey .box3 div.box-inner-text {
    color: #575757; }
    .cms-elements div.dark.box3 div.box-inner-text h1, .cms-elements div.dark.box3 div.box-inner-text h2, .cms-elements div.dark.box3 div.box-inner-text h3, .cms-elements div.dark.box3 div.box-inner-text h4, .cms-elements div.dark.box3 div.box-inner-text h5, .cms-elements div.dark.box3 div.box-inner-text h6, .cms-elements div.dark .box3 div.box-inner-text h1, .cms-elements div.dark .box3 div.box-inner-text h2, .cms-elements div.dark .box3 div.box-inner-text h3, .cms-elements div.dark .box3 div.box-inner-text h4, .cms-elements div.dark .box3 div.box-inner-text h5, .cms-elements div.dark .box3 div.box-inner-text h6, .cms-elements div.darkgrey.box3 div.box-inner-text h1, .cms-elements div.darkgrey.box3 div.box-inner-text h2, .cms-elements div.darkgrey.box3 div.box-inner-text h3, .cms-elements div.darkgrey.box3 div.box-inner-text h4, .cms-elements div.darkgrey.box3 div.box-inner-text h5, .cms-elements div.darkgrey.box3 div.box-inner-text h6, .cms-elements div.darkgrey .box3 div.box-inner-text h1, .cms-elements div.darkgrey .box3 div.box-inner-text h2, .cms-elements div.darkgrey .box3 div.box-inner-text h3, .cms-elements div.darkgrey .box3 div.box-inner-text h4, .cms-elements div.darkgrey .box3 div.box-inner-text h5, .cms-elements div.darkgrey .box3 div.box-inner-text h6 {
      color: #575757; }

.cms-elements div.light, .cms-elements div.lightgrey {
  background-color: #e3e3e3; }
  .cms-elements div.light.box5, .cms-elements div.lightgrey.box5 {
    color: #575757; }

.cms-elements div.white {
  background-color: #FFFFFF; }

.cms-elements div.highlight, .cms-elements div.black, .cms-elements div.special, .cms-elements div.confirm, .cms-elements div.rating {
  color: #FFFFFF; }
  .cms-elements div.highlight h1, .cms-elements div.highlight h2, .cms-elements div.highlight h3, .cms-elements div.highlight h4, .cms-elements div.highlight h5, .cms-elements div.highlight h6, .cms-elements div.black h1, .cms-elements div.black h2, .cms-elements div.black h3, .cms-elements div.black h4, .cms-elements div.black h5, .cms-elements div.black h6, .cms-elements div.special h1, .cms-elements div.special h2, .cms-elements div.special h3, .cms-elements div.special h4, .cms-elements div.special h5, .cms-elements div.special h6, .cms-elements div.confirm h1, .cms-elements div.confirm h2, .cms-elements div.confirm h3, .cms-elements div.confirm h4, .cms-elements div.confirm h5, .cms-elements div.confirm h6, .cms-elements div.rating h1, .cms-elements div.rating h2, .cms-elements div.rating h3, .cms-elements div.rating h4, .cms-elements div.rating h5, .cms-elements div.rating h6 {
    color: #FFFFFF; }
  .cms-elements div.highlight a, .cms-elements div.black a, .cms-elements div.special a, .cms-elements div.confirm a, .cms-elements div.rating a {
    color: #FFFFFF; }
  .cms-elements div.highlight div.box-inner-text, .cms-elements div.highlight div.box-inner-text h1, .cms-elements div.highlight div.box-inner-text h2, .cms-elements div.highlight div.box-inner-text h3, .cms-elements div.highlight div.box-inner-text h4, .cms-elements div.highlight div.box-inner-text h5, .cms-elements div.highlight div.box-inner-text h6, .cms-elements div.black div.box-inner-text, .cms-elements div.black div.box-inner-text h1, .cms-elements div.black div.box-inner-text h2, .cms-elements div.black div.box-inner-text h3, .cms-elements div.black div.box-inner-text h4, .cms-elements div.black div.box-inner-text h5, .cms-elements div.black div.box-inner-text h6, .cms-elements div.special div.box-inner-text, .cms-elements div.special div.box-inner-text h1, .cms-elements div.special div.box-inner-text h2, .cms-elements div.special div.box-inner-text h3, .cms-elements div.special div.box-inner-text h4, .cms-elements div.special div.box-inner-text h5, .cms-elements div.special div.box-inner-text h6, .cms-elements div.confirm div.box-inner-text, .cms-elements div.confirm div.box-inner-text h1, .cms-elements div.confirm div.box-inner-text h2, .cms-elements div.confirm div.box-inner-text h3, .cms-elements div.confirm div.box-inner-text h4, .cms-elements div.confirm div.box-inner-text h5, .cms-elements div.confirm div.box-inner-text h6, .cms-elements div.rating div.box-inner-text, .cms-elements div.rating div.box-inner-text h1, .cms-elements div.rating div.box-inner-text h2, .cms-elements div.rating div.box-inner-text h3, .cms-elements div.rating div.box-inner-text h4, .cms-elements div.rating div.box-inner-text h5, .cms-elements div.rating div.box-inner-text h6 {
    color: #575757; }
  .cms-elements div.highlight a.product-item, .cms-elements div.black a.product-item, .cms-elements div.special a.product-item, .cms-elements div.confirm a.product-item, .cms-elements div.rating a.product-item {
    color: #FFFFFF; }
    .cms-elements div.highlight a.product-item .price, .cms-elements div.black a.product-item .price, .cms-elements div.special a.product-item .price, .cms-elements div.confirm a.product-item .price, .cms-elements div.rating a.product-item .price {
      color: #FFFFFF; }
  .cms-elements div.highlight .btn-highlight, .cms-elements div.black .btn-highlight, .cms-elements div.special .btn-highlight, .cms-elements div.confirm .btn-highlight, .cms-elements div.rating .btn-highlight {
    color: #575757 !important;
    background-color: #e3e3e3; }

.cms-elements .bg-highlight-o0 {
  background-color: rgba(0, 86, 157, 0); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-o0 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0) 50%, rgba(0, 86, 157, 0) 75%, transparent 100%); }
    .cms-elements .bg-highlight-o0 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0) 50%, rgba(0, 86, 157, 0) 75%, transparent 100%); } }

.cms-elements .bg-highlight-accessible-o0 {
  background-color: rgba(0, 86, 157, 0); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-accessible-o0 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0) 50%, rgba(0, 86, 157, 0) 75%, transparent 100%); }
    .cms-elements .bg-highlight-accessible-o0 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0) 50%, rgba(0, 86, 157, 0) 75%, transparent 100%); } }

.cms-elements .bg-black-o0 {
  background-color: rgba(0, 0, 0, 0); }
  @media (min-width: 992px) {
    .cms-elements .bg-black-o0 .shadow-left {
      background: linear-gradient(90deg, #000000 25%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 75%, transparent 100%); }
    .cms-elements .bg-black-o0 .shadow-right {
      background: linear-gradient(-90deg, #000000 25%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 75%, transparent 100%); } }

.cms-elements .bg-special-o0 {
  background-color: rgba(220, 55, 28, 0); }
  @media (min-width: 992px) {
    .cms-elements .bg-special-o0 .shadow-left {
      background: linear-gradient(90deg, #dc371c 25%, rgba(220, 55, 28, 0) 50%, rgba(220, 55, 28, 0) 75%, transparent 100%); }
    .cms-elements .bg-special-o0 .shadow-right {
      background: linear-gradient(-90deg, #dc371c 25%, rgba(220, 55, 28, 0) 50%, rgba(220, 55, 28, 0) 75%, transparent 100%); } }

.cms-elements .bg-confirm-o0 {
  background-color: rgba(48, 129, 54, 0); }
  @media (min-width: 992px) {
    .cms-elements .bg-confirm-o0 .shadow-left {
      background: linear-gradient(90deg, #308136 25%, rgba(48, 129, 54, 0) 50%, rgba(48, 129, 54, 0) 75%, transparent 100%); }
    .cms-elements .bg-confirm-o0 .shadow-right {
      background: linear-gradient(-90deg, #308136 25%, rgba(48, 129, 54, 0) 50%, rgba(48, 129, 54, 0) 75%, transparent 100%); } }

.cms-elements .bg-rating-o0 {
  background-color: rgba(245, 145, 0, 0); }
  @media (min-width: 992px) {
    .cms-elements .bg-rating-o0 .shadow-left {
      background: linear-gradient(90deg, #f59100 25%, rgba(245, 145, 0, 0) 50%, rgba(245, 145, 0, 0) 75%, transparent 100%); }
    .cms-elements .bg-rating-o0 .shadow-right {
      background: linear-gradient(-90deg, #f59100 25%, rgba(245, 145, 0, 0) 50%, rgba(245, 145, 0, 0) 75%, transparent 100%); } }

.cms-elements .bg-darkgrey-o0 {
  background-color: rgba(87, 87, 87, 0); }
  @media (min-width: 992px) {
    .cms-elements .bg-darkgrey-o0 .shadow-left {
      background: linear-gradient(90deg, #575757 25%, rgba(87, 87, 87, 0) 50%, rgba(87, 87, 87, 0) 75%, transparent 100%); }
    .cms-elements .bg-darkgrey-o0 .shadow-right {
      background: linear-gradient(-90deg, #575757 25%, rgba(87, 87, 87, 0) 50%, rgba(87, 87, 87, 0) 75%, transparent 100%); } }

.cms-elements .bg-lightgrey-o0 {
  background-color: rgba(227, 227, 227, 0); }
  @media (min-width: 992px) {
    .cms-elements .bg-lightgrey-o0 .shadow-left {
      background: linear-gradient(90deg, #e3e3e3 25%, rgba(227, 227, 227, 0) 50%, rgba(227, 227, 227, 0) 75%, transparent 100%); }
    .cms-elements .bg-lightgrey-o0 .shadow-right {
      background: linear-gradient(-90deg, #e3e3e3 25%, rgba(227, 227, 227, 0) 50%, rgba(227, 227, 227, 0) 75%, transparent 100%); } }

.cms-elements .bg-white-o0 {
  background-color: rgba(255, 255, 255, 0); }
  @media (min-width: 992px) {
    .cms-elements .bg-white-o0 .shadow-left {
      background: linear-gradient(90deg, #FFFFFF 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 75%, transparent 100%); }
    .cms-elements .bg-white-o0 .shadow-right {
      background: linear-gradient(-90deg, #FFFFFF 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 75%, transparent 100%); }
    .cms-elements .bg-white-o0 .showSiteArticle-toggle {
      border-left: 1px solid #aeaeae; }
    .cms-elements .bg-white-o0 .container h2 {
      color: #575757; } }

.cms-elements .bg-highlight-o10 {
  background-color: rgba(0, 86, 157, 0.1); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-o10 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.1) 50%, rgba(0, 86, 157, 0.05) 75%, transparent 100%); }
    .cms-elements .bg-highlight-o10 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.1) 50%, rgba(0, 86, 157, 0.05) 75%, transparent 100%); } }

.cms-elements .bg-highlight-accessible-o10 {
  background-color: rgba(0, 86, 157, 0.1); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-accessible-o10 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.1) 50%, rgba(0, 86, 157, 0.05) 75%, transparent 100%); }
    .cms-elements .bg-highlight-accessible-o10 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.1) 50%, rgba(0, 86, 157, 0.05) 75%, transparent 100%); } }

.cms-elements .bg-black-o10 {
  background-color: rgba(0, 0, 0, 0.1); }
  @media (min-width: 992px) {
    .cms-elements .bg-black-o10 .shadow-left {
      background: linear-gradient(90deg, #000000 25%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.05) 75%, transparent 100%); }
    .cms-elements .bg-black-o10 .shadow-right {
      background: linear-gradient(-90deg, #000000 25%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.05) 75%, transparent 100%); } }

.cms-elements .bg-special-o10 {
  background-color: rgba(220, 55, 28, 0.1); }
  @media (min-width: 992px) {
    .cms-elements .bg-special-o10 .shadow-left {
      background: linear-gradient(90deg, #dc371c 25%, rgba(220, 55, 28, 0.1) 50%, rgba(220, 55, 28, 0.05) 75%, transparent 100%); }
    .cms-elements .bg-special-o10 .shadow-right {
      background: linear-gradient(-90deg, #dc371c 25%, rgba(220, 55, 28, 0.1) 50%, rgba(220, 55, 28, 0.05) 75%, transparent 100%); } }

.cms-elements .bg-confirm-o10 {
  background-color: rgba(48, 129, 54, 0.1); }
  @media (min-width: 992px) {
    .cms-elements .bg-confirm-o10 .shadow-left {
      background: linear-gradient(90deg, #308136 25%, rgba(48, 129, 54, 0.1) 50%, rgba(48, 129, 54, 0.05) 75%, transparent 100%); }
    .cms-elements .bg-confirm-o10 .shadow-right {
      background: linear-gradient(-90deg, #308136 25%, rgba(48, 129, 54, 0.1) 50%, rgba(48, 129, 54, 0.05) 75%, transparent 100%); } }

.cms-elements .bg-rating-o10 {
  background-color: rgba(245, 145, 0, 0.1); }
  @media (min-width: 992px) {
    .cms-elements .bg-rating-o10 .shadow-left {
      background: linear-gradient(90deg, #f59100 25%, rgba(245, 145, 0, 0.1) 50%, rgba(245, 145, 0, 0.05) 75%, transparent 100%); }
    .cms-elements .bg-rating-o10 .shadow-right {
      background: linear-gradient(-90deg, #f59100 25%, rgba(245, 145, 0, 0.1) 50%, rgba(245, 145, 0, 0.05) 75%, transparent 100%); } }

.cms-elements .bg-darkgrey-o10 {
  background-color: rgba(87, 87, 87, 0.1); }
  @media (min-width: 992px) {
    .cms-elements .bg-darkgrey-o10 .shadow-left {
      background: linear-gradient(90deg, #575757 25%, rgba(87, 87, 87, 0.1) 50%, rgba(87, 87, 87, 0.05) 75%, transparent 100%); }
    .cms-elements .bg-darkgrey-o10 .shadow-right {
      background: linear-gradient(-90deg, #575757 25%, rgba(87, 87, 87, 0.1) 50%, rgba(87, 87, 87, 0.05) 75%, transparent 100%); } }

.cms-elements .bg-lightgrey-o10 {
  background-color: rgba(227, 227, 227, 0.1); }
  @media (min-width: 992px) {
    .cms-elements .bg-lightgrey-o10 .shadow-left {
      background: linear-gradient(90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.1) 50%, rgba(227, 227, 227, 0.05) 75%, transparent 100%); }
    .cms-elements .bg-lightgrey-o10 .shadow-right {
      background: linear-gradient(-90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.1) 50%, rgba(227, 227, 227, 0.05) 75%, transparent 100%); } }

.cms-elements .bg-white-o10 {
  background-color: rgba(255, 255, 255, 0.1); }
  @media (min-width: 992px) {
    .cms-elements .bg-white-o10 .shadow-left {
      background: linear-gradient(90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.05) 75%, transparent 100%); }
    .cms-elements .bg-white-o10 .shadow-right {
      background: linear-gradient(-90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.05) 75%, transparent 100%); }
    .cms-elements .bg-white-o10 .showSiteArticle-toggle {
      border-left: 1px solid #aeaeae; }
    .cms-elements .bg-white-o10 .container h2 {
      color: #575757; } }

.cms-elements .bg-highlight-o20 {
  background-color: rgba(0, 86, 157, 0.2); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-o20 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.2) 50%, rgba(0, 86, 157, 0.1) 75%, transparent 100%); }
    .cms-elements .bg-highlight-o20 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.2) 50%, rgba(0, 86, 157, 0.1) 75%, transparent 100%); } }

.cms-elements .bg-highlight-accessible-o20 {
  background-color: rgba(0, 86, 157, 0.2); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-accessible-o20 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.2) 50%, rgba(0, 86, 157, 0.1) 75%, transparent 100%); }
    .cms-elements .bg-highlight-accessible-o20 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.2) 50%, rgba(0, 86, 157, 0.1) 75%, transparent 100%); } }

.cms-elements .bg-black-o20 {
  background-color: rgba(0, 0, 0, 0.2); }
  @media (min-width: 992px) {
    .cms-elements .bg-black-o20 .shadow-left {
      background: linear-gradient(90deg, #000000 25%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.1) 75%, transparent 100%); }
    .cms-elements .bg-black-o20 .shadow-right {
      background: linear-gradient(-90deg, #000000 25%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.1) 75%, transparent 100%); } }

.cms-elements .bg-special-o20 {
  background-color: rgba(220, 55, 28, 0.2); }
  @media (min-width: 992px) {
    .cms-elements .bg-special-o20 .shadow-left {
      background: linear-gradient(90deg, #dc371c 25%, rgba(220, 55, 28, 0.2) 50%, rgba(220, 55, 28, 0.1) 75%, transparent 100%); }
    .cms-elements .bg-special-o20 .shadow-right {
      background: linear-gradient(-90deg, #dc371c 25%, rgba(220, 55, 28, 0.2) 50%, rgba(220, 55, 28, 0.1) 75%, transparent 100%); } }

.cms-elements .bg-confirm-o20 {
  background-color: rgba(48, 129, 54, 0.2); }
  @media (min-width: 992px) {
    .cms-elements .bg-confirm-o20 .shadow-left {
      background: linear-gradient(90deg, #308136 25%, rgba(48, 129, 54, 0.2) 50%, rgba(48, 129, 54, 0.1) 75%, transparent 100%); }
    .cms-elements .bg-confirm-o20 .shadow-right {
      background: linear-gradient(-90deg, #308136 25%, rgba(48, 129, 54, 0.2) 50%, rgba(48, 129, 54, 0.1) 75%, transparent 100%); } }

.cms-elements .bg-rating-o20 {
  background-color: rgba(245, 145, 0, 0.2); }
  @media (min-width: 992px) {
    .cms-elements .bg-rating-o20 .shadow-left {
      background: linear-gradient(90deg, #f59100 25%, rgba(245, 145, 0, 0.2) 50%, rgba(245, 145, 0, 0.1) 75%, transparent 100%); }
    .cms-elements .bg-rating-o20 .shadow-right {
      background: linear-gradient(-90deg, #f59100 25%, rgba(245, 145, 0, 0.2) 50%, rgba(245, 145, 0, 0.1) 75%, transparent 100%); } }

.cms-elements .bg-darkgrey-o20 {
  background-color: rgba(87, 87, 87, 0.2); }
  @media (min-width: 992px) {
    .cms-elements .bg-darkgrey-o20 .shadow-left {
      background: linear-gradient(90deg, #575757 25%, rgba(87, 87, 87, 0.2) 50%, rgba(87, 87, 87, 0.1) 75%, transparent 100%); }
    .cms-elements .bg-darkgrey-o20 .shadow-right {
      background: linear-gradient(-90deg, #575757 25%, rgba(87, 87, 87, 0.2) 50%, rgba(87, 87, 87, 0.1) 75%, transparent 100%); } }

.cms-elements .bg-lightgrey-o20 {
  background-color: rgba(227, 227, 227, 0.2); }
  @media (min-width: 992px) {
    .cms-elements .bg-lightgrey-o20 .shadow-left {
      background: linear-gradient(90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.2) 50%, rgba(227, 227, 227, 0.1) 75%, transparent 100%); }
    .cms-elements .bg-lightgrey-o20 .shadow-right {
      background: linear-gradient(-90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.2) 50%, rgba(227, 227, 227, 0.1) 75%, transparent 100%); } }

.cms-elements .bg-white-o20 {
  background-color: rgba(255, 255, 255, 0.2); }
  @media (min-width: 992px) {
    .cms-elements .bg-white-o20 .shadow-left {
      background: linear-gradient(90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 100%); }
    .cms-elements .bg-white-o20 .shadow-right {
      background: linear-gradient(-90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 100%); }
    .cms-elements .bg-white-o20 .showSiteArticle-toggle {
      border-left: 1px solid #aeaeae; }
    .cms-elements .bg-white-o20 .container h2 {
      color: #575757; } }

.cms-elements .bg-highlight-o30 {
  background-color: rgba(0, 86, 157, 0.3); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-o30 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.3) 50%, rgba(0, 86, 157, 0.15) 75%, transparent 100%); }
    .cms-elements .bg-highlight-o30 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.3) 50%, rgba(0, 86, 157, 0.15) 75%, transparent 100%); } }

.cms-elements .bg-highlight-accessible-o30 {
  background-color: rgba(0, 86, 157, 0.3); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-accessible-o30 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.3) 50%, rgba(0, 86, 157, 0.15) 75%, transparent 100%); }
    .cms-elements .bg-highlight-accessible-o30 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.3) 50%, rgba(0, 86, 157, 0.15) 75%, transparent 100%); } }

.cms-elements .bg-black-o30 {
  background-color: rgba(0, 0, 0, 0.3); }
  @media (min-width: 992px) {
    .cms-elements .bg-black-o30 .shadow-left {
      background: linear-gradient(90deg, #000000 25%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 75%, transparent 100%); }
    .cms-elements .bg-black-o30 .shadow-right {
      background: linear-gradient(-90deg, #000000 25%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 75%, transparent 100%); } }

.cms-elements .bg-special-o30 {
  background-color: rgba(220, 55, 28, 0.3); }
  @media (min-width: 992px) {
    .cms-elements .bg-special-o30 .shadow-left {
      background: linear-gradient(90deg, #dc371c 25%, rgba(220, 55, 28, 0.3) 50%, rgba(220, 55, 28, 0.15) 75%, transparent 100%); }
    .cms-elements .bg-special-o30 .shadow-right {
      background: linear-gradient(-90deg, #dc371c 25%, rgba(220, 55, 28, 0.3) 50%, rgba(220, 55, 28, 0.15) 75%, transparent 100%); } }

.cms-elements .bg-confirm-o30 {
  background-color: rgba(48, 129, 54, 0.3); }
  @media (min-width: 992px) {
    .cms-elements .bg-confirm-o30 .shadow-left {
      background: linear-gradient(90deg, #308136 25%, rgba(48, 129, 54, 0.3) 50%, rgba(48, 129, 54, 0.15) 75%, transparent 100%); }
    .cms-elements .bg-confirm-o30 .shadow-right {
      background: linear-gradient(-90deg, #308136 25%, rgba(48, 129, 54, 0.3) 50%, rgba(48, 129, 54, 0.15) 75%, transparent 100%); } }

.cms-elements .bg-rating-o30 {
  background-color: rgba(245, 145, 0, 0.3); }
  @media (min-width: 992px) {
    .cms-elements .bg-rating-o30 .shadow-left {
      background: linear-gradient(90deg, #f59100 25%, rgba(245, 145, 0, 0.3) 50%, rgba(245, 145, 0, 0.15) 75%, transparent 100%); }
    .cms-elements .bg-rating-o30 .shadow-right {
      background: linear-gradient(-90deg, #f59100 25%, rgba(245, 145, 0, 0.3) 50%, rgba(245, 145, 0, 0.15) 75%, transparent 100%); } }

.cms-elements .bg-darkgrey-o30 {
  background-color: rgba(87, 87, 87, 0.3); }
  @media (min-width: 992px) {
    .cms-elements .bg-darkgrey-o30 .shadow-left {
      background: linear-gradient(90deg, #575757 25%, rgba(87, 87, 87, 0.3) 50%, rgba(87, 87, 87, 0.15) 75%, transparent 100%); }
    .cms-elements .bg-darkgrey-o30 .shadow-right {
      background: linear-gradient(-90deg, #575757 25%, rgba(87, 87, 87, 0.3) 50%, rgba(87, 87, 87, 0.15) 75%, transparent 100%); } }

.cms-elements .bg-lightgrey-o30 {
  background-color: rgba(227, 227, 227, 0.3); }
  @media (min-width: 992px) {
    .cms-elements .bg-lightgrey-o30 .shadow-left {
      background: linear-gradient(90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.3) 50%, rgba(227, 227, 227, 0.15) 75%, transparent 100%); }
    .cms-elements .bg-lightgrey-o30 .shadow-right {
      background: linear-gradient(-90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.3) 50%, rgba(227, 227, 227, 0.15) 75%, transparent 100%); } }

.cms-elements .bg-white-o30 {
  background-color: rgba(255, 255, 255, 0.3); }
  @media (min-width: 992px) {
    .cms-elements .bg-white-o30 .shadow-left {
      background: linear-gradient(90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 100%); }
    .cms-elements .bg-white-o30 .shadow-right {
      background: linear-gradient(-90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 100%); }
    .cms-elements .bg-white-o30 .showSiteArticle-toggle {
      border-left: 1px solid #aeaeae; }
    .cms-elements .bg-white-o30 .container h2 {
      color: #575757; } }

.cms-elements .bg-highlight-o40 {
  background-color: rgba(0, 86, 157, 0.4); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-o40 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.4) 50%, rgba(0, 86, 157, 0.2) 75%, transparent 100%); }
    .cms-elements .bg-highlight-o40 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.4) 50%, rgba(0, 86, 157, 0.2) 75%, transparent 100%); } }

.cms-elements .bg-highlight-accessible-o40 {
  background-color: rgba(0, 86, 157, 0.4); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-accessible-o40 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.4) 50%, rgba(0, 86, 157, 0.2) 75%, transparent 100%); }
    .cms-elements .bg-highlight-accessible-o40 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.4) 50%, rgba(0, 86, 157, 0.2) 75%, transparent 100%); } }

.cms-elements .bg-black-o40 {
  background-color: rgba(0, 0, 0, 0.4); }
  @media (min-width: 992px) {
    .cms-elements .bg-black-o40 .shadow-left {
      background: linear-gradient(90deg, #000000 25%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.2) 75%, transparent 100%); }
    .cms-elements .bg-black-o40 .shadow-right {
      background: linear-gradient(-90deg, #000000 25%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.2) 75%, transparent 100%); } }

.cms-elements .bg-special-o40 {
  background-color: rgba(220, 55, 28, 0.4); }
  @media (min-width: 992px) {
    .cms-elements .bg-special-o40 .shadow-left {
      background: linear-gradient(90deg, #dc371c 25%, rgba(220, 55, 28, 0.4) 50%, rgba(220, 55, 28, 0.2) 75%, transparent 100%); }
    .cms-elements .bg-special-o40 .shadow-right {
      background: linear-gradient(-90deg, #dc371c 25%, rgba(220, 55, 28, 0.4) 50%, rgba(220, 55, 28, 0.2) 75%, transparent 100%); } }

.cms-elements .bg-confirm-o40 {
  background-color: rgba(48, 129, 54, 0.4); }
  @media (min-width: 992px) {
    .cms-elements .bg-confirm-o40 .shadow-left {
      background: linear-gradient(90deg, #308136 25%, rgba(48, 129, 54, 0.4) 50%, rgba(48, 129, 54, 0.2) 75%, transparent 100%); }
    .cms-elements .bg-confirm-o40 .shadow-right {
      background: linear-gradient(-90deg, #308136 25%, rgba(48, 129, 54, 0.4) 50%, rgba(48, 129, 54, 0.2) 75%, transparent 100%); } }

.cms-elements .bg-rating-o40 {
  background-color: rgba(245, 145, 0, 0.4); }
  @media (min-width: 992px) {
    .cms-elements .bg-rating-o40 .shadow-left {
      background: linear-gradient(90deg, #f59100 25%, rgba(245, 145, 0, 0.4) 50%, rgba(245, 145, 0, 0.2) 75%, transparent 100%); }
    .cms-elements .bg-rating-o40 .shadow-right {
      background: linear-gradient(-90deg, #f59100 25%, rgba(245, 145, 0, 0.4) 50%, rgba(245, 145, 0, 0.2) 75%, transparent 100%); } }

.cms-elements .bg-darkgrey-o40 {
  background-color: rgba(87, 87, 87, 0.4); }
  @media (min-width: 992px) {
    .cms-elements .bg-darkgrey-o40 .shadow-left {
      background: linear-gradient(90deg, #575757 25%, rgba(87, 87, 87, 0.4) 50%, rgba(87, 87, 87, 0.2) 75%, transparent 100%); }
    .cms-elements .bg-darkgrey-o40 .shadow-right {
      background: linear-gradient(-90deg, #575757 25%, rgba(87, 87, 87, 0.4) 50%, rgba(87, 87, 87, 0.2) 75%, transparent 100%); } }

.cms-elements .bg-lightgrey-o40 {
  background-color: rgba(227, 227, 227, 0.4); }
  @media (min-width: 992px) {
    .cms-elements .bg-lightgrey-o40 .shadow-left {
      background: linear-gradient(90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.4) 50%, rgba(227, 227, 227, 0.2) 75%, transparent 100%); }
    .cms-elements .bg-lightgrey-o40 .shadow-right {
      background: linear-gradient(-90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.4) 50%, rgba(227, 227, 227, 0.2) 75%, transparent 100%); } }

.cms-elements .bg-white-o40 {
  background-color: rgba(255, 255, 255, 0.4); }
  @media (min-width: 992px) {
    .cms-elements .bg-white-o40 .shadow-left {
      background: linear-gradient(90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 100%); }
    .cms-elements .bg-white-o40 .shadow-right {
      background: linear-gradient(-90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 100%); }
    .cms-elements .bg-white-o40 .showSiteArticle-toggle {
      border-left: 1px solid #aeaeae; }
    .cms-elements .bg-white-o40 .container h2 {
      color: #575757; } }

.cms-elements .bg-highlight-o50 {
  background-color: rgba(0, 86, 157, 0.5); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-o50 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.5) 50%, rgba(0, 86, 157, 0.25) 75%, transparent 100%); }
    .cms-elements .bg-highlight-o50 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.5) 50%, rgba(0, 86, 157, 0.25) 75%, transparent 100%); } }

.cms-elements .bg-highlight-accessible-o50 {
  background-color: rgba(0, 86, 157, 0.5); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-accessible-o50 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.5) 50%, rgba(0, 86, 157, 0.25) 75%, transparent 100%); }
    .cms-elements .bg-highlight-accessible-o50 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.5) 50%, rgba(0, 86, 157, 0.25) 75%, transparent 100%); } }

.cms-elements .bg-black-o50 {
  background-color: rgba(0, 0, 0, 0.5); }
  @media (min-width: 992px) {
    .cms-elements .bg-black-o50 .shadow-left {
      background: linear-gradient(90deg, #000000 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.25) 75%, transparent 100%); }
    .cms-elements .bg-black-o50 .shadow-right {
      background: linear-gradient(-90deg, #000000 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.25) 75%, transparent 100%); } }

.cms-elements .bg-special-o50 {
  background-color: rgba(220, 55, 28, 0.5); }
  @media (min-width: 992px) {
    .cms-elements .bg-special-o50 .shadow-left {
      background: linear-gradient(90deg, #dc371c 25%, rgba(220, 55, 28, 0.5) 50%, rgba(220, 55, 28, 0.25) 75%, transparent 100%); }
    .cms-elements .bg-special-o50 .shadow-right {
      background: linear-gradient(-90deg, #dc371c 25%, rgba(220, 55, 28, 0.5) 50%, rgba(220, 55, 28, 0.25) 75%, transparent 100%); } }

.cms-elements .bg-confirm-o50 {
  background-color: rgba(48, 129, 54, 0.5); }
  @media (min-width: 992px) {
    .cms-elements .bg-confirm-o50 .shadow-left {
      background: linear-gradient(90deg, #308136 25%, rgba(48, 129, 54, 0.5) 50%, rgba(48, 129, 54, 0.25) 75%, transparent 100%); }
    .cms-elements .bg-confirm-o50 .shadow-right {
      background: linear-gradient(-90deg, #308136 25%, rgba(48, 129, 54, 0.5) 50%, rgba(48, 129, 54, 0.25) 75%, transparent 100%); } }

.cms-elements .bg-rating-o50 {
  background-color: rgba(245, 145, 0, 0.5); }
  @media (min-width: 992px) {
    .cms-elements .bg-rating-o50 .shadow-left {
      background: linear-gradient(90deg, #f59100 25%, rgba(245, 145, 0, 0.5) 50%, rgba(245, 145, 0, 0.25) 75%, transparent 100%); }
    .cms-elements .bg-rating-o50 .shadow-right {
      background: linear-gradient(-90deg, #f59100 25%, rgba(245, 145, 0, 0.5) 50%, rgba(245, 145, 0, 0.25) 75%, transparent 100%); } }

.cms-elements .bg-darkgrey-o50 {
  background-color: rgba(87, 87, 87, 0.5); }
  @media (min-width: 992px) {
    .cms-elements .bg-darkgrey-o50 .shadow-left {
      background: linear-gradient(90deg, #575757 25%, rgba(87, 87, 87, 0.5) 50%, rgba(87, 87, 87, 0.25) 75%, transparent 100%); }
    .cms-elements .bg-darkgrey-o50 .shadow-right {
      background: linear-gradient(-90deg, #575757 25%, rgba(87, 87, 87, 0.5) 50%, rgba(87, 87, 87, 0.25) 75%, transparent 100%); } }

.cms-elements .bg-lightgrey-o50 {
  background-color: rgba(227, 227, 227, 0.5); }
  @media (min-width: 992px) {
    .cms-elements .bg-lightgrey-o50 .shadow-left {
      background: linear-gradient(90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.5) 50%, rgba(227, 227, 227, 0.25) 75%, transparent 100%); }
    .cms-elements .bg-lightgrey-o50 .shadow-right {
      background: linear-gradient(-90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.5) 50%, rgba(227, 227, 227, 0.25) 75%, transparent 100%); } }

.cms-elements .bg-white-o50 {
  background-color: rgba(255, 255, 255, 0.5); }
  @media (min-width: 992px) {
    .cms-elements .bg-white-o50 .shadow-left {
      background: linear-gradient(90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, transparent 100%); }
    .cms-elements .bg-white-o50 .shadow-right {
      background: linear-gradient(-90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.25) 75%, transparent 100%); }
    .cms-elements .bg-white-o50 .showSiteArticle-toggle {
      border-left: 1px solid #aeaeae; }
    .cms-elements .bg-white-o50 .container h2 {
      color: #575757; } }

.cms-elements .bg-highlight-o60 {
  background-color: rgba(0, 86, 157, 0.6); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-o60 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.6) 50%, rgba(0, 86, 157, 0.3) 75%, transparent 100%); }
    .cms-elements .bg-highlight-o60 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.6) 50%, rgba(0, 86, 157, 0.3) 75%, transparent 100%); } }

.cms-elements .bg-highlight-accessible-o60 {
  background-color: rgba(0, 86, 157, 0.6); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-accessible-o60 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.6) 50%, rgba(0, 86, 157, 0.3) 75%, transparent 100%); }
    .cms-elements .bg-highlight-accessible-o60 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.6) 50%, rgba(0, 86, 157, 0.3) 75%, transparent 100%); } }

.cms-elements .bg-black-o60 {
  background-color: rgba(0, 0, 0, 0.6); }
  @media (min-width: 992px) {
    .cms-elements .bg-black-o60 .shadow-left {
      background: linear-gradient(90deg, #000000 25%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.3) 75%, transparent 100%); }
    .cms-elements .bg-black-o60 .shadow-right {
      background: linear-gradient(-90deg, #000000 25%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.3) 75%, transparent 100%); } }

.cms-elements .bg-special-o60 {
  background-color: rgba(220, 55, 28, 0.6); }
  @media (min-width: 992px) {
    .cms-elements .bg-special-o60 .shadow-left {
      background: linear-gradient(90deg, #dc371c 25%, rgba(220, 55, 28, 0.6) 50%, rgba(220, 55, 28, 0.3) 75%, transparent 100%); }
    .cms-elements .bg-special-o60 .shadow-right {
      background: linear-gradient(-90deg, #dc371c 25%, rgba(220, 55, 28, 0.6) 50%, rgba(220, 55, 28, 0.3) 75%, transparent 100%); } }

.cms-elements .bg-confirm-o60 {
  background-color: rgba(48, 129, 54, 0.6); }
  @media (min-width: 992px) {
    .cms-elements .bg-confirm-o60 .shadow-left {
      background: linear-gradient(90deg, #308136 25%, rgba(48, 129, 54, 0.6) 50%, rgba(48, 129, 54, 0.3) 75%, transparent 100%); }
    .cms-elements .bg-confirm-o60 .shadow-right {
      background: linear-gradient(-90deg, #308136 25%, rgba(48, 129, 54, 0.6) 50%, rgba(48, 129, 54, 0.3) 75%, transparent 100%); } }

.cms-elements .bg-rating-o60 {
  background-color: rgba(245, 145, 0, 0.6); }
  @media (min-width: 992px) {
    .cms-elements .bg-rating-o60 .shadow-left {
      background: linear-gradient(90deg, #f59100 25%, rgba(245, 145, 0, 0.6) 50%, rgba(245, 145, 0, 0.3) 75%, transparent 100%); }
    .cms-elements .bg-rating-o60 .shadow-right {
      background: linear-gradient(-90deg, #f59100 25%, rgba(245, 145, 0, 0.6) 50%, rgba(245, 145, 0, 0.3) 75%, transparent 100%); } }

.cms-elements .bg-darkgrey-o60 {
  background-color: rgba(87, 87, 87, 0.6); }
  @media (min-width: 992px) {
    .cms-elements .bg-darkgrey-o60 .shadow-left {
      background: linear-gradient(90deg, #575757 25%, rgba(87, 87, 87, 0.6) 50%, rgba(87, 87, 87, 0.3) 75%, transparent 100%); }
    .cms-elements .bg-darkgrey-o60 .shadow-right {
      background: linear-gradient(-90deg, #575757 25%, rgba(87, 87, 87, 0.6) 50%, rgba(87, 87, 87, 0.3) 75%, transparent 100%); } }

.cms-elements .bg-lightgrey-o60 {
  background-color: rgba(227, 227, 227, 0.6); }
  @media (min-width: 992px) {
    .cms-elements .bg-lightgrey-o60 .shadow-left {
      background: linear-gradient(90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.6) 50%, rgba(227, 227, 227, 0.3) 75%, transparent 100%); }
    .cms-elements .bg-lightgrey-o60 .shadow-right {
      background: linear-gradient(-90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.6) 50%, rgba(227, 227, 227, 0.3) 75%, transparent 100%); } }

.cms-elements .bg-white-o60 {
  background-color: rgba(255, 255, 255, 0.6); }
  @media (min-width: 992px) {
    .cms-elements .bg-white-o60 .shadow-left {
      background: linear-gradient(90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 100%); }
    .cms-elements .bg-white-o60 .shadow-right {
      background: linear-gradient(-90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0.3) 75%, transparent 100%); }
    .cms-elements .bg-white-o60 .showSiteArticle-toggle {
      border-left: 1px solid #aeaeae; }
    .cms-elements .bg-white-o60 .container h2 {
      color: #575757; } }

.cms-elements .bg-highlight-o70 {
  background-color: rgba(0, 86, 157, 0.7); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-o70 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.7) 50%, rgba(0, 86, 157, 0.35) 75%, transparent 100%); }
    .cms-elements .bg-highlight-o70 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.7) 50%, rgba(0, 86, 157, 0.35) 75%, transparent 100%); } }

.cms-elements .bg-highlight-accessible-o70 {
  background-color: rgba(0, 86, 157, 0.7); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-accessible-o70 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.7) 50%, rgba(0, 86, 157, 0.35) 75%, transparent 100%); }
    .cms-elements .bg-highlight-accessible-o70 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.7) 50%, rgba(0, 86, 157, 0.35) 75%, transparent 100%); } }

.cms-elements .bg-black-o70 {
  background-color: rgba(0, 0, 0, 0.7); }
  @media (min-width: 992px) {
    .cms-elements .bg-black-o70 .shadow-left {
      background: linear-gradient(90deg, #000000 25%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.35) 75%, transparent 100%); }
    .cms-elements .bg-black-o70 .shadow-right {
      background: linear-gradient(-90deg, #000000 25%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.35) 75%, transparent 100%); } }

.cms-elements .bg-special-o70 {
  background-color: rgba(220, 55, 28, 0.7); }
  @media (min-width: 992px) {
    .cms-elements .bg-special-o70 .shadow-left {
      background: linear-gradient(90deg, #dc371c 25%, rgba(220, 55, 28, 0.7) 50%, rgba(220, 55, 28, 0.35) 75%, transparent 100%); }
    .cms-elements .bg-special-o70 .shadow-right {
      background: linear-gradient(-90deg, #dc371c 25%, rgba(220, 55, 28, 0.7) 50%, rgba(220, 55, 28, 0.35) 75%, transparent 100%); } }

.cms-elements .bg-confirm-o70 {
  background-color: rgba(48, 129, 54, 0.7); }
  @media (min-width: 992px) {
    .cms-elements .bg-confirm-o70 .shadow-left {
      background: linear-gradient(90deg, #308136 25%, rgba(48, 129, 54, 0.7) 50%, rgba(48, 129, 54, 0.35) 75%, transparent 100%); }
    .cms-elements .bg-confirm-o70 .shadow-right {
      background: linear-gradient(-90deg, #308136 25%, rgba(48, 129, 54, 0.7) 50%, rgba(48, 129, 54, 0.35) 75%, transparent 100%); } }

.cms-elements .bg-rating-o70 {
  background-color: rgba(245, 145, 0, 0.7); }
  @media (min-width: 992px) {
    .cms-elements .bg-rating-o70 .shadow-left {
      background: linear-gradient(90deg, #f59100 25%, rgba(245, 145, 0, 0.7) 50%, rgba(245, 145, 0, 0.35) 75%, transparent 100%); }
    .cms-elements .bg-rating-o70 .shadow-right {
      background: linear-gradient(-90deg, #f59100 25%, rgba(245, 145, 0, 0.7) 50%, rgba(245, 145, 0, 0.35) 75%, transparent 100%); } }

.cms-elements .bg-darkgrey-o70 {
  background-color: rgba(87, 87, 87, 0.7); }
  @media (min-width: 992px) {
    .cms-elements .bg-darkgrey-o70 .shadow-left {
      background: linear-gradient(90deg, #575757 25%, rgba(87, 87, 87, 0.7) 50%, rgba(87, 87, 87, 0.35) 75%, transparent 100%); }
    .cms-elements .bg-darkgrey-o70 .shadow-right {
      background: linear-gradient(-90deg, #575757 25%, rgba(87, 87, 87, 0.7) 50%, rgba(87, 87, 87, 0.35) 75%, transparent 100%); } }

.cms-elements .bg-lightgrey-o70 {
  background-color: rgba(227, 227, 227, 0.7); }
  @media (min-width: 992px) {
    .cms-elements .bg-lightgrey-o70 .shadow-left {
      background: linear-gradient(90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.7) 50%, rgba(227, 227, 227, 0.35) 75%, transparent 100%); }
    .cms-elements .bg-lightgrey-o70 .shadow-right {
      background: linear-gradient(-90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.7) 50%, rgba(227, 227, 227, 0.35) 75%, transparent 100%); } }

.cms-elements .bg-white-o70 {
  background-color: rgba(255, 255, 255, 0.7); }
  @media (min-width: 992px) {
    .cms-elements .bg-white-o70 .shadow-left {
      background: linear-gradient(90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.35) 75%, transparent 100%); }
    .cms-elements .bg-white-o70 .shadow-right {
      background: linear-gradient(-90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.35) 75%, transparent 100%); }
    .cms-elements .bg-white-o70 .showSiteArticle-toggle {
      border-left: 1px solid #aeaeae; }
    .cms-elements .bg-white-o70 .container h2 {
      color: #575757; } }

.cms-elements .bg-highlight-o80 {
  background-color: rgba(0, 86, 157, 0.8); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-o80 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.8) 50%, rgba(0, 86, 157, 0.4) 75%, transparent 100%); }
    .cms-elements .bg-highlight-o80 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.8) 50%, rgba(0, 86, 157, 0.4) 75%, transparent 100%); } }

.cms-elements .bg-highlight-accessible-o80 {
  background-color: rgba(0, 86, 157, 0.8); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-accessible-o80 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.8) 50%, rgba(0, 86, 157, 0.4) 75%, transparent 100%); }
    .cms-elements .bg-highlight-accessible-o80 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.8) 50%, rgba(0, 86, 157, 0.4) 75%, transparent 100%); } }

.cms-elements .bg-black-o80 {
  background-color: rgba(0, 0, 0, 0.8); }
  @media (min-width: 992px) {
    .cms-elements .bg-black-o80 .shadow-left {
      background: linear-gradient(90deg, #000000 25%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.4) 75%, transparent 100%); }
    .cms-elements .bg-black-o80 .shadow-right {
      background: linear-gradient(-90deg, #000000 25%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.4) 75%, transparent 100%); } }

.cms-elements .bg-special-o80 {
  background-color: rgba(220, 55, 28, 0.8); }
  @media (min-width: 992px) {
    .cms-elements .bg-special-o80 .shadow-left {
      background: linear-gradient(90deg, #dc371c 25%, rgba(220, 55, 28, 0.8) 50%, rgba(220, 55, 28, 0.4) 75%, transparent 100%); }
    .cms-elements .bg-special-o80 .shadow-right {
      background: linear-gradient(-90deg, #dc371c 25%, rgba(220, 55, 28, 0.8) 50%, rgba(220, 55, 28, 0.4) 75%, transparent 100%); } }

.cms-elements .bg-confirm-o80 {
  background-color: rgba(48, 129, 54, 0.8); }
  @media (min-width: 992px) {
    .cms-elements .bg-confirm-o80 .shadow-left {
      background: linear-gradient(90deg, #308136 25%, rgba(48, 129, 54, 0.8) 50%, rgba(48, 129, 54, 0.4) 75%, transparent 100%); }
    .cms-elements .bg-confirm-o80 .shadow-right {
      background: linear-gradient(-90deg, #308136 25%, rgba(48, 129, 54, 0.8) 50%, rgba(48, 129, 54, 0.4) 75%, transparent 100%); } }

.cms-elements .bg-rating-o80 {
  background-color: rgba(245, 145, 0, 0.8); }
  @media (min-width: 992px) {
    .cms-elements .bg-rating-o80 .shadow-left {
      background: linear-gradient(90deg, #f59100 25%, rgba(245, 145, 0, 0.8) 50%, rgba(245, 145, 0, 0.4) 75%, transparent 100%); }
    .cms-elements .bg-rating-o80 .shadow-right {
      background: linear-gradient(-90deg, #f59100 25%, rgba(245, 145, 0, 0.8) 50%, rgba(245, 145, 0, 0.4) 75%, transparent 100%); } }

.cms-elements .bg-darkgrey-o80 {
  background-color: rgba(87, 87, 87, 0.8); }
  @media (min-width: 992px) {
    .cms-elements .bg-darkgrey-o80 .shadow-left {
      background: linear-gradient(90deg, #575757 25%, rgba(87, 87, 87, 0.8) 50%, rgba(87, 87, 87, 0.4) 75%, transparent 100%); }
    .cms-elements .bg-darkgrey-o80 .shadow-right {
      background: linear-gradient(-90deg, #575757 25%, rgba(87, 87, 87, 0.8) 50%, rgba(87, 87, 87, 0.4) 75%, transparent 100%); } }

.cms-elements .bg-lightgrey-o80 {
  background-color: rgba(227, 227, 227, 0.8); }
  @media (min-width: 992px) {
    .cms-elements .bg-lightgrey-o80 .shadow-left {
      background: linear-gradient(90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.8) 50%, rgba(227, 227, 227, 0.4) 75%, transparent 100%); }
    .cms-elements .bg-lightgrey-o80 .shadow-right {
      background: linear-gradient(-90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.8) 50%, rgba(227, 227, 227, 0.4) 75%, transparent 100%); } }

.cms-elements .bg-white-o80 {
  background-color: rgba(255, 255, 255, 0.8); }
  @media (min-width: 992px) {
    .cms-elements .bg-white-o80 .shadow-left {
      background: linear-gradient(90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 100%); }
    .cms-elements .bg-white-o80 .shadow-right {
      background: linear-gradient(-90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 100%); }
    .cms-elements .bg-white-o80 .showSiteArticle-toggle {
      border-left: 1px solid #aeaeae; }
    .cms-elements .bg-white-o80 .container h2 {
      color: #575757; } }

.cms-elements .bg-highlight-o90 {
  background-color: rgba(0, 86, 157, 0.9); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-o90 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.9) 50%, rgba(0, 86, 157, 0.45) 75%, transparent 100%); }
    .cms-elements .bg-highlight-o90 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.9) 50%, rgba(0, 86, 157, 0.45) 75%, transparent 100%); } }

.cms-elements .bg-highlight-accessible-o90 {
  background-color: rgba(0, 86, 157, 0.9); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-accessible-o90 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 0.9) 50%, rgba(0, 86, 157, 0.45) 75%, transparent 100%); }
    .cms-elements .bg-highlight-accessible-o90 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 0.9) 50%, rgba(0, 86, 157, 0.45) 75%, transparent 100%); } }

.cms-elements .bg-black-o90 {
  background-color: rgba(0, 0, 0, 0.9); }
  @media (min-width: 992px) {
    .cms-elements .bg-black-o90 .shadow-left {
      background: linear-gradient(90deg, #000000 25%, rgba(0, 0, 0, 0.9) 50%, rgba(0, 0, 0, 0.45) 75%, transparent 100%); }
    .cms-elements .bg-black-o90 .shadow-right {
      background: linear-gradient(-90deg, #000000 25%, rgba(0, 0, 0, 0.9) 50%, rgba(0, 0, 0, 0.45) 75%, transparent 100%); } }

.cms-elements .bg-special-o90 {
  background-color: rgba(220, 55, 28, 0.9); }
  @media (min-width: 992px) {
    .cms-elements .bg-special-o90 .shadow-left {
      background: linear-gradient(90deg, #dc371c 25%, rgba(220, 55, 28, 0.9) 50%, rgba(220, 55, 28, 0.45) 75%, transparent 100%); }
    .cms-elements .bg-special-o90 .shadow-right {
      background: linear-gradient(-90deg, #dc371c 25%, rgba(220, 55, 28, 0.9) 50%, rgba(220, 55, 28, 0.45) 75%, transparent 100%); } }

.cms-elements .bg-confirm-o90 {
  background-color: rgba(48, 129, 54, 0.9); }
  @media (min-width: 992px) {
    .cms-elements .bg-confirm-o90 .shadow-left {
      background: linear-gradient(90deg, #308136 25%, rgba(48, 129, 54, 0.9) 50%, rgba(48, 129, 54, 0.45) 75%, transparent 100%); }
    .cms-elements .bg-confirm-o90 .shadow-right {
      background: linear-gradient(-90deg, #308136 25%, rgba(48, 129, 54, 0.9) 50%, rgba(48, 129, 54, 0.45) 75%, transparent 100%); } }

.cms-elements .bg-rating-o90 {
  background-color: rgba(245, 145, 0, 0.9); }
  @media (min-width: 992px) {
    .cms-elements .bg-rating-o90 .shadow-left {
      background: linear-gradient(90deg, #f59100 25%, rgba(245, 145, 0, 0.9) 50%, rgba(245, 145, 0, 0.45) 75%, transparent 100%); }
    .cms-elements .bg-rating-o90 .shadow-right {
      background: linear-gradient(-90deg, #f59100 25%, rgba(245, 145, 0, 0.9) 50%, rgba(245, 145, 0, 0.45) 75%, transparent 100%); } }

.cms-elements .bg-darkgrey-o90 {
  background-color: rgba(87, 87, 87, 0.9); }
  @media (min-width: 992px) {
    .cms-elements .bg-darkgrey-o90 .shadow-left {
      background: linear-gradient(90deg, #575757 25%, rgba(87, 87, 87, 0.9) 50%, rgba(87, 87, 87, 0.45) 75%, transparent 100%); }
    .cms-elements .bg-darkgrey-o90 .shadow-right {
      background: linear-gradient(-90deg, #575757 25%, rgba(87, 87, 87, 0.9) 50%, rgba(87, 87, 87, 0.45) 75%, transparent 100%); } }

.cms-elements .bg-lightgrey-o90 {
  background-color: rgba(227, 227, 227, 0.9); }
  @media (min-width: 992px) {
    .cms-elements .bg-lightgrey-o90 .shadow-left {
      background: linear-gradient(90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.9) 50%, rgba(227, 227, 227, 0.45) 75%, transparent 100%); }
    .cms-elements .bg-lightgrey-o90 .shadow-right {
      background: linear-gradient(-90deg, #e3e3e3 25%, rgba(227, 227, 227, 0.9) 50%, rgba(227, 227, 227, 0.45) 75%, transparent 100%); } }

.cms-elements .bg-white-o90 {
  background-color: rgba(255, 255, 255, 0.9); }
  @media (min-width: 992px) {
    .cms-elements .bg-white-o90 .shadow-left {
      background: linear-gradient(90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.45) 75%, transparent 100%); }
    .cms-elements .bg-white-o90 .shadow-right {
      background: linear-gradient(-90deg, #FFFFFF 25%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.45) 75%, transparent 100%); }
    .cms-elements .bg-white-o90 .showSiteArticle-toggle {
      border-left: 1px solid #aeaeae; }
    .cms-elements .bg-white-o90 .container h2 {
      color: #575757; } }

.cms-elements .bg-highlight-o100 {
  background-color: rgba(0, 86, 157, 1); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-o100 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 1) 50%, rgba(0, 86, 157, 0.5) 75%, transparent 100%); }
    .cms-elements .bg-highlight-o100 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 1) 50%, rgba(0, 86, 157, 0.5) 75%, transparent 100%); } }

.cms-elements .bg-highlight-accessible-o100 {
  background-color: rgba(0, 86, 157, 1); }
  @media (min-width: 992px) {
    .cms-elements .bg-highlight-accessible-o100 .shadow-left {
      background: linear-gradient(90deg, #00569d 25%, rgba(0, 86, 157, 1) 50%, rgba(0, 86, 157, 0.5) 75%, transparent 100%); }
    .cms-elements .bg-highlight-accessible-o100 .shadow-right {
      background: linear-gradient(-90deg, #00569d 25%, rgba(0, 86, 157, 1) 50%, rgba(0, 86, 157, 0.5) 75%, transparent 100%); } }

.cms-elements .bg-black-o100 {
  background-color: rgba(0, 0, 0, 1); }
  @media (min-width: 992px) {
    .cms-elements .bg-black-o100 .shadow-left {
      background: linear-gradient(90deg, #000000 25%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0.5) 75%, transparent 100%); }
    .cms-elements .bg-black-o100 .shadow-right {
      background: linear-gradient(-90deg, #000000 25%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0.5) 75%, transparent 100%); } }

.cms-elements .bg-special-o100 {
  background-color: rgba(220, 55, 28, 1); }
  @media (min-width: 992px) {
    .cms-elements .bg-special-o100 .shadow-left {
      background: linear-gradient(90deg, #dc371c 25%, rgba(220, 55, 28, 1) 50%, rgba(220, 55, 28, 0.5) 75%, transparent 100%); }
    .cms-elements .bg-special-o100 .shadow-right {
      background: linear-gradient(-90deg, #dc371c 25%, rgba(220, 55, 28, 1) 50%, rgba(220, 55, 28, 0.5) 75%, transparent 100%); } }

.cms-elements .bg-confirm-o100 {
  background-color: rgba(48, 129, 54, 1); }
  @media (min-width: 992px) {
    .cms-elements .bg-confirm-o100 .shadow-left {
      background: linear-gradient(90deg, #308136 25%, rgba(48, 129, 54, 1) 50%, rgba(48, 129, 54, 0.5) 75%, transparent 100%); }
    .cms-elements .bg-confirm-o100 .shadow-right {
      background: linear-gradient(-90deg, #308136 25%, rgba(48, 129, 54, 1) 50%, rgba(48, 129, 54, 0.5) 75%, transparent 100%); } }

.cms-elements .bg-rating-o100 {
  background-color: rgba(245, 145, 0, 1); }
  @media (min-width: 992px) {
    .cms-elements .bg-rating-o100 .shadow-left {
      background: linear-gradient(90deg, #f59100 25%, rgba(245, 145, 0, 1) 50%, rgba(245, 145, 0, 0.5) 75%, transparent 100%); }
    .cms-elements .bg-rating-o100 .shadow-right {
      background: linear-gradient(-90deg, #f59100 25%, rgba(245, 145, 0, 1) 50%, rgba(245, 145, 0, 0.5) 75%, transparent 100%); } }

.cms-elements .bg-darkgrey-o100 {
  background-color: rgba(87, 87, 87, 1); }
  @media (min-width: 992px) {
    .cms-elements .bg-darkgrey-o100 .shadow-left {
      background: linear-gradient(90deg, #575757 25%, rgba(87, 87, 87, 1) 50%, rgba(87, 87, 87, 0.5) 75%, transparent 100%); }
    .cms-elements .bg-darkgrey-o100 .shadow-right {
      background: linear-gradient(-90deg, #575757 25%, rgba(87, 87, 87, 1) 50%, rgba(87, 87, 87, 0.5) 75%, transparent 100%); } }

.cms-elements .bg-lightgrey-o100 {
  background-color: rgba(227, 227, 227, 1); }
  @media (min-width: 992px) {
    .cms-elements .bg-lightgrey-o100 .shadow-left {
      background: linear-gradient(90deg, #e3e3e3 25%, rgba(227, 227, 227, 1) 50%, rgba(227, 227, 227, 0.5) 75%, transparent 100%); }
    .cms-elements .bg-lightgrey-o100 .shadow-right {
      background: linear-gradient(-90deg, #e3e3e3 25%, rgba(227, 227, 227, 1) 50%, rgba(227, 227, 227, 0.5) 75%, transparent 100%); } }

.cms-elements .bg-white-o100 {
  background-color: rgba(255, 255, 255, 1); }
  @media (min-width: 992px) {
    .cms-elements .bg-white-o100 .shadow-left {
      background: linear-gradient(90deg, #FFFFFF 25%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.5) 75%, transparent 100%); }
    .cms-elements .bg-white-o100 .shadow-right {
      background: linear-gradient(-90deg, #FFFFFF 25%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.5) 75%, transparent 100%); }
    .cms-elements .bg-white-o100 .showSiteArticle-toggle {
      border-left: 1px solid #aeaeae; }
    .cms-elements .bg-white-o100 .container h2 {
      color: #575757; } }

.cms-elements div.highlight {
  background-color: #00569d; }

.cms-elements div.black {
  background-color: #000000; }

.cms-elements div.special {
  background-color: #dc371c; }

.cms-elements div.confirm {
  background-color: #308136; }

.cms-elements div.rating {
  background-color: #f59100; }

.cms-elements div.row.full-width-bg {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden; }
  @media (max-width: 767.98px) {
    .cms-elements div.row.full-width-bg {
      background-size: cover !important; } }
  .cms-elements div.row.full-width-bg .shadow-slider {
    position: relative; }
    @media (min-width: 992px) {
      .cms-elements div.row.full-width-bg .shadow-slider {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw; } }
    @media (max-width: 991.98px) {
      .cms-elements div.row.full-width-bg .shadow-slider {
        flex: 0 0 100%;
        max-width: 100%; } }
  .cms-elements div.row.full-width-bg .containter {
    max-width: 100% !important; }

.cms-elements .bg-image-layer {
  background-position-y: center; }
  .cms-elements .bg-image-layer.full-width-bg {
    left: 50%;
    right: 50%;
    background-size: cover;
    background-repeat: no-repeat; }
    .cms-elements .bg-image-layer.full-width-bg.bg-image-layer-fixed {
      margin-left: -50vw;
      margin-right: -50vw; }
  .cms-elements .bg-image-layer.bg-image-layer-fixed {
    height: 100vh !important;
    width: unset !important;
    z-index: 0;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    pointer-events: none; }

.cms-elements div.dark.box3, .cms-elements div.dark.box4, .cms-elements div.light.box3, .cms-elements div.light.box4, .cms-elements div.highlight.box3, .cms-elements div.highlight.box4, .cms-elements div.darkgrey.box3, .cms-elements div.darkgrey.box4, .cms-elements div.lightgrey.box3, .cms-elements div.lightgrey.box4 {
  background-clip: content-box; }

.cms-elements div.dark form, .cms-elements div.dark .cms-slider, .cms-elements div.light form, .cms-elements div.light .cms-slider, .cms-elements div.highlight form, .cms-elements div.highlight .cms-slider, .cms-elements div.darkgrey form, .cms-elements div.darkgrey .cms-slider, .cms-elements div.lightgrey form, .cms-elements div.lightgrey .cms-slider {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.cms-elements div.dark a.product-item, .cms-elements div.light a.product-item, .cms-elements div.highlight a.product-item, .cms-elements div.darkgrey a.product-item, .cms-elements div.lightgrey a.product-item {
  padding-bottom: 1rem; }

.cms-elements div.hasBgImage, .cms-elements div.hasBgVideo {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover; }
  .cms-elements div.hasBgImage p, .cms-elements div.hasBgImage img, .cms-elements div.hasBgImage h1, .cms-elements div.hasBgImage h2, .cms-elements div.hasBgImage h3, .cms-elements div.hasBgImage h4, .cms-elements div.hasBgImage h5, .cms-elements div.hasBgImage h6, .cms-elements div.hasBgImage a, .cms-elements div.hasBgImage ul, .cms-elements div.hasBgImage li, .cms-elements div.hasBgImage input.btn, .cms-elements div.hasBgImage span, .cms-elements div.hasBgVideo p, .cms-elements div.hasBgVideo img, .cms-elements div.hasBgVideo h1, .cms-elements div.hasBgVideo h2, .cms-elements div.hasBgVideo h3, .cms-elements div.hasBgVideo h4, .cms-elements div.hasBgVideo h5, .cms-elements div.hasBgVideo h6, .cms-elements div.hasBgVideo a, .cms-elements div.hasBgVideo ul, .cms-elements div.hasBgVideo li, .cms-elements div.hasBgVideo input.btn, .cms-elements div.hasBgVideo span {
    position: relative; }
  .cms-elements div.hasBgImage div.bg-layer, .cms-elements div.hasBgVideo div.bg-layer {
    margin-top: 0;
    background-color: #000000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: .7; }
  .cms-elements div.hasBgImage div.bg-image-layer, .cms-elements div.hasBgVideo div.bg-image-layer {
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0); }
  .cms-elements div.hasBgImage .videoBgContainer, .cms-elements div.hasBgVideo .videoBgContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    margin: 0; }
    .cms-elements div.hasBgImage .videoBgContainer video.bg-video, .cms-elements div.hasBgVideo .videoBgContainer video.bg-video {
      margin-top: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      min-height: 100%;
      min-width: 100%;
      -o-object-fit: cover;
         object-fit: cover; }
      .cms-elements div.hasBgImage .videoBgContainer video.bg-video.center, .cms-elements div.hasBgVideo .videoBgContainer video.bg-video.center {
        top: 50%;
        transform: translate(0, -50%); }
        .cms-elements div.hasBgImage .videoBgContainer video.bg-video.center.center, .cms-elements div.hasBgVideo .videoBgContainer video.bg-video.center.center {
          left: 50%;
          transform: translate(-50%, -50%); }
        .cms-elements div.hasBgImage .videoBgContainer video.bg-video.center.right, .cms-elements div.hasBgVideo .videoBgContainer video.bg-video.center.right {
          left: 100%;
          transform: translate(-100%, -50%); }
        .cms-elements div.hasBgImage .videoBgContainer video.bg-video.center.left, .cms-elements div.hasBgVideo .videoBgContainer video.bg-video.center.left {
          left: 0;
          transform: translate(0, -50%); }
      .cms-elements div.hasBgImage .videoBgContainer video.bg-video.bottom, .cms-elements div.hasBgVideo .videoBgContainer video.bg-video.bottom {
        top: 100%; }
        .cms-elements div.hasBgImage .videoBgContainer video.bg-video.bottom.center, .cms-elements div.hasBgVideo .videoBgContainer video.bg-video.bottom.center {
          left: 50%;
          transform: translate(-50%, -100%); }
        .cms-elements div.hasBgImage .videoBgContainer video.bg-video.bottom.right, .cms-elements div.hasBgVideo .videoBgContainer video.bg-video.bottom.right {
          left: 100%;
          transform: translate(-100%, -100%); }
        .cms-elements div.hasBgImage .videoBgContainer video.bg-video.bottom.left, .cms-elements div.hasBgVideo .videoBgContainer video.bg-video.bottom.left {
          left: 0;
          transform: translate(0, -100%); }
      .cms-elements div.hasBgImage .videoBgContainer video.bg-video.top, .cms-elements div.hasBgVideo .videoBgContainer video.bg-video.top {
        top: 0;
        transform: translate(-50%, 0); }
        .cms-elements div.hasBgImage .videoBgContainer video.bg-video.top.center, .cms-elements div.hasBgVideo .videoBgContainer video.bg-video.top.center {
          left: 50%;
          transform: translate(-50%, 0); }
        .cms-elements div.hasBgImage .videoBgContainer video.bg-video.top.right, .cms-elements div.hasBgVideo .videoBgContainer video.bg-video.top.right {
          left: 100%;
          transform: translate(-100%, 0); }
        .cms-elements div.hasBgImage .videoBgContainer video.bg-video.top.left, .cms-elements div.hasBgVideo .videoBgContainer video.bg-video.top.left {
          left: 0;
          transform: translate(0, 0); }
  .cms-elements div.hasBgImage form, .cms-elements div.hasBgImage .cms-slider, .cms-elements div.hasBgVideo form, .cms-elements div.hasBgVideo .cms-slider {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .cms-elements div.hasBgImage a.product-item, .cms-elements div.hasBgVideo a.product-item {
    padding-bottom: 1rem; }
  .cms-elements div.hasBgImage.box-text, .cms-elements div.hasBgVideo.box-text {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .cms-elements div.hasBgImage.parallaxBg, .cms-elements div.hasBgVideo.parallaxBg {
    height: 100%;
    background-repeat: no-repeat;
    overflow: hidden; }

.cms-elements.cms-page > .row:first-child {
  margin-top: 0; }

.cms-elements .cms-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: #e3e3e3;
  color: #575757; }
  .cms-elements .cms-preview img {
    max-height: 20rem; }
  .cms-elements .cms-preview h2 {
    margin-top: 1rem; }

.cms-elements .categoryDescription > .row:first-child {
  margin-top: 0; }

.cms-elements span.highlight {
  color: #00569d; }

.cms-elements [data-elementanimation] {
  visibility: hidden; }

.cms-elements .zoom-effect img {
  transition: transform 1s ease-in-out; }

.cms-elements .zoom-effect:hover img {
  transform: scale(1.1); }

.cms-elements img.zoom-effect {
  transition: transform 1s ease-in-out; }
  .cms-elements img.zoom-effect:hover {
    transform: scale(1.1); }

.cms-elements .pt-10 {
  padding-top: 10rem !important; }

.cms-elements .ra-cms-search-container {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  height: 100%; }
  .cms-elements .ra-cms-search-container form {
    width: 100%; }
    .cms-elements .ra-cms-search-container form .input-group.search-group-highlight {
      border: 2px solid #00569d; }
      .cms-elements .ra-cms-search-container form .input-group.search-group-highlight .ra-cms-search-btn {
        background-color: #00569d;
        color: #FFFFFF;
        border: 2px solid #00569d; }
    .cms-elements .ra-cms-search-container form .input-group.search-group-lightgrey {
      border: 2px solid #e3e3e3; }
      .cms-elements .ra-cms-search-container form .input-group.search-group-lightgrey .ra-cms-search-btn {
        background-color: #e3e3e3;
        color: #575757;
        border: 2px solid #e3e3e3; }
    .cms-elements .ra-cms-search-container form .input-group.search-group-darkgrey {
      border: 2px solid #575757; }
      .cms-elements .ra-cms-search-container form .input-group.search-group-darkgrey .ra-cms-search-btn {
        background-color: #575757;
        color: #FFFFFF;
        border: 2px solid #575757; }
    .cms-elements .ra-cms-search-container form .input-group.search-group-black {
      border: 2px solid #000000; }
      .cms-elements .ra-cms-search-container form .input-group.search-group-black .ra-cms-search-btn {
        background-color: #000000;
        color: #FFFFFF;
        border: 2px solid #000000; }
    .cms-elements .ra-cms-search-container form .input-group.search-group-special {
      border: 2px solid #dc371c; }
      .cms-elements .ra-cms-search-container form .input-group.search-group-special .ra-cms-search-btn {
        background-color: #dc371c;
        color: #FFFFFF;
        border: 2px solid #dc371c; }
    .cms-elements .ra-cms-search-container form .input-group.search-group-confirm {
      border: 2px solid #308136; }
      .cms-elements .ra-cms-search-container form .input-group.search-group-confirm .ra-cms-search-btn {
        background-color: #308136;
        color: #FFFFFF;
        border: 2px solid #308136; }
    .cms-elements .ra-cms-search-container form .input-group.search-group-rating {
      border: 2px solid #f59100; }
      .cms-elements .ra-cms-search-container form .input-group.search-group-rating .ra-cms-search-btn {
        background-color: #f59100;
        color: #FFFFFF;
        border: 2px solid #f59100; }
    .cms-elements .ra-cms-search-container form .input-group .ra-cms-search-input {
      border: 2px solid #FFFFFF;
      border-right: none;
      box-shadow: none;
      height: 4rem;
      transition: none !important; }
    .cms-elements .ra-cms-search-container form .input-group .ra-cms-search-btn {
      background-color: #FFFFFF;
      color: #575757;
      border: 2px solid transparent;
      border-left: none;
      height: 4rem; }
      .cms-elements .ra-cms-search-container form .input-group .ra-cms-search-btn:focus {
        outline: none;
        box-shadow: none; }
      .cms-elements .ra-cms-search-container form .input-group .ra-cms-search-btn i {
        width: 2.5rem;
        height: 2.5rem;
        font-size: 2.5rem; }
  .cms-elements .ra-cms-search-container.with-shadow .input-group {
    box-shadow: 0 1rem 1.5rem -0.5rem #aeaeae; }

.pointer {
  cursor: pointer; }

.mo-box-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  position: absolute;
  width: 26rem;
  min-height: 25rem;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: .5s all;
  opacity: 0; }
  .mo-box-outer .loading-icon {
    font-size: 6rem;
    animation: rotate-mo-load-icon infinite .5s;
    color: #e3e3e3; }
  .mo-box-outer.show {
    opacity: 1; }
  .mo-box-outer.ready {
    display: block; }
  .mo-box-outer .btn {
    margin-top: 1rem;
    width: 100%; }
  .mo-box-outer .product-item .ribbons {
    margin-left: 1.8rem; }
  .mo-box-outer .product-item .product-item-title {
    height: auto; }
  .mo-box-outer .product-item .product-item-attributes {
    margin-top: 1rem;
    height: auto; }

@keyframes rotate-mo-load-icon {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(-359deg); } }

.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-top: 1rem;
  position: relative;
  overflow: hidden;
  text-align: center; }
  .responsive-video:first-child {
    margin-top: 0; }
  .responsive-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-video .button-box {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.4); }
    .responsive-video .button-box .ra-icon {
      font-size: 10rem;
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .2s all;
      color: #FFFFFF; }
      .responsive-video .button-box .ra-icon:hover {
        transform: scale(1.3); }
  .responsive-video .background-cover {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute; }
    .responsive-video .background-cover img {
      width: 100%; }
      .responsive-video .background-cover img.fallback {
        width: auto;
        max-height: 65rem; }

.map-snippet .map-click-enable {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e3e3e3;
  height: 100%;
  cursor: pointer; }
  .map-snippet .map-click-enable p {
    padding: 1rem;
    text-align: center; }
  .map-snippet .map-click-enable:hover > .ra-icon {
    font-size: 4.3rem; }
  .map-snippet .map-click-enable:hover > p {
    font-size: 1.5rem; }
  .map-snippet .map-click-enable .ra-icon, .map-snippet .map-click-enable p {
    transition: all .25s; }
  .map-snippet .map-click-enable .ra-icon {
    color: #575757;
    font-size: 4rem; }

.cms-gallery {
  position: relative;
  margin: 2rem 0;
  display: flex; }
  .cms-gallery .responsive-video {
    height: inherit;
    padding-bottom: 0;
    position: relative;
    overflow: hidden; }
    .cms-gallery .responsive-video .background-cover img {
      height: 100%;
      width: auto;
      margin: 0 auto; }
    @media (max-width: 767.98px) {
      .cms-gallery .responsive-video {
        overflow: visible; } }
  .cms-gallery img.image-fit {
    max-width: 100%;
    max-height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    display: block;
    margin: 0 auto; }
  .cms-gallery img.image-fluid {
    -o-object-fit: cover;
       object-fit: cover; }
  .cms-gallery .cms-gallery-item-image {
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit; }
  .cms-gallery .cms-slider-right, .cms-gallery .cms-slider-left {
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 990;
    height: 20%;
    min-height: 4rem;
    max-height: 6rem;
    line-height: 1;
    width: 3rem;
    text-align: center;
    vertical-align: middle;
    margin-top: 0 !important;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center; }
    .cms-gallery .cms-slider-right i, .cms-gallery .cms-slider-left i {
      color: #000000;
      font-size: 3rem; }
  .cms-gallery .cms-slider-right.outside {
    right: -2.5rem; }
    @media (max-width: 767.98px) {
      .cms-gallery .cms-slider-right.outside {
        background: none; } }
  .cms-gallery .cms-slider-left.outside {
    left: -2.5rem; }
    @media (max-width: 767.98px) {
      .cms-gallery .cms-slider-left.outside {
        background: none; } }
  .cms-gallery .cms-gallery-item-info {
    text-align: center; }
    .cms-gallery .cms-gallery-item-info .cms-gallery-item-title {
      font-weight: bold;
      padding: 1rem;
      margin-bottom: 0; }
      .cms-gallery .cms-gallery-item-info .cms-gallery-item-title span {
        float: right; }
    .cms-gallery .cms-gallery-item-info .cms-gallery-item-desc {
      padding: 1rem;
      margin-bottom: 0; }
  .cms-gallery.cms-slider {
    padding: 0; }
  .cms-gallery.greyscale .cms-gallery-item img {
    filter: grayscale(1) opacity(0.8);
    transition: filter .5s; }
  .cms-gallery.greyscale .cms-gallery-item:hover img {
    filter: grayscale(0) opacity(1); }
  .cms-gallery.greyscale .cms-gallery-item .cms-gallery-item-layer:hover + img {
    filter: grayscale(0); }
  .cms-gallery.hover-effect .cms-gallery-item img {
    transition: transform .5s; }
  .cms-gallery.hover-effect .cms-gallery-item:hover img {
    transform: scale(1.05); }
  .cms-gallery.hover-effect .cms-gallery-item .cms-gallery-item-layer:hover + img {
    transform: scale(1.05); }
  .cms-gallery .cms-gallery-item-spacer {
    position: relative;
    overflow: hidden;
    height: inherit;
    width: 100% !important;
    display: flex; }
  .cms-gallery .cms-gallery-item {
    position: relative;
    overflow: hidden;
    height: inherit;
    width: 100% !important; }
    .cms-gallery .cms-gallery-item > div {
      position: relative;
      overflow: hidden;
      width: inherit; }
    .cms-gallery .cms-gallery-item .cms-gallery-item-layer {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: inherit;
      height: inherit;
      background-color: transparent;
      visibility: hidden;
      text-align: center;
      cursor: pointer;
      z-index: 989; }
    .cms-gallery .cms-gallery-item:hover .cms-gallery-item-layer {
      visibility: visible; }
  .cms-gallery.cms-gallery-slider .cms-gallery-item-info {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(87, 87, 87, 0.65);
    color: #FFFFFF;
    width: 100%; }
    .cms-gallery.cms-gallery-slider .cms-gallery-item-info .cms-gallery-item-title {
      font-weight: bold;
      padding: 1rem; }
      .cms-gallery.cms-gallery-slider .cms-gallery-item-info .cms-gallery-item-title span {
        float: right; }
    .cms-gallery.cms-gallery-slider .cms-gallery-item-info .cms-gallery-item-desc {
      padding: 1rem; }
  .cms-gallery.cms-gallery-grid, .cms-gallery.cms-gallery-carousel, .cms-gallery.cms-gallery-slider {
    flex-wrap: wrap;
    justify-content: space-evenly; }
    .cms-gallery.cms-gallery-grid.chevrons-outside, .cms-gallery.cms-gallery-carousel.chevrons-outside, .cms-gallery.cms-gallery-slider.chevrons-outside {
      overflow: visible !important; }
    .cms-gallery.cms-gallery-grid .cms-gallery-item, .cms-gallery.cms-gallery-carousel .cms-gallery-item, .cms-gallery.cms-gallery-slider .cms-gallery-item {
      flex-grow: 0;
      flex-shrink: 0; }
      @media (max-width: 767.98px) {
        .cms-gallery.cms-gallery-grid .cms-gallery-item.mobile-2, .cms-gallery.cms-gallery-carousel .cms-gallery-item.mobile-2, .cms-gallery.cms-gallery-slider .cms-gallery-item.mobile-2 {
          flex-basis: 48% !important; }
        .cms-gallery.cms-gallery-grid .cms-gallery-item.mobile-1, .cms-gallery.cms-gallery-carousel .cms-gallery-item.mobile-1, .cms-gallery.cms-gallery-slider .cms-gallery-item.mobile-1 {
          flex-basis: 99% !important;
          flex-grow: 1; } }
  .cms-gallery.cms-gallery-grid {
    row-gap: 1.5rem; }
  .cms-gallery.cms-gallery-carousel .cms-gallery-item {
    display: none;
    flex-direction: column; }

.cms-gallery-lightbox .modal-header {
  border: none; }
  .cms-gallery-lightbox .modal-header .close {
    font-weight: bold;
    font-size: 2.4rem;
    cursor: pointer; }

.cms-gallery-lightbox .modal-content {
  height: inherit;
  min-height: inherit; }

.cms-gallery-lightbox .modal-body {
  position: relative;
  padding: .5rem;
  width: inherit; }
  .cms-gallery-lightbox .modal-body .cms-gallery-lightbox-close {
    position: absolute;
    top: .5rem;
    right: .5rem;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 5;
    padding: .5rem;
    text-align: center;
    height: 3rem;
    width: 3rem;
    line-height: 3rem; }
    .cms-gallery-lightbox .modal-body .cms-gallery-lightbox-close i {
      font-weight: bold;
      font-size: 2.4rem;
      cursor: pointer; }

.cms-gallery-lightbox .cms-slider {
  padding: 0;
  width: inherit; }
  .cms-gallery-lightbox .cms-slider img {
    max-height: 85vh;
    width: auto;
    margin: auto; }

.cms-gallery-lightbox .cms-gallery-item-info {
  background-color: rgba(87, 87, 87, 0.5);
  color: #FFFFFF;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0; }
  .cms-gallery-lightbox .cms-gallery-item-info .cms-gallery-item-title {
    font-weight: bold;
    padding: 1rem; }
    .cms-gallery-lightbox .cms-gallery-item-info .cms-gallery-item-title span {
      float: right; }
  .cms-gallery-lightbox .cms-gallery-item-info .cms-gallery-item-desc {
    padding: 1rem; }

.cms-gallery-lightbox .cms-slider-right, .cms-gallery-lightbox .cms-slider-left {
  background-color: rgba(255, 255, 255, 0.75);
  height: 20%;
  min-height: 4rem;
  max-height: 6rem;
  line-height: 1;
  width: 3rem;
  text-align: center;
  vertical-align: middle;
  margin-top: 0 !important;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 990; }
  .cms-gallery-lightbox .cms-slider-right i, .cms-gallery-lightbox .cms-slider-left i {
    color: #000000;
    font-size: 3rem; }

.cms-gallery-lightbox .cms-slider-left {
  left: 0 !important; }

.cms-gallery-lightbox .cms-slider-right {
  right: 0 !important; }

.cms-gallery-lightbox .cms-slider-item, .cms-gallery-lightbox .responsive-video {
  display: flex;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%; }

.cms-gallery-lightbox .responsive-video > iframe {
  display: flex;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%; }

.blog-categories {
  padding: 0 1rem;
  display: flex;
  gap: 1rem; }
  .blog-categories .blog-category-link {
    padding: .5rem 1rem;
    border: 1px solid #e3e3e3;
    color: #00569d;
    background-color: #e3e3e3;
    text-align: center; }
    .blog-categories .blog-category-link.active {
      background-color: #00569d;
      color: #e3e3e3; }

#blog-posts {
  margin-top: 3rem; }
  #blog-posts .more-info {
    font-weight: bold;
    padding-top: 1rem; }
  #blog-posts .blog-post {
    margin-bottom: 4rem; }
    #blog-posts .blog-post .blog-post-panel {
      height: 100%;
      border: 1px solid #e3e3e3;
      display: flex;
      flex-direction: column; }
      #blog-posts .blog-post .blog-post-panel:hover {
        background-color: #f6f6f6; }
      #blog-posts .blog-post .blog-post-panel .blog-post-categories-col {
        padding: 1rem;
        display: flex;
        gap: 1rem; }
        #blog-posts .blog-post .blog-post-panel .blog-post-categories-col span {
          padding: .5rem 1rem;
          border: 1px solid #e3e3e3;
          color: #00569d;
          background-color: #e3e3e3; }
      #blog-posts .blog-post .blog-post-panel .blog-post-more-info-col {
        margin-top: auto;
        padding: 0 1rem;
        display: flex; }
      #blog-posts .blog-post .blog-post-panel .blog-post-data-col {
        padding: 0 1rem; }
      #blog-posts .blog-post .blog-post-panel .blog-post-img-col {
        width: auto;
        height: 25rem;
        position: relative;
        overflow: hidden;
        padding: 0; }
        #blog-posts .blog-post .blog-post-panel .blog-post-img-col a {
          display: block;
          width: 100%;
          height: 100%; }
        #blog-posts .blog-post .blog-post-panel .blog-post-img-col .blog-post-img {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
             object-fit: cover;
          -o-object-position: center;
             object-position: center; }
      #blog-posts .blog-post .blog-post-panel .more-info {
        color: #00569d; }
    #blog-posts .blog-post.blog-post-list {
      border: 1px solid #e3e3e3; }
      #blog-posts .blog-post.blog-post-list .blog-post-categories-col {
        padding: 1rem;
        display: flex;
        justify-content: flex-start;
        justify-items: flex-start;
        align-items: flex-end;
        align-content: flex-end;
        flex-direction: column;
        gap: 1rem; }
        #blog-posts .blog-post.blog-post-list .blog-post-categories-col span {
          padding: .5rem 1rem;
          border: 1px solid #e3e3e3;
          color: #00569d;
          background-color: #e3e3e3; }
      #blog-posts .blog-post.blog-post-list .blog-post-img-col {
        width: 100%;
        height: auto;
        position: relative;
        overflow: hidden;
        padding: 0; }
        #blog-posts .blog-post.blog-post-list .blog-post-img-col a {
          display: block;
          width: 100%;
          height: 100%; }
        #blog-posts .blog-post.blog-post-list .blog-post-img-col .blog-post-img {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
             object-fit: cover;
          -o-object-position: center;
             object-position: center; }
      #blog-posts .blog-post.blog-post-list .blog-post-data-col {
        padding: 0 2rem; }
    #blog-posts .blog-post .blog-post-data-col .blog-post-title {
      margin-top: 2rem; }
    #blog-posts .blog-post .blog-post-data-col .blog-post-release-date {
      font-style: italic;
      margin-top: 2rem;
      display: block; }
    #blog-posts .blog-post .blog-post-data-col .blog-post-text {
      margin-top: 2rem; }
    #blog-posts .blog-post .more-info {
      margin-top: 2rem;
      margin-bottom: 2.5rem; }
      #blog-posts .blog-post .more-info a {
        color: #00569d; }
  #blog-posts .showMorePosts, #blog-posts .showLessPosts, #blog-posts .showBackToStart {
    display: block;
    width: 100%;
    line-height: 2rem;
    background-color: #e3e3e3;
    cursor: pointer;
    opacity: .5;
    font-weight: bold;
    padding: 1rem 0;
    height: 4rem;
    margin-top: 3rem; }

.ramedia-accordion {
  margin-top: 2rem;
  width: 100%; }
  .ramedia-accordion .accordion-group .group-header button {
    min-height: 4rem;
    padding: 0 1rem;
    display: block;
    width: 100%;
    text-align: left;
    text-decoration: none;
    outline: none;
    border: none;
    background-color: transparent;
    color: #575757;
    margin-bottom: 1rem; }
    .ramedia-accordion .accordion-group .group-header button span {
      margin: 1rem 0;
      font-size: 1.6rem;
      line-height: 1;
      font-weight: bold;
      display: inline-flex;
      width: 100%;
      word-wrap: break-word;
      align-content: center;
      align-items: center;
      gap: 1rem; }
      .ramedia-accordion .accordion-group .group-header button span i.ra-icon:not(.dropdown) {
        font-size: 2.5rem; }
      .ramedia-accordion .accordion-group .group-header button span i.ra-icon.dropdown {
        margin-left: auto; }
    .ramedia-accordion .accordion-group .group-header button .dropdown {
      float: right;
      vertical-align: middle;
      line-height: 1;
      font-size: 2.4rem;
      transform: scale(-1);
      transition: transform .2s linear; }
    .ramedia-accordion .accordion-group .group-header button.collapsed {
      border-bottom: 1px solid #e3e3e3; }
      .ramedia-accordion .accordion-group .group-header button.collapsed .dropdown {
        transform: none; }
    .ramedia-accordion .accordion-group .group-header button:focus .ramedia-accordion .accordion-group .group-header button:hover {
      text-decoration: none;
      outline: none; }
  .ramedia-accordion .accordion-group .group-body {
    padding: 0rem 2rem 1rem 2rem;
    border-bottom: 1px solid #e3e3e3; }
  .ramedia-accordion.accordion-border .group-header {
    background-color: #aeaeae; }
    .ramedia-accordion.accordion-border .group-header button {
      color: #FFFFFF;
      border: none; }
  .ramedia-accordion.accordion-lightgrey .group-header {
    background-color: #e3e3e3; }
    .ramedia-accordion.accordion-lightgrey .group-header button {
      color: #575757;
      border: none; }
  .ramedia-accordion.accordion-lightgrey-o .group-header {
    background-color: #f6f6f6; }
    .ramedia-accordion.accordion-lightgrey-o .group-header button {
      color: #575757;
      border: none; }
  .ramedia-accordion.accordion-darkgrey .group-header {
    background-color: #575757; }
    .ramedia-accordion.accordion-darkgrey .group-header button {
      color: #FFFFFF;
      border: none; }
  .ramedia-accordion.accordion-darkgrey-o .group-header {
    background-color: #747474; }
    .ramedia-accordion.accordion-darkgrey-o .group-header button {
      color: #FFFFFF;
      border: none; }
  .ramedia-accordion.accordion-highlight .group-header {
    background-color: #00569d; }
    .ramedia-accordion.accordion-highlight .group-header button {
      color: #FFFFFF;
      border: none; }
  .ramedia-accordion.accordion-special .group-header {
    background-color: #dc371c; }
    .ramedia-accordion.accordion-special .group-header button {
      color: #FFFFFF;
      border: none; }
  .ramedia-accordion.accordion-confirm .group-header {
    background-color: #308136; }
    .ramedia-accordion.accordion-confirm .group-header button {
      color: #FFFFFF;
      border: none; }
  .ramedia-accordion.accordion-rating .group-header {
    background-color: #f59100; }
    .ramedia-accordion.accordion-rating .group-header button {
      color: #FFFFFF;
      border: none; }

.ramedia-tabs {
  width: 100%;
  margin-top: 2rem; }
  .ramedia-tabs .ramedia-tabs-data {
    padding: 1rem 2rem; }
  .ramedia-tabs .ramedia-tabs-list {
    background-color: #f6f6f6;
    min-height: 6rem;
    list-style: none;
    border: none;
    padding: 0 2rem 0 0; }
    .ramedia-tabs .ramedia-tabs-list a {
      display: inline-block;
      padding: 0 2rem;
      border-right: 1px solid #aeaeae;
      margin: 1rem 0;
      max-height: 6rem; }
      .ramedia-tabs .ramedia-tabs-list a:last-child {
        border-right: none; }
      .ramedia-tabs .ramedia-tabs-list a.active > span {
        border-bottom: 3px solid #00569d; }
      .ramedia-tabs .ramedia-tabs-list a:hover:not(.active) > span {
        border-bottom: 3px solid #575757; }
      .ramedia-tabs .ramedia-tabs-list a > span {
        font-weight: bold;
        display: inline-flex;
        padding: 1rem 0;
        transition: .2s all;
        text-decoration: none;
        border-bottom: 3px solid transparent;
        cursor: pointer;
        color: #575757;
        align-items: center;
        align-content: center;
        gap: 1rem; }
        .ramedia-tabs .ramedia-tabs-list a > span i.ra-icon {
          font-size: 2.5rem; }
  .ramedia-tabs.tabs-border .ramedia-tabs-list {
    background-color: #aeaeae; }
    .ramedia-tabs.tabs-border .ramedia-tabs-list a {
      border-right: 1px solid #FFFFFF; }
      .ramedia-tabs.tabs-border .ramedia-tabs-list a:last-child {
        border-right: none; }
      .ramedia-tabs.tabs-border .ramedia-tabs-list a > span {
        color: #FFFFFF; }
  .ramedia-tabs.tabs-lightgrey .ramedia-tabs-list {
    background-color: #e3e3e3; }
    .ramedia-tabs.tabs-lightgrey .ramedia-tabs-list a > span {
      color: #575757; }
  .ramedia-tabs.tabs-lightgrey-o .ramedia-tabs-list {
    background-color: #f6f6f6; }
    .ramedia-tabs.tabs-lightgrey-o .ramedia-tabs-list a > span {
      color: #575757; }
  .ramedia-tabs.tabs-darkgrey .ramedia-tabs-list {
    background-color: #575757; }
    .ramedia-tabs.tabs-darkgrey .ramedia-tabs-list a:hover:not(.active) > span {
      border-bottom: 3px solid #FFFFFF; }
    .ramedia-tabs.tabs-darkgrey .ramedia-tabs-list a > span {
      color: #FFFFFF; }
  .ramedia-tabs.tabs-darkgrey-o .ramedia-tabs-list {
    background-color: #747474; }
    .ramedia-tabs.tabs-darkgrey-o .ramedia-tabs-list a:hover:not(.active) > span {
      border-bottom: 3px solid #FFFFFF; }
    .ramedia-tabs.tabs-darkgrey-o .ramedia-tabs-list a > span {
      color: #FFFFFF; }
  .ramedia-tabs.tabs-highlight .ramedia-tabs-list {
    background-color: #00569d; }
    .ramedia-tabs.tabs-highlight .ramedia-tabs-list a.active > span {
      border-bottom: 3px solid #FFFFFF; }
    .ramedia-tabs.tabs-highlight .ramedia-tabs-list a > span {
      color: #FFFFFF; }
  .ramedia-tabs.tabs-special .ramedia-tabs-list {
    background-color: #dc371c; }
    .ramedia-tabs.tabs-special .ramedia-tabs-list a.active > span {
      border-bottom: 3px solid #FFFFFF; }
    .ramedia-tabs.tabs-special .ramedia-tabs-list a > span {
      color: #FFFFFF; }
  .ramedia-tabs.tabs-confirm .ramedia-tabs-list {
    background-color: #308136; }
    .ramedia-tabs.tabs-confirm .ramedia-tabs-list a.active > span {
      border-bottom: 3px solid #FFFFFF; }
    .ramedia-tabs.tabs-confirm .ramedia-tabs-list a > span {
      color: #FFFFFF; }
  .ramedia-tabs.tabs-rating .ramedia-tabs-list {
    background-color: #f59100; }
    .ramedia-tabs.tabs-rating .ramedia-tabs-list a.active > span {
      border-bottom: 3px solid #FFFFFF; }
    .ramedia-tabs.tabs-rating .ramedia-tabs-list a > span {
      color: #FFFFFF; }
  .ramedia-tabs.tabs-white .ramedia-tabs-list {
    background-color: #FFFFFF; }
    .ramedia-tabs.tabs-white .ramedia-tabs-list a.active > span {
      border-bottom: 3px solid #575757; }
    .ramedia-tabs.tabs-white .ramedia-tabs-list a > span {
      color: #575757; }
  .ramedia-tabs .align-left {
    justify-content: flex-start; }
  .ramedia-tabs .align-middle {
    justify-content: center; }
  .ramedia-tabs .align-right {
    justify-content: flex-end; }

.hr-border {
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #e3e3e3; }

.cms-tooltip-trigger {
  position: relative;
  height: inherit;
  line-height: inherit;
  display: inline-block; }
  .cms-tooltip-trigger .cms-tooltip {
    position: absolute;
    left: 50%;
    height: auto;
    z-index: 1000;
    margin-left: auto;
    transform: translateX(-50%); }
    .cms-tooltip-trigger .cms-tooltip.out-left {
      left: 0;
      transform: none; }
      .cms-tooltip-trigger .cms-tooltip.out-left .cms-tooltip-arrow {
        margin: 0 0 0 1rem; }
    .cms-tooltip-trigger .cms-tooltip.out-right {
      right: 0;
      transform: none; }
      .cms-tooltip-trigger .cms-tooltip.out-right .cms-tooltip-arrow {
        margin: 0 1rem 0 0; }
    .cms-tooltip-trigger .cms-tooltip.out-left.out-right {
      width: auto !important; }
  .cms-tooltip-trigger .cms-tooltip-arrow {
    width: 0;
    height: 0;
    border-left: .5rem solid transparent;
    border-right: .5rem solid transparent;
    border-bottom: 0.5rem solid #575757;
    display: none;
    z-index: 1000;
    margin: 0 auto; }
  .cms-tooltip-trigger .cms-tooltip-inner {
    position: relative;
    height: auto;
    font-size: 1.2rem;
    line-height: 1.4rem;
    padding: 1rem;
    background-color: #FFFFFF;
    border: 1px solid #aeaeae;
    color: #575757;
    z-index: 1000;
    display: none;
    border-radius: .2rem;
    text-align: center; }
    .cms-tooltip-trigger .cms-tooltip-inner p {
      float: none !important;
      margin-right: 0 !important; }
  .cms-tooltip-trigger .moreinfo {
    margin-top: 1rem;
    display: block; }
  .cms-tooltip-trigger .highlight {
    color: #00569d; }
  .cms-tooltip-trigger:hover .cms-tooltip-arrow, .cms-tooltip-trigger:hover .cms-tooltip-inner, .cms-tooltip-trigger .cms-tooltip:hover .cms-tooltip-arrow, .cms-tooltip-trigger .cms-tooltip:hover .cms-tooltip-inner {
    display: block; }

.ra-icon-box-background {
  padding: 1rem;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  justify-self: center;
  margin: 0 auto 1rem auto;
  align-items: center; }

.cms-countdown.flipdown {
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  overflow: hidden;
  gap: 0 1rem; }
  @media (max-width: 575.98px) {
    .cms-countdown.flipdown {
      gap: 0 .5rem; } }
  @media (min-width: 768px) {
    .cms-countdown.flipdown {
      gap: 0 2rem; }
      .cms-countdown.flipdown .countdown-element-group {
        flex: 0 1 0%;
        max-width: unset !important;
        width: auto !important; } }
  .cms-countdown.flipdown .countdown-element-group {
    text-align: center;
    flex: 0 0 0%;
    max-width: 25%; }
    .cms-countdown.flipdown .countdown-element-group .group-title {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      justify-items: center;
      height: 3.75rem; }
      @media (max-width: 767.98px) {
        .cms-countdown.flipdown .countdown-element-group .group-title {
          font-size: 1.4rem !important;
          height: 2.1rem; } }
      .cms-countdown.flipdown .countdown-element-group .group-title.uppercase {
        text-transform: uppercase; }
    .cms-countdown.flipdown .countdown-element-group .countdown-elements {
      display: flex;
      justify-items: center;
      justify-content: center;
      align-items: center;
      align-content: center;
      color: #575757;
      gap: 0 1rem;
      padding: 1rem 0; }
      @media (max-width: 767.98px) {
        .cms-countdown.flipdown .countdown-element-group .countdown-elements {
          gap: 0 0.5rem; } }
      .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element {
        position: relative;
        height: 70px;
        width: 50px;
        border-radius: 8px;
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08); }
        @media (max-width: 767.98px) {
          .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element {
            height: 60px;
            width: 35px; } }
        .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element:not(.bg-black):not(.bg-highlight):not(.bg-special):not(.bg-confirm):not(.bg-rating):not(.bg-lightgrey):not(.bg-lightgrey-o):not(.bg-darkgrey):not(.bg-darkgrey-o):not(.bg-border) {
          background-color: #FFFFFF; }
        .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element > span {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          font-weight: 700;
          font: bold 4.94em/67px "Open Sans", Tahoma, Verdana, Arial; }
          @media (max-width: 767.98px) {
            .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element > span {
              font: bold 2.5em/60px "Open Sans", Tahoma, Verdana, Arial; } }
        .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element .top:after, .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element .bottom-back:after {
          content: "";
          position: absolute;
          z-index: -1;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
        .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element .top {
          z-index: 3;
          background-color: inherit;
          transform-origin: 50% 100%;
          -webkit-transform-origin: 50% 100%;
          transform: perspective(200px);
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem; }
        .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element .bottom {
          z-index: 1; }
          .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element .bottom:before {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 50%;
            background-color: rgba(0, 0, 0, 0.02); }
        .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element .bottom-back {
          z-index: 2;
          top: 0;
          height: 50%;
          overflow: hidden;
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
          background-color: inherit; }
          .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element .bottom-back span {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto; }
        .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element .top, .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element .top-back {
          height: 50%;
          overflow: hidden;
          -webkit-backface-visibility: hidden;
                  backface-visibility: hidden; }
        .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element .top-back {
          z-index: 4;
          bottom: 0;
          transform-origin: 50% 0;
          transform: perspective(200px) rotateX(180deg);
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
          background-color: inherit; }
          .cms-countdown.flipdown .countdown-element-group .countdown-elements .countdown-element .top-back span {
            position: absolute;
            top: -100%;
            left: 0;
            right: 0;
            margin: auto; }

.google-review-widget-outer {
  padding: 1rem; }
  .google-review-widget-outer .google-review-widget-inner {
    box-shadow: 0 1rem 1.5rem -0.5rem #aeaeae;
    max-width: 40rem;
    margin: 0 auto;
    text-align: center;
    padding: .5rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    cursor: default; }
    .google-review-widget-outer .google-review-widget-inner .widget-icon {
      text-align: center;
      max-width: 5rem;
      max-height: 5rem;
      display: block;
      margin: 0 auto; }
    .google-review-widget-outer .google-review-widget-inner .to-reviews-link {
      font-size: 1.1rem;
      text-decoration: underline; }
    .google-review-widget-outer .google-review-widget-inner .rating, .google-review-widget-outer .google-review-widget-inner .rating-stars {
      display: inline-block;
      line-height: 3rem; }
      .google-review-widget-outer .google-review-widget-inner .rating i, .google-review-widget-outer .google-review-widget-inner .rating-stars i {
        font-size: 2.4rem;
        line-height: 3rem;
        vertical-align: sub; }
    .google-review-widget-outer .google-review-widget-inner .rating {
      font-weight: bold;
      font-size: 2rem;
      margin-right: .5rem; }
    .google-review-widget-outer .google-review-widget-inner .rating-headline {
      font-size: 1.6rem;
      margin: .5rem 0; }

.ra-inquiry-contact-widget {
  margin: 2rem auto;
  max-width: 65rem;
  overflow: hidden; }
  .ra-inquiry-contact-widget .row {
    width: -moz-fit-content;
    width: fit-content;
    max-width: 100%; }
  .ra-inquiry-contact-widget .headline {
    display: block;
    margin-top: 1rem;
    font-weight: 700;
    color: #575757; }
  .ra-inquiry-contact-widget .img-wrapper, .ra-inquiry-contact-widget .data-wrapper {
    display: flex;
    justify-content: center;
    align-items: center; }
    .ra-inquiry-contact-widget .img-wrapper.align-left, .ra-inquiry-contact-widget .data-wrapper.align-left {
      justify-content: flex-start; }
    .ra-inquiry-contact-widget .img-wrapper.align-right, .ra-inquiry-contact-widget .data-wrapper.align-right {
      justify-content: flex-end; }
  .ra-inquiry-contact-widget .img-wrapper-inner {
    margin: .5rem 0;
    overflow: auto; }
  .ra-inquiry-contact-widget .img-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden; }
    .ra-inquiry-contact-widget .img-container img {
      border-radius: 50%;
      border: 1px solid #aeaeae;
      flex: 0 1 auto;
      max-width: 7.5rem;
      height: auto;
      overflow: hidden;
      margin: .5rem; }
    .ra-inquiry-contact-widget .img-container.big img {
      max-width: 12rem;
      width: auto;
      height: auto; }
  .ra-inquiry-contact-widget .data-container {
    margin: .5rem 0; }
    .ra-inquiry-contact-widget .data-container span {
      display: block;
      width: auto;
      margin-bottom: .5rem; }
      .ra-inquiry-contact-widget .data-container span.bold {
        font-weight: 700; }
      .ra-inquiry-contact-widget .data-container span a {
        display: inline-block;
        width: auto; }
        .ra-inquiry-contact-widget .data-container span a.increased-size {
          font-size: 1.6rem;
          font-weight: bold;
          color: #575757; }
      .ra-inquiry-contact-widget .data-container span i.ra-icon {
        font-size: 2rem;
        vertical-align: text-top;
        color: #575757; }
    .ra-inquiry-contact-widget .data-container a {
      color: #00569d; }
      .ra-inquiry-contact-widget .data-container a.increased-size {
        font-size: 1.6rem;
        font-weight: bold;
        color: #575757; }
    .ra-inquiry-contact-widget .data-container .btn {
      margin: .5rem 0; }

.single-article-box {
  box-shadow: 1px 0 0 0 #e3e3e3, 0 1px 0 0 #e3e3e3, 1px 0 0 0 #e3e3e3 inset, 0 1px 0 0 #e3e3e3 inset;
  height: 100%; }

.ra-social-media-box {
  border: 1px solid #e3e3e3;
  padding: 1rem;
  border-radius: .5rem;
  margin: 2rem 0; }
  .ra-social-media-box .sm-box-action-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 3.5rem;
    cursor: pointer; }
    .ra-social-media-box .sm-box-action-btn.left {
      left: -1rem; }
    .ra-social-media-box .sm-box-action-btn.right {
      right: -1rem; }
  .ra-social-media-box .sm-box-header {
    display: flex;
    flex-direction: row;
    height: 5rem;
    margin-bottom: 1rem;
    gap: 1rem; }
    .ra-social-media-box .sm-box-header.sm-box-header-slide {
      padding: 0 2.5rem; }
    .ra-social-media-box .sm-box-header > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center; }
    .ra-social-media-box .sm-box-header .logo {
      align-content: center;
      align-items: center;
      max-width: 5rem;
      overflow: hidden;
      border: 1px solid #e3e3e3;
      border-radius: 50%; }
      .ra-social-media-box .sm-box-header .logo a {
        display: flex;
        width: 100%;
        height: 100%;
        align-content: center;
        align-items: center;
        justify-content: center;
        justify-items: center;
        padding: .5rem; }
    .ra-social-media-box .sm-box-header span {
      display: block;
      width: 100%; }
      .ra-social-media-box .sm-box-header span.name {
        font-weight: bold; }
  .ra-social-media-box .sm-box-body {
    position: relative; }
    .ra-social-media-box .sm-box-body.sm-box-body-slide {
      padding: 0 2.5rem; }
    .ra-social-media-box .sm-box-body .sm-box-body-inner {
      display: grid;
      grid-template-rows: repeat(1, 1fr);
      gap: 1rem; }
      .ra-social-media-box .sm-box-body .sm-box-body-inner.ppl-1 {
        grid-template-columns: repeat(1, 1fr); }
      .ra-social-media-box .sm-box-body .sm-box-body-inner.ppl-2 {
        grid-template-columns: repeat(2, 1fr); }
      .ra-social-media-box .sm-box-body .sm-box-body-inner.ppl-3 {
        grid-template-columns: repeat(3, 1fr); }
      .ra-social-media-box .sm-box-body .sm-box-body-inner.ppl-4 {
        grid-template-columns: repeat(4, 1fr); }
      .ra-social-media-box .sm-box-body .sm-box-body-inner.ppl-5 {
        grid-template-columns: repeat(5, 1fr); }
      .ra-social-media-box .sm-box-body .sm-box-body-inner.ppl-6 {
        grid-template-columns: repeat(6, 1fr); }
      @media (max-width: 767.98px) {
        .ra-social-media-box .sm-box-body .sm-box-body-inner.ppl-m-1 {
          grid-template-columns: repeat(1, 1fr); }
        .ra-social-media-box .sm-box-body .sm-box-body-inner.ppl-m-2 {
          grid-template-columns: repeat(2, 1fr); }
        .ra-social-media-box .sm-box-body .sm-box-body-inner.ppl-m-3 {
          grid-template-columns: repeat(3, 1fr); }
        .ra-social-media-box .sm-box-body .sm-box-body-inner.ppl-m-4 {
          grid-template-columns: repeat(4, 1fr); } }
    .ra-social-media-box .sm-box-body .sm-box-element {
      position: relative; }
      .ra-social-media-box .sm-box-body .sm-box-element .sm-box-img img {
        pointer-events: none;
        -o-object-fit: contain;
           object-fit: contain;
        -o-object-position: center;
           object-position: center;
        width: 100%;
        height: 100%; }
        .ra-social-media-box .sm-box-body .sm-box-element .sm-box-img img.img-cover {
          -o-object-fit: cover;
             object-fit: cover; }
      .ra-social-media-box .sm-box-body .sm-box-element .sm-box-img.aspect-1-1 {
        aspect-ratio: 1/1; }
      .ra-social-media-box .sm-box-body .sm-box-element .sm-box-img.aspect-2-1 {
        aspect-ratio: 2/1; }
      .ra-social-media-box .sm-box-body .sm-box-element .sm-box-img.aspect-3-4 {
        aspect-ratio: 3/4; }
      .ra-social-media-box .sm-box-body .sm-box-element .sm-box-img.aspect-4-5 {
        aspect-ratio: 4/5; }
      .ra-social-media-box .sm-box-body .sm-box-element .sm-box-img.aspect-9-16 {
        aspect-ratio: 9/16; }
      .ra-social-media-box .sm-box-body .sm-box-element .sm-box-img.aspect-16-9 {
        aspect-ratio: 16/9; }
      .ra-social-media-box .sm-box-body .sm-box-element .sm-title-and-desc {
        font-size: 1.2rem;
        line-height: 1.2rem; }
        .ra-social-media-box .sm-box-body .sm-box-element .sm-title-and-desc .title {
          font-weight: 700;
          display: block;
          margin-bottom: .5rem; }
        .ra-social-media-box .sm-box-body .sm-box-element .sm-title-and-desc > div {
          padding: 1rem 0; }
        .ra-social-media-box .sm-box-body .sm-box-element .sm-title-and-desc.sm-show-title-and-desc-image {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(87, 87, 87, 0.75);
          color: #FFFFFF;
          height: 6rem;
          min-height: 6rem;
          max-height: 100%;
          transition: .75s ease;
          overflow: hidden; }
          .ra-social-media-box .sm-box-body .sm-box-element .sm-title-and-desc.sm-show-title-and-desc-image span {
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            display: block; }
          .ra-social-media-box .sm-box-body .sm-box-element .sm-title-and-desc.sm-show-title-and-desc-image > div {
            padding: 1rem; }
        .ra-social-media-box .sm-box-body .sm-box-element .sm-title-and-desc.sm-show-title-and-desc-mouseover {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(87, 87, 87, 0.75);
          color: #FFFFFF;
          height: 0;
          transition: .75s ease;
          overflow: hidden; }
          .ra-social-media-box .sm-box-body .sm-box-element .sm-title-and-desc.sm-show-title-and-desc-mouseover > div {
            padding: 1rem; }
      .ra-social-media-box .sm-box-body .sm-box-element:hover .sm-show-title-and-desc-mouseover, .ra-social-media-box .sm-box-body .sm-box-element:hover .sm-show-title-and-desc-image {
        height: auto; }
        .ra-social-media-box .sm-box-body .sm-box-element:hover .sm-show-title-and-desc-mouseover span, .ra-social-media-box .sm-box-body .sm-box-element:hover .sm-show-title-and-desc-image span {
          text-overflow: unset;
          white-space: unset; }

.scroll-icon-container {
  position: absolute;
  bottom: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 12rem;
  z-index: 999; }
  .scroll-icon-container .scroll-icon-text {
    font-weight: 700;
    font-size: 1.6rem;
    display: none;
    position: absolute !important;
    top: 0;
    text-align: center; }
    .scroll-icon-container .scroll-icon-text + .chevron:hover {
      display: block; }
  .scroll-icon-container:hover .scroll-icon-text {
    display: block; }
  .scroll-icon-container .chevron {
    position: absolute;
    top: 2rem;
    width: 3.5rem;
    height: 0.8rem;
    opacity: 0;
    transform: scale(0.3);
    background-color: transparent !important;
    animation: move-chevron 3s ease-out infinite; }
    .scroll-icon-container .chevron.highlight:before, .scroll-icon-container .chevron.highlight:after {
      background: #00569d; }
    .scroll-icon-container .chevron.border:before, .scroll-icon-container .chevron.border:after {
      background: #aeaeae; }
    .scroll-icon-container .chevron.lightgrey:before, .scroll-icon-container .chevron.lightgrey:after {
      background: #e3e3e3; }
    .scroll-icon-container .chevron.lightgrey-o:before, .scroll-icon-container .chevron.lightgrey-o:after {
      background: #f6f6f6; }
    .scroll-icon-container .chevron.darkgrey:before, .scroll-icon-container .chevron.darkgrey:after {
      background: #575757; }
    .scroll-icon-container .chevron.darkgrey-o:before, .scroll-icon-container .chevron.darkgrey-o:after {
      background: #747474; }
    .scroll-icon-container .chevron.black:before, .scroll-icon-container .chevron.black:after {
      background: #000000; }
    .scroll-icon-container .chevron.white:before, .scroll-icon-container .chevron.white:after {
      background: #FFFFFF; }
    .scroll-icon-container .chevron.special:before, .scroll-icon-container .chevron.special:after {
      background: #dc371c; }
    .scroll-icon-container .chevron.confirm:before, .scroll-icon-container .chevron.confirm:after {
      background: #308136; }
    .scroll-icon-container .chevron.rating:before, .scroll-icon-container .chevron.rating:after {
      background: #f59100; }
  .scroll-icon-container .chevron:first-of-type {
    animation: move-chevron 3s ease-out 1s infinite; }
  .scroll-icon-container .chevron:nth-of-type(2) {
    animation: move-chevron 3s ease-out 2s infinite; }
  .scroll-icon-container .chevron:before,
  .scroll-icon-container .chevron:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%; }
  .scroll-icon-container .chevron:before {
    left: 0;
    transform: skewY(30deg); }
  .scroll-icon-container .chevron:after {
    right: 0;
    width: 50%;
    transform: skewY(-30deg); }

@keyframes move-chevron {
  25% {
    opacity: 1; }
  33.3% {
    opacity: 1;
    transform: translateY(3.8rem); }
  66.6% {
    opacity: 1;
    transform: translateY(5.2rem); }
  100% {
    opacity: 0;
    transform: translateY(8rem) scale(0.5); } }

#contentTeaser .teaserContent {
  margin-bottom: 2.5rem; }

#contentTeaser.row-fullscreen {
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw; }

.tooltip {
  z-index: 9999; }

.tooltip-inner {
  font-size: 1.2rem;
  max-width: 30rem;
  padding: 1rem;
  color: #575757;
  text-align: center;
  background-color: #FFFFFF;
  border: 1px solid #aeaeae; }

.tooltip-arrow {
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: .5rem solid transparent;
  border-right: .5rem solid transparent;
  border-bottom: 0.5rem solid #575757; }

.ra-details-oxvarselect-tooltip-trigger {
  display: inline-block; }
  .ra-details-oxvarselect-tooltip-trigger .ra-details-oxvarselect {
    font-size: 1.8rem;
    font-weight: bold; }
  .ra-details-oxvarselect-tooltip-trigger:hover .ra-details-oxvarselect-tooltip .tooltip-inner, .ra-details-oxvarselect-tooltip-trigger:hover .ra-details-oxvarselect-tooltip .tooltip-arrow {
    display: block; }
  .ra-details-oxvarselect-tooltip-trigger .ra-details-oxvarselect-tooltip {
    position: absolute;
    max-width: 30rem;
    height: auto;
    z-index: 1000;
    font-weight: 400;
    margin-left: -1rem;
    transform: none;
    min-width: 10rem; }
    .ra-details-oxvarselect-tooltip-trigger .ra-details-oxvarselect-tooltip .tooltip-arrow {
      margin: 0 1rem; }
    .ra-details-oxvarselect-tooltip-trigger .ra-details-oxvarselect-tooltip .tooltip-inner, .ra-details-oxvarselect-tooltip-trigger .ra-details-oxvarselect-tooltip .tooltip-arrow {
      display: none;
      font-size: 1.2rem; }
      .ra-details-oxvarselect-tooltip-trigger .ra-details-oxvarselect-tooltip .tooltip-inner .tooltip-moreinfo, .ra-details-oxvarselect-tooltip-trigger .ra-details-oxvarselect-tooltip .tooltip-arrow .tooltip-moreinfo {
        display: block;
        width: 100%;
        margin-top: 1rem; }
    .ra-details-oxvarselect-tooltip-trigger .ra-details-oxvarselect-tooltip:hover .tooltip-inner, .ra-details-oxvarselect-tooltip-trigger .ra-details-oxvarselect-tooltip:hover .tooltip-arrow {
      display: block; }

.ra-details-delivery-tooltip-trigger {
  display: inline-block; }
  .ra-details-delivery-tooltip-trigger:hover .ra-details-delivery-tooltip .tooltip-inner, .ra-details-delivery-tooltip-trigger:hover .ra-details-delivery-tooltip .tooltip-arrow {
    display: block; }
  .ra-details-delivery-tooltip-trigger .ra-details-delivery-tooltip {
    position: absolute;
    max-width: 30rem;
    min-width: 30rem;
    height: auto;
    z-index: 1000;
    transform: translateX(-50%);
    font-weight: 400;
    margin-left: .75rem; }
    .ra-details-delivery-tooltip-trigger .ra-details-delivery-tooltip .tooltip-inner, .ra-details-delivery-tooltip-trigger .ra-details-delivery-tooltip .tooltip-arrow {
      display: none;
      font-size: 1.2rem; }
      .ra-details-delivery-tooltip-trigger .ra-details-delivery-tooltip .tooltip-inner .tooltip-moreinfo, .ra-details-delivery-tooltip-trigger .ra-details-delivery-tooltip .tooltip-arrow .tooltip-moreinfo {
        display: block;
        width: 100%;
        margin-top: 1rem; }
    .ra-details-delivery-tooltip-trigger .ra-details-delivery-tooltip:hover .tooltip-inner, .ra-details-delivery-tooltip-trigger .ra-details-delivery-tooltip:hover .tooltip-arrow {
      display: block; }

.ra-udws-mincalculation-tooltip-trigger {
  display: inline-block; }
  .ra-udws-mincalculation-tooltip-trigger:hover .ra-udws-mincalculation-tooltip .tooltip-inner, .ra-udws-mincalculation-tooltip-trigger:hover .ra-udws-mincalculation-tooltip .tooltip-arrow {
    display: block; }
  .ra-udws-mincalculation-tooltip-trigger .ra-udws-mincalculation-tooltip {
    position: absolute;
    max-width: 30rem;
    min-width: 30rem;
    height: auto;
    z-index: 1000;
    transform: translateX(-50%);
    font-weight: 400;
    margin-left: .75rem; }
    .ra-udws-mincalculation-tooltip-trigger .ra-udws-mincalculation-tooltip .tooltip-inner, .ra-udws-mincalculation-tooltip-trigger .ra-udws-mincalculation-tooltip .tooltip-arrow {
      display: none;
      font-size: 1.2rem; }
      .ra-udws-mincalculation-tooltip-trigger .ra-udws-mincalculation-tooltip .tooltip-inner .tooltip-moreinfo, .ra-udws-mincalculation-tooltip-trigger .ra-udws-mincalculation-tooltip .tooltip-arrow .tooltip-moreinfo {
        display: block;
        width: 100%;
        margin-top: 1rem; }
    .ra-udws-mincalculation-tooltip-trigger .ra-udws-mincalculation-tooltip:hover .tooltip-inner, .ra-udws-mincalculation-tooltip-trigger .ra-udws-mincalculation-tooltip:hover .tooltip-arrow {
      display: block; }

.express-tooltip .tooltip-inner {
  font-size: 1.2rem;
  max-width: 30rem;
  padding: 1rem;
  background-color: #FFFFFF;
  border: 1px solid #aeaeae; }

.rating-recommendation-tooltip .tooltip-inner {
  font-size: 1.2rem;
  max-width: 30rem;
  padding: 1rem;
  background-color: #FFFFFF;
  border: 1px solid #aeaeae; }

.basket-notification-popup {
  position: fixed;
  bottom: -3rem;
  right: 1rem;
  height: 3rem;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  padding: .5rem;
  transition: .5s all; }
  .basket-notification-popup b {
    font-weight: bold; }
  .basket-notification-popup.show {
    bottom: 1rem; }

.please-select-variant-tooltip {
  top: -1.3rem !important; }
  .please-select-variant-tooltip .tooltip-inner {
    font-size: 1.4rem;
    color: #dc371c; }

.below-minorder-tooltip .tooltip-inner {
  font-size: 1.4rem;
  color: #dc371c;
  max-width: 35rem; }

.directorder-btn-tooltip {
  color: #dc371c; }

.bulky-tooltip, .bulky-xxl-tooltip, .longitem-tooltip {
  display: inline-block;
  font-weight: normal !important;
  width: 2rem;
  text-align: center; }
  .bulky-tooltip .ra-icon, .bulky-xxl-tooltip .ra-icon, .longitem-tooltip .ra-icon {
    font-size: 1.5rem !important;
    vertical-align: middle !important; }
  .bulky-tooltip .tooltip-outer, .bulky-xxl-tooltip .tooltip-outer, .longitem-tooltip .tooltip-outer {
    position: absolute;
    z-index: 999;
    transform: translateX(-50%);
    margin-left: 1rem; }
    .bulky-tooltip .tooltip-outer .tooltip-inner, .bulky-xxl-tooltip .tooltip-outer .tooltip-inner, .longitem-tooltip .tooltip-outer .tooltip-inner {
      display: none;
      font-size: 1.2rem;
      max-width: 30rem;
      min-width: 30rem;
      padding: 1rem;
      text-align: center;
      background-color: #FFFFFF;
      border: 1px solid #aeaeae; }
    .bulky-tooltip .tooltip-outer .tooltip-arrow, .bulky-xxl-tooltip .tooltip-outer .tooltip-arrow, .longitem-tooltip .tooltip-outer .tooltip-arrow {
      display: none;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-left: .5rem solid transparent;
      border-right: .5rem solid transparent;
      border-bottom: 0.5rem solid #575757; }
  .bulky-tooltip:hover .tooltip-arrow, .bulky-tooltip:hover .tooltip-inner, .bulky-tooltip i:hover .tooltip-arrow, .bulky-tooltip i:hover .tooltip-inner, .bulky-xxl-tooltip:hover .tooltip-arrow, .bulky-xxl-tooltip:hover .tooltip-inner, .bulky-xxl-tooltip i:hover .tooltip-arrow, .bulky-xxl-tooltip i:hover .tooltip-inner, .longitem-tooltip:hover .tooltip-arrow, .longitem-tooltip:hover .tooltip-inner, .longitem-tooltip i:hover .tooltip-arrow, .longitem-tooltip i:hover .tooltip-inner {
    display: block; }

.popover.share-popover {
  border-radius: 0;
  background-color: transparent;
  border: none;
  max-width: 30rem;
  z-index: 980;
  position: absolute;
  right: 1rem !important;
  top: 0 !important;
  left: unset !important;
  transform: none !important; }
  .popover.share-popover .popover-arrow {
    margin: 0 4.5rem 0 auto;
    width: 0;
    height: 0;
    border-left: .5rem solid transparent;
    border-right: .5rem solid transparent;
    border-bottom: 0.5rem solid #575757; }
  .popover.share-popover .popover-body {
    background-color: #FFFFFF;
    border: 1px solid #aeaeae; }
  .popover.share-popover .desktop-share-menu {
    display: block !important; }
    .popover.share-popover .desktop-share-menu ul {
      list-style: none;
      padding: 0;
      margin: 0 !important;
      font-size: 1.1rem;
      line-height: 2rem; }
      .popover.share-popover .desktop-share-menu ul .ra-icon {
        font-size: 2rem;
        margin-right: 1rem; }
      .popover.share-popover .desktop-share-menu ul li {
        padding: .5rem;
        border-bottom: 1px solid #aeaeae; }
        .popover.share-popover .desktop-share-menu ul li a {
          display: flex;
          flex-direction: row;
          align-items: center; }
        .popover.share-popover .desktop-share-menu ul li:last-child {
          border: none; }
  .popover.share-popover .qrcode-share-menu .qr-header {
    font-size: 1.4rem;
    font-weight: bold;
    padding: .75rem; }
  .popover.share-popover .qrcode-share-menu .qr-footer {
    font-size: 1.2rem;
    line-height: 1.2rem;
    padding: .75rem; }

.tooltip-rss {
  top: auto !important;
  transform: none !important;
  right: 0;
  position: absolute;
  z-index: 9999;
  background-color: #FFFFFF;
  color: #333333;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-weight: 700; }
  .tooltip-rss .tooltip-title {
    font-size: 1.6rem;
    margin-left: 0.5rem; }
  .tooltip-rss .tooltip-rss-inner {
    font-size: 1.4rem;
    border: 1px solid #aeaeae;
    padding: 1rem;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .tooltip-rss .rss-icon {
    font-size: 36px;
    margin: 0.5rem; }
  .tooltip-rss .rss-icon-text {
    font-weight: 100;
    text-align: left;
    margin-left: 1.5rem; }
  .tooltip-rss .rss-buttons {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 1.6rem; }
    .tooltip-rss .rss-buttons span {
      align-self: center; }
    .tooltip-rss .rss-buttons i {
      align-self: center;
      font-size: 3rem; }
  .tooltip-rss .rss-inner-button {
    padding: .5rem;
    display: flex;
    cursor: pointer; }
  .tooltip-rss .rss-line {
    border-bottom: 1px solid #e3e3e3; }
  .tooltip-rss .rss-text {
    align-items: inherit;
    display: flex; }
  .tooltip-rss .tooltip-arrow {
    margin: 0 auto 0 30rem; }

.tooltip-opener {
  cursor: pointer; }

.details-contact {
  margin-top: 2rem;
  min-height: 8rem; }
  .details-contact span {
    color: #575757;
    font-weight: bold; }
    .details-contact span.ContactAsTeam {
      font-size: 1.6rem; }
  .details-contact .fullwidth {
    width: 100%;
    padding-left: 0;
    padding-right: 0; }
    .details-contact .fullwidth img, .details-contact .fullwidth .div-inquiry {
      border-radius: 0 !important; }
  .details-contact a {
    color: #005296; }
  .details-contact div.ContactAsTeam {
    margin: 0.5rem auto;
    display: inline-block; }
    .details-contact div.ContactAsTeam.img-box:not(.fullwidth) {
      width: 33%; }
    .details-contact div.ContactAsTeam a {
      font-size: 1.6rem;
      font-weight: bold;
      color: #575757; }
  .details-contact .contactquestion {
    font-size: 8rem;
    float: right;
    color: #e3e3e3; }
  .details-contact .use-image {
    padding: 0; }
    .details-contact .use-image #inquiryContact .info-text {
      font-size: 1.2rem; }
    .details-contact .use-image #inquiryContact .contact-data {
      margin-top: 1rem; }
      .details-contact .use-image #inquiryContact .contact-data a {
        margin-bottom: .25rem; }
        .details-contact .use-image #inquiryContact .contact-data a i {
          color: #575757; }
      .details-contact .use-image #inquiryContact .contact-data img {
        border-radius: 50%; }
      .details-contact .use-image #inquiryContact .contact-data .div-inquiry {
        margin-top: .5rem;
        border-radius: 50%;
        border: 1px solid #aeaeae;
        overflow: hidden;
        z-index: 5;
        display: inline-block; }
        .details-contact .use-image #inquiryContact .contact-data .div-inquiry img {
          transform: scale(1);
          transition: all .4s linear; }
          .details-contact .use-image #inquiryContact .contact-data .div-inquiry img:hover {
            transform: scale(1.1);
            transition: all .4s linear; }
      .details-contact .use-image #inquiryContact .contact-data .contact-name {
        font-weight: bold; }
        .details-contact .use-image #inquiryContact .contact-data .contact-name.normalText {
          font-weight: normal;
          margin-top: 1rem; }
        .details-contact .use-image #inquiryContact .contact-data .contact-name.boldTextWithMargin {
          margin-top: 1rem; }
    .details-contact .use-image #inquiryContact #ra-details-question-button {
      margin: 1rem 0;
      height: 3rem;
      font-size: 1.2rem; }
      .details-contact .use-image #inquiryContact #ra-details-question-button i {
        font-size: 2rem;
        line-height: 2rem;
        margin-right: .5rem;
        vertical-align: middle; }
      .details-contact .use-image #inquiryContact #ra-details-question-button.normalTextLink {
        font-size: 14px; }

#inquiryModal .modal-header {
  flex-direction: column; }
  #inquiryModal .modal-header button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 2.3rem;
    cursor: pointer; }
    #inquiryModal .modal-header button:hover, #inquiryModal .modal-header button:focus, #inquiryModal .modal-header button:active {
      outline: none; }

#inquiryModal #attachment {
  border: 1px solid #E5E2DB;
  padding: 5px 9px 11px 11px; }
  #inquiryModal #attachment legend {
    width: auto;
    font-size: 1.25rem; }

#inquiryModal .leasing-desc {
  padding-bottom: 1rem; }

#inquiryModal .loading-overlay {
  display: none;
  align-items: center; }
  #inquiryModal .loading-overlay .loading-overlay-inner {
    position: absolute;
    background-color: #575757;
    opacity: .3;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 999; }
  #inquiryModal .loading-overlay .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -1.3rem;
    z-index: 9999; }
    #inquiryModal .loading-overlay .spinner div {
      background-color: #000000; }
  #inquiryModal .loading-overlay.show {
    display: flex; }

#inquiryModal .inquiry-success-msg {
  margin: 3rem 0; }

#inquiryModal .leasing-desc-small {
  font-size: 1.1rem; }
  #inquiryModal .leasing-desc-small p {
    margin: 0; }

@media (max-width: 991.98px) {
  #inquiryModal .leasing-spacing-td {
    width: 70%; } }

@media (max-width: 991.98px) {
  #inquiryModal .leasing-optional-last-leasing-rate-desc {
    font-size: 1rem; } }

#inquiryModal #inquiryModalLabel {
  padding-top: .5rem; }

#inquiryModal .ra-inquiry-modal-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 999;
  -webkit-backdrop-filter: blur(1.25px);
          backdrop-filter: blur(1.25px);
  display: flex;
  justify-content: center; }
  #inquiryModal .ra-inquiry-modal-blur .ra-inquiry-leasing-info {
    display: flex;
    align-self: center;
    background-color: #FFFFFF;
    padding: 3rem;
    border: 1px solid #aeaeae;
    box-shadow: 0 0 5px rgba(116, 116, 116, 0.7);
    max-width: 450px; }
    @media (max-width: 1199.98px) {
      #inquiryModal .ra-inquiry-modal-blur .ra-inquiry-leasing-info {
        align-self: start; } }
  #inquiryModal .ra-inquiry-modal-blur.hide {
    display: none; }

#inquiryModal .modal-footer {
  display: block;
  border: none;
  position: relative; }
  #inquiryModal .modal-footer .footer-col {
    padding: 0 2rem 2rem 2rem; }
    #inquiryModal .modal-footer .footer-col .sendCopy {
      margin-bottom: 1rem; }

#inquiryModal a.highlight {
  color: #00569d; }

#inquiryModal .modal-info {
  display: block;
  width: 100%;
  float: left; }

#inquiryModal .article-and-contact-row {
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 1rem; }
  #inquiryModal .article-and-contact-row .article-info {
    margin-bottom: .5rem; }
    #inquiryModal .article-and-contact-row .article-info img {
      margin-top: .5rem; }
    #inquiryModal .article-and-contact-row .article-info span {
      display: block;
      width: 100%; }
      #inquiryModal .article-and-contact-row .article-info span.title {
        font-weight: bold; }
  #inquiryModal .article-and-contact-row .contact-info {
    margin-top: 0; }

#inquiryModal .contact-info {
  margin-bottom: .5rem;
  margin-top: 2rem; }
  #inquiryModal .contact-info .headline {
    font-weight: bold; }
  #inquiryModal .contact-info a {
    color: #00569d; }
  #inquiryModal .contact-info .contactquestion {
    font-size: 8rem;
    float: right;
    color: #e3e3e3; }
  #inquiryModal .contact-info img {
    border-radius: 50%;
    margin-top: .5rem;
    border: 1px solid #aeaeae; }

#inquiryModal .scaleprice-info {
  font-size: 1.2rem;
  margin-top: -1rem;
  margin-bottom: 3rem; }
  #inquiryModal .scaleprice-info i {
    font-size: 2rem; }

#inquiryModal .security-info {
  font-size: 1.2rem;
  margin-bottom: 0; }

.notOnStock {
  margin: 1rem 0;
  color: #dc371c;
  font-weight: bold; }

#sidebar.with-header {
  animation: animate-to-header-margin .4s forwards; }

#sidebar.margin-header {
  margin-top: 2rem; }

#sidebar.with-header-out {
  animation: animate-from-header-margin .4s forwards; }

@media (max-width: 1199.98px) {
  #sidebar {
    overflow-y: scroll;
    height: 100vh; } }

@media (max-width: 767.98px) {
  #sidebar {
    overflow-y: hidden;
    height: 0; } }

#sidebar .categorytree {
  margin-top: 1rem; }
  #sidebar .categorytree ol.adviserSteps, #sidebar .categorytree ol.moreAdvisers {
    list-style: none;
    padding: 0;
    margin: 0 0 3rem 0; }
  #sidebar .categorytree ol.cat-tree > li.active > a, #sidebar .categorytree ol.cat-tree > li.exp > a {
    font-weight: bold;
    font-size: 1.2em; }
    #sidebar .categorytree ol.cat-tree > li.active > a.rootCat, #sidebar .categorytree ol.cat-tree > li.exp > a.rootCat {
      line-height: 1.8rem; }
  #sidebar .categorytree ol.cat-tree ul.nav li.exp > a {
    font-weight: normal;
    font-size: inherit; }
  #sidebar .categorytree ol.cat-tree ul.nav li ul li {
    padding: 0 0 0 16px; }
  #sidebar .categorytree ol.cat-tree .nav {
    display: block; }
    #sidebar .categorytree ol.cat-tree .nav li {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-start;
      align-content: center; }
      #sidebar .categorytree ol.cat-tree .nav li a {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: stretch;
        align-content: center;
        line-height: 1.5rem;
        height: 3.5rem;
        width: 100%; }
        #sidebar .categorytree ol.cat-tree .nav li a .row {
          margin: 0 !important; }
      #sidebar .categorytree ol.cat-tree .nav li ul {
        display: block;
        width: 100%; }
      #sidebar .categorytree ol.cat-tree .nav li.active > a {
        font-weight: bold; }
  #sidebar .categorytree .cat-text span, #sidebar .categorytree .cat-icon span {
    display: flex;
    align-items: flex-start;
    height: 100%; }
    #sidebar .categorytree .cat-text span svg, #sidebar .categorytree .cat-icon span svg {
      max-height: 100%; }
  #sidebar .categorytree .cat-icon {
    padding: 0; }

#sidebar #categories ul, #sidebar #categories ol, #sidebar #categories-found ul, #sidebar #categories-found ol {
  list-style: none;
  padding-left: 10px;
  margin-bottom: 30px; }
  #sidebar #categories ul:first-child, #sidebar #categories ol:first-child, #sidebar #categories-found ul:first-child, #sidebar #categories-found ol:first-child {
    padding: 0; }
  #sidebar #categories ul li, #sidebar #categories ol li, #sidebar #categories-found ul li, #sidebar #categories-found ol li {
    white-space: normal;
    font-weight: normal;
    font-size: 14px; }
    #sidebar #categories ul li.selected, #sidebar #categories ol li.selected, #sidebar #categories-found ul li.selected, #sidebar #categories-found ol li.selected {
      font-weight: bold; }
    #sidebar #categories ul li.active.lvl1, #sidebar #categories ol li.active.lvl1, #sidebar #categories-found ul li.active.lvl1, #sidebar #categories-found ol li.active.lvl1 {
      font-size: 1.2em; }
    #sidebar #categories ul li .item-count, #sidebar #categories ol li .item-count, #sidebar #categories-found ul li .item-count, #sidebar #categories-found ol li .item-count {
      font-size: 1.1rem;
      opacity: .6;
      background-color: #e3e3e3;
      color: #000000;
      margin-left: .5rem;
      padding: 0 .5rem;
      border-radius: .5rem; }

#sidebar .boxtitle {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 8px; }

@keyframes animate-to-header-margin {
  0% {
    top: 0rem; }
  100% {
    top: 15rem; } }

@keyframes animate-from-header-margin {
  0% {
    top: 15rem; }
  100% {
    top: 0rem; } }

#countrySelectionModal .countryselection-flag {
  margin-right: 1rem; }

#countrySelectionModal .loading-box {
  text-align: center;
  min-height: 8rem;
  padding-top: 2rem; }
  #countrySelectionModal .loading-box .ra-icon.reload {
    font-size: 5rem;
    animation: animate-menu-loading .75s ease-in-out infinite; }

#jumptotop {
  position: fixed;
  z-index: 10000;
  right: -15rem;
  bottom: 4rem;
  cursor: pointer;
  background-color: rgba(87, 87, 87, 0.5);
  width: 20rem;
  display: none; }
  #jumptotop .icon {
    font-size: 3rem;
    line-height: 3rem;
    vertical-align: middle;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    padding: .5rem 0; }
  #jumptotop .text {
    font-size: 1.4rem;
    line-height: 1.4rem;
    vertical-align: middle;
    color: #FFFFFF;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: .5rem 0; }
  #jumptotop:hover {
    transform: translateX(-15rem);
    transition: all .5s linear; }
  #jumptotop.show {
    display: flex; }

.security-info {
  margin-bottom: 2rem; }

#inquiryCountryChoose button, #inquiryCountryChoose .bootstrap-select {
  width: 100%; }

.cl-forgotpwd #content form {
  background: #f6f6f6;
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 2rem; }

.text-bold {
  font-weight: bold; }

.text-italic {
  font-style: italic; }

.margin-bottom {
  margin-bottom: 1rem; }

.btn.dropdown-toggle:focus, .dropdown-item, .form-control:focus {
  outline: none !important;
  box-shadow: none;
  border-color: #aeaeae; }

.table.table-hover tr:hover {
  background-color: #f6f6f6; }

@media (max-width: 767.98px) {
  body.cl-details #breadcrumb {
    margin-bottom: 0 !important; }
  body.cl-details .main-container, body.cl-details .page, body.cl-details .main-image-box, body.cl-details .content-box {
    padding: 0; } }

#details_container .details-sidebar .price {
  color: #00569d; }

#details_container .tabs-outer .tabs-bar ul li a.active {
  border-bottom: 3px solid #00569d; }

#details_container .star-ratings .review-link {
  color: #00569d; }

#ra-whatsapp-badge {
  z-index: 999;
  position: fixed;
  left: 2rem;
  bottom: 3rem;
  max-height: 5rem;
  box-sizing: border-box;
  transition: opacity 0.5s ease 0s; }
  #ra-whatsapp-badge .ra-whatsapp-badge-inner {
    display: flex;
    margin-top: 1.4rem;
    position: relative;
    justify-content: flex-start; }
    #ra-whatsapp-badge .ra-whatsapp-badge-inner a.ra-whatsapp-badge-icon {
      width: 5rem;
      height: 5rem;
      order: 1;
      padding: .5rem;
      box-sizing: border-box;
      border-radius: 50%;
      cursor: pointer;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.4) 0.2rem 0.2rem 0.6rem;
      transition: all 0.5s ease 0s;
      position: relative;
      display: block;
      border: 0;
      background: #4dc247 !important;
      font-size: 4rem;
      text-align: center;
      line-height: 4rem;
      color: #FFFFFF; }
    #ra-whatsapp-badge .ra-whatsapp-badge-inner .ra-whatsapp-badge-arrow {
      display: flex;
      order: 2;
      margin: auto 0 auto 1rem;
      width: 0;
      height: 0;
      border-top: .5rem solid transparent;
      border-bottom: .5rem solid transparent;
      border-right: 0.5rem solid #575757; }
    #ra-whatsapp-badge .ra-whatsapp-badge-inner a.ra-whatsapp-badge-text {
      transform: translateX(0px);
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.1) 0.2rem 0.2rem 1.3rem;
      border: 1px solid #aeaeae;
      background: #FFFFFF;
      cursor: pointer;
      margin: auto;
      max-width: 15rem;
      max-height: 4.5rem;
      display: flex;
      order: 3;
      text-decoration: none !important; }
      #ra-whatsapp-badge .ra-whatsapp-badge-inner a.ra-whatsapp-badge-text:hover {
        text-decoration: none;
        color: #000000; }
      #ra-whatsapp-badge .ra-whatsapp-badge-inner a.ra-whatsapp-badge-text div {
        text-align: center;
        padding: 1rem;
        line-height: 1.4rem;
        font-size: 1.4rem;
        margin: auto; }

@media print {
  body.cl-rabfccontroller {
    padding-top: 0 !important; }
    body.cl-rabfccontroller h1 {
      display: none; }
    body.cl-rabfccontroller .info .row {
      page-break-inside: avoid; }
    body.cl-rabfccontroller .info b {
      margin-right: 1rem; }
    body.cl-rabfccontroller #ra-bfc-assembly-clockwise {
      page-break-inside: avoid !important; }
    body.cl-rabfccontroller #bfc-app {
      display: block; }
    body.cl-rabfccontroller #bfc-display, body.cl-rabfccontroller header, body.cl-rabfccontroller #header-banner, body.cl-rabfccontroller footer, body.cl-rabfccontroller .mobile-menu-app, body.cl-rabfccontroller #cookie-modal, body.cl-rabfccontroller #bfc-sidebar-menu, body.cl-rabfccontroller #bfc-mini-menu, body.cl-rabfccontroller #bfc-total-weight, body.cl-rabfccontroller .hide-print, body.cl-rabfccontroller #contact-box, body.cl-rabfccontroller #contact-box-btn, body.cl-rabfccontroller .contact-box-background, body.cl-rabfccontroller .contact-box-background.show, body.cl-rabfccontroller .information-box, body.cl-rabfccontroller #jumptotop, body.cl-rabfccontroller iframe, body.cl-rabfccontroller div[id^="button--userlike-"] {
      display: none !important; }
    body.cl-rabfccontroller #bfc-print-display {
      display: block !important; }
    body.cl-rabfccontroller #bfc-article-list {
      page-break-inside: auto; }
    body.cl-rabfccontroller .page-break {
      page-break-after: always !important; }
    body.cl-rabfccontroller #bfc-display-canvas {
      border: none !important; }
      body.cl-rabfccontroller #bfc-display-canvas.rotate {
        transform: rotate(90deg); }
    body.cl-rabfccontroller .article-row {
      page-break-inside: avoid; }
    body.cl-rabfccontroller .print-only {
      display: block !important; }
      body.cl-rabfccontroller .print-only #bfc-print-img {
        page-break-before: always;
        position: relative;
        display: block; }
        body.cl-rabfccontroller .print-only #bfc-print-img.rotate {
          transform: rotate(90deg); } }

.ra-theme-switcher {
  height: 3.5rem;
  line-height: 3.5rem;
  vertical-align: middle;
  display: none;
  align-items: center;
  cursor: pointer; }
  .ra-theme-switcher.header {
    border-top: 1px solid #aeaeae;
    margin: .5rem 0;
    padding-top: .5rem; }
  .ra-theme-switcher.show {
    display: flex; }
  .ra-theme-switcher input {
    opacity: 0;
    width: 0;
    height: 0; }
  .ra-theme-switcher .ra-theme-switcher-text {
    margin-lefT: 1rem; }
  .ra-theme-switcher .ra-theme-switcher-element {
    width: 4rem;
    height: 2rem;
    background-color: #e3e3e3;
    border-radius: 1rem;
    border: 1px solid #aeaeae;
    position: relative; }
    .ra-theme-switcher .ra-theme-switcher-element:before {
      position: absolute;
      content: "";
      height: 1.5rem;
      width: 1.5rem;
      left: .2rem;
      top: 50%;
      transform: translateY(-50%);
      background-color: #FFFFFF;
      transition: .4s;
      border-radius: 50%; }
  .ra-theme-switcher input:checked + .ra-theme-switcher-element {
    background-color: #00569d; }
  .ra-theme-switcher input:checked + .ra-theme-switcher-element:before {
    transform: translate(1.9rem, -50%); }

.fade-gradient::after {
  box-shadow: inset 0px 600px 400px -500px black;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  pointer-events: none;
  z-index: 2; }

.fullWidthSlider.fullWidthSlider-image-zoom {
  animation: zoom 3s;
  transform: scale(1.02);
  -ms-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -webkit-transform: scale(1.02);
  -o-transform: scale(1.02); }

@keyframes zoom {
  from {
    transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1); }
  to {
    transform: scale(1.02);
    -ms-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -o-transform: scale(1.02); } }

.category-banner-headline.size-50-percent {
  font-size: 1.2rem !important; }

.category-banner-headline.size-75-percent {
  font-size: 1.8rem !important; }

.category-banner-headline.size-125-percent {
  font-size: 3rem !important; }

.category-banner-headline.size-150-percent {
  font-size: 3.6rem !important; }

.category-banner-text.size-50-percent {
  font-size: 0.8rem !important; }

.category-banner-text.size-75-percent {
  font-size: 1.2rem !important; }

.category-banner-text.size-125-percent {
  font-size: 2rem !important; }

.category-banner-text.size-150-percent {
  font-size: 2.4rem !important; }

.header-banner-text.size-50-percent {
  font-size: 50%; }

.header-banner-text.size-75-percent {
  font-size: 75%; }

.header-banner-text.size-125-percent {
  font-size: 125%; }

.header-banner-text.size-150-percent {
  font-size: 150%; }

@media (max-width: 991.98px) {
  #header .header-box .logo-col {
    text-align: center; } }

@media (max-width: 767.98px) {
  #header .header-box .search-col {
    margin-top: 1rem; } }

@media (max-width: 767.98px) {
  #header #mainnav #navigation-outer li a {
    padding: .5rem 1rem;
    border-bottom: 1px solid #e3e3e3;
    display: block;
    width: 100%;
    transition: .3s all; }
    #header #mainnav #navigation-outer li a:hover {
      border-bottom: 1px solid #747474; } }

@media (min-width: 768px) {
  #header #mainnav #navigation-outer {
    padding-left: 1.5rem;
    padding-right: 1.5rem; } }

@media (max-width: 767.98px) {
  #footer {
    text-align: center; }
    #footer table {
      margin: 0; }
    #footer h4 {
      margin-top: 1rem; }
    #footer p {
      margin-bottom: 0; } }

#searchFilter {
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  #searchFilter .filterHeader {
    display: none; }
  @media (max-width: 767.98px) {
    #searchFilter {
      position: fixed;
      top: 0;
      left: -85%;
      width: 85%;
      height: 100%;
      background-color: #FFFFFF;
      z-index: 999;
      transition: left .2s linear;
      overflow-y: scroll; }
      #searchFilter .filterHeader {
        display: block;
        position: sticky;
        top: 0;
        z-index: 1000;
        padding: 1rem; }
        #searchFilter .filterHeader i {
          float: right; }
        #searchFilter .filterHeader span {
          font-weight: bold; }
      #searchFilter .selected-filters {
        display: none;
        margin-top: 0 !important; }
      #searchFilter.btn {
        float: left; }
      #searchFilter.show {
        left: 0%; } }

#mobileFilterBtnFixed {
  z-index: 2;
  position: fixed;
  right: 5%;
  top: -10%; }
  #mobileFilterBtnFixed.no-header {
    animation: animate-from-fixed-btn .4s forwards; }
  #mobileFilterBtnFixed.header {
    animation: animate-to-fixed-btn .4s forwards; }
  #mobileFilterBtnFixed.out-window {
    animation: animate-out-of-window .4s forwards; }
  #mobileFilterBtnFixed.out-window-small {
    animation: animate-out-of-window-small .4s forwards; }
  #mobileFilterBtnFixed.in-window {
    animation: animate-in-window .4s forwards; }
  #mobileFilterBtnFixed .btn {
    box-shadow: 0 5px 10px #575757 !important;
    border-radius: 10px; }

#mobileFilterBtn, #mobileFilterBtnFixed {
  display: none; }
  #mobileFilterBtn .filter-btn, #mobileFilterBtnFixed .filter-btn {
    position: relative; }
  @media (max-width: 767.98px) {
    #mobileFilterBtn, #mobileFilterBtnFixed {
      margin-top: 1.5rem;
      display: block; }
      #mobileFilterBtn i, #mobileFilterBtnFixed i {
        position: relative;
        top: 0.3rem; } }
  #mobileFilterBtn .filter-indicator, #mobileFilterBtnFixed .filter-indicator {
    background-color: #FFFFFF;
    color: #575757;
    height: 2rem;
    min-width: 2rem;
    padding: 0 .5rem;
    line-height: 2rem;
    border-radius: .5rem;
    text-align: center;
    display: inline-block;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%); }
    #mobileFilterBtn .filter-indicator i, #mobileFilterBtnFixed .filter-indicator i {
      font-size: 1rem;
      line-height: 1;
      vertical-align: middle;
      position: relative !important;
      top: unset !important;
      right: unset !important;
      transform: none !important; }

#mobileFilter {
  display: none; }
  @media (max-width: 767.98px) {
    #mobileFilter {
      display: block;
      margin-top: 0 !important; }
      #mobileFilter .selected-filters {
        margin: 0; } }

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; }

@media (max-width: 767.98px) {
  .custom-result-content {
    margin: 0 !important; } }

@keyframes animate-to-fixed-btn {
  0% {
    margin-top: 0;
    top: .5rem; }
  100% {
    top: .5rem; } }

@keyframes animate-from-fixed-btn {
  0% {
    top: .5rem; }
  100% {
    margin-top: 0;
    top: .5rem; } }

@keyframes animate-out-of-window {
  0% {
    top: 0; }
  100% {
    margin-top: 0rem; } }

@keyframes animate-in-window {
  0% {
    margin-top: 0rem; }
  100% {
    margin-top: .5rem;
    top: 0%; } }

@keyframes animate-out-of-window-small {
  0% {
    top: 0;
    margin-top: .5rem; }
  100% {
    margin-top: 0rem; } }

.uc-embedding-container .uc-embedding-wrapper {
  border-radius: 0px;
  font-family: Open Sans, Tahoma, Verdana, Arial;
  font-size: 12px;
  color: #575757; }
  .uc-embedding-container .uc-embedding-wrapper h3 {
    font-size: 16px;
    color: #575757;
    font-weight: 700; }

.uc-embedding-container .uc-embedding-buttons {
  border-radius: 0;
  font-size: 1.4rem;
  min-height: 4rem;
  font-weight: 700;
  line-height: 3rem; }
  .uc-embedding-container .uc-embedding-buttons .uc-embedding-accept {
    color: #fff !important;
    background-color: #008297;
    border-color: #006c7e;
    box-shadow: none !important; }
  .uc-embedding-container .uc-embedding-buttons .uc-embedding-more-info {
    color: #575757 !important;
    background-color: #e3e3e3;
    border-color: #aeaeae;
    box-shadow: none !important; }

.ra-consent-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }
  .ra-consent-box .ra-consent-box-inner {
    position: absolute;
    left: 10%;
    top: 10%;
    width: 80%;
    height: 80%;
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 2px #000000;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .ra-consent-box .ra-consent-box-inner h2 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin: 0;
      padding: 0; }
    @media (max-width: 767.98px) {
      .ra-consent-box .ra-consent-box-inner .ra-consent-box-text {
        font-size: 10px; } }
    .ra-consent-box .ra-consent-box-inner .ra-consent-box-btn-box {
      margin-top: 10px; }
      .ra-consent-box .ra-consent-box-inner .ra-consent-box-btn-box .btn {
        margin: 0 5px;
        min-height: 4rem;
        line-height: 3rem; }

.ie-warning {
  display: none; }

.disable-visual-search, img {
  pointer-events: none; }

.cursor-pointer {
  cursor: pointer; }
